import styled from 'styled-components';

import { Text } from '../../../';

export const CollapseLabel = styled(Text)`
  && {
    margin: 0 3px;
    font-size: 14px;
    letter-spacing: 0.4px;
    scale: 0.9;
    width: max-content;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: 'rgb(10, 170, 255)';
    }

    svg {
      transition: all .1s ease;
      transform: ${({ hasDescription }) => !hasDescription ? 'rotate(-90deg)' : 'none'};
    }
  }
`;
