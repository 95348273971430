import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';

import { createPortal } from 'react-dom';

import { analytics } from '../../../..';

import {
  AppContext,
  ColorContext,
  DataContext,
  MapContext,
} from '../../../../contexts';

import {
  Dialog,
  Abas,
  Aba,
} from '../../..';

import useStorage from '../../../../hooks/useStorage';

import WindowStorage from '../../../../utils/storage';

import AbaColorirResultadoFiltro from './components/AbaColorirResultadoFiltro';
import AbaColorirRegioes from './components/AbaColorirRegioes';

import { LegendaAuxiliarCores } from './components/RegrasDeCores';

import { AbaWrapper } from './styles';

const STORE_INICIAL = { data: new Date() };
const storageDialogoCores = WindowStorage.localStorage('dialogo-cores');

function DialogoCores() {
  const {
    activeFilters,
    setAuxiliarBox,
    setOptions,
  } = useContext(AppContext);

  const {
    metodoColoracao,
    calcularGradienteDoValor,
    isDialogoCoresVisivel,
    setMetodoColoracao,
    setDialogoCoresVisivel,
  } = useContext(ColorContext);

  const {
    getResult,
  } = useContext(DataContext);

  const {
    visibleCities,
    setShowColors,
  } = useContext(MapContext);

  const dispatchStoreDialogoCores = useStorage(storageDialogoCores, STORE_INICIAL);

  const dadosFiltro = useMemo(() => getResult(), [getResult]);

  const [aba, setAba] = useState('aba-regioes');

  useEffect(() => {
    setAba(() => {
      if (metodoColoracao.tipo === 'por-regiao') return 'aba-regioes';
      return 'aba-filtros';
    });
  }, [metodoColoracao.tipo]);

  const refDialogoCores = useRef(null);

  const criarPortalActions = useCallback((node) => (
    refDialogoCores.current
      ? createPortal(
        node,
        refDialogoCores.current.querySelector('.MuiDialogActions-root')
      )
      : null
  ), []);

  const fechar = useCallback(() => setDialogoCoresVisivel(false), [setDialogoCoresVisivel]);

  const calcularIntervaloGradiente = useCallback(() => {
    const resultadosVisiveis = Object.entries(dadosFiltro.result)
      .reduce((total, [key, value]) => {
        if (visibleCities.length === 0 || visibleCities.includes(key)) {
          return { ...total, [key]: value };
        }
        return total;
      }, {});

    // gera Array de valores distintos e organiza
    const valoresDistintos = Array.from(
      new Set(Object.values(resultadosVisiveis))
    ).sort((a, b) => a - b);

    const posicoes = [0, 0.10, 0.25, 0.50, 0.75, 0.9, 1]
      .map(p => (
        p !== 1
          ? Math.floor(valoresDistintos.length * p)
          : valoresDistintos.length - 1
      ));

    const intervalo = Array.from(new Set(posicoes)) // garante que as posições sao unicas
      .map(posicao => ({
        valor: valoresDistintos.at(posicao),
        cor: calcularGradienteDoValor(valoresDistintos.at(posicao))
      }));

    return intervalo;
  }, [calcularGradienteDoValor, dadosFiltro.result, visibleCities]);

  const getLegendaAuxiliar = useCallback(() => {
    switch (metodoColoracao.tipo) {
      case 'gradiente':
        return (
          <LegendaAuxiliarCores
            regrasCor={calcularIntervaloGradiente()}
            renderizar={(regra) => Number(regra.valor).toLocaleString('pt-BR')}
          />
        );
      case 'escala-cores':
        return (
          <LegendaAuxiliarCores
            regrasCor={metodoColoracao.regras}
            renderizar={(regra) => (
              <>
                {Number(regra.de).toLocaleString('pt-BR')}
                {' - '}
                {
                  !(regra.ate === Number.MAX_VALUE) && !Number.isNaN(regra.ate)
                    ? regra.ate.toLocaleString('pt-BR')
                    : '...'
                }
              </>
            )}
          />
        );
      case 'por-filtro':
        return (
          <LegendaAuxiliarCores
            regrasCor={metodoColoracao.regras}
            renderizar={(regra) => (
              <>
                {regra.item}
                {
                  regra.intervalo && (
                    <>
                      <br />
                      {'De '}
                      {Number(regra.intervalo.de).toLocaleString('pt-BR')}
                      {' a '}
                      {
                        (
                          !Number.isNaN(regra.intervalo.ate)
                          && !regra.intervalo.ate === Number.MAX_VALUE
                            ? regra.intervalo.ate.toLocaleString('pt-BR')
                            : '...'
                        )
                      }
                    </>
                  )
                }
              </>
            )}
          />
        );
      default: return <></>;
    }
  }, [calcularIntervaloGradiente, metodoColoracao.tipo, metodoColoracao.regras]);

  const aplicarLegenda = useCallback(() => {
    if (dadosFiltro.settings) {
      return setAuxiliarBox({
        state: 'visible',
        title: 'Legenda',
        description: dadosFiltro.settings.title || [],
        content: getLegendaAuxiliar(),
      });
    }

    if (Object.values(dadosFiltro).reduce((n, lista) => n + lista.length, 0) > 1) {
      return setAuxiliarBox({
        state: 'visible',
        title: 'Legenda',
        description: ['Regras do Filtro'],
        content: getLegendaAuxiliar(),
      });
    }

    return setAuxiliarBox({
      state: 'hidden',
      title: 'Legenda',
      description: [],
      content: <></>,
    });
  }, [dadosFiltro, getLegendaAuxiliar, setAuxiliarBox]);

  const onAplicar = useCallback((novoModo, fecharDialogo = true) => {
    setMetodoColoracao(() => {
      setDialogoCoresVisivel(!fecharDialogo);
      setOptions(options => ({ ...options, showColors: true }));
      setShowColors(true);
      dispatchStoreDialogoCores({ tipo: 'ALTERA_E_PERSISTE', metodoColoracao: novoModo });
      analytics.logEvent('dialog-cores-aplicar-coloracao');
      return novoModo;
    });
  }, [
    dispatchStoreDialogoCores,
    setDialogoCoresVisivel,
    setOptions,
    setMetodoColoracao,
    setShowColors,
  ]);

  useEffect(() => aplicarLegenda(), [aplicarLegenda]);

  return (
    <Dialog
      ref={refDialogoCores}
      disableBackdropClick
      title="Cores"
      show={isDialogoCoresVisivel}
      onClose={fechar}
    >
      <Abas
        value={aba}
        onChange={(_, aba) => setAba(aba)}
        contexto={{
          criarPortalActions,
          dispatchStoreDialogoCores,
          onAplicar,
          refDialogoCores,
        }}
        renderizarAbasEm={<AbaWrapper />}
      >
        <Aba
          value="aba-regioes"
          label="Regiões"
          disabled={activeFilters.length > 0}
          componente={<AbaColorirRegioes />}
        />
        <Aba
          value="aba-filtros"
          label="Filtros"
          disabled={activeFilters.length === 0}
          componente={<AbaColorirResultadoFiltro />}
        />
      </Abas>
    </Dialog>
  );
}

export default memo(DialogoCores);
