import React, {
  memo,
  useCallback,
  useState,
} from 'react';

import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DividerTitle } from '../../../../../../..';

import { ExpandButton } from './styles';

function CollapseDivider({ children, label }) {
  const [expanded, setExpanded] = useState(false);

  const toggleCollapse = useCallback(() => setExpanded(old => !old), []);

  return (
    <>
      <DividerTitle
        title={(
          <>
            {label}
            <ExpandButton onClick={toggleCollapse}>
              <ExpandMoreIcon className={expanded ? 'upward' : 'downward'} />
            </ExpandButton>
          </>
        )}
        color="#000"
        textStyle={{ display: 'flex', justifyContent: 'space-between' }}
      />
      <Collapse in={expanded}>
        {children}
      </Collapse>
    </>
  );
}

export default memo(CollapseDivider);
