/**
 * Verifica se o item é filtro de pessoas.
 * @param {Filter} item Item de filtro
 */
export function isPeopleFilter(item) {
  return (
    item.settings.subject === 'pessoas' // filtro 'simples' de pessoas
    || item.settings.subType === 'pessoas' // filtro 'composto' de pessoas
  );
}

/**
 * Verifica se o item é filtro de eventos.
 * @param {Filter} item Item de filtro
 */
export function isEventsFilter(item) {
  return (
    item.settings.subject === 'eventos'
  );
}

/**
 * Verifica se o item é filtro político.
 * @param {Filter} item Item de filtro
 */
export function isPoliticFilter(item) {
  return (
    item.settings.subject === 'politico'
  );
}

/**
 * Verifica se o item é filtro pessoas.
 * @param {Filter} item Item de filtro
 */
export function isAmendmentsFilter(item) {
  return (
    item.settings.subject === 'emendas'
  );
}
