import React, {
  useCallback,
  useRef,
  useEffect,
  useContext,
  useState,
  memo,
} from 'react';

import ViewingTransformer from './ViewingTransformer';
import SVGStyle from './components/SVGStyle';
import Tip from './components/Tip';

import { estados } from './assets/mapas';

import {
  MapContext,
  MapEventsContext,
} from '../../../../contexts';

import DialogCity from '../DialogCity';
import Hulls from './components/Hulls';
import Labels from './components/Labels';
import SVGDefs from './components/SVGDefs';

import {
  MapContainer,
  ZoomArea,
} from './styles';

const VIEWBOX = {
  width: 420,
  height: 475,
};
function Map() {
  const {
    refMap,
    transformer,
    setMounted,
    currentCity,
    selectCity,
    setActiveCity,
  } = useContext(MapContext);

  const {
    handleEventZoom,
    handleMouseDown,
    handleMouseEnter,
    handleMouseMove,
    handleMouseUp,
    refCursor,
    svgMouseDown,
    svgMouseMove,
    zoomRect,
  } = useContext(MapEventsContext);

  const [selectedCity, setSelectedCity] = useState({ id: -1 });

  useEffect(() => {
    if (currentCity.id !== -1) {
      selectCity(currentCity.id)
        .then((data) => setSelectedCity(data));
    } else {
      setSelectedCity({ id: -1 });
    }
  }, [currentCity.id, selectCity]);

  const refSVGCities = useRef(null);

  /**
   * Essa função carrega os elementos path das cidades no mapa
   * e inicializa o carregamento de eventos e componentes do mapa
   */
  const renderStates = useCallback(() => {
    const svgEstados = Object.values(estados).reduce((total, item) => total + item);
    refSVGCities.current.innerHTML = svgEstados;
  }, [refSVGCities]);

  /**
   * Essa função dispara o carregamento do mapa
   */
  const mountMap = useCallback(() => {
    setMounted(old => ({ ...old, cities: 'loading' }));
    renderStates();
  }, [renderStates, setMounted]);

  useEffect(() => {
    const target = document.querySelector('.mapa');
    transformer.current = new ViewingTransformer(target);
    mountMap();
  }, [mountMap, transformer]);

  return (
    <>
      <MapContainer
        ref={refMap}
        role="presentation"
        id="box"
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        onTouchMove={handleMouseMove}
        onTouchStart={handleMouseDown}
        onWheel={handleEventZoom}
      >
        <Tip ref={refCursor} />
        <svg
          className="mapa"
          viewBox={`0 0 ${VIEWBOX.width} ${VIEWBOX.height}`}
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transformOrigin: '0 0 0',
            width: '100%',
            height: '100%',
          }}
          onMouseDown={svgMouseDown}
          onMouseMove={svgMouseMove}
        >
          <SVGStyle />
          <SVGDefs />
          <g className="cities" ref={refSVGCities} />
          <Hulls />
          <Labels />
        </svg>
        <ZoomArea
          id="zoom-area"
          ref={zoomRect}
          onMouseMove={svgMouseMove}
        />
      </MapContainer>
      <DialogCity
        cityData={selectedCity}
        show={selectedCity.id !== -1}
        onClose={() => setActiveCity(null)}
      />
    </>
  );
};

export default memo(Map);
