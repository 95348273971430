import React, {
  memo,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Collapse from '@material-ui/core/Collapse';

import { analytics } from '../../../../';

import {
  MapViewContext,
} from '../../../../contexts';

import {
  AutoComplete,
  Botao,
  Dialog,
  DividerTitle,
  FlexContainer,
  Form,
  TextField,
} from '../../../';

import { CollapseLabel } from './styles';

/**
 * @typedef {object} SaveViewDialogProps
 * @prop {boolean} isVisible Booleano que indica se o dialogo está vísivel
 * @prop {SetState<boolean>} setVisible SetState para `isVisible`
 */
/**
 * @param {SaveViewDialogProps} props
 * @returns {Dialog} Diálogo usado para salvar visualização do mapa atual
 */
function SaveViewDialog(props) {
  const { isVisible, setVisible } = props;

  const {
    getUsersFromSchema,
    saveView,
    setFeedback,
  } = useContext(MapViewContext);

  /**
   * @typedef {object} ViewSaveOptions
   * @prop {string} name Nome salvo da visualização
   * @prop {string | null} description Descrição opcional da visualização
   * @prop {number[]} sharedWith Conjunto de usuários com quem compartilhar a visualização
   */
  /**
   * Estado de controle do objeto de visualização a ser salvo
   * @type {[ViewSaveOptions, SetState<ViewSaveOptions>]}
   */
  const [view, setView] = useState({
    name: '',
    description: null,
    sharedWith: [],
  });

  /**
   * Memo que monitora se a visualização tem uma descrição
   * @type {boolean}
   */
  const hasDescription = useMemo(() => view.description !== null, [view.description]);

  /**
   * Lista de usuários com quem é possível compartilhar a visualização
   * @type {[UserOption[], SetState<UserOption[]]}
   */
  const [usersToShareWith, setUsersToShareWith] = useState([]);

  useEffect(() => {
    if (isVisible) {
      getUsersFromSchema()
        .then(result => setUsersToShareWith(result))
        .catch(error => console.error(error)); // eslint-disable-line
    }
  }, [getUsersFromSchema, isVisible]);

  /**
   * @callback handleViewNameChangeCallback
   * @param {React.ChangeEvent<HTMLInputElement>} e Objeto de Evento
   * @returns {void}
   *
   * Callback que controla o valor do nome da visualização
   * @type {handleViewNameChangeCallback}
   */
  const handleViewNameChange = useCallback((e) => {
    setView(old => ({
      ...old,
      name: e.target.value,
    }));
  }, []);

  /**
   * @callback handleViewNameChangeCallback
   * @param {React.ChangeEvent<HTMLInputElement>} e Objeto de Evento
   * @returns {void}
   *
   * Callback que controla o valor do nome da visualização
   * @type {handleViewNameChangeCallback}
   */
  const handleViewDescriptionChange = useCallback((e) => {
    setView(old => ({
      ...old,
      description: e.target.value,
    }));
  }, []);

  /**
   * @callback handleViewSharedWithChangeCallback
   * @param {UserOption} option
   * @param {string | undefined} reason
   *
   * @type {handleViewSharedWithChangeCallback}
   */
  const handleViewSharedWithChange = useCallback((option, reason = undefined) => {
    if (reason && reason === 'clear') setView(old => ({ ...old, sharedWith: [] }));
    else if (Array.isArray(option)) setView(old => ({ ...old, sharedWith: option }));
    else {
      setView(old => ({
        ...old,
        sharedWith: old.sharedWith.some((item) => item.id === option.id)
          ? old.sharedWith.filter((item) => item.id !== option.id)
          : [...old.sharedWith, option]
      }));
    }
  }, []);

  /**
   * @callback handleSaveClickCallback
   * @returns {void}
   *
   * Callback chamado para salvar as informações da visualização
   * @type {handleSaveClickCallback}
   */
  const handleSaveClick = useCallback(async () => {
    const isSaveSuccessful = await saveView(view);
    if (isSaveSuccessful) {
      setView({ name: '', description: null, sharedWith: [] });
      setVisible(false);
    } else {
      const message = (
        <>
          Não foi possível salvar a sua visualização.
          <br />
          Tente novamente.
        </>
      );
      setFeedback(message);
    }
    analytics.logEvent('dialog-salvar-visualização-salvar');
  }, [saveView, setFeedback, setVisible, view]);

  /**
   * @callback handleCancelClickCallback
   * @returns {void}
   *
   * Callback chamado para cancelar as ações do Diálogo
   * @type {handleCancelClickCallback}
   */
  const handleCancelClick = useCallback(() => {
    setView({ name: '', description: null, sharedWith: [] });
    setVisible(false);
    analytics.logEvent('dialog-salvar-visualização-cancelar');
  }, [setVisible]);

  return (
    <Dialog
      actions={(
        <FlexContainer justify="space-evenly">
          <Botao
            style={{
              backgroundColor: '#3498DB',
              color: '#fff',
              marginRight: 10,
              padding: 6,
            }}
            onClick={handleSaveClick}
            disabled={view.name === ''}
          >
            Salvar
          </Botao>
          <Botao
            style={{
              backgroundColor: 'transparent',
              color: '#222',
              padding: 6,
            }}
            onClick={handleCancelClick}
          >
            Cancelar
          </Botao>
        </FlexContainer>
      )}
      title="Salvar visualização"
      show={isVisible}
      onClose={() => setVisible(false)}
      width="100%"
    >
      <Form>
        <FlexContainer
          flow="column"
          align="center"
          style={{
            marginBottom: 10,
            minWidth: 250,
          }}
        >
          <TextField
            error={view.name === ''}
            value={view.name}
            placeholder="Nome"
            required
            label="Nome da Visualização"
            onChange={handleViewNameChange}
            style={{
              margin: !hasDescription ? '3px 0' : '3px 0 0',
              width: '100%',
            }}
          />
          <CollapseLabel
            color="rgb(52, 152, 219)"
            textAlign="left"
            onClick={() => {
              setView(old => ({
                ...old,
                description: hasDescription ? null : '',
              }));
            }}
            hasDescription={hasDescription}
          >
            {hasDescription ? 'Remover descrição' : 'Adicionar descrição'}
            <ArrowDropDownIcon />
          </CollapseLabel>
          <Collapse
            in={hasDescription}
            style={{ width: '100%' }}
          >
            <TextField
              multiline
              rows={4}
              value={view.description}
              placeholder="Descrição"
              label="Descrição da Visualização"
              onChange={handleViewDescriptionChange}
              inputProps={{ style: { height: '100%' } }}
              style={{ width: '100%' }}
            />
          </Collapse>
        </FlexContainer>
        <DividerTitle
          title="Compartilhamento"
          textStyle={{ marginTop: 5 }}
          dividerStyle={{ backgroundColor: 'rgba(0, 0, 0, .4)' }}
        />
        <AutoComplete
          multiple
          label="Compartilhar com"
          placeholder="Usuário"
          options={usersToShareWith}
          value={view.sharedWith}
          disabled={usersToShareWith.length === 0}
          getOptionLabel={option => option.name}
          customOnChange
          onChange={handleViewSharedWithChange}
        />
      </Form>
    </Dialog>
  );
}

/**
 * Diálogo que permite salvar uma visualização do mapa.
 * As opções salvas são as atualmente aplicadas no mapa.
 *
 * @param isVisible Booleano que liga/desliga visibilidade do diálogo
 * @param setVisible SetState que controla visibilidade do diálogo
 */
export default memo(SaveViewDialog);
