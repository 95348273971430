import React, {
  memo,
} from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ConteinerFormulario,
  ConteinerInput,
} from './styles';

/**
 * @typedef {object} FormularioIntervaloProps
 * @prop {number} id ID da regra
 * @prop {number} de "De" da regra
 * @prop {number} ate "Até" da regra
 * @prop {boolean} regraInvalida Booleano que define erro de formulário
 * @prop {SetState<RegraEscalaCores>} alterarValorIntervalo Callback para alterar valores do intervalo
 */
/**
 * @param {FormularioIntervaloProps} props
 * @returns {JSX.Element} Formulario de iontervalo de escala de cores
 */
function FormularioIntervalo(props) {
  const {
    de,
    ate,
    regraInvalida,
    alterarValorIntervalo,
  } = props;

  const matches = useMediaQuery('(max-width: 420px)');

  return (
    <ConteinerFormulario
      flow={`${matches ? 'column' : 'row'} nowrap`}
      align="center"
      matches={matches}
    >
      <ConteinerInput align="center" matches={matches}>
        <Typography>De</Typography>
        <TextField
          type="number"
          value={de}
          error={regraInvalida}
          onBlur={(e) => (e.target.value < 1) && alterarValorIntervalo(1, 'de')}
          onChange={(e) => alterarValorIntervalo(e.target.value, 'de')}
        />
      </ConteinerInput>
      <ConteinerInput align="center" matches={matches}>
        <Typography>até</Typography>
        <TextField
          type="number"
          value={ate === Number.MAX_VALUE ? '' : ate}
          onBlur={(e) => (e.target.value <= de) && alterarValorIntervalo((ate <= de ? de + 1 : ate), 'ate')}
          onChange={(e) => alterarValorIntervalo(e.target.value, 'ate')}
        />
      </ConteinerInput>
    </ConteinerFormulario>
  );
}

/**
 * Formulario de intervalo de quantidades para regra de escala de cores
 * @param de Valor inicial
 * @param ate Valor final
 * @param regraInvalida Booleano de erro de formulario
 * @param alterarValorIntervalo Callback para alterar valores do intervalo
 */
export default memo(FormularioIntervalo);
