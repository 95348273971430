import Handler from './Handler';

export default class ValidateSessionHandler extends Handler {
  constructor(token) {
    super();
    this.token = token;
  }

  async execute() {
    try {
      const request = await this.request.post(`/auth/sessao/${this.token}`);
      if (request.status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return false;
  }
}
