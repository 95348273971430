import styled from 'styled-components';

import InputLabel from '@material-ui/core/InputLabel';

export const StyledInputLabel = styled(InputLabel)`
  & {
    &.Mui-required {
      span[class*=asterisk] {
        color: red;
      }
    }
  }
`;
