import React, { useRef } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRight from '@material-ui/icons/ArrowRight';

function LateralSubMenu(props) {
  const {
    children,
    label,
    showMenu,
    divRef,
    style = {
      transition: null,
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
      fontSize: '14px',
    },
  } = props;

  const subMenuRef = useRef(null);
  const menuContainerRef = useRef(null);

  return (
    <div>
      <MenuItem
        ref={subMenuRef}
        style={style}
      >
        {label} <ArrowRight />
      </MenuItem>
      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        anchorEl={divRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={showMenu}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </div>
  )
}

export default LateralSubMenu;