import Resource from './Resource';

export default class GetCandidatesFromCityResource extends Resource {
  constructor(token, id) {
    super(token);
    this.id = id;
  }

  async result() {
    try {
      const { data, status } = await this.request.get(`/candidatos/${this.id}`);

      if (status === 200) {
        return data.candidatos; //eslint-disable-line
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return {};
  }
}
