import React from 'react';

import { FilterProvider } from './index';
import { FilterLocationsProvider } from './FilterLocationsContext';

export default ({ children }) => (
  <FilterLocationsProvider>
    <FilterProvider>
      { children }
    </FilterProvider>
  </FilterLocationsProvider>
);
