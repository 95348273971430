import React, {
  memo,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';

import {
  FilterContext,
} from '../../../../../../../contexts';

import {
  Botao,
  Select,
} from '../../../../../../../components';

import {
  FlexRowFormContainer,
  WarningContainer
} from '../styles';

function EventsTab() {
  const {
    setFieldEvents,
    // eventsFilters,
    getFilterOptions,
    getYearsPerOption,
  } = useContext(FilterContext);

  const warning = useRef('');

  const eventOptions = useMemo(() => getFilterOptions('eventos'), [getFilterOptions]);
  const [event, setEvent] = useState(eventOptions[0] ? eventOptions[0].id : undefined);

  const yearsOptions = useMemo(() => {
    const [first, ...others] = getYearsPerOption('eventos', event);
    return [...others, first];
  }, [event, getYearsPerOption]);
  const [year, setYear] = useState(yearsOptions[0]);

  const [version, setVersion] = useState('total');

  const disableSelection = useMemo(() => {
    const noEvent = event === undefined;
    const noYear = year === undefined;
    if (noEvent) {
      warning.current = 'Nenhum filtro deste tipo disponível';
    } else if (noYear) {
      warning.current = 'Nenhum dado foi encontrado para este filtro';
    }
    return noEvent || noYear;
  }, [event, year]);

  const addFilter = useCallback(() => {
    setFieldEvents(old => {
      const oldEvents = old.reduce((events, fieldEvent) => {
        const { filter } = fieldEvent;
        if (filter && filter.type === 'Eventos') {
          return [...events, fieldEvent];
        }

        // possivel regra para impedir filtros de serem duplicados
        // mesmo quando o filtro já faz parte de um filtro composto
        // if (filters && filters.length > 0) {
        //   const f = filters.filter(f => f.filter.type === 'Eventos');
        //   return [...events, ...f];
        // }

        return events;
      }, []);
      const newItem = {
        type: 'Eventos',
        name: event,
        year,
      };

      const values = Object.values(newItem);
      let label = values.map(i => i.toUpperCase());
      if (event === 'svn' && year !== 'total') {
        newItem.version = version;

        if (version !== 'total') {
          label = values.map(i => i.replace(/svn/i, version).toUpperCase());
        }
      }

      if (!oldEvents.find(item => item.label.join(', ') === label.join(', '))) {
        return [...old, { filter: newItem, label }];
      }
      return old;
    });
  }, [event, setFieldEvents, version, year]);

  useEffect(() => {
    if (yearsOptions && yearsOptions.length > 0) {
      setYear(yearsOptions[0]);
    }
  }, [yearsOptions]);

  return (
    <>
      <FlexRowFormContainer>
        <Select
          style={{
            width: 150,
            margin: '0 10px 10px 0',
          }}
          label="Tipo de evento"
          value={event}
          disabled={event === undefined}
          onChange={({ target }) => setEvent(target.value)}
        >
          {eventOptions}
        </Select>
        <Select
          style={{
            width: 130,
            margin: '0 10px 10px 0',
          }}
          label="Ano"
          value={year}
          disabled={year === undefined}
          onChange={({ target }) => setYear(target.value)}
        >
          {
            [
              { id: 'total', label: 'Todos' },
              ...yearsOptions
                .filter(item => item !== 'total')
                .sort()
                .map((item) => ({ id: item, label: item }))
            ]
          }
        </Select>
        {
          event === 'svn' && (
            <Select
              style={{
                width: 135,
                margin: '0 10px 10px 0',
              }}
              label="Versão"
              disabled={year === 'total'}
              value={version}
              onChange={({ target }) => setVersion(target.value)}
            >
              {
                [
                  { id: 'total', label: 'Todas' },
                  { id: 'svn1', label: 1 },
                  { id: 'svn2', label: 2 },
                  { id: 'svn3', label: 3 },
                ]
              }
            </Select>
          )
        }
        <Botao
          disabled={disableSelection}
          style={{
            backgroundColor: '#3498DB',
            color: '#fff',
            padding: '5px 10px',
          }}
          onClick={addFilter}
        >
          Selecionar
        </Botao>
      </FlexRowFormContainer>
      <WarningContainer>{disableSelection ? warning.current : ''}</WarningContainer>
    </>
  );
}

export default memo(EventsTab);
