import React from 'react';

import Typography from '@material-ui/core/Typography';
import { Container, TextBlock } from './styles';

export default function NotAuthorizedScreen(props) {
  const { status } = props;

  return (
    <Container>
      <TextBlock>
        {
          status === false && (
            <>
              <Typography
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 18,
                }}
              >
                Sessão não autorizada
              </Typography>
              <Typography
                style={{
                  color: '#fff',
                  fontSize: 16,
                  textAlign: 'center',
                  marginTop: 20,
                }}
              >
                Verifique se você possui autorização para utilizar esse módulo<br />
                e recarregue a página novamente
              </Typography>
            </>
          )
        }
      </TextBlock>
    </Container>
  );
}
