import Resource from './Resource';

export default class GetAvailableViewsResource extends Resource {
  /**
   * Busca a lista de visualizações.
   * @returns {ViewOption[]}
   */
  async result() {
    try {
      const { data, status } = await this.request.get('/usuarios/visualizacoes');
      if (status === 200) {
        return data.map(item => ({
          id: item.id_bi_visualizacao_usuario,
          name: item.titulo,
          description: item.descricao,
        }));
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    return [];
  }
}
