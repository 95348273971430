import React, {
  memo,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';

import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import AutoCompleteList from './components/AutoCompleteList';

function CircleButton(props) {
  const {
    children,
    disabled = false,
    field,
    getOptionLabel,
    isInput,
    input,
    large,
    onClick,
    onSelect,
    options,
    selected,
    shifted = false,
    state,
    style,
    textColor,
    title,
  } = props;

  const [hover, setHover] = useState(false);

  const [showIndicator, setShowIndicator] = useState(true);

  const isExpanded = useMemo(() => state === 'expanded', [state]);

  const indicator = useMemo(() => (
    <div
      style={{
        position: 'absolute',
        top: 2,
        right: 2,
        backgroundColor: '#bb0000',
        width: 12,
        height: 12,
        borderRadius: 6,
      }}
    />
  ), []);

  useEffect(() => {
    if (!isExpanded && selected) {
      setTimeout(() => {
        setShowIndicator(true);
      }, 300);
    } else {
      setShowIndicator(false);
    }
  }, [isExpanded, selected]);

  useEffect(() => {
    const item = document.querySelector('#input-base');
    if (isInput && item) {
      item.focus();
    }
  }, [isInput]);

  const textComponent = useMemo(() => (
    <Typography
      style={{
        fontSize: large ? 16 : 14,
        color: textColor || ((selected || large) ? '#fff' : '#000'),
        paddingLeft: shifted ? 8 : 4,
        fontWeight: (!large && selected) ? 'bold' : 'auto',
        whiteSpace: 'nowrap',
        cursor: 'inherit',
        display: isExpanded ? 'flex' : 'none',
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
    >
      {title}
    </Typography>
  ), [disabled, isExpanded, large, onClick, selected, shifted, textColor, title]);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        left: shifted ? 5 : 0,
      }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <ButtonBase
        disableRipple={disabled}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled && onClick) {
            onClick(e);
          }
        }}
        style={{
          borderRadius: large ? 24 : 18,
          display: 'flex',
          position: 'relative',
          justifyContent: 'flex-start',
          padding: large ? 12 : 8,
          width: isExpanded && selected ? '100%' : 'auto',
          margin: large ? '12px 4px' : '4px',
          boxShadow: large ? '0 4px 8px #bbb' : '',
          cursor: disabled ? 'not-allowed' : 'pointer',
          filter: disabled ? 'grayscale(80%)' : undefined,
          ...style,
        }}
      >
        { children }
        { (selected || large) && textComponent }
      </ButtonBase>
      { !input && (!(selected || large)) && textComponent}
      { showIndicator && indicator }
      {
        options && getOptionLabel && hover && (
          <AutoCompleteList
            {...{ options, getOptionLabel, onSelect }}
            searchValue={field}
          />
        )
      }
    </div>
  );
}

export default memo(CircleButton);
