import React from 'react';

import CollapseDivider from '../CollapseDivider';

import {
  TableListItem,
  TableTagsContainer,
} from './styles';

function DataTableListItem(props) {
  const {
    children,
    title,
  } = props;

  return (
    <TableListItem flow="column">
      <CollapseDivider label={title}>
        <TableTagsContainer flow="wrap">
          {children}
        </TableTagsContainer>
      </CollapseDivider>
    </TableListItem>
  );
}

export default DataTableListItem;
