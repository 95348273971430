import React, {
  createContext,
  useState,
  useCallback,
} from 'react';

import {
  SecondaryMenu
} from '../../components';

/**
 * Propriedades que definem a âncora do menu de contexto e suas opções
 * @typedef {object} SecondaryMenuParameters
 * @prop {EventTarget & (HTMLElement | SVGElement)} target
 * Elemento âncora do menu.
 * @prop {SecondaryMenuOptions[]} options
 * Proppriedades das opções renderizadas no menu.
 */

/**
 * @typedef {object} SecondaryMenuContextType
 * @prop {SecondaryMenuParameters} parameters
 * @prop {(parameters: SecondaryMenuParameters) => void} showSecondaryMenu
 */
/**
 * @type {React.Context<SecondaryMenuContextType>}
 */
const SecondaryMenuContext = createContext({});

export const SecondaryMenuProvider = ({ children }) => {
  const [parameters, setParameters] = useState({
    target: null,
    options: [],
  });

  /**
   * Essa função mostra o menu de contexto referenciando
   * pelo atributo 'target', e com a lista de opções
   * pelo atributo 'options'.
   */
  const showSecondaryMenu = useCallback((parameters) => {
    setParameters({
      ...parameters,
    });
  }, []);

  return (
    <SecondaryMenuContext.Provider
      value={{
        parameters,
        showSecondaryMenu
      }}
    >
      { children}
      <SecondaryMenu />
    </SecondaryMenuContext.Provider>
  );
};

export default SecondaryMenuContext;
