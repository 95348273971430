import React, {
  memo,
} from 'react';

import {
  CorItemLegenda,
  LegendaAuxiliar,
  TextoItemLegenda
} from './styles';

/**
 * @typedef {RegraEscalaCores | RegraCorPorFiltro | { color: string, value: number }} ColorRule
 *
 * @typedef {object} LegendaAuxiliarCoresProps
 * @prop {ColorRule[]} regrasCor Conjunto de regras de cor
 * @prop {(regra: ColorRule) => JSX.Element} renderizar
 * Formatação que a regra deve criar para apresentar os dados na legenda
 */
/**
 * @param {LegendaAuxiliarCoresProps} props
 * @returns {JSX.Element} Componente base de conteúdo da legenda do mapa
 */
function LegendaAuxiliarCores(props) {
  const {
    regrasCor,
    renderizar,
  } = props;

  return (
    <LegendaAuxiliar flow="column">
      {
        regrasCor.map((regra) => (
          !regra.desabilitar
            ? (
              <div
                key={`interval-item-${regra.valor || regra.id}`}
                className="item-legenda-auxiliar-cor"
              >
                <CorItemLegenda cor={regra.cor} />
                <TextoItemLegenda>
                  {renderizar(regra)}
                </TextoItemLegenda>
              </div>
            ) : <></>
        ))
      }
    </LegendaAuxiliar>
  );
}

/**
 * Componente base para componetes de cor da legenda
 * @param regrasCor array com regras para criação da componente
 * @param renderizar callback que constroi o texto da legenda de cor
 */
export default memo(LegendaAuxiliarCores);
