import React, {
  memo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { analytics } from '../../../../';

import {
  Dialog,
} from '../../../';

import { MapViewContext } from '../../../../contexts';

import ViewOption from './components/ViewOption';

/**
 * @typedef {object} OpenViewDialogProps
 * @prop {boolean} isVisible Booleano que indica se o dialogo está vísivel
 * @prop {SetState<setVisible>} setVisible SetState para `isVisible`
 */
/**
 * @param {OpenViewDialogProps} props
 * @returns {Dialog}
 */
function OpenViewDialog(props) {
  const { isVisible, setVisible } = props;

  const {
    getAvailableViews,
    getView,
    handleViewLoadEnd,
    openView,
    setFeedback,
  } = useContext(MapViewContext);

  /**
   * Estado que monitora o qual descrição está aberta atualmente
   * @type {[string, SetState<string>]}
   */
  const [isOpened, setOpened] = useState(undefined);

  /**
   * Lista de visualizações salvas e disponíveis para o usuário.
   * @type {[ViewOption[], SetState<ViewOption[]>]}
   */
  const [viewOptions, setViewOptions] = useState([]);

  /**
   * Effect que carrega os dados da lista de visualizações.
   */
  useEffect(() => {
    if (isVisible) {
      getAvailableViews()
        .then(result => setViewOptions(result))
        .catch(error => console.error(error)); // eslint-disable-line
    }
  }, [getAvailableViews, isVisible]);

  /**
   * @callback handleOpenViewClickCallback
   * @param {number} id
   * @returns {void}
   *
   * Callback chamado no clique do botão abrir de uma view.
   * @type {handleOpenViewClickCallback}
   */
  const handleOpenViewClick = useCallback((id) => {
    getView(id)
      .then(async view => {
        await openView(view);
        handleViewLoadEnd();
        setVisible(false);
      })
      .catch(error => {
        console.error(error); // eslint-disable-line
        const message = (
          <>
            Não foi possível abrir a visualização.
            <br />
            Tente novamente.
          </>
        );
        handleViewLoadEnd();
        setFeedback(message);
      });
    analytics.logEvent('dialog-abrir-visualização-abrir');
  }, [getView, handleViewLoadEnd, openView, setFeedback, setVisible]);

  const matches = useMediaQuery('(max-width: 600px)');

  return (
    <Dialog
      title="Abrir visualização"
      show={isVisible}
      onClose={() => setVisible(false)}
      style={{
        minWidth: !matches ? '50vw' : undefined,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      {
        viewOptions.map((view, index) => (
          <ViewOption
            index={index}
            id={view.id}
            title={view.name}
            description={view.description}
            onOpen={handleOpenViewClick}
            {...{ isOpened, setOpened }}
          />
        ))
      }
    </Dialog>
  );
}

/**
 * Diálogo que permite abrir uma visualização salva do mapa.
 * As visulizações salvas são as salvas pelo usuário e as compartilhadas com ele.
 *
 * @param isVisible Booleano que liga/desliga visibilidade do diálogo
 * @param setVisible SetState que controla visibilidade do diálogo
 */
export default memo(OpenViewDialog);
