import styled from 'styled-components';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export const Controlador = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    align-items: self-end;
  }
`;

export const ComponenteRadio = styled(Radio)`
  &.MuiRadio-root {
    border-radius: 50%;
    margin-inline-end: .5rem;

    &:not([aria-disabled="true"]) {
      color: #3498DB !important;
    }
  }
`;
