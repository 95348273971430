import React, {
  memo,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import Collapse from '@material-ui/core/Collapse';

import {
  AppContext,
} from '../../../../../../../contexts';

import {
  FlexContainer,
  Select,
} from '../../../../../..';

import AddRemoveButton from '../../AddRemoveButton';

import FormularioIntervalo from './components/FormularioIntervalo';

import {
  CollapseLabel,
  IntervalContainer,
  SeletorDeCorEstilizado,
} from './styles';

/**
 * @typedef {object} RegraCorPorFiltroProps
 * @prop {SetState<RegraCorPorFiltro>} alterarCorPorFiltro Callback para alterar as regras
 * @prop {RegraCorPorFiltro[]} corPorFiltro Lista de regras definidas atualmente
 * @prop {RegraCorPorFiltro} regra Regra para esta componente
 * @prop {number} posicaoRegra Indíce da regra desta componente
 * @prop {boolean} permitirAplicar Booleano que define se a regra é aplicavel
 * @prop {SetState<boolean>} setPermitirAplicar setState de validação da regra
 */
/**
 * @param {RegraCorPorFiltroProps} props
 * @returns {JSX.Element} Componente que define uma regra de escala de cores
 */
function RegraCorPorFiltro(props) {
  const {
    corPorFiltro,
    permitirAplicar,
    regra,
    posicaoRegra,
    alterarCorPorFiltro,
    setPermitirAplicar,
  } = props;

  const { activeFilters } = useContext(AppContext);

  const { id, cor, item, intervalo, desabilitar } = regra;

  const adicionarRegra = useCallback(() => {
    alterarCorPorFiltro(atual => {
      const { length } = atual;
      const item = {
        id: atual[length - 1].id + 1,
        cor: '#0B8209',
        item: '',
      };
      return [...atual, item];
    });
  }, [alterarCorPorFiltro]);

  const removerRegra = useCallback(() => {
    alterarCorPorFiltro(old => old.filter(cs => cs.id !== id));
  }, [alterarCorPorFiltro, id]);

  const alterarRegra = useCallback((valor, chave) => {
    alterarCorPorFiltro(old => old.map((cs) => {
      if (cs.id === id) {
        cs[chave] = chave === 'interval' ? { ...cs[chave], ...valor } : valor;
      }
      return cs;
    }));
  }, [alterarCorPorFiltro, id]);

  const minimoInvalido = useMemo(() => (
    intervalo && (
      Number.isNaN(intervalo.de)
      || intervalo.de > intervalo.until || intervalo.de < 0
    )
  ), [intervalo]);

  const maximoInvalido = useMemo(() => (
    intervalo && (
      Number.isNaN(intervalo.de) || intervalo.de > intervalo.ate
    )
  ), [intervalo]);

  /**
   * Opções de filtro para regra de cor
   * @type {SelectComponentOption[]}
   */
  const opcoesSelecao = useMemo(() => (
    activeFilters.length > 0
      ? activeFilters.map((item) => ({
        id: item.settings.title.join(', '),
        label: item.settings.title.join(', '),
      }))
      : []
  ), [activeFilters]);

  /**
   * Callback para adicionar um intervalo à regra.
   */
  const adicionarIntervalo = useCallback(() => {
    if (item && !desabilitar) {
      alterarCorPorFiltro(atual => (
        atual.map(regra => {
          if (regra.id !== id) return regra;
          return {
            ...regra,
            intervalo: {
              de: 0,
              ate: Number.MAX_VALUE,
            }
          };
        })
      ));
    }
  }, [item, id, desabilitar, alterarCorPorFiltro]);

  /**
   * Callback para remover o intervalo da regra.
   */
  const removerIntervalo = useCallback(() => {
    alterarCorPorFiltro(atual => (
      atual.map(regra => {
        if (regra.id !== id) return regra;
        delete regra.intervalo;
        return regra;
      })
    ));
  }, [id, alterarCorPorFiltro]);

  useEffect(() => {
    setPermitirAplicar(
      intervalo
        ? item !== '' && (intervalo.de >= 0 && intervalo.de <= intervalo.ate)
        : item !== '' && !corPorFiltro.some(regra => regra.id !== id && regra.item === item && !regra.intervalo)
    );
  }, [corPorFiltro, id, item, intervalo, setPermitirAplicar]);

  return (
    <FlexContainer
      key={`regra-${id}-cor-por-filtro`}
      align="center"
      style={{ margin: '4px 0' }}
    >
      <SeletorDeCorEstilizado
        color={cor}
        setColor={(cor) => alterarRegra(cor, 'cor')}
        tamanho="1.25rem"
        popperTransform="translate(0.5rem, -50%)"
      />

      <FlexContainer
        flow="column"
        style={{ margin: '0 5px' }}
      >
        <Select
          disabled={desabilitar}
          onChange={({ target }) => alterarRegra(target.value, 'item')}
          label="Opções"
          value={item}
          style={{ margin: '0 3px', width: '100%' }}
          formControlStyle={{ minWidth: 210, width: '100%' }}
        >
          {
            desabilitar
              ? [{ id: item, label: item }]
              : opcoesSelecao.sort((a, b) => ((a.label > b.label) ? 1 : -1))
          }
        </Select>
        <CollapseLabel
          itemExists={!!item}
          intervalExists={!!intervalo}
          disable={desabilitar}
          variant="button"
          onClick={adicionarIntervalo}
        >
          DEFINIR QUANTIDADE
        </CollapseLabel>
        <IntervalContainer>
          <Collapse
            in={!desabilitar && item && intervalo}
            direction="up"
          >
            <FormularioIntervalo
              de={intervalo && intervalo.de}
              deInvalido={minimoInvalido}
              ate={intervalo && intervalo.ate}
              ateInvalido={maximoInvalido}
              alterarValorIntervalo={({ target }) => alterarRegra({ [target.name]: Number(target.value) }, 'intervalo')}
              removerIntervalo={removerIntervalo}
            />
          </Collapse>
        </IntervalContainer>
      </FlexContainer>
      <FlexContainer>
        {/* Remove */}
        {
          corPorFiltro.length > 1 && (
            <AddRemoveButton
              type="Remover"
              onClick={removerRegra}
              style={{ marginRight: 2 }}
            />
          )
        }

        {/* Add */}
        {
          posicaoRegra === corPorFiltro.length - 1 && (
            <AddRemoveButton
              type="Adder"
              onClick={adicionarRegra}
              disabled={!permitirAplicar}
              style={{ marginLeft: 2 }}
            />
          )
        }
      </FlexContainer>
    </FlexContainer>
  );
}

/**
 * Componente de definição da regra de coloração de Cor por Filtro.
 * Define uma cor para um filtro e pode definir um intervalo de quantidade a este filtro
 * @param alterarCorPorFiltro Callback para alterar as regras
 * @param corPorFiltro Lista de regras definidas atualmente
 * @param regra Regra para esta componente
 * @param posicaoRegra Indíce da regra desta componente
 * @param permitirAplicar Booleano que define se a regra é aplicavel
 * @param setPermitirAplicar setState de validação da regra
 */
export default memo(RegraCorPorFiltro);
