import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Collapse from '@mui/material/Collapse';

import {
  ColorContext,
  DataContext,
} from '../../../../../../contexts';

import AbasContext from '../../../../../Abas/assets/AbasContext';

import { RadioButton } from '../../../../..';

import WindowStorage from '../../../../../../utils/storage';

import { chaveExisteEm } from '../../../../../../utils';

import {
  RegraGradiente,
  RegraEscalaCores,
  RegraCorPorFiltro,
} from '../RegrasDeCores';

import ActionsDialogoCores from '../ActionsDialogCores';

import {
  ConteinerRegrasCores,
  PendenteAplicar
} from '../../styles';

const ESCALA_CORES_INICIAL = [{
  id: 1,
  cor: '#0B8209',
  de: 1,
  ate: Number.MAX_VALUE,
}];

const POR_FILTRO_INICIAL = [{
  id: 1,
  cor: '#0B8209',
  item: '',
}];

const storageDialogoCores = WindowStorage.localStorage('dialogo-cores');

/*
  TODO: MELHORIA
  Migrar para componente para VisibilidadeSelecaoRadio
    - separa logica relacionada aos RadioButtons
    - minimiza estrutura JSX do componente
 */
function AbaColorirResultadoFiltro() {
  const { isSingleFilter } = useContext(DataContext);

  const { metodoColoracao } = useContext(ColorContext);

  const {
    abaAtual,
    criarPortalActions,
    dispatchStoreDialogoCores,
    onAplicar,
  } = useContext(AbasContext);

  const isAbaSelecionada = useMemo(() => abaAtual === 'aba-filtros', [abaAtual]);

  const [corGradiente, setCorGradiente] = useState(() => {
    const valorInicial = (
      storageDialogoCores.existe() && storageDialogoCores.get('corGradiente')
        ? storageDialogoCores.get('corGradiente')
        : '#0B8209'
    );
    dispatchStoreDialogoCores({ tipo: 'ALTERA', corGradiente: valorInicial });
    return valorInicial;
  });
  const [escalaCores, setEscalaCores] = useState(() => {
    const valorInicial = (
      storageDialogoCores.existe() && storageDialogoCores.get('escalaCores')
        ? storageDialogoCores.get('escalaCores')
        : ESCALA_CORES_INICIAL
    );
    dispatchStoreDialogoCores({ tipo: 'ALTERA', escalaCores: valorInicial });
    return valorInicial;
  });
  const [corPorFiltro, setCorPorFiltro] = useState(() => {
    const valorInicial = (
      storageDialogoCores.existe() && storageDialogoCores.get('corPorFiltro')
        ? storageDialogoCores.get('corPorFiltro')
        : POR_FILTRO_INICIAL
    );
    dispatchStoreDialogoCores({ tipo: 'ALTERA', corPorFiltro: valorInicial });
    return valorInicial;
  });

  const [tipoColoracaoFiltro, setTipoColoracaoFiltro] = useState(() => {
    const valorInicial = (
      storageDialogoCores.existe() && storageDialogoCores.get('tipoColoracaoFiltro')
        ? storageDialogoCores.get('tipoColoracaoFiltro')
        : 'radio-gradiente'
    );
    dispatchStoreDialogoCores({ tipo: 'ALTERA', tipoColoracaoFiltro: valorInicial });
    return valorInicial;
  });

  const alterarSelecaoRadio = useCallback((action) => {
    setTipoColoracaoFiltro(atual => {
      const novoValor = typeof action === 'function' ? action(atual) : action;
      dispatchStoreDialogoCores({ tipo: 'ALTERA', tipoColoracaoFiltro: novoValor });
      return novoValor;
    });
  }, [dispatchStoreDialogoCores]);

  const alterarCorGradiente = useCallback((action) => {
    setCorGradiente(atual => {
      const novoValor = typeof action === 'function' ? action(atual) : action;
      dispatchStoreDialogoCores({ tipo: 'ALTERA', corGradiente: novoValor });
      return novoValor;
    });
  }, [dispatchStoreDialogoCores]);

  const alterarEscalaCores = useCallback((action) => {
    setEscalaCores(atual => {
      const novoValor = typeof action === 'function' ? action(atual) : action;
      dispatchStoreDialogoCores({ tipo: 'ALTERA', escalaCores: novoValor });
      return novoValor;
    });
  }, [dispatchStoreDialogoCores]);

  const alterarCorPorFiltro = useCallback((action) => {
    setCorPorFiltro(atual => {
      const novoValor = typeof action === 'function' ? action(atual) : action;
      dispatchStoreDialogoCores({ tipo: 'ALTERA', corPorFiltro: novoValor });
      return novoValor;
    });
  }, [dispatchStoreDialogoCores]);

  useEffect(() => {
    if (metodoColoracao.tipo === 'gradiente') {
      alterarCorGradiente(metodoColoracao.cor);
      alterarSelecaoRadio('radio-gradiente');
    } else if (metodoColoracao.tipo === 'escala-cores') {
      alterarEscalaCores(
        metodoColoracao.regras
          .map(({ ate = Number.MAX_VALUE, ...resto }) => ({ ...resto, ate }))
      );
      alterarSelecaoRadio('radio-escala-cores');
    } else if (metodoColoracao.tipo === 'por-filtro') {
      const regras = metodoColoracao.regras.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      alterarCorPorFiltro(regras);
      alterarSelecaoRadio('radio-por-filtro');
    }
  }, [
    alterarCorGradiente,
    alterarCorPorFiltro,
    alterarEscalaCores,
    alterarSelecaoRadio,
    metodoColoracao.cor,
    metodoColoracao.regras,
    metodoColoracao.tipo
  ]);

  const [permitirAplicarEscala, setPermitirAplicarEscala] = useState(false);
  const [permitirAplicarCorPorFiltro, setPermitirAplicarCorPorFiltro] = useState(false);

  const aplicar = useCallback(() => {
    const novoMetodo = {};

    if (tipoColoracaoFiltro === 'radio-gradiente') {
      novoMetodo.tipo = 'gradiente';
      novoMetodo.cor = corGradiente;
    } else if (tipoColoracaoFiltro === 'radio-escala-cores') {
      novoMetodo.tipo = 'escala-cores';
      // garantir gerar novos objetos, não passar objetos como referencia
      novoMetodo.regras = escalaCores
        .map(({ ate = Number.MAX_VALUE, ...resto }) => ({ ...resto, ate }))
    } else if (tipoColoracaoFiltro === 'radio-por-filtro') {
      novoMetodo.tipo = 'por-filtro';
      novoMetodo.regras = corPorFiltro
        .map(({ desabilitado = false, ...resto }) => {
          const objeto = { ...resto, desabilitado };

          if (chaveExisteEm(resto, 'intervalo') && resto.intervalo) {
            objeto.intervalo = { ...resto.intervalo };
          }

          return objeto;
        });
    }

    onAplicar(novoMetodo);
  }, [
    corPorFiltro,
    escalaCores,
    corGradiente,
    onAplicar,
    tipoColoracaoFiltro
  ]);

  const regrasValidas = useMemo(() => {
    switch (tipoColoracaoFiltro) {
      case 'radio-gradiente': return true;
      case 'radio-escala-cores': return permitirAplicarEscala;
      case 'radio-por-filtro': return permitirAplicarCorPorFiltro;
      default: return false;
    }
  }, [permitirAplicarEscala, permitirAplicarCorPorFiltro, tipoColoracaoFiltro]);

  const faltaAplicarRegraGradiente = useMemo(() => (
    metodoColoracao.tipo !== 'gradiente'
    || metodoColoracao.cor !== corGradiente
  ), [metodoColoracao.tipo, metodoColoracao.cor, corGradiente]);

  const faltaAplicarRegraEscalaCores = useMemo(() => (
    metodoColoracao.tipo !== 'escala-cores' || (
      metodoColoracao.regras.length !== escalaCores.length
      || !metodoColoracao.regras.every(regra => {
        const temRegra = escalaCores.find(item => item.id === regra.id);
        if (!temRegra) return false;
        return Object.entries(temRegra)
          .every(([chave, valor]) => {
            return chave in regra && valor === regra[chave];
          });
      })
    )
  ), [metodoColoracao.tipo, metodoColoracao.regras, escalaCores]);

  const faltaAplicarRegraCorPorFiltro = useMemo(() => (
    metodoColoracao.tipo !== 'por-filtro' || (
      metodoColoracao.regras.length !== corPorFiltro.length
      || !metodoColoracao.regras.every(regra => {
        const temRegra = corPorFiltro.find(item => item.id === regra.id);
        if (!temRegra) return false;
        return Object.entries(temRegra)
          .every(([chave, valor]) => (
            chave in regra && (
              chave === 'intervalo'
                ? valor.de === regra.intervalo.de && valor.ate === regra.intervalo.ate
                : valor === regra[chave]
            )
          ));
      })
    )
  ), [metodoColoracao.tipo, metodoColoracao.regras, corPorFiltro]);

  return (
    <ConteinerRegrasCores
      value={tipoColoracaoFiltro}
      onChange={(e) => alterarSelecaoRadio(e.target.value)}
    >
      <RadioButton
        value="radio-gradiente"
        selected={tipoColoracaoFiltro === 'radio-gradiente'}
        disabled={!isSingleFilter}
      >
        Degradê
      </RadioButton>
      <Collapse in={tipoColoracaoFiltro === 'radio-gradiente'}>
        <RegraGradiente {...{ corGradiente, alterarCorGradiente }} />
      </Collapse>

      <RadioButton
        value="radio-escala-cores"
        selected={tipoColoracaoFiltro === 'radio-escala-cores'}
        disabled={!isSingleFilter}
      >
        Escala de Cores
      </RadioButton>
      <Collapse in={tipoColoracaoFiltro === 'radio-escala-cores'}>
        {
          escalaCores.map((regra, index) => (
            <RegraEscalaCores
              escalaCores={escalaCores}
              alterarEscalaCores={alterarEscalaCores}
              regra={regra}
              posicaoRegra={index}
              permitirAplicar={permitirAplicarEscala}
              setPermitirAplicar={setPermitirAplicarEscala}
            />
          ))
        }
      </Collapse>

      <RadioButton
        value="radio-por-filtro"
        selected={tipoColoracaoFiltro === 'radio-por-filtro'}
        disabled={isSingleFilter}
      >
        Cor por Filtro
      </RadioButton>
      <Collapse in={tipoColoracaoFiltro === 'radio-por-filtro'}>
        {
          corPorFiltro.map((regra, index) => (
            <RegraCorPorFiltro
              corPorFiltro={corPorFiltro}
              alterarCorPorFiltro={alterarCorPorFiltro}
              regra={regra}
              posicaoRegra={index}
              permitirAplicar={permitirAplicarCorPorFiltro}
              setPermitirAplicar={setPermitirAplicarCorPorFiltro}
            />
          ))
        }
      </Collapse>
      <PendenteAplicar>
        {
          (tipoColoracaoFiltro === 'radio-gradiente' && faltaAplicarRegraGradiente)
          && 'A cor selecionada ainda não foi aplicada'
        }
        {
          (tipoColoracaoFiltro === 'radio-escala-cores' && faltaAplicarRegraEscalaCores)
          && 'Existem regras selecionadas ainda não aplicadas'
        }
        {
          (tipoColoracaoFiltro === 'radio-por-filtro' && faltaAplicarRegraCorPorFiltro)
          && 'Existem regras selecionadas ainda não aplicadas'
        }
      </PendenteAplicar>
      {
        isAbaSelecionada && (
          criarPortalActions(
            <ActionsDialogoCores
              aplicar={aplicar}
              permitirAplicar={regrasValidas}
            />
          )
        )
      }
    </ConteinerRegrasCores>
  );
}

export default memo(AbaColorirResultadoFiltro);
