import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { createPortal } from 'react-dom';

import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

import { AppContext, ColorContext } from '../../../../../../contexts';

import AbasContext from '../../../../../Abas/assets/AbasContext';
import NoArvoreExpansivel from '../../../../../ArvoreExpansivel/assets/NoArvoreExpansivel';
import useArvoreExpansivel from '../../../../../ArvoreExpansivel/assets/useArvoreExpansivel';

import {
  ArvoreExpansivel,
  Botao,
  VisibilidadeSelecaoSelect
} from '../../../../..';

import WindowStorage from '../../../../../../utils/storage';

import { chaveExisteEm } from '../../../../../../utils';

import { PendenteAplicar } from '../../styles';

import ActionsDialogoCores from '../ActionsDialogCores';

import {
  ArvoreWrapper,
  BotaoItemArvore,
  BotaoLimparSelecao,
  CaixaSeletorDeCorInline,
  CorSelecaoSimultanea,
  EstadoCorItemArvore,
  RotuloItemArvore,
  SeletorCor,
  SeletorCorWrapper,
  VisibilidadeWrapper
} from './styles';

const storageDialogoCores = WindowStorage.localStorage('dialogo-cores');

function gerarObjetoArvore(item) {
  const objeto = {
    id: item.id,
    nome: item.name,
  };

  if (chaveExisteEm(item, 'entidade')) {
    objeto[item.entidade] = item[item.entidade];
  }

  return objeto;
}

function AbaColorirRegioes() {
  const {
    admRegions,
    bacias,
    cities,
    dioceses,
    regions
  } = useContext(AppContext);

  const { metodoColoracao } = useContext(ColorContext);

  const {
    abaAtual,
    criarPortalActions,
    dispatchStoreDialogoCores,
    onAplicar,
    refDialogoCores,
  } = useContext(AbasContext);

  const isAbaSelecionada = useMemo(() => abaAtual === 'aba-regioes', [abaAtual]);

  const inicializarCidades = useCallback((cidades) => (
    cidades.map(cidade => gerarObjetoArvore(cidade))
  ), []);

  const inicializarBacias = useCallback(() => (
    new NoArvoreExpansivel(
      ['bacias', 'cidades'],
      Object.entries(bacias)
        .map(([idBacia, bacia]) => ({
          id: idBacia,
          nome: bacia.name,
          cidades: inicializarCidades(
            Object.entries(cities)
              .filter(([, cidade]) => cidade.bacia === Number(idBacia))
              .map(([idCidade, cidade]) => ({ ...cidade, id: idCidade }))
          ),
        }))
    )
  ), [bacias, cities, inicializarCidades]);

  const inicializarRegioesAdm = useCallback(() => (
    new NoArvoreExpansivel(
      ['regioes-adm', 'cidades'],
      Object.entries(admRegions)
        .map(([idRegiaoAdm, regiaoAdm]) => ({
          id: idRegiaoAdm,
          nome: regiaoAdm.name,
          cidades: inicializarCidades(
            Object.entries(cities)
              .filter(([, cidade]) => cidade.admRegion === Number(idRegiaoAdm))
              .map(([idCidade, cidade]) => ({ ...cidade, id: idCidade }))
          ),
        }))
    )
  ), [admRegions, cities, inicializarCidades]);

  const inicializarDioceses = useCallback((dioceses) => (
    dioceses.map(diocese => (
      gerarObjetoArvore({
        ...diocese,
        entidade: 'cidades',
        cidades: inicializarCidades(
          Object.entries(cities)
            .filter(([, cidade]) => cidade.diocese === Number(diocese.id))
            .map(([idCidade, cidade]) => ({ ...cidade, id: idCidade }))
        )
      })
    ))
  ), [cities, inicializarCidades]);

  const inicializarRegioes = useCallback(() => (
    new NoArvoreExpansivel(
      ['regioes', 'dioceses', 'cidades'],
      Object.entries(regions)
        .map(([idRegiao, regiao]) => ({
          id: idRegiao,
          nome: regiao.name,
          dioceses: inicializarDioceses(
            Object.entries(dioceses)
              .filter(([, diocese]) => diocese.region === Number(idRegiao))
              .map(([idDiocese, diocese]) => ({ ...diocese, id: idDiocese }))
          ),
        }))
    )
  ), [regions, dioceses, inicializarDioceses]);

  const [tipoRegiao, setTipoRegiao] = useState(() => {
    const valorInicial = (
      storageDialogoCores.existe() && storageDialogoCores.get('tipoRegiao')
        ? storageDialogoCores.get('tipoRegiao')
        : 'regioes'
    );
    dispatchStoreDialogoCores({ tipo: 'ALTERA', tipoRegiao: valorInicial });
    return valorInicial;
  });

  const alterarTipoRegiao = useCallback((action) => {
    setTipoRegiao(atual => {
      const novoValor = typeof action === 'function' ? action(atual) : action;
      dispatchStoreDialogoCores({ tipo: 'ALTERA', tipoRegiao: novoValor });
      return novoValor;
    });
  }, [dispatchStoreDialogoCores]);

  const regioesState = useArvoreExpansivel((atual) => {
    if (storageDialogoCores.existe()) {
      const salvo = storageDialogoCores.get('porRegiao');
      if (salvo) return new NoArvoreExpansivel(salvo);
    }
    return inicializarRegioes(atual);
  }, [inicializarRegioes]);

  const regioesAdmState = useArvoreExpansivel((atual) => {
    if (storageDialogoCores.existe()) {
      const salvo = storageDialogoCores.get('porRegiao');
      if (salvo) return new NoArvoreExpansivel(salvo);
    }
    return inicializarRegioesAdm(atual);
  }, [inicializarRegioesAdm]);

  const baciasState = useArvoreExpansivel((atual) => {
    if (storageDialogoCores.existe()) {
      const salvo = storageDialogoCores.get('porRegiao');
      if (salvo) return new NoArvoreExpansivel(salvo);
    }
    return inicializarBacias(atual);
  }, [inicializarBacias]);

  const [arvore, dispatch] = useMemo(() => {
    switch (tipoRegiao) {
      case 'bacias': return baciasState;
      case 'regioes-adm': return regioesAdmState;

      case 'regioes':
      default: return regioesState;
    }
  }, [baciasState, regioesState, regioesAdmState, tipoRegiao]);

  const alterarEstadoArvore = useCallback((action) => {
    dispatch((atual, reducer) => {
      // remove referencia à raiz e estados do storage
      const salvar = atual.clonarSemReferenciaCiclica();
      salvar.getEntidades().forEach(entidade => {
        salvar.getNosEntidade(entidade).forEach(item => {
          item.maximizado = false;
          item.estado = 'nao-selecionado';
        });
      });
      dispatchStoreDialogoCores({ tipo: 'ALTERA', porRegiao: salvar });
      return reducer(atual, action);
    });
  }, [dispatch, dispatchStoreDialogoCores]);

  const ComponenteArvore = useMemo(() => (
    <ArvoreExpansivel
      arvore={arvore}
      dispatch={alterarEstadoArvore}
      renderizarListItem={(item, alterarItem) => (
        <BotaoItemArvore
          disableRipple
          className={`item-${item.estado}`}
          selected={item.estado === 'selecionado'}
          disabled={item.estado === 'desabilitado'}
          onClick={() => alterarItem({ tipo: 'ALTERNA_ESTADO_NO' })}
        >
          <FormatColorResetIcon
            viewBox="0 4 24 20"
            onClick={() => alterarItem({ cor: undefined })}
            titleAccess="Limpar cor"
          />
          <EstadoCorItemArvore
            color={item.dados.cor}
            tamanho="0.625rem"
          />
          <RotuloItemArvore>{item.nome}</RotuloItemArvore>
        </BotaoItemArvore>
      )}
    />
  ), [arvore, alterarEstadoArvore]);

  const onAlteraSelecao = useCallback((_, valor) => alterarTipoRegiao(valor), [alterarTipoRegiao]);

  const limparSelecao = useCallback(() => {
    alterarEstadoArvore((atual) => {
      atual.getEntidades().forEach(entidade => {
        atual.getNosEntidade(entidade)
          .filter(no => no.estado === 'selecionado')
          .forEach(no => {
            no.estado = 'nao-selecionado';
          });
      });

      return new NoArvoreExpansivel(atual);
    });
  }, [alterarEstadoArvore]);

  const refCorSelecao = useRef('');
  const refSeletorWrapper = useRef(null);
  const [seletorPopper, setSeletorPopper] = useState({ top: undefined, left: undefined });

  const abrirSeletor = useCallback((e) => {
    e.preventDefault();
    const { top, left } = e.currentTarget.getBoundingClientRect();
    setSeletorPopper({ top, left });
    if (refSeletorWrapper.current) refSeletorWrapper.current.focus();
  }, []);

  const onAlteraCor = useCallback(({ rgb }) => {
    refCorSelecao.current = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a || 1})`;
    alterarEstadoArvore((atual) => {
      atual.getEntidades().forEach(entidade => {
        atual.getNosEntidade(entidade)
          .filter(no => no.estado === 'selecionado')
          .forEach(no => {
            no.dados.cor = refCorSelecao.current;
          });
      });

      return new NoArvoreExpansivel(atual);
    });
  }, [alterarEstadoArvore]);

  const aplicavel = useMemo(() => (
    arvore.getNosEntidade('cidades')
      .some(cidade => chaveExisteEm(cidade.dados, 'cor') && cidade.dados.cor !== '')
  ), [arvore]);

  const aplicar = useCallback(() => {
    onAplicar({ tipo: 'por-regiao', regras: arvore.clonarSemReferenciaCiclica() });
    limparSelecao();
  }, [arvore, limparSelecao, onAplicar]);

  const limparCores = useCallback(() => {
    alterarEstadoArvore((atual) => {
      atual.getEntidades().forEach((entidade) => {
        atual.getNosEntidade(entidade).forEach(item => {
          item.estado = 'nao-selecionado';
          item.dados.cor = undefined;
        });
      });

      const novoValor = new NoArvoreExpansivel(atual);
      onAplicar({ tipo: 'por-regiao', regras: novoValor.clonarSemReferenciaCiclica() }, false);
      return novoValor;
    });
  }, [alterarEstadoArvore, onAplicar]);

  const faltaAplicarRegraCorPorRegiao = useMemo(() => {
    if (metodoColoracao.tipo !== 'por-regiao') return true;
    const cidadesAplicadas = metodoColoracao.regras.getNosEntidade('cidades')
      .filter(item => chaveExisteEm(item.dados, 'cor') && item.dados.cor !== '');
    const cidadesPendentes = arvore.getNosEntidade('cidades')
      .filter(item => chaveExisteEm(item.dados, 'cor') && item.dados.cor !== '');
    return (
      cidadesAplicadas.length !== cidadesPendentes.length
      || !cidadesAplicadas.every(cidadeAplicada => {
        const temRegra = cidadesPendentes
          .find(cidadeSelecionada => cidadeSelecionada.dados.id === cidadeAplicada.dados.id);
        if (!temRegra) return false;
        return temRegra.dados.cor === cidadeAplicada.dados.cor;
      })
    );
  }, [arvore, metodoColoracao.tipo, metodoColoracao.regras]);

  return (
    <VisibilidadeSelecaoSelect
      value={tipoRegiao}
      label="Tipo de região"
      onAlteraSelect={onAlteraSelecao}
      opcoes={[
        { id: 'regioes', label: 'Regiões', componente: ComponenteArvore },
        { id: 'regioes-adm', label: 'Regiões Administrativas', componente: ComponenteArvore },
        { id: 'bacias', label: 'Bacias PV', componente: ComponenteArvore },
      ]}
      renderizarEm={<ArvoreWrapper />} // Este Wrapper possui um elemento :before
      componente={<VisibilidadeWrapper />}
    >
      <CorSelecaoSimultanea>
        <span>
          Selecione itens para atribuir uma cor:
          <CaixaSeletorDeCorInline
            cor={refCorSelecao.current}
            onClick={abrirSeletor}
          />
          {
            refDialogoCores.current && (
              createPortal(
                <SeletorCorWrapper
                  tabIndex={-1}
                  ref={refSeletorWrapper}
                >
                  <SeletorCor
                    top={seletorPopper.top}
                    left={seletorPopper.left}
                    color={refCorSelecao.current}
                    onChange={onAlteraCor}
                  />
                </SeletorCorWrapper>,
                refDialogoCores.current.querySelector('.MuiDialog-container')
              )
            )
          }
        </span>
        <BotaoLimparSelecao onClick={limparSelecao}>
          Limpar Seleção
        </BotaoLimparSelecao>
      </CorSelecaoSimultanea>
      <PendenteAplicar>
        {faltaAplicarRegraCorPorRegiao && 'Existem regras selecionadas ainda não aplicadas'}
      </PendenteAplicar>
      {
        isAbaSelecionada && (
          criarPortalActions(
            <>
              <ActionsDialogoCores
                aplicar={aplicar}
                permitirAplicar={aplicavel}
              />
              <Botao onClick={limparCores}>
                Limpar Cores
              </Botao>
            </>
          )
        )
      }
    </VisibilidadeSelecaoSelect>
  );
}

export default AbaColorirRegioes;
