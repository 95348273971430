import Resource from './Resource';

export default class GetCandidatesPerYearAndRoleResource extends Resource {
  constructor(token, year, role, name) {
    super(token);
    this.year = year;
    this.role = role;
    this.name = name;
  }

  async result() {
    try {
      const { data, status } = await this.request.get(`/candidatos/${this.year}/${this.role}?nome=${this.name}`);

      if (status === 200) {
        return data; //eslint-disable-line
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return [];
  }
}
