import styled from 'styled-components';

import { Text } from '../../../../..';

/**
 * @type {StyledComponent<"div", { index: number }>}
 */
export const OptionContainer = styled.div`
  background-color: ${props => props.index % 2 === 0 ? '#e5e5e5' : '#b5b5b5'};
`;

export const Tip = styled(Text)`
  && {
    display: none;
    padding: 0 0 2px;
    border-radius: 4px;
    z-index: 10;
  }
`;
