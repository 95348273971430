import styled from 'styled-components';

import Typography from '@mui/material/Typography';

export const Prompt = styled(Typography)`
  && {
    color: #444;
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const FiltersOptions = styled.div`
  && {
    margin-bottom: 60px;
    padding-top: 20px;
    padding-left: 5px;
  }
`;

export const FilterSubject = styled.div`
  && {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
`;

export const FilterType = styled(Typography)`
  && {
    font-size: 1.125rem;
    text-transform: uppercase;
  }
`;
