import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export const StyledTip = styled(Typography)`
  && {
    color: #fff;
    display: none;
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    font-size: 14;
    padding: 10px;
    border-radius: 4px;
    z-index: 10;
  }
`;