import React, {
  memo,
  useLayoutEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  ColorContext,
  DataContext,
  MapContext,
} from '../../../../../../../../contexts';

import {
  getBrightness,
  getMeanBrightness,
  numberAbbreviation,
} from '../../../../../../../../utils';

function Label(props) {
  const {
    type,
    initialData,
    classes,
    x,
    y
  } = props;

  const { getResult, isSingleFilter } = useContext(DataContext);

  const { metodoColoracao, coresCidades } = useContext(ColorContext);

  const {
    createCompoundColorPattern,
    getSums,
    labelsSize,
    numberLabels,
    refFontStatus,
    refMap,
    setPatterns,
  } = useContext(MapContext);

  const filterData = useMemo(() => getResult(), [getResult]);

  /**
   * Essa função calcula a quebra de linha de uma dada string,
   * geralmente o nome da cidade, em diversas linhas.
   * @param {string} name Nome da cidade
   *
   * @returns Composição de linhas do nome da cidade
   */
  const divideWord = useCallback((name) => (
    name.split(' ').reduce((total, item) => {
      const { length } = total;
      if (total[length - 1].length <= 5 && `${total[length - 1]} ${item}`.length < 10) {
        total[length - 1] = `${total[length - 1]} ${item}`.trim();
      } else {
        total = [...total, item];
      }
      return total;
    }, ['']).filter((item) => item.length !== 0)
  ), []);

  const getDataId = useCallback(() => {
    const [identifierClass] = classes;

    return identifierClass.split('-').pop();
  }, [classes]);

  const nameData = useMemo(() => {
    const id = getDataId();
    if (!initialData[id]) return [];

    return type === 'city'
      ? divideWord(initialData[id].name)
      : [initialData[id].name];
  }, [divideWord, getDataId, initialData, type]);

  const numberData = useMemo(() => {
    if (type !== 'city' || numberLabels.length === 0) return [];

    const id = getDataId();
    if (isSingleFilter) {
      const quantity = filterData.result && filterData.result[id];
      return quantity ? [[id, quantity]] : [];
    }

    const cityNumbers = numberLabels
      .reduce((total, label) => {
        const [type, filter] = label.split('_');
        const filterObject = filterData[type].find(({ settings }) => settings.title.join(', ') === filter); // encontra filtro
        if (filterObject && filterObject.result[id]) { // verifica cidade
          return {
            ...total,
            [label]: filterObject.result[id],
          };
        }

        return total;
      }, {});

    if (cityNumbers.length === 0) return [];

    const data = [];
    const { eventsSum, politicsSum, peopleSum, emendasSum } = getSums(cityNumbers);

    if (eventsSum) data.push(['SUM_EVENTS', eventsSum]);
    if (politicsSum) data.push(['SUM_POLITICS', politicsSum]);
    if (peopleSum) data.push(['SUM_PEOPLE', peopleSum]);
    if (emendasSum) data.push(['SUM_EMENDAS', emendasSum]);

    data.push(
      ...Object.entries(cityNumbers).reduce((total, [typedFilter, quantity]) => {
        const [, filter] = typedFilter.split('_');

        return [
          ...total,
          [filter, quantity],
        ];
      }, [])
    );

    return data;
  }, [getDataId, getSums, isSingleFilter, numberLabels, type, filterData]);

  const getAllNumberTspan = useCallback(() => (
    Array.from(refMap.current.querySelectorAll(`g.city-${getDataId()} tspan.number-label`))
  ), [refMap, getDataId]);

  const [badges, setBadges] = useState([]);
  const [badgesColors, setBadgesColors] = useState({});

  const getAllColorsOfFilter = useCallback((filter) => {
    if (filter.includes('SUM')) {
      const [, sumType] = filter.split('_');
      const sumFilters = filterData[sumType.toLowerCase()]
        .filter(({ settings }) => (
          numberLabels.find(label => label.match(`${sumType.toLowerCase()}_${settings.title.join(', ')}`))
        ));
      const filtersToSum = sumFilters.map(({ settings }) => settings.title.join(', '));

      return metodoColoracao.regras
        .filter(({ item }) => filtersToSum.includes(item))
        .map(({ cor = undefined }) => cor || '#000');
    }
    return metodoColoracao.regras
      .filter(({ item }) => item === filter)
      .map(({ cor }) => cor);
  }, [metodoColoracao.regras, filterData, numberLabels]);

  useLayoutEffect(() => {
    if (isSingleFilter || numberData.length === 0) {
      setBadges([]);
      setBadgesColors({});
      return;
    }

    const allNumberLabels = getAllNumberTspan();
    const badgesColors = {};
    const newPatterns = [];
    const badges = allNumberLabels.map(tspan => {
      const pathId = getDataId();
      const { filter } = tspan.dataset;

      if (coresCidades[pathId]) {
        // pega todas as cores que validas para este filtro
        const filterColors = getAllColorsOfFilter(filter);
        // das cores do filtro, seleciona aquelas que sao válidas nesta cidade
        const badgeItemColors = filterColors
          .filter(item => coresCidades[pathId].includes(item));
        // resultado `badgeItemColors` sao cores da badge atual

        if (badgeItemColors.length > 0) {
          if (badgeItemColors.length > 1) {
            // badge tem mais de uma cor
            const patternId = `_${badgeItemColors.join('-')}-badge`;
            if (!newPatterns.find(pattern => pattern.id === patternId)) {
              newPatterns.push(
                createCompoundColorPattern({ patternId, patternColors: badgeItemColors }, 25)
              );
              badgesColors[filter] = badgeItemColors;
            }
          } else {
            // badge tem uma cor
            const [color] = badgeItemColors;
            badgesColors[filter] = [color];
          }
        } else {
          // quando a badge nao tem cores
          badgesColors[filter] = ['#000'];
        }
      } else {
        // quando a cidade nao tem cores
        badgesColors[filter] = ['#000'];
      }

      const { x, y, width, height } = tspan.getBBox();

      return {
        x: x - (width * 0.02),
        y: y + (height * 0.05),
        width,
        height: height * 0.9,
        filter,
      };
    });

    setPatterns(old => [
      ...old,
      ...newPatterns.filter(item => !old.find(oldItem => oldItem.id === item.id)),
    ]);
    setBadges(badges);
    setBadgesColors(badgesColors);
  }, [
    coresCidades,
    createCompoundColorPattern,
    getAllColorsOfFilter,
    getDataId,
    getAllNumberTspan,
    isSingleFilter,
    numberData,
    setPatterns,
  ]);

  useLayoutEffect(() => {
    if (!isSingleFilter) {
      const allNumberLabels = getAllNumberTspan();
      const updatedBadges = allNumberLabels.map(tspan => {
        const { x, y, width, height } = tspan.getBBox();
        const { filter } = tspan.dataset;

        return {
          x: x - (width * 0.02),
          y: y + (height * 0.05),
          width,
          height: height * 0.9,
          filter,
        };
      });

      setBadges(updatedBadges);
    }
  }, [isSingleFilter, labelsSize, getAllNumberTspan]);

  const badgesRules = useMemo(() => {
    const [id] = classes;

    return Object.entries(badgesColors)
      .flatMap(([filter, colors]) => {
        if (colors.length > 1) {
          const gradient = `#_${colors.join('-')}-badge`;
          const light = getMeanBrightness(colors);
          return [
            `rect.${id}[data-filter="${filter}"]`,
            `{ fill: url("${gradient}"); stroke: url("${gradient}"); }`,
            (
              light < 80
                ? `tspan[data-filter="${filter}"] { fill: white; }`
                : ''
            ),
          ];
        }

        const [color] = colors;
        const light = getBrightness(color);
        return [
          `rect.${id}[data-filter="${filter}"]`,
          `{ fill: ${color}; stroke: ${color}; }`,
          (
            light < 80
              ? `tspan[data-filter="${filter}"] { fill: white; }`
              : ''
          ),
        ];
      });
  }, [badgesColors, classes]);

  return (
    <g
      className={`${type}-label-group ${classes[0]}`}
      badgesColors={badgesColors}
      data-x={0}
      data-y={0}
      data-override-x={x}
      data-override-y={y}
      data-class-id={classes[0]}
    >
      <style>{badgesRules}</style>
      {
        badges.map(badge => (
          <rect
            data-filter={badge.filter}
            className={classes[0]} // classe de identificação
            width={badge.width}
            height={badge.height}
            x={badge.x}
            y={badge.y}
            rx={0.5}
          />
        ))
      }
      <text
        textAnchor="middle"
        className={classes.join(' ')}
        x={x}
        y={y}
      >
        {
          nameData.map(data => (
            <tspan
              className="name-label"
              dy={refFontStatus.current.size.cityFont}
              x={x}
            >
              {data}
            </tspan>
          ))
        }
        {
          numberData.map(([filter, data]) => (
            <tspan
              className="number-label"
              dy={refFontStatus.current.size.cityFont + 0.5}
              data-filter={filter}
              x={x}
            >
              {numberAbbreviation(data)}
            </tspan>
          ))
        }
      </text>
    </g>
  );
}

export default memo(Label);
