import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import {
  GetCitiesResource,
  GetDiocesesResource,
  GetRegionsResource,
  GetBaciasResource,
  GetAdmRegionsResource,
  GetAvailableFiltersResource,
} from '../../api/resources';

import './ContextTypes';

/**
 * @typedef {object} AppContextType
 * @prop {Filter[]} activeFilters
 * Conjunto de filtro ativos atualmente
 * @prop {() => void} addTaskRunning
 * Callback que indica que uma tarefa grande começou.
 *
 * Caso uma tarefa como essa esteja sendo processada, o componente `Loader` fica ligado.
 * @prop {LocationsInfo} admRegions
 * Conjunto de objetos de informações das regiões administrativas.
 * @prop {AuxiliarBoxProps} auxiliarBox
 * Objeto que armazena as propriedades atualmente aplicadas ao componente `AuxiliarBox`
 * @prop {LocationsInfo} bacias
 * Conjunto de objetos de informações das bacias.
 * @prop {CitiesInfo} cities
 * Conjunto de objetos de informações das cidades.
 * @prop {string} currentModule
 * Estado que monitora o módulo atual
 * @prop {DiocesesInfo} dioceses
 * Conjunto de objetos de informações das dioceses.
 * @prop {AvailableFilterOptions} filterOptions
 * Objeto que traz as informações disponíveis de filtro para o usuário do app.
 * @prop {React.MutableRefObject<boolean>} hasViewLoading
 * Referência que monitora se alguma visualização está sendo processada.
 * @prop {boolean} isLibcomSchema
 * Booleano que define se o schema logado é libcom.
 * @prop {boolean} isMobileDevice
 * Booleano que define se a aplicação está rodando em um dispositivo móvel.
 * @prop {boolean} isVisibleBorderRegionsDialog
 * Estado que define se o diálogo de mostrar contornos está vísivel.
 * @prop {boolean} menuClicked
 * Estado que define se o botão para expandir a componente `LateralMenu` foi clicado.
 * Se for verdade, `LateralMenu` se mantém expandido até um próximo clique.
 * @prop {AppOptionsType} options
 * Objeto que armazena algumas configurações atuais da aplicação.
 * @prop {RegionsInfo} regions
 * Conjunto de objetos de informações das regiões.
 * @prop {() => void} removeTaskRunning
 * Callback que indica que uma tarefa grande terminou.
 *
 * Caso nenhuma outra tarefa como essa esteja sendo processada,
 * o componente `Loader` fica desligado.
 * @prop {boolean} showExportDialog
 * Booleano que define se o diálogo de exportar imagem do mapa está vísivel.
 * @prop {boolean} showLoader
 * Booleano que define se o componente `Loader` está vísivel.
 * @prop {SetState<object[]>} setActiveFilters
 * SetState para o estado `activeFilters`
 * @prop {SetState<AuxiliarBoxProps>} setAuxiliarBox
 * SetState para o estado `auxiliarBox`
 * @prop {SetState<string>} setCurrentModule
 * SetState para o estado `currentModule`
 * @prop {SetState<boolean>} setLoader
 * SetState para o estado `loader`
 * @prop {SetState<boolean>} setMenuClicked
 * SetState para o estado `menuClicked`
 * @prop {SetState<AppOptionsType>} setOptions
 * SetState para o estado `options`
 * @prop {SetState<boolean>} setShowExportDialog
 * SetState para o estado `showExportDialog`
 * @prop {SetState<string>} setStateMenu
 * SetState para o estado `stateMenu`
 * @prop {SetState<boolean>} setVisibleBorderRegionsDialog
 * SetState para o estado `visibleBorderRegionsDialog`
 * @prop {"hidden" | "minimized" | "expanded"} stateMenu
 * Estado que monitora o estado atual do `LateralMenu`.
 * O estado `hidden` é usado apenas em dispositivos móveis.
 * @prop {string} token
 * Token gerado para a sessão da aplicação.
 * @prop {string[]} unbindedMapEvents
 * Estado que monitora quais componentes do mapa estão sem seus eventos.
 * @prop {(token: string) => Promise<boolean>} validateToken
 * Callback que valida o token de sessão gerado.
 */
/**
 * @type {React.Context<AppContextType>}
 */
const AppContext = createContext({});

export const AppProvider = ({ token, children }) => {
  const [currentModule, setCurrentModule] = useState('map');

  const taskRunningCounter = useRef(0);
  const [isTaskRunning, setTaskRunning] = useState(false);

  /**
   * Referencia que monitora se uma visualização está sendo carregada
   * @type {React.MutableRefObject<boolean>}
   */
  const hasViewLoading = useRef(false); // eslint-disable-line

  /**
   * Registra que uma tarefa demorada foi iniciada.
   * O state isTaskRunning é alterado.
   */
  const addTaskRunning = useCallback(() => {
    if (hasViewLoading.current) return;
    taskRunningCounter.current += 1;
    if (taskRunningCounter.current >= 1) {
      setTaskRunning(true);
    }
  }, []);

  /**
   * Registra que uma tarefa demorada foi concluída.
   * O state isTaskRunning é alterado.
   */
  const removeTaskRunning = useCallback(() => {
    if (hasViewLoading.current) return;
    taskRunningCounter.current -= 1;
    if (taskRunningCounter.current <= 0) {
      setTaskRunning(false);
    }
    if (taskRunningCounter.current < 0) taskRunningCounter.current = 0;
  }, []);

  /**
   * Estado que monitora diversas configurações da aplicação.
   * - showSearch: monitora pesquisa de cidades na HeaderBar
   * - showNames: monitora quais labels de nomes devem aparecer no mapa
   * - showQuantities: monitora se labels de quantidade devem aparecer no mapa
   * - showColors: monitora se o mapa deve estar colorido ou descolorido
   */
  const [options, setOptions] = useState({
    showSearch: false,
    showNames: {
      showCities: false,
      showRegions: false,
      showDioceses: false,
      showBacias: false,
      showAdmRegions: false,
    },
    showQuantities: false,
    showColors: false,
  });

  /**
   * Dados de localização carregados no início da aplicação
   */
  const [regions, setRegions] = useState({});
  const [dioceses, setDioceses] = useState({});
  const [cities, setCities] = useState({});
  const [bacias, setBacias] = useState({});
  const [admRegions, setAdmRegions] = useState({});

  /**
   * filterOptions: opções de filtro carregado no inicio da aplicação
   * activeFilters: array de filtros ativos após uma filtragem do mapa
   */
  const [filterOptions, setFilterOptions] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);

  /**
   * Estado do botão de menu na HeaderBar
   *
   * - menuClicked: monitora se o LateralMenu foi aberto pelo botão de menu
   */
  const [menuClicked, setMenuClicked] = useState(false);

  /**
   * Estados que controlam visibilidade de diferentes componentes
   */
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [isVisibleBorderRegionsDialog, setVisibleBorderRegionsDialog] = useState(false);

  /**
   * Estado que armazena configurações da legenda do mapa usada
   * quando o mapa é pintado.
   * - state: estado da legenda ('visible' ou 'hidden')
   * - title: texto de título
   * - description: texto de descrição
   * - content: conteúdo
   */
  const [auxiliarBox, setAuxiliarBox] = useState({
    state: 'hidden',
    title: '',
    description: [],
    content: <></>,
  });

  /** Booleano que define se o schema logado é libcom. */
  const isLibcomSchema = useMemo(() => {
    const domain = window.location.hostname;
    return domain.includes('libcom.org.br');
  }, []);

  /**
   * Essa função realiza a busca da lista de regiões
   * disponíveis na API
   * @returns Object Relação de regiões
   */
  const loadRegions = useCallback(async () => {
    const resource = new GetRegionsResource(token);
    const data = await resource.result();
    return data;
  }, [token]);

  /**
   * Essa função realiza a busca da lista de dioceses
   * disponíveis na API
   * @returns Object Relação de dioceses
   */
  const loadDioceses = useCallback(async () => {
    const resource = new GetDiocesesResource(token);
    const data = await resource.result();
    return data;
  }, [token]);

  /**
   * Essa função realiza a busca da lista de cidades
   * disponíveis na API
   * @returns Object Relação de cidades
   */
  const loadCities = useCallback(async () => {
    const resource = new GetCitiesResource(token);
    const data = await resource.result();
    return data;
  }, [token]);

  /**
   * Essa função realiza a busca da lista de bacias
   * disponíveis na API
   * @returns Object Relação de regiões administrativas
   */
  const loadBacias = useCallback(async () => {
    const resource = new GetBaciasResource(token);
    const data = await resource.result();
    return data;
  }, [token]);

  /**
   * Essa função realiza a busca da lista de regiões
   * administrativas disponíveis na API
   * @returns Object Relação de regiões administrativas
   */
  const loadAdmRegions = useCallback(async () => {
    const resource = new GetAdmRegionsResource(token);
    const data = await resource.result();
    return data;
  }, [token]);

  /**
   * Essa função busca a relação de filtros disponíveis
   * na API para utilização no app
   * @returns Promise<Object> Relação de filtros disponíveis na aplicação
   */
  const loadFilters = useCallback(async () => {
    const resource = new GetAvailableFiltersResource(token);
    const data = await resource.result();
    return data;
  }, [token]);

  /**
   * Este useEffect hook é disparado na inicialização da aplicação
   * para carregar a listagem de todos os tipos de localidades (regiões,
   * diocese, cidades ...) usados em toda aplicação e as opções de filtro.
   */
  useEffect(() => {
    addTaskRunning();
    Promise.all([
      loadRegions(),
      loadDioceses(),
      loadCities(),
      loadBacias(),
      loadAdmRegions(),
      loadFilters(),
    ]).then(result => {
      const [regions, dioceses, cities, bacias, admRegions, options] = result;
      setRegions(regions);
      setDioceses(dioceses);
      setCities(cities);
      setBacias(bacias);
      setAdmRegions(admRegions);
      setFilterOptions(options);
    }).catch(error => {
      console.error(error); //eslint-disable-line
    }).finally(() => {
      removeTaskRunning();
    });
  }, [
    addTaskRunning,
    loadAdmRegions,
    loadBacias,
    loadCities,
    loadDioceses,
    loadFilters,
    loadRegions,
    removeTaskRunning,
    token,
  ]);

  /**
   * Este estado indica se a aplicação está
   * rodando em um dispositivo móvel
   * @type boolean Verdadeiro se está rodando em um dispositivo móvel
   */
  const isMobileDevice = useMemo(() => {
    const result = navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i);

    if (Array.isArray(result)) {
      return result.length > 0;
    }
    return !!result;
  }, []);

  const [stateMenu, setStateMenu] = useState(isMobileDevice ? 'hidden' : 'minimized');

  return (
    <AppContext.Provider
      value={{
        activeFilters,
        addTaskRunning,
        admRegions,
        auxiliarBox,
        bacias,
        cities,
        currentModule,
        dioceses,
        filterOptions,
        hasViewLoading,
        isLibcomSchema,
        isMobileDevice,
        isTaskRunning,
        isVisibleBorderRegionsDialog,
        menuClicked,
        options,
        regions,
        removeTaskRunning,
        setActiveFilters,
        setAuxiliarBox,
        setCurrentModule,
        setMenuClicked,
        setOptions,
        setShowExportDialog,
        setStateMenu,
        setVisibleBorderRegionsDialog,
        showExportDialog,
        stateMenu,
        token,
      }}
    >
      {children({ isTaskRunning })}
    </AppContext.Provider>
  );
};

export default AppContext;
