import styled from 'styled-components';

/**
 * @typedef {object} FlexContainerProps
 * @prop {CSSProps["flexFlow"]} flow
 * Propriedade de estilo `flexDirection`
 * @prop {CSSProps["justifyContent"]} justify
 * Definição da centralização `justifyContent`
 * @prop {CSSProps["alignItems"]} align
 * Definição da centralização `alignItems`
 */

/**
 * Elemento `div` genérico e estilizado com `display: "flex"`
 *
 * Por padrão, tem
 * - `flexFlow: "row"`. Pode ser alterado pela prop `flow`.
 * - `alignItems: "stretch"`. Pode ser alterado pela prop `align`.
 * - `justifyContent: "flex-start"`. Pode ser alterado pela prop `justify`.
 *
 * Caso contrário os valores padrões são utilizados.
 *
 * @type {StyledComponent<"div", FlexContainerProps>}
 */
const FlexContainer = styled.div`
  && {
    display: flex;
    flex-flow: ${({ flow }) => flow || 'row'};
    align-items: ${({ align }) => align || 'stretch'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
  }
`;

export default FlexContainer;
