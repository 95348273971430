import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef
} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../styles.css';

import { AMENDMENTS_COLS } from '../../../../assets/constants';

import {
  rowCellBodyTemplate,
  multiselectItemTemplate,
  rowCellColorTextBgTemplate,
} from '../assets/templates';

function EmendasTable(props) {
  const { rows } = props;

  const [yearOptions, setYearOptions] = useState([]);
  const [organOptions, setOrganOptions] = useState([]);
  const [objectOptions, setObjectOptions] = useState([]);
  const [proponentOptions, setProponentOptions] = useState([]);
  const [situacaoOptions, setSituacaoOptions] = useState([]);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedOrgan, setSelectedOrgan] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedProponent, setSelectedProponent] = useState(null);
  const [selectedSituacao, setSelectedSituacao] = useState(null);

  const dt = useRef(null);

  // useEffect impede conflito de opções do multiselect na mudança de abas/anos
  // useEffect(() => {
  //   if (currentTab) {
  //     setSelectedYear(null);
  //     setSelectedOrgan(null);
  //     setSelectedObject(null);
  //     setSelectedProponent(null);
  //     setSelectedSituacao(null);
  //     dt.current.reset();
  //   }
  // }, [currentTab]);

  /**
   * Este useEffect pega as informações que estao contidas na tabela
   * para criar arrays de opçoes para os filtros de multiselect
   * existentes na tabela escolhido em InitialPage
   */
  useEffect(() => {
    if (rows.length > 0) {
      setYearOptions([
        ...new Set(rows.map((item) => item.Ano))
      ]);

      setOrganOptions([
        ...new Set(rows.map((item) => item.Orgão))
      ]);

      setObjectOptions([
        ...new Set(rows.map((item) => item.Objeto))
      ]);

      setProponentOptions([
        ...new Set(rows.map((item) => item.Proponente))
      ]);

      const array = rows.map((item) => (item.Situação.text));
      const values = new Set(array);
      const situations = Array.from(values).map(situation => (
        rows.map((item) => (item.Situação))
          .find(({ text }) => text === situation)
      ));
      setSituacaoOptions(situations);
    }
  }, [rows]);

  const onMultiSelectChange = (e, column) => {
    dt.current.filter(e.value, column, 'in');
    if (column === AMENDMENTS_COLS.year) {
      setSelectedYear(e.value);
    } else if (column === AMENDMENTS_COLS.situation) {
      setSelectedSituacao(e.value);
    } else if (column === AMENDMENTS_COLS.organ) {
      setSelectedOrgan(e.value);
    } else if (column === AMENDMENTS_COLS.object) {
      setSelectedObject(e.value);
    } else if (column === AMENDMENTS_COLS.proponent) {
      setSelectedProponent(e.value);
    }
  };

  const getFilterType = useCallback((key) => {
    if (
      key === AMENDMENTS_COLS.leader
      || key === AMENDMENTS_COLS.amount
      || key === AMENDMENTS_COLS.object
    ) {
      return 'Text';
    }

    if ([
      AMENDMENTS_COLS.year,
      AMENDMENTS_COLS.organ,
      AMENDMENTS_COLS.proponent,
      AMENDMENTS_COLS.situation,
    ].includes(key)) {
      return 'MultiSelect';
    }

    return '';
  }, []);

  const getKeyVariables = useCallback((key) => {
    if (key === AMENDMENTS_COLS.year) {
      return [selectedYear, yearOptions];
    }

    if (key === AMENDMENTS_COLS.organ) {
      return [selectedOrgan, organOptions];
    }

    if (key === AMENDMENTS_COLS.object) {
      return [selectedObject, objectOptions];
    }

    if (key === AMENDMENTS_COLS.proponent) {
      return [selectedProponent, proponentOptions];
    }

    if (key === AMENDMENTS_COLS.situation) {
      return [selectedSituacao, situacaoOptions];
    }

    return [];
  }, [
    yearOptions,
    organOptions,
    objectOptions,
    proponentOptions,
    selectedYear,
    selectedOrgan,
    selectedObject,
    selectedProponent,
    selectedSituacao,
    situacaoOptions,
  ]);

  const getFilterElement = useCallback((key, type) => {
    const [value, options] = getKeyVariables(key);
    if (type === 'MultiSelect') {
      return (
        <MultiSelect
          className="p-column-filter"
          value={value}
          options={options}
          optionLabel={(option) => option.text ? option.text : option}
          onChange={(e) => onMultiSelectChange(e, key)}
          itemTemplate={(option) => multiselectItemTemplate(option.text ? option.text : option)}
          placeholder="Todos"
        />
      );
    }
    return <></>;
  }, [getKeyVariables]);

  const columns = useMemo(() => {
    const [first] = rows;
    if (!first) {
      return [
        <Column />
      ];
    }
    return Object.keys(first).map((key) => {
      const filterType = getFilterType(key);
      const filterElement = getFilterElement(key, filterType);
      return (filterType !== 'Text')
        ? (
          <Column
            field={key}
            header={key}
            body={
              (rowData) => key === AMENDMENTS_COLS.situation
                ? rowCellColorTextBgTemplate(rowData, AMENDMENTS_COLS.situation)
                : rowCellBodyTemplate(key, rowData)
            }
            filter
            filterElement={filterElement}
            sortable
            sortField={(rowData) => (
              (key === AMENDMENTS_COLS.situation && rowData.Situação.text)
                ? rowData.Situação.text
                : rowData[key]
            )}
          />
        ) : (
          <Column
            field={key}
            header={key}
            body={(rowData) => rowCellBodyTemplate(key, rowData)}
            filter
            filterPlaceholder={(key === AMENDMENTS_COLS.amount) ? 'Mínimo' : 'Filtro por Nome'}
            filterMatchMode={(key === AMENDMENTS_COLS.amount) ? 'gte' : 'contains'}
            filterMaxLength={(key === AMENDMENTS_COLS.amount) ? 10 : 30}
            sortable
          />
        );
    });
  }, [getFilterElement, getFilterType, rows]);

  return (
    <div className="datatable-div">
      <div className="card">
        <DataTable
          ref={dt}
          value={rows}
          rows={rows.length}
          className="p-datatable-component"
          scrollable
          removableSort
          emptyMessage="Não há itens a serem listados."
        >
          {columns}
        </DataTable>
      </div>
    </div>
  );
}

export default EmendasTable;
