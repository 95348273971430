import React, {
  memo,
  useContext,
  useMemo,
} from 'react';

import {
  AppContext,
  BorderRegionsContext,
  ColorContext,
  MapContext,
} from '../../../../../../contexts';

/**
 * @returns {JSX.Element} Componente de estilização do SVG do mapa
 */
function SVGStyle() {
  const { options } = useContext(AppContext);

  const { coresCidades } = useContext(ColorContext);

  const {
    adjustLabelMode,
    labelsSize,
    showColors,
    showNames,
    showNumbers,
    visibleAdmRegions,
    visibleBacias,
    visibleCities,
    visibleDioceses,
    visibleRegions,
    currentCity,
  } = useContext(MapContext);

  const { borderType } = useContext(BorderRegionsContext);

  const cityColorRule = useMemo(() => {
    return [
      '.cities path',
      (
        showColors
          ? '{ fill: rgb(204, 204, 204); opacity: 1; }' // regra inicial de cores do mapa
          : '{ fill: rgb(204, 204, 204) !important; opacity: 1 !important; }' // reseta qualquer cor para a padrão
      ),
    ]
  }, [showColors]);

  const labelsFontSize = useMemo(() => {
    return [
      '.city-labels',
      `{ font-size: ${labelsSize.cityFont}px; }`,

      '.region-labels',
      `{ font-size: ${labelsSize.regionFont}px; }`,

      '.adm-region-labels, .bacia-labels, .diocese-labels',
      `{ font-size: ${labelsSize.othersFont}px; }`,
    ];
  }, [labelsSize]);

  const labelsPointer = useMemo(() => {
    if (adjustLabelMode === 'disabled') return [];
    return [
      '.labels text { pointer-events: initial; cursor: move; }'
    ];
  }, [adjustLabelMode]);

  const activeCityRule = useMemo(() => (
    currentCity.id !== -1
      ? [
        `path.city-${currentCity.id}[data-active="on"]`,
        '{ fill: green }',
      ]
      : []
  ), [currentCity.id]);

  const bordersColorRule = useMemo(() => {
    if (borderType === 'none') return [];
    if (borderType === 'region+diocese') {
      return [
        '.hulls .region-hull, .hulls .diocese-hull { stroke-opacity: 1; }',
        '.hulls .diocese-hull { stroke: #8d0cf0; }'
      ];
    }

    return [`.hulls .${borderType}-hull { stroke-opacity: 1; }`];
  }, [borderType]);

  const labelsVisibility = useMemo(() => {
    const {
      showAdmRegions,
      showBacias,
      showCities,
      showDioceses,
      showRegions,
    } = options.showNames;

    return [
      `g .adm-region-labels tspan.name-label { fill-opacity: ${showAdmRegions ? 1 : 0}; }`,
      `g .bacia-labels tspan.name-label { fill-opacity: ${showBacias ? 1 : 0}; }`,
      `g .city-labels tspan.name-label { fill-opacity: ${showCities ? 1 : 0}; }`,
      `g .diocese-labels tspan.name-label { fill-opacity: ${showDioceses ? 1 : 0}; }`,
      `g .region-labels tspan.name-label { fill-opacity: ${showRegions ? 1 : 0}; }`,

      `.labels tspan.name-label { fill-opacity: ${showNames ? 1 : 0}; }`,
      `.labels tspan.number-label { fill-opacity: ${showNumbers ? 1 : 0}; }`,
    ]
  }, [options.showNames, showNames, showNumbers]);

  const citiesVisibility = useMemo(() => {
    if (visibleCities.length === 0) return [];

    const citiesClasses = visibleCities.map(item => `.city-${item}`).join(', ');
    const admRegionsClasses = visibleAdmRegions.map(item => `.adm-region-${item}`).join(', ');
    const baciasClasses = visibleBacias.map(item => `.bacia-${item}`).join(', ');
    const diocesesClasses = visibleDioceses.map(item => `.diocese-${item}`).join(', ');
    const regionsClasses = visibleRegions.map(item => `.region-${item}`).join(', ');

    return [
      ...(
        citiesClasses.length > 0
          ? [
            `.cities path:not(${citiesClasses}) { display: none; }`,
            `.city-label-group:not(${citiesClasses}) { opacity: 0; }`
          ]
          : []
      ),
      ...(
        admRegionsClasses.length > 0
          ? [
            `.adm-region-hull:not(${admRegionsClasses}) { display: none; }`,
            `.adm-region-label-group:not(${admRegionsClasses}) { opacity: 0; }`
          ]
          : []
      ),
      ...(
        baciasClasses.length > 0
          ? [
            `.bacia-hull:not(${baciasClasses}) { display: none; }`,
            `.bacia-label-group:not(${baciasClasses}) { opacity: 0; }`
          ]
          : []
      ),
      ...(
        diocesesClasses.length > 0
          ? [
            `.diocese-hull:not(${diocesesClasses}) { display: none; }`,
            `.diocese-label-group:not(${diocesesClasses}) { opacity: 0; }`
          ]
          : []
      ),
      ...(
        regionsClasses.length > 0
          ? [
            `.region-hull:not(${regionsClasses}) { display: none; }`,
            `.region-label-group:not(${regionsClasses}) { opacity: 0; }`
          ]
          : []
      ),
    ]
  }, [visibleAdmRegions, visibleBacias, visibleCities, visibleDioceses, visibleRegions]);

  const cityColoring = useMemo(() => (
    Object.entries(coresCidades).map(([id, colors]) => {
      if (colors.length > 1) {
        const gradient = `#_${colors.join('-')}`;
        return `path.city-${id} { fill: url("${gradient}"); }`;
      }

      const [color] = colors;
      return `path.city-${id} { fill: ${color}; }`;
    })
  ), [coresCidades]);

  return (
    <style>
      {
        /* Lista de string para poder usar as chaves ("{" ou "}") */
        [
          'svg.mapa',
          `{
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed, supported by Chrome, Edge, Opera and Firefox */
          }`,

          '.cities path',
          '{ stroke: #fff; stroke-width: 0.3px; }',

          '.hulls path',
          '{ stroke: #000; stroke-width: 0.5px; fill: none; stroke-opacity: 0; }',

          '.labels text',
          '{ pointer-events: none; font-family: sans-serif; }',

          '.labels tspan.number-label',
          '{ font-family: monospace; }',

          '.diocese-labels',
          '{ fill: #8d0cf0; }',

          '.region-labels, .diocese-labels',
          '{ font-weight: bold; }',

          '.labels rect',
          '{ stroke-width: 0.4; }',

          ...cityColorRule,
          ...bordersColorRule,
          ...labelsFontSize,
          ...labelsPointer,
          ...labelsVisibility,
          ...citiesVisibility,
          ...cityColoring,
          ...activeCityRule,
        ]
      }
    </style>
  );
}

/**
 * Componente de estilização do SVG do mapa.
 */
export default memo(SVGStyle);
