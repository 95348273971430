import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import { FlexContainer } from '../../../../../..';

export const LegendaAuxiliar = styled(FlexContainer)`
  & {
    padding: .625rem;

    .item-legenda-auxiliar-cor {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: .25rem .375rem;
    }
  }
`;

export const CorItemLegenda = styled.div`
  & {
    width: 20px;
    aspect-ratio: 4 / 3;
    background-color: ${props => props.cor};
  }
`;

export const TextoItemLegenda = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    white-space: nowrap;
    margin-inline-start: .75rem;
    height: 1.25em; // centraliza o texto num elemento de texto (p/span)
  }
`;
