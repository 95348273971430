import React, {
  forwardRef
} from 'react';

import Typography from '@mui/material/Typography';

import { ComponenteRadio, Controlador } from './styles';

/**
 * @typedef {object} RadioButtonProps
 * @prop {React.ReactNode} children Rotulo do componente
 * @prop {boolean} disabled Booleano que desabilita o componente
 * @prop {CSSProps} formControlStyle Estilização do componente <FormControlLabel>
 * @prop {boolean} selected Booleano que define se o <Radio> está selecionado
 * @prop {string} size Tamanho do componente <Radio>
 * @prop {CSSProps} style Estilização do componente <Radio>
 * @prop {unknown} value Valor do componente
 */
/**
 * @param {RadioButtonProps} props Props do componente
 * @param {React.ForwardedRef<HTMLLabelElement>} ref Referencia ao elemento raiz
 * @returns {JSX.Element} Componente RadioButton baseado em <FormControlLabel>
 */
function RadioButton(props, ref) {
  const {
    children,
    disabled,
    selected,
    size,
    style,
    value,
  } = props;

  return (
    <Controlador
      ref={ref}
      label={<Typography style={{ fontSize: 14 }}>{children}</Typography>}
      value={value}
      control={<ComponenteRadio {...{ selected, disabled, size }} style={style} />}
    />
  );
}

export default forwardRef(RadioButton);
