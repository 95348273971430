import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react';

import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@material-ui/core/Typography';

import {
  Dialog,
  TextField,
  Botao,
  RadioButton,
  Select,
} from '../../../../components';

import {
  ExportImageContext,
} from '../../../../contexts';

import { analytics } from '../../../../index';

function ExportImageDialog(props) {
  const {
    show,
    onClose,
  } = props;

  const { exportImage } = useContext(ExportImageContext);

  const [fileFormat, setFileFormat] = useState('png');
  const [size, setSize] = useState(1);
  const [backgroundOption, setBackgroundOption] = useState('transparent');

  const [filename, setFilename] = useState('');
  const formatOptions = useMemo(() => [
    { id: 'png', label: 'PNG' },
    { id: 'svg', label: 'SVG' },
  ], []);

  const sizeOptions = useMemo(() => [
    { id: 1, label: '1x' },
    { id: 2, label: '2x' },
    { id: 5, label: '5x' },
    { id: 10, label: '10x' },
  ], []);

  const handleExportClick = useCallback(() => {
    exportImage(filename, fileFormat, { size, background: backgroundOption });
    onClose();
    analytics.logEvent('dialog-exportar-imagem-aplicar-exportacao');
  }, [backgroundOption, exportImage, fileFormat, filename, onClose, size]);

  const showPNGOptions = useMemo(() => (
    fileFormat === 'png'
  ), [fileFormat]);

  return (
    <Dialog
      title="Exportar imagem"
      {...{ show, onClose }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
      >
        <TextField
          placeholder="Digite o nome do arquivo"
          label="Nome do arquivo"
          value={filename}
          onChange={({ target }) => setFilename(target.value)}
          style={{
            width: '100%',
            marginBottom: 5,
          }}
        />
        <div
          style={{
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Select
            label="Selecione o formato"
            style={{
              width: 'calc(50% - 10px)',
              minWidth: 160,
            }}
            value={fileFormat}
            onChange={(event) => setFileFormat(event.target.value)}
          >
            {formatOptions}
          </Select>
          {
            showPNGOptions && (
              <Select
                label="Selecione o tamanho"
                style={{
                  width: 'calc(50% - 10px)',
                  minWidth: 160,
                }}
                value={size}
                onChange={(event) => setSize(event.target.value)}
              >
                { sizeOptions }
              </Select>
            )
          }
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: 5,
            marginLeft: 10,
            marginRight: 15,
          }}
        >
          {
            showPNGOptions && (
              <RadioGroup
                style={{ marginRight: 4 }}
                value={backgroundOption}
                onChange={(event) => setBackgroundOption(event.target.value)}
              >
                <Typography
                  style={{
                    color: '#222',
                    fontSize: 14,
                  }}
                >
                  Selecione a cor de fundo da imagem:
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <RadioButton
                    value="transparent"
                    selected={backgroundOption === 'transparent'}
                  >
                    Transparente
                  </RadioButton>
                  <RadioButton
                    value="white"
                    selected={backgroundOption === 'white'}
                  >
                    Branco
                  </RadioButton>
                </div>
              </RadioGroup>
            )
          }
          <Botao
            style={{
              color: '#fff',
              marginTop: 6,
              backgroundColor: '#3498db',
              width: 110,
              height: 35,
              alignSelf: 'center',
            }}
            onClick={handleExportClick}
            disabled={filename.length < 4}
          >
            Exportar
          </Botao>
        </div>
      </div>
    </Dialog>
  );
}

export default ExportImageDialog;
