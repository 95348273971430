import { useReducer, useCallback, useEffect } from 'react';

import { chaveExisteEm } from '../../../utils';

import NoArvoreExpansivel from './NoArvoreExpansivel';

function toggleEstado(estado) {
  return estado !== 'selecionado' ? 'selecionado' : 'nao-selecionado';
}

function reducer(atual, action) {
  if (typeof action === 'function') return action(atual, reducer);

  const { tipo, posicao, estado, maximizado, ...resto } = action;
  let no;
  switch (tipo) {
    case 'INICIALIZAR':
      return resto.inicializar(atual);

    case 'REINICIALIZAR':
      return resto.inicializar(atual, true);

    case 'ALTERA_ESTADO_NO':
      no = atual.getNo(posicao);
      no.estado = estado;
      if (no.entidade) {
        no.getLista().forEach((item) => {
          reducer(atual, { tipo: 'ALTERA_ESTADO_NO', posicao: item.posicao, estado: no.estado });
        });
      }
      return new NoArvoreExpansivel(atual);

    case 'ALTERNA_ESTADO_NO':
      return reducer(atual, { tipo: 'ALTERA_ESTADO_NO', posicao, estado: toggleEstado(atual.getNo(posicao).estado) });

    case 'ALTERA_MAXIMIZADO_NO':
      no = atual.getNo(posicao);
      no.maximizado = maximizado;
      return new NoArvoreExpansivel(atual);

    case 'ALTERA_NO':
      no = atual.getNo(posicao);
      no.dados = { ...no.dados, ...resto };
      if (chaveExisteEm(action, 'estado')) reducer(atual, { tipo: 'ALTERA_ESTADO_NO', posicao, estado });
      if (chaveExisteEm(action, 'maximizado')) reducer(atual, { tipo: 'ALTERA_MAXIMIZADO_NO', posicao, maximizado });
      return new NoArvoreExpansivel(atual);

    default:
      return atual;
  }
}

function useArvoreExpansivel(callback, dependencies) {
  const [arvore, dispatch] = useReducer(reducer, new NoArvoreExpansivel([], []));

  const inicializar = useCallback(callback, dependencies);

  useEffect(() => dispatch({ tipo: 'INICIALIZAR', inicializar }), [inicializar]);

  return [arvore, dispatch];
}

export default useArvoreExpansivel;
