import React, { memo } from 'react';

import {
  Collapser,
  ControlButton,
  Expander,
} from './styles';

/**
 * @typedef {object} ExpandControlBoxProps
 * @prop {boolean} maximized Booleano que define o estado das opções
 * @prop {VoidFunction} onClick Callback de manipulaçao do estado das opçoes
 */
/**
 * @param {ExpandControlBoxProps} props
 * @returns {JSX.Element} Caixa de controle que expande/colapsa as opçoes de isolamento
 */
function ExpandControlBox(props) {
  const { maximized, onClick } = props;

  return (
    <ControlButton>
      {
        maximized
          ? <Collapser onClick={onClick} />
          : <Expander onClick={onClick} />
      }
    </ControlButton>
  );
}

/**
 * Caixa de controle que expande/colapsa as opçoes de isolamento
 * dado uma função de controle de estado
 * @param maximized Booleano que define o estado das opções
 * @param onClick Callback de controle do estado das opções
 *
 */
export default memo(ExpandControlBox);
