import axios from 'axios';

/**
 * @typedef {object} RequestConfig
 * @prop {string} baseURL
 * @prop {object} [headers]
 * @prop {string} headers.Authorization
 */

/**
 * @class
 * Classe "abstrata" para implementação de requisições.
 * @member {string} token
 * @member {RequestConfig} request
 */
export default class Request {
  /**
   * @param {string} token
   * Token de autorização da sessão atual
   */
  constructor(token) {
    if (token) {
      this.token = token;
      this.request = axios.create({
        baseURL: Request.getBaseURL(),
        headers: {
          Authorization: this.token,
          app: 9,
        }
      });
    } else {
      this.request = axios.create({
        baseURL: Request.getBaseURL(),
      });
    }
  }

  static getBaseURL() {
    const path = window.location.href;
    if (
      path.includes('develop')
      || path.includes('localhost')
      || path.includes('10.0.0.10')
    ) {
      return 'https://api-bi-develop.uc.r.appspot.com';
    }
    return 'https://api-bi-production.uc.r.appspot.com';
  }
}
