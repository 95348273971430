import React, {
  memo,
  useCallback,
  useContext,
} from 'react';

import Typography from '@material-ui/core/Typography';

import {
  Dialog,
  Botao,
  RadioButton
} from '../../../../components';

import {
  BorderRegionsContext,
} from '../../../../contexts';

import { analytics } from '../../../../index';

import { GrupoRadioContornos } from './styles';

function BorderRegionsDialog(props) {
  const { isVisible, setVisible } = props;

  const {
    option,
    setOption,
    setBorderType
  } = useContext(BorderRegionsContext);

  const handleCloseClick = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleApply = useCallback(() => {
    setBorderType(option);
    handleCloseClick();
    analytics.logEvent('dialog-limites-regioes-aplicar-contorno');
  }, [handleCloseClick, option, setBorderType]);

  return (
    <Dialog
      height={400}
      title="Contornos das regiões"
      show={isVisible}
      onClose={handleCloseClick}
    >
      <Typography
        style={{
          color: '#444',
          fontSize: 14,
          marginBottom: 10,
        }}
      >
        Selecione qual limite deve ser exibido:
      </Typography>
      <div
        style={{
          marginBottom: 60,
          paddingTop: 20,
          paddingLeft: 5,
        }}
      >

        <GrupoRadioContornos
          value={option}
          onChange={(event) => {
            setOption(event.target.value);
          }}
        >
          <RadioButton
            value="none"
            selected={option === 'none'}
          >
            Nenhum
          </RadioButton>
          <RadioButton
            value="bacia"
            selected={option === 'bacia'}
          >
            Bacias
          </RadioButton>
          <RadioButton
            value="diocese"
            selected={option === 'diocese'}
          >
            Dioceses
          </RadioButton>
          <RadioButton
            value="region"
            selected={option === 'region'}
          >
            Regiões
          </RadioButton>
          <RadioButton
            value="region+diocese"
            selected={option === 'region+diocese'}
          >
            Regiões + Dioceses
          </RadioButton>
          <RadioButton
            value="adm-region"
            selected={option === 'adm-region'}
          >
            Regiões Administrativas
          </RadioButton>
        </GrupoRadioContornos>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100% - 20px)',
          justifyContent: 'flex-end',
          padding: '10px',
          backgroundColor: '#fff',
          zIndex: 10,
        }}
      >
        <Botao
          style={{
            backgroundColor: '#3498DB',
            color: '#fff',
            margin: '10px',
            padding: '5px 15px',
          }}
          onClick={handleApply}
        >
          Aplicar
        </Botao>
      </div>
    </Dialog>
  );
}

export default memo(BorderRegionsDialog);
