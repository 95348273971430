import Resource from './Resource';

import '../../contexts/FilterContext/ContextTypes';
import { emptyObject } from '../../utils/utils';

function compoundTypeToRequest(type) {
  switch (type) {
    case 'eventos':
    case 'pessoas':
    case 'participantes':
      return 'pessoas'; // requisitar filtros compostos de pessoas

    default:
      return '';
  }
}
export default class GetFilterResource extends Resource {
  /**
   * Resource para requisição de filtragem do mapa.
   * Pode buscar dados baseado em uma lista de filtros FieldEvents
   * @constructor
   * @param {string} token Token de Sessão
   * @param {FieldEvent[]} filter Array de objetos com propriedades de filtro
   */
  constructor(token, filter) {
    super(token);
    this.singles = filter.filter(item => Array.isArray(item.label));
    this.compounds = filter.filter(item => typeof item.id === 'number');
  }

  generateURLFilter() {
    const singlesList = this.singles.map(fieldEventItem => {
      let request = '';
      let query = new URLSearchParams();
      const params = {};

      const { filter } = fieldEventItem;
      switch (filter.type) {
        case 'Eventos':
          request = 'pessoas';

          params.eventos = `${filter.name}.${filter.year}`;
          if (filter.version) params.eventos += `.${filter.version}`;
          // break 'Eventos'
          break;

        case 'Político':
          request = filter.name;

          switch (filter.name) {
            case 'politicos':
              params.ano = filter.year;
              if (filter.cargo) params.cargos = filter.cargo;
              if (filter.partido) params.partidos = filter.partido;
              if (filter.project) params.projetos = filter.project;
              // endpoint já permite filtrar por situacao (app falta implementar)
              // if (item.status) params.situacoes = item.status
              break;

            case 'tse':
              params.ano = filter.year;
              if (filter.cargo) params.cargos = filter.cargo;
              if (filter.partido) params.partidos = filter.partido;
              // endpoint já permite filtrar por situacao (app falta implementar)
              // if (item.status) params.situacoes = item.status
              break;

            case 'candidatos':
              params.ano = filter.year;
              params.cargo = filter.role;
              params.nome = filter.candidato;
              break;

            case 'votos-validos':
              // votos-validos atualmente nao tem parametros
              break;

            default: break;
          }

          // break 'Político'
          break;

        case 'Emendas':
          request = 'emendas';

          params.ano = filter.year;
          if (filter.agent !== 'total') params.mandatario = filter.agent;
          if (filter.organ !== 'total') params.orgao = filter.organ;

          // break 'Emendas'
          break;

        case 'Pessoas':
          request = 'pessoas';

          switch (filter.name) {
            case 'perfis':
              params.perfis = filter.profile;
              break;

            case 'cores':
              params.cor = filter.color;
              if (filter.year) {
                request = 'cores-anos';
                params.ano = filter.year;
              }
              break;

              // case 'cores-anos':
              //   implementar filtro cor por ano separad do de cores
              //   app ainda nao permitir isso por causa do formulario
              //   break;

            case 'grupos':
              params.grupos = filter.group;
              break;

            case 'cadastros':
              request = 'cadastros';
              params.ano = filter.year;
              break;

            // não deve cair em default nunca
            default: break;
          }

          // break 'Pessoas'
          break;

        // não deve cair em default nunca
        default: break;
      }

      // filtros sem parametros, passam apenas a 'request'
      if (emptyObject(params)) return request;

      query = new URLSearchParams(params);
      return `${request}?${query}`;
    });

    const compoundsList = this.compounds.map(item => {
      const request = compoundTypeToRequest(item.type);
      let query = new URLSearchParams();

      const params = item.filters.reduce((p, fieldEventItem) => {
        const { filter } = fieldEventItem;
        if (filter.type === 'Pessoas') {
          switch (filter.name) {
            case 'perfis':
              p.perfis = [...(p.perfis || []), filter.profile];
              break;

            case 'grupos':
              p.grupos = [...(p.grupos || []), filter.group];
              break;

            case 'cores':
              p.cor = filter.color;
              break;

            // não deve cair em default nunca
            default: break;
          }
        } else if (fieldEventItem.filter.type === 'Eventos') {
          switch (filter.name) {
            case 'efl':
              p.eventos = [...(p.eventos || []), `efl.${filter.year}`];
              break;

            case 'svn':
              p.eventos = [...(p.eventos || []), `svn.${filter.year}${filter.version ? `.${filter.version}` : ''}`];
              break;

            default: break;
          }
        }

        return p;
      }, {});

      if (emptyObject(params)) return request;

      query = new URLSearchParams(params);
      return `${request}?${query}`;
    });

    return [...singlesList, ...compoundsList].join(';');
  }

  buildFilter() {
    const filterQuery = this.generateURLFilter();
    return new URLSearchParams({ filtros: filterQuery });
  }

  /**
   * @typedef {object} ItemFiltro
   * @prop {string} tipo
   * @prop {string} subtipo
   * @prop {Record<string, any>} parametros
   * @prop {Record<number, any> | null} resultado
   */
  /**
   * Método de busca de dados no endpoint `/filters/composite`
   * @returns {Array<ItemFiltro>}
   * Objeto de resultado da filtragem
   */
  async result() {
    try {
      const params = this.buildFilter();
      const response = await this.request.get(`/filtros/multiplos/?${params}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    return {};
  }
}
