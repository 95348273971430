import { chaveExisteEm } from '../../../utils';

class NoArvoreExpansivel {
  /** @public */
  atualizarEstado() {
    const lista = this.getLista().filter(item => item.estado !== 'desabilitado');
    if (lista.length === 0) return 'desabilitado';

    if (lista.every(item => item.estado === 'selecionado')) return 'selecionado';

    return (
      lista.some(item => item.estado.match(/^(selecionado|incompleto)$/))
        ? 'incompleto'
        : 'nao-selecionado'
    );
  }

  /** @private */
  setNoProps(no) {
    this.entidades = no instanceof NoArvoreExpansivel ? no.getEntidades() : no.entidades;
    this.entidade = no.entidade;
    this.subentidade = no.subentidade;
    this.raiz = no instanceof NoArvoreExpansivel ? no.getRaiz() : no.raiz;
    this.posicao = no.posicao;

    if (this.entidade) {
      this[this.entidade] = no[this.entidade].map(item => new NoArvoreExpansivel(item));
    }

    this.nome = no.nome;
    this.estado = this.entidade ? this.atualizarEstado() : no.estado;
    this.maximizado = no.maximizado;
    this.dados = no.dados;
  }

  constructor(arg1, arg2, arg3 = { posicao: [], raiz: null }) {
    if (Array.isArray(arg1)) {
      const { posicao, raiz, estado, maximizado, ...dados } = arg3;

      /** @private */
      this.entidades = arg1;
      /** @public */
      this.entidade = this.entidades.at(0);
      /** @public */
      this.subentidade = this.entidades.at(1);
      /** @private */
      this.raiz = chaveExisteEm(arg3, 'raiz') ? raiz : null;
      /** @public */
      this.posicao = chaveExisteEm(arg3, 'posicao') ? posicao : [];

      if (this.entidade && Array.isArray(arg2)) {
        /** @private */
        this[this.entidade] = (
          arg2.map((item, index) => (
            new NoArvoreExpansivel(
              this.entidades.slice(1),
              this.subentidade && item[this.subentidade],
              {
                ...item,
                posicao: [...arg3.posicao, index],
                raiz: this.raiz || this,
              }
            )
          ))
        );
      }

      /** @public */
      this.nome = arg3.nome;
      /** @public */
      this.estado = chaveExisteEm(arg3, 'estado') ? estado : 'nao-selecionado';
      /** @public */
      this.maximizado = chaveExisteEm(arg3, 'maximizado') ? maximizado : false;
      /** @public */
      this.dados = dados;
      delete this.dados[this.entidade];
    } else if (arg1 !== null && typeof arg1 === 'object') {
      this.setNoProps(arg1);
    }
  }

  /** @public */
  getRaiz() {
    return this.raiz;
  }

  /** @public */
  getEntidades() {
    return this.entidades;
  }

  /** @public */
  getLista() {
    return this[this.entidade] || [];
  }

  /** @public */
  getNosEntidade(entidade) {
    if (this.entidade === undefined) return [];
    if (this.entidade === entidade) return this.getLista();
    return this.getLista().flatMap(item => item.getNosEntidade(entidade));
  }

  /** @public */
  getNo(posicao) {
    return posicao.reduce((final, index) => final.getLista()[index], this);
  }

  /** @public */
  setNo(posicao, valor) {
    const lista = this.getNo(posicao.slice(0, -1));
    lista[posicao[posicao.length - 1]] = valor;
  }

  /** @public */
  getNoPai() {
    if (this.raiz === null) return null;
    return this.raiz.getNo(this.posicao.slice(0, -1));
  }

  /** @public */
  clonarSemReferenciaCiclica() {
    const clone = new NoArvoreExpansivel(this);

    clone.getEntidades().forEach(entidade => {
      clone.getNosEntidade(entidade).forEach(item => {
        delete item.raiz;
      });
    });
    delete clone.raiz;

    return clone;
  }
}

export default NoArvoreExpansivel;
