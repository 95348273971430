import React, {
  memo,
  useCallback,
  useMemo
} from 'react';

import {
  CheckBox,
  FlexContainer,
} from '../../../../../../components';

import ExpandControlBox from '../ExpandControlBox';

function AdmRegionsTab(props) {
  const {
    isActive,
    isolateAdmRegions,
    setIsolateAdmRegions,
    isolateCities,
    setIsolateCities,
  } = props;

  const updateAdmRegion = useCallback((admRegion, result) => {
    const items = Object.entries(isolateCities)
      .filter(([, city]) => city.admRegion === admRegion);
    const quantity = items.filter(([, city]) => city.status !== 'unchecked').length;

    if (result === 'checked' && quantity === items.length - 1) {
      setIsolateAdmRegions(old => ({ ...old, [admRegion]: { ...old[admRegion], status: 'checked' } }));
    } else if (result === 'unchecked' && quantity === 1) {
      setIsolateAdmRegions(old => ({ ...old, [admRegion]: { ...old[admRegion], status: 'unchecked' } }));
    } else {
      setIsolateAdmRegions(old => ({ ...old, [admRegion]: { ...old[admRegion], status: 'incomplete' } }));
    }
  }, [isolateCities, setIsolateAdmRegions]);

  const handleCheckCity = useCallback((id) => {
    setIsolateCities(old => {
      const result = old[id].status === 'unchecked' ? 'checked' : 'unchecked';
      const { admRegion } = old[id];
      updateAdmRegion(admRegion, result);
      return {
        ...old,
        [id]: { ...old[id], status: result }
      };
    });
  }, [setIsolateCities, updateAdmRegion]);

  const handleMaximizedAdmRegion = useCallback((id) => {
    setIsolateAdmRegions(old => ({
      ...old,
      [id]: { ...old[id], maximized: !old[id].maximized }
    }));
  }, [setIsolateAdmRegions]);

  const handleCheckAdmRegion = useCallback((id) => {
    setIsolateAdmRegions(old => ({
      ...old,
      [id]: { ...old[id], status: old[id].status === 'unchecked' ? 'checked' : 'unchecked' }
    }));
  }, [setIsolateAdmRegions]);

  const content = useMemo(() => (
    Object.entries(isolateAdmRegions).map(([idAdmRegion, admRegion]) => (
      <React.Fragment key={`fragment-${idAdmRegion}`}>
        <FlexContainer
          key={`div-adm-region-${idAdmRegion}`}
          justify="space-between"
          align="center"
        >
          <CheckBox
            key={`adm-region-${idAdmRegion}`}
            checked={admRegion.status === 'checked'}
            onClick={() => handleCheckAdmRegion(idAdmRegion)}
            label={admRegion.name}
            indeterminate={admRegion.status === 'incomplete'}
          />
          <ExpandControlBox
            maximized={admRegion.maximized}
            onClick={() => handleMaximizedAdmRegion(idAdmRegion)}
          />
        </FlexContainer>
        {
          admRegion.maximized
          && Object.entries(isolateCities)
            .filter(([, item]) => item.admRegion === Number(idAdmRegion))
            .map(([idCity, city]) => (
              <React.Fragment key={`rf-${idCity}`}>
                <FlexContainer
                  key={`div-adm-region-${idCity}`}
                  align="center"
                  style={{ marginLeft: 20 }}
                >
                  <CheckBox
                    key={`adm-region-${idCity}`}
                    checked={city.status === 'checked'}
                    onClick={() => handleCheckCity(idCity)}
                    label={city.name}
                    indeterminate={city.status === 'incomplete'}
                  />
                </FlexContainer>
              </React.Fragment>
            ))
        }
      </React.Fragment>
    ))
  ), [handleCheckAdmRegion, handleCheckCity, handleMaximizedAdmRegion,
    isolateAdmRegions, isolateCities]);

  return isActive ? content : <></>;
}

export default memo(AdmRegionsTab);
