import React, { memo, useCallback } from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import CheckBox from '../CheckBox';

import { ControleExpansao, ConteinerIndentado, ItemArvore } from './styles';

function renderizarListItemPadrao(item, alterarItem) {
  return (
    <CheckBox
      label={item.nome}
      checked={item.estado === 'selecionado'}
      indeterminate={item.estado === 'incompleto'}
      disabled={item.estado === 'desabilitado'}
      onClick={
        item.estado !== 'desabilitado'
          ? () => alterarItem({ tipo: 'ALTERNA_ESTADO_NO' })
          : undefined
      }
    />
  );
}

function ArvoreExpansivel(props) {
  const {
    arvore,
    className,
    dispatch,
    renderizarListItem = renderizarListItemPadrao,
  } = props;

  const alternarExpansao = useCallback((posicao, maximizado) => {
    dispatch({ tipo: 'ALTERA_MAXIMIZADO_NO', posicao, maximizado });
  }, [dispatch]);

  const alterarItem = useCallback((posicao, dados) => {
    dispatch({ tipo: 'ALTERA_NO', posicao, ...dados });
  }, [dispatch]);

  return (
    <List disablePadding className={className}>
      {
        arvore.getLista().map((item) => (
          <React.Fragment key={`arvore-galho-${item.nome}-expandivel`}>
            <ItemArvore
              disableGutters
              secondaryAction={
                (item.entidade && item[item.entidade].length > 0)
                  ? (
                    <ControleExpansao
                      onClick={() => alternarExpansao(item.posicao, !item.maximizado)}
                      title="Expandir/Retrair lista"
                    >
                      {item.maximizado ? <RemoveIcon /> : <AddIcon />}
                    </ControleExpansao>
                  )
                  : undefined
              }
            >
              {renderizarListItem(item, (dados) => alterarItem(item.posicao, dados))}
            </ItemArvore>
            <Collapse
              in={item.maximizado}
              mountOnEnter
              component={ConteinerIndentado}
            >
              <ArvoreExpansivel
                arvore={item}
                renderizarListItem={renderizarListItem}
                dispatch={dispatch}
              />
            </Collapse>
          </React.Fragment>
        ))
      }
    </List>
  );
}

export default memo(ArvoreExpansivel);
