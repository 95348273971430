import React, {
  useContext,
  useMemo,
  memo,
} from 'react';

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import Image from 'material-ui-image';

import {
  AppContext,
} from '../../../../../../contexts';

import {
  Botao,
  FlexContainer,
  Text,
} from '../../../../../../components';

import CollapseDivider from './components/CollapseDivider';
import DataTableListItem from './components/DataTableListItem/';

import { STATUS, STATUS_GROUPS } from '../../assets/constants';

function InitialPage({ cityData, graphicLink, setStatus }) {
  const { isLibcomSchema } = useContext(AppContext);

  const peopleColorButtons = useMemo(() => {
    const style = {
      width: '30%',
      padding: 8,
      margin: 5,
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 0 3px #ccc',
    };
    return STATUS_GROUPS.COLORS.map(status => {
      const color = status.split('-').pop();
      const colorsYears = Object.keys(cityData.profile.colors);
      const mostRecent = Math.max(...colorsYears.map(item => Number(item)));
      const mostRecentColors = cityData.profile.colors[mostRecent];
      let cor;
      switch (color) {
        case 'green':
          cor = 1;
          break;
        case 'yellow':
          cor = 2;
          break;
        case 'red':
          cor = 3;
          break;
        default:
      }
      const value = mostRecentColors ? mostRecentColors[cor] : 0;
      return (
        <Botao
          style={{
            backgroundColor: color,
            ...style,
          }}
          onClick={() => setStatus(status)}
        >
          <Text color={color === 'yellow' ? '#000' : '#fff'}>
            {(value || 0).toLocaleString('pt-BR')}
          </Text>
        </Botao>
      );
    });
  }, [cityData, setStatus]);

  const tableTagsMap = useMemo(() => {
    const style = {
      margin: '4px 3px',
      backgroundColor: '#535353',
      color: '#fff',
    };

    const tagsMap = {};

    if (cityData.events) {
      tagsMap.Eventos = STATUS_GROUPS.EVENTS.map(status => (
        <Botao
          style={style}
          onClick={() => setStatus(status)}
        >
          {
            status === STATUS.PEOPLE_EFL
              ? `EFL (${cityData.events.efl.toLocaleString('pt-BR')})`
              : `SVN (${cityData.events.svn.toLocaleString('pt-BR')})`
          }
        </Botao>
      ));
    }

    if (!isLibcomSchema) {
      tagsMap.Político = STATUS_GROUPS.POLITICS.map(status => (
        <Botao
          style={style}
          onClick={() => setStatus(status)}
        >
          {status}
        </Botao>
      ));
    }

    tagsMap.Perfis = STATUS_GROUPS.PROFILES.map(status => (
      <Botao
        style={style}
        onClick={() => setStatus(status)}
      >
        {
          status === STATUS.PEOPLE_DYNAMO
            ? 'Dínamo'
            : 'Voluntário'
        }
      </Botao>
    ));

    return tagsMap;
  }, [cityData, isLibcomSchema, setStatus]);

  return (
    <>
      <FlexContainer
        flow="column"
        style={{ padding: 10 }}
      >
        <Typography>
          {cityData.demographic.population.toLocaleString('pt-BR')} habitantes
        </Typography>
        <Typography>
          {cityData.demographic.voters.toLocaleString('pt-BR')} eleitores
        </Typography>
        <Typography>
          {cityData.demographic.votes.toLocaleString('pt-BR')} votos válidos
        </Typography>
        <Typography>
          {cityData.profile.registrations.toLocaleString('pt-BR')} cadastros
        </Typography>
      </FlexContainer>

      <FlexContainer justify="space-between">{peopleColorButtons}</FlexContainer>

      <FlexContainer
        flow="column"
        style={{ marginTop: 5, maxWidth: 300 }}
      >
        {
          Object.keys(tableTagsMap).length > 0 && (
            Object.entries(tableTagsMap).map(([title, tags]) => (
              <DataTableListItem title={title}>
                {tags}
              </DataTableListItem>
            ))
          )
        }
      </FlexContainer>

      {
        !isLibcomSchema && (
          <CollapseDivider label="Gráfico">
            <Image
              src={graphicLink}
              onClick={(e) => {
                const img = e.currentTarget.firstElementChild;
                if (img.style.opacity === '1') setStatus('graphic');
              }}
              errorIcon="Não há gráfico para essa cidade."
              style={{ padding: 0, marginTop: 10 }}
              iconContainerStyle={{
                width: '100%',
                height: 'min-content',
                pointerEvents: 'none !important'
              }}
              imageStyle={{
                position: 'unset',
                maxWidth: 300,
                cursor: 'pointer'
              }}
              aspectRatio={4 / 3}
            />
          </CollapseDivider>
        )
      }
    </>
  );
}

InitialPage.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  }).isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default memo(InitialPage);
