import React, {
  memo,
  useCallback,
  useContext,
} from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';

import NestedMenuItem from 'material-ui-nested-menu-item'; // eslint-disable-line

import {
  SecondaryMenuContext
} from '../../contexts';

function SecondaryMenu() {
  const {
    parameters,
    showSecondaryMenu,
  } = useContext(SecondaryMenuContext);

  const handleClose = useCallback(() => {
    showSecondaryMenu(null);
  }, [showSecondaryMenu]);

  const renderMenu = useCallback((items) => (
    items.map((item) => {
      if (item.disable) return null;
      if (item.type === 'item') {
        return (
          <MenuItem
            key={`menu-item-${item.label}`}
            dense
            onClick={(e) => {
              item.action(e);
              handleClose();
            }}
          >
            <Typography variant="inherit" noWrap>
              {
                item.icon && item.icon === 'check' && (
                  <CheckIcon
                    fontSize="small"
                    style={{ color: '#3498db', marginRight: 10 }}
                  />
                )
              }
              {item.label}
            </Typography>
          </MenuItem>
        );
      }
      return (
        <NestedMenuItem
          key={`menu-item-${item.label}`}
          label={item.label}
          parentMenuOpen={Boolean(parameters.target)}
          dense
        >
          { renderMenu(item.options)}
        </NestedMenuItem>
      );
    })
  ), [handleClose, parameters.target]);

  return (
    parameters.target
      ? (
        <Menu
          id="simple-menu"
          anchorEl={parameters.target}
          keepMounted
          open={Boolean(parameters.target)}
          onClose={handleClose}
        >
          {
            parameters.options && (
              renderMenu(parameters.options)
            )
          }
        </Menu>
      )
      : <></>
  );
}

export default memo(SecondaryMenu);
