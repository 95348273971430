import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

export const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      margin: 0;
      max-width: calc(100% - 64px);
    }

    .MuiDialogTitle-root,
    .MuiDialogContent-root,
    .MuiDialogActions-root {
      box-sizing: border-box;
    }

    .MuiDialogActions-root {
      justify-content: flex-start;
    }
  }
`;
