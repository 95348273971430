import React, {
  createContext,
  memo,
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import AppContext from '../AppContext';

import { BorderRegionsProvider } from './BorderRegionsContext';
import { MapEventsProvider } from './MapEventsContext';
import { MapIsolateRegionsProvider } from './MapIsolateRegionsContext';
import { MapLabelsProvider } from './MapLabelsContext';
import { MapProvider } from './index';

/**
 * @typedef {object} MapProviderContextType
 * @prop {() => SVGPathElement[]} getAllCities
 * Callback que retorna todos os elementos de cidade
 * @prop {() => SVGPathElement[]} getAllHulls
 * Callback que retorna todos os elementos de contornos
 * @prop {(slug: string) => SVGPathElement} getItemBySlug
 * Callback que retorna uma cidade do mapa baseado em seu `slug`
 * @prop {(item: SVGPathElement) => DOMPoint[]} getPointsOfPath
 * Callback que retorna o conjunto de pontos que formam um elemento Path
 * @prop {MapMode} mode
 * Estado que monitora o modo atual do mapa. O modo `move` é o modo padrão.
 * @prop {MountState} mounted
 * Estado que monitora os estado dos componentes do mapa.
 * @prop {React.MutableRefObject<HTMLDivElement>} refMap
 * Referencia ao componente recipiente do mapa
 * @prop {SetState<MapMode>} setMode
 * SetState para o estado `mode`
 * @prop {SetState<MountState>} setMounted
 * SetState do estado `mounted`
 * @prop {SetState<boolean>} setShowColors
 * SetState do estado `showColors`
 * @prop {SetState<boolean>} setShowNames
 * SetState do estado `showNames`
 * @prop {SetState<boolean>} setShowNumbers
 * SetState do estado `showNumbers`
 * @prop {boolean} showColors
 * Estado que monitora a função de colorir e descolorir do mapa
 * @prop {boolean} showNames
 * Estado que monitora se algum label de nome está ativo
 * @prop {boolean} showNumbers
 * Estado que monitora se o mapa está mostrando quantidades
 * @prop {React.MutableRefObject<ViewingTransformer>} transformer
 * Referencia ao transformador do mapa
 */
/** @type {React.Context<MapProviderContextType>} */
const MapProviderContext = createContext({});

function MapProviderProvider({ children }) {
  const { cities } = useContext(AppContext);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const refMap = useRef(null);
  /** @type {React.MutableRefObject<ViewingTransformer>} */
  const transformer = useRef(null);

  /** Este estado indica o estado de carregamento dos componentes do mapa */
  const [mounted, setMounted] = useState({
    cities: 'not-loaded',
    hulls: 'not-loaded',
    labels: 'not-loaded',
  });

  const [mode, setMode] = useState('move');

  /**
   * Esse useEffect hook monitora o modo de manipulação do mapa
   * e o tipo de cursor atual
   */
  useEffect(() => {
    refMap.current.mode = mode;
    refMap.current.style.cursor = mode === 'move' ? 'auto' : 'crosshair';
  }, [mode]);

  /**
   * Este estado controla a exibição de nomes das cidades
   * no mapa
   */
  const [showNames, setShowNames] = useState(false);

  /**
   * Este estado controla a exibição dos valores das cidades como
   * resultado do filtro aplicado
   */
  const [showNumbers, setShowNumbers] = useState(false);

  /**
   * Este estado controla a exibição de cores das cidades no mapa.
   * @type {[boolean, SetState<boolean>]}
   */
  const [showColors, setShowColors] = useState(false);

  /**
   * Busca uma cidade pelo nome compactado
   *
   * @param slug Nome em minusculo sem acentos e caracteres especiais
   *
   * @returns Retorna o elemento HTML da cidade
   */
  const getItemBySlug = useCallback((slug) => {
    if (slug === '') return null;
    return refMap.current.querySelector(`[data-slug='${slug}']`);
  }, []);

  /**
   * Essa função busca todos os elementos HTML/PATH das cidades
   * referentes ao mapa
   */
  const getAllCities = useCallback(() => (
    Array.from(refMap.current.querySelectorAll('.cities path'))
  ), [refMap]);

  /**
   * Essa função busca todos os elementos HTML/PATH dos hulls
   * referentes ao mapa
   */
  const getAllHulls = useCallback(() => (
    Array.from(refMap.current.querySelectorAll('.hulls path'))
  ), [refMap]);

  /**
   * Esta função obtém a lista de pontos que compõem o
   * desenho de um elemento PATH no mapa
   *
   * @param item Elemento HTML da cidade
   *
   * @returns Object[]
   */
  const getPointsOfPath = useCallback((item) => {
    const total = item.getTotalLength();
    const items = [];
    for (let i = 0; i < total; i += 1) {
      items.push(item.getPointAtLength(i));
    }
    return items;
  }, []);

  /**
   * Essa função obtém o slug de um nome fornecido
   * removendo todos os espaços e caracteres especiais
   *
   * @param name Nome fornecido
   *
   * @returns Slug do nome
   */
  const convertNameToSlug = useCallback((name) => {
    name = name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let letra = '';
    let result = '';
    for (let i = 0; i < name.length; i += 1) {
      if (name[i] === '\'') {
        letra = '';
      } else if (name[i] === ' ') {
        letra = '-';
      } else {
        letra = name[i];
      }
      result = result.concat(letra);
    }
    return result;
  }, []);

  /**
   * Essa função atribui as informações básicas de uma
   * cidade no conjunto dataset do elemento HTML
   *
   * @param item Elemento HTML da cidade
   * @param id ID da cidade
   * @param params Informações da cidade
   *
   * @returns void
   */
  const setBasicInfo = useCallback((item, id, params) => {
    const { name, diocese, region, bacia, admRegion } = params;
    item.dataset.id = id;
    item.dataset.diocese = diocese;
    item.dataset.region = region;
    item.dataset.bacia = bacia;
    item.dataset.admRegion = admRegion;
    item.dataset.nome = name;
    item.dataset.active = 'off';
    item.setAttribute('class', `city-${id}`);
  }, []);

  /**
   * Esse useEffect hook monitora o estado de carregamento do mapa
   * e configuração das cidades e hulls.
   * Executa a inicialização dos dados referentes aos elementos
   * das cidades
   */
  useEffect(() => {
    if (mounted.cities === 'loading' && Object.keys(cities).length > 0) {
      Object.entries(cities).forEach(([id, params]) => {
        const slug = convertNameToSlug(params.name);
        const item = getItemBySlug(slug);

        if (item) setBasicInfo(item, id, params);
      });
      // dispara o carregamento de hulls
      setMounted(old => ({ ...old, cities: 'loaded', hulls: 'loading' }));
    }
  }, [
    cities,
    convertNameToSlug,
    getItemBySlug,
    mounted.cities,
    setBasicInfo,
  ]);

  return (
    <MapProviderContext.Provider
      value={{
        getAllCities,
        getAllHulls,
        getItemBySlug,
        getPointsOfPath,
        mode,
        mounted,
        refMap,
        setMode,
        setMounted,
        setShowColors,
        setShowNames,
        setShowNumbers,
        showColors,
        showNames,
        showNumbers,
        transformer,
      }}
    >
      <MapIsolateRegionsProvider>
        <BorderRegionsProvider>
          <MapLabelsProvider>
            <MapProvider>
              <MapEventsProvider>
                {children}
              </MapEventsProvider>
            </MapProvider>
          </MapLabelsProvider>
        </BorderRegionsProvider>
      </MapIsolateRegionsProvider>
    </MapProviderContext.Provider>
  );
}

export const MapProviderGroup = memo(MapProviderProvider);

export default MapProviderContext;
