import Resource from './Resource';

export default class GetPartidosPerYearResource extends Resource {
  constructor(token, year) {
    super(token);
    this.year = year;
  }

  async result() {
    try {
      const { data, status } = await this.request.get(`/partidos/${this.year}`);

      if (status === 200) {
        return data; //eslint-disable-line
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return [];
  }
}
