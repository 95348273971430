import Resource from './Resource';

export default class GetUsersFromSchemaResource extends Resource {
  /**
   * Busca a lista de usuários.
   * @returns {UserOption[]}
   */
  async result() {
    try {
      const { data, status } = await this.request.get('/usuarios');
      if (status === 200) {
        return data.map(item => ({ id: item.idusuario, name: item.nome }));
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    return [];
  }
}
