/**
 * Possíveis valores para os tipos de filtros do mapa
 */
export const FILTER_TYPES = {
  PESSOAS: 'people',
  EVENTOS: 'events',
  POLITICOS: 'politics',
  EMENDAS: 'emendas',
};

/**
 * Override para Região de Santo André,
 * usado apenas quando não há outro override region-18
 */
export const INITIAL_LABEL_OVERRIDES = {
  'region-18': {
    x: 316.2323512027138,
    y: 324.7426846152858,
  },
};

/**
 * Estado inicial das fontes
 */
export const INITIAL_LABELS_SIZE = {
  cityFont: 1,
  regionFont: 6,
  othersFont: 3,
};
