import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';

export const ExpandButton = styled(IconButton)`
  && {
    padding: 1px;
    
    svg {
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.upward {
        transform: rotate(-180deg);
      }

      &.downward {
        transform: none;
      }
    }
    
  }
`;