import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

/**
 * @typedef {object} FooterObject
 * @prop {number} id
 * @prop {'left' | 'right'} align
 * @prop {string} label
 * Objeto de propriedades de um item de footer
 */

/**
 * @param {{ footer: FooterObject[] }} props da componente
 * @returns {JSX.Element} Componente de Footer
 */
function EnhancedTableFooter(props) {
  const { footer } = props;

  return (
    <TableFooter>
      <TableRow>
        {
          footer.map((footCell) => (
            <TableCell
              key={footCell.id}
              style={{ color: 'black', fontWeight: 'bold' }}
              align={footCell.align || 'left'}
              padding="default"
            >
              {footCell.label}
            </TableCell>
          ))
        }
      </TableRow>
    </TableFooter>
  );
}

/**
 * Componente do footer da componente `<Table />`
 * @param footer Array de objetos de propriedades de um item de footer
 */
export default EnhancedTableFooter;
