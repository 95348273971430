import React, {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';

import {
  AppContext,
} from '../../contexts';

/**
 * @typedef {object} MapIsolateRegionsContextType
 * @prop {IsolateTypes} isolateType
 * Estado que monitora o tipo de isolamento a ser usado no mapa
 * @prop {SetState<IsolateTypes>} setIsolateType
 * SetState para o estado `isolateType`
 * @prop {SetState<string[]>} setVisibleCities
 * SetState para o estado `visibleCities`
 * @prop {number[]} visibleAdmRegions
 * Conjunto de ids das regiões administrativas com ao menos uma cidade vísivel
 * @prop {number[]} visibleBacias
 * Conjunto de ids das bacias com ao menos uma cidade vísivel
 * @prop {string[]} visibleCities
 * Conjunto de ids das cidades vísiveis
 * @prop {number[]} visibleDioceses
 * Conjunto de ids das dioceses com ao menos uma cidade vísivel
 * @prop {number[]} visibleRegions
 * Conjunto de ids das regiões com ao menos uma cidade vísivel
 */
/**
 * @type {React.Context<MapIsolateRegionsContextType>}
 */
const MapIsolateRegionsContext = createContext({});

export function MapIsolateRegionsProvider({ children }) {
  const { cities, dioceses } = useContext(AppContext);

  /**
   * Este estado contém a lista de idCidades que estão visíveis no mapa
   * - Se a lista está vazia todas as cidades as cidades estão visíveis
   * - Se a lista contiver um ou mais elementos, somente as cidades referentes
   * a esses idCidades estarão visíveis.
   * @type [number[], (items: number[]) => void]
   */
  const [visibleCities, setVisibleCities] = useState([]);

  /**
   * Este estado contém a lista de idDioceses vísiveis com base na lista
   * de cidades visíveis.
   * @type number[]
   */
  const visibleDioceses = useMemo(() => (
    visibleCities.reduce((total, item) => (
      total.includes(cities[item].diocese) ? total : [...total, cities[item].diocese]
    ), [])
  ), [cities, visibleCities]);

  /**
   * Este estado contém a lista de idRegions visíveis com base na lista
   * de dioceses visíveis.
   * @type number[]
   */
  const visibleRegions = useMemo(() => (
    visibleDioceses.reduce((total, item) => (
      total.includes(dioceses[item].region) ? total : [...total, dioceses[item].region]
    ), [])
  ), [dioceses, visibleDioceses]);

  /**
   * Este estado contém a lista de idAdmRegions visíveis com base na lista de
   * cidades visíveis.
   * @type number[]
   */
  const visibleAdmRegions = useMemo(() => (
    visibleCities.reduce((total, item) => (
      total.includes(cities[item].admRegion) ? total : [...total, cities[item].admRegion]
    ), [])
  ), [cities, visibleCities]);

  /**
   * Este estado contém a lista de idBacias visíveis com base na lista de
   * cidades visíveis.
   * @type number[]
   */
  const visibleBacias = useMemo(() => (
    visibleCities.reduce((total, item) => (
      total.includes(cities[item].bacia) ? total : [...total, cities[item].bacia]
    ), [])
  ), [cities, visibleCities]);

  /**
   * Estado que define o tipo de isolamento de regiões do mapa.
   * @type {[IsolateTypes, SetState<IsolateTypes>]}
   */
  const [isolateType, setIsolateType] = useState('regions');

  return (
    <MapIsolateRegionsContext.Provider
      value={{
        isolateType,
        setIsolateType,
        setVisibleCities,
        visibleAdmRegions,
        visibleBacias,
        visibleCities,
        visibleDioceses,
        visibleRegions,
      }}
    >
      {children}
    </MapIsolateRegionsContext.Provider>
  );
}

export default MapIsolateRegionsContext;
