export default class ViewingTransformer {
  /**
   * @param {SVGSVGElement} svg SVG a ser controlado
   */
  constructor(svg) {
    this.element = svg || document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.reset();
  }

  getCSSTransform() {
    const { a, d, e, f } = this.vtm;
    
    return `matrix(${a}, 0, 0, ${d}, ${e}, ${f})`;
  }

  setVTM(mat) {
    this.vtm = mat;
    this.element.style.transform = this.getCSSTransform();
  }

  createSVGMatrix() {
    const matrix = this.element.createSVGMatrix();
    return matrix;
  }

  getResetMatrix() {
    const mat = this.createSVGMatrix();
    mat.a = 1.5;
    mat.d = 1.5;
    mat.e = -0.25 * window.innerWidth;
    mat.f = -0.25 * window.innerHeight;
    return mat;
  }

  reset() {
    const mat = this.getResetMatrix();
    this.setVTM(mat);
  }

  isMaxZoomed() {
    const { a, d } = this.vtm;

    return (a > 12 || d > 12);
  }

  isMinZoomed() {
    const { a, d } = this.vtm;

    return (a < 0.8 || d < 0.8);
  }

  scale(xFactor, yFactor, origin) {
    if (this.isMaxZoomed() && (xFactor > 1 || yFactor > 1)) {
      return;
    }

    if (this.isMinZoomed() && (xFactor < 1 || yFactor < 1)) {
      return;
    }
    
    const mat = this.createSVGMatrix()
      .translate(origin.x, origin.y)
      .scale(xFactor, yFactor)
      .translate(-origin.x, -origin.y)
      .multiply(this.vtm);

    this.setVTM(mat);
  }

  translate(offsetX, offsetY) {
    const mat = this.createSVGMatrix()
      .translate(offsetX, offsetY)
      .multiply(this.vtm);

    this.setVTM(mat);
  }

  center(translate, scale) {
    this.translate(translate.x, translate.y);
    this.scale(
      scale.x,
      scale.y,
      { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    );
  }
}
