import React, {
  useContext,
} from 'react';

import { MapContext } from '../../../../../../contexts';

function SVGDefs() {
  const { patterns } = useContext(MapContext);
  
  return (
    <defs>
      {
        patterns.map(pattern => 
          <linearGradient
            id={pattern.id}
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            {pattern.colorStops}
          </linearGradient>
        )
      }
    </defs>
  );
}

export default SVGDefs;