import styled from 'styled-components';

export const FilterItemContainer = styled.div`
  & {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 15px;
    margin-bottom: 1px;
    min-height: 15px;

    font-size: 1rem;
    color: #eee;
    background-color: #333;

    transition-property: filter;
    transition-duration: 300ms;

    user-select: none;
    touch-action: none;

    &:not(:active) {
      transition-property: filter, transform;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.react-draggable {
      cursor: grab;

      &:has(> [disabled]) {
        cursor: default;
      }

      &.react-draggable-dragging {
        transition-property: filter;
        position: absolute !important;
        top: ${props => props.top}px;
        left: ${props => props.left}px;
        pointer-events: none;
      }
    }

    &:hover {
      filter: brightness(1.2);
      transform: translateX(3px) !important;
    }

    & > div {
      position: relative;
      color: #cecece;
      width: 1.25rem;
      height: 1.25rem;
      cursor: inherit;

      &:hover:not([disabled]) {
        filter: brightness(1.3);
      }

      &::after, &::before {
        content: '';
        width: 50%;
        height: 50%;

        transform: rotate(45deg) scale(.65);
        position: absolute;
        left: 25%;
      }

      &::after {
        bottom: 0;
        background-image: linear-gradient(to top left, currentColor 50%, transparent 50%);
      }

      &::before {
        top: 0;
        background-image: linear-gradient(to bottom right, currentColor 50%, transparent 50%);
      }

      .drag-handle {
        font-size: 1.25rem;
      }

      &[disabled] {
        color: #777;
      }
    }

    & > span {
      display: inline-block;
      margin: 0 10px;
      width: 100%;
    }
  }

  .compound-item & > span:has(> .filter-item-part.filter-item-color) {

    & > .filter-item-part.filter-item-year {
      opacity: .4;
    }
  }
`;
