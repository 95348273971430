import styled from 'styled-components';

import { FlexContainer } from '../../../../../../../..';

export const ConteinerFormulario = styled(FlexContainer)`
  && {
    margin: 0 4px 0 8px;
    width: ${({ matches }) => matches ? 'auto' : 'max-content'};
  }
`;

export const ConteinerInput = styled(FlexContainer)`
  && {
    padding: ${({ matches }) => matches ? '2px 0' : '0 3px'};
    width: max-content;

    .MuiFormControl-root {
      max-width: 100px;
      padding: 0 5px;
    }
  }
`;
