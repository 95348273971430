import { chaveExisteEm } from './utils';

/**
 * Classe para manipulação das stores localStorage e sessionStorage do objeto Window
 */
class WindowStorage {
  // TODO: corrigir erro com parser eslint
  static PREFIX = 'app-mapas';

  /**
   * Função de acesso a localStorage
   *
   * @param {string} chave Chave da localStorage a ser manipulada
   * @returns {WindowStorage}
   */
  static localStorage(chave) {
    return new WindowStorage('local', chave);
  }

  /**
   * Função de acesso a sessionStorage
   *
   * @param {string} chave Chave da sessionStorage a ser manipulada
   * @returns {WindowStorage}
   */
  static sessionStorage(chave) {
    return new WindowStorage('session', chave);
  }

  /**
   * @param {"local" | "session"} tipo Tipo de store
   * @param {string} chave Chave a ser manipulada
   */
  constructor(tipo, chave) {
    this.storage = tipo === 'local' ? window.localStorage : window.sessionStorage;
    this.chave = `${WindowStorage.PREFIX}:${chave}`;
  }

  set(valor) {
    this.storage.setItem(this.chave, JSON.stringify(valor));
  }

  existe() {
    return (this.storage.getItem(this.chave) !== null);
  }

  /**
   * @overload
   * Função que retorna um valor na storage manipulada
   *
   * @param {string} item Chave do objeto em store para recuperar uma unica propriedade
   * @returns {any}
   */
  /**
   * @overload
   * Função que retorna o objeto em storage
   *
   * @param {undefined} item
   * @returns {any}
   */
  /**
   * @overload
   * Função que retorna o objeto em storage ou um valor na storage manipulada
   *
   * @param {string} [item] Chave do objeto em store para recuperar uma unica propriedade
   * @returns {any}
   */
  get(item) {
    if (!this.existe()) return null
    const objeto = JSON.parse(this.storage.getItem(this.chave));

    if (typeof item === 'string') return objeto[item] || null;

    return objeto
  }

  limpa() {
    this.storage.removeItem(this.chave)
  }

  /**
   *
   * @param {string} dataInicial String de data em formato YYYY-MM-DD
   * @returns {boolean} Validacao se a store foi atualizada desde a dataInicial
   */
  validaDataStore(dataInicial) {
    const salvo = this.get();
    // se storage for de um objeto, verificar validade do objeto
    if (typeof salvo === 'object' && !Array.isArray(salvo) && salvo !== null) {
      if (!chaveExisteEm(salvo, 'data') || new Date(salvo.data) < new Date(dataInicial)) {
        this.limpa();
        return false;
      }
    }
    return true;
  }
}

export default WindowStorage;
