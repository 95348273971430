import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Aba, Abas } from '../../../../../';

import {
  FilterContext,
  MapContext,
} from '../../../../../../contexts';

import { emptyObject } from '../../../../../../utils/utils';

import {
  PoliticsTable,
  PessoasTable,
  EmendasTable,
} from './components';

import { STATUS, AMENDMENTS_COLS, PEOPLE_COLS, ROLE_MAPPER } from '../../assets/constants';

function DataTable(props) {
  const { city, status } = props;
  const {
    getAmendmentsFromCity,
    getCandidatesFromCity,
    getElectedFromCity,
    getPessoasFromCity,
    getPreCandidatesFromCity,
  } = useContext(MapContext);

  const { peopleFilters } = useContext(FilterContext);

  /**
   * useMemo que carrega as opções de Dínamo
   * @type {number[]}
   */
  const dynamoIds = useMemo(() => {
    if (peopleFilters && peopleFilters.perfis) {
      const profiles = peopleFilters.perfis.perfis;
      return profiles
        .filter(({ descricao }) => descricao.startsWith('Dínamo'))
        .map(({ id_perfil: idperfil }) => idperfil);
    }
    return [];
  }, [peopleFilters]);

  /**
   * @type {[Record<string, any> | undefined, SetState<Record<string,any> | undefined>]}
   */
  const [resultByYear, setResultByYear] = useState();
  /**
   * @type {[any[] | undefined, SetState<any[] | undefined>]}
   */
  const [simpleResult, setSimpleResult] = useState();

  /**
   * Função para habilitar a troca entre os tipos de resultado
   */
  const setResult = useCallback(
    /**
     * @param {Record<string, any> | any[] | undefined} result
     */
    (result) => {
      if (!result) {
        setSimpleResult(undefined);
        setResultByYear(undefined);
        return;
      }

      if (Array.isArray(result)) {
        setSimpleResult(result);
        setResultByYear(undefined);
      } else if (!emptyObject(result)) {
        setResultByYear(result);
        setSimpleResult(undefined);
      } else {
        setSimpleResult(undefined);
        setResultByYear(undefined);
      }
    },
    []
  );

  const formatRows = useCallback((yearData) => (
    Object.entries(yearData)
      .reduce((total, [role, electeds]) => {
        const list = electeds.map((elected) => {
          if (status === STATUS.ELECTED) {
            return {
              Cor: elected.cor || { nome: 'Sem Cor' },
              Nome: elected.nome,
              Cargo: ROLE_MAPPER[role],
              Partido: elected.partido,
              Projeto: elected.projeto || 'Nenhum',
              Situação: elected.situacao,
              Votos: elected.total_votos,
            };
          }

          if (status === STATUS.PRE_CANDIDATES) {
            return {
              Nome: elected.nome,
              Cargo: ROLE_MAPPER[role],
              Partido: elected.partido,
              Projeto: elected.projetos.length > 0
                ? elected.projetos.join(', ')
                  .replace('regiao', 'REGIÃO')
                  .replace('estado', 'ESTADO')
                : 'Nenhum',
              'Votos Previstos': elected.votos_previstos,
            };
          }

          return {
            Nome: elected.nome,
            Cargo: ROLE_MAPPER[role],
            Partido: elected.partido,
            Projeto: elected.projetos.length > 0
              ? elected.projetos.join(', ')
                .replace('regiao', 'REGIÃO')
                .replace('estado', 'ESTADO')
              : 'Nenhum',
            'Votos Previstos': elected.votos_previstos,
            'Votos Obtidos': elected.votos_obtidos,
            Situação: elected.situacao,
            Observação: elected.status,
          };
        });
        return [...total, ...list];
      }, [])
  ), [status]);

  /**
   * @description Objeto com configurações que comandarão possível aba com emendas filtradas
   * @steps
   *  - Confere se o filtro global em `currentFilterType` contém um filtro de `'emendas'`
   *  - Se sim, separa os filtros selecionados no array `emendasFiltroGlobal.filtros`
   *  - Faz o filtro do conteúdo de `result` atual para com `emendasFiltroGlobal.filtros`
   *  - Retorna objeto emendasFiltroGlobal configurado para renderização da aba filtrada
   */
  // const emendasFiltroGlobal = useMemo(() => { // eslint-disable-line
  //   const config = { ativo: false, filtros: [], resultado: [] };

  //   if (currentFilterType === FILTER_TYPES.EMENDAS && fieldEvents && fieldEvents.length > 0) {
  //     config.ativo = true;
  //     config.resultado = result;

  //     const { name, year } = fieldEvents[0];

  //     // quando name === 'emendas', não filtrar orgao
  //     if (name !== FILTER_TYPES.EMENDAS) {
  //       config.filtros.push({
  //         campo: [AMENDMENTS_COLS.organ], valor: name
  //       });
  //     }
  //     // quando year == total, não filtrar ano
  //     if (year !== 'total') config.filtros.push({ campo: [AMENDMENTS_COLS.year], valor: year });
  //   }

  //   if (config.filtros.length > 0 && result.length > 0) {
  //     config.filtros.forEach((filtro) => {
  //       config.resultado = config.resultado
  //         .filter((valor) => valor[filtro.campo] === filtro.valor);
  //     });
  //   }

  //   return config;
  // }, [result]);

  useEffect(() => {
    if (city !== -1) {
      if (status === STATUS.ELECTED) {
        getElectedFromCity(city).then(res => {
          setResult(res);
        });
      } else if (status === STATUS.PRE_CANDIDATES) {
        getPreCandidatesFromCity(city).then(res => {
          setResult(res);
        });
      } else if (status === STATUS.CANDIDATES) {
        getCandidatesFromCity(city).then(res => {
          setResult(res);
        });
      } else if (status === STATUS.AMENDMENTS) {
        getAmendmentsFromCity(city).then(res => {
          // Mapeamento das chaves para label das colunas para funcionar ordenação da Table simples
          setResult(res.map((emenda) => ({
            [AMENDMENTS_COLS.year]: emenda.ano.toString(),
            [AMENDMENTS_COLS.organ]: emenda.orgao,
            [AMENDMENTS_COLS.object]: emenda.objeto,
            [AMENDMENTS_COLS.leader]: emenda.mandatario,
            [AMENDMENTS_COLS.proponent]: emenda.proponente,
            [AMENDMENTS_COLS.situation]: {
              text: emenda.situacao,
              textColor: emenda.cortexto,
              bgColor: emenda.cor,
            },
            [AMENDMENTS_COLS.amount]: emenda.valor_repasse,
          })));
        });
      } else if (status.startsWith('people-')) {
        const url = window.location.href;

        // se cor nao for definida, nao havera filtro de cor
        let currentColorId;
        // caso não sejam definidos, não haverá busca por esses parâmetros
        let currentEvent;
        let currentProfileId;

        if (status === STATUS.PEOPLE_GREEN) currentColorId = 1;
        else if (status === STATUS.PEOPLE_YELLOW) currentColorId = 2;
        else if (status === STATUS.PEOPLE_RED) currentColorId = 3;

        // Atualmente as tabelas de evento separam os dados de efl e svn
        if (status === STATUS.PEOPLE_EFL) currentEvent = 'efl';
        else if (status === STATUS.PEOPLE_SVN) currentEvent = 'svn';

        // Tabelas por perfil
        if (status === STATUS.PEOPLE_DYNAMO) {
          currentProfileId = !(url.includes('localhost') || url.includes('homologacao'))
            ? dynamoIds
            : 104;
        } else if (status === STATUS.PEOPLE_VOLUNTEER) {
          currentProfileId = !(url.includes('localhost') || url.includes('homologacao'))
            ? 89
            : 17;
        }

        // para parâmetros undefined, não ocorre busca
        const search = {
          colors: currentColorId,
          events: currentEvent,
          profiles: currentProfileId,
        };

        getPessoasFromCity(city, search).then(res => {
          setResult(res.map(({ id_pessoa: idpessoa, ...person }) => {
            const result = {
              [PEOPLE_COLS.name]: person.nome,
              [PEOPLE_COLS.nickname]: person.apelido,
              [PEOPLE_COLS.color]: person.cores || { cor_nome: 'Sem Cor' },
            };
            if (currentEvent) {
              result[currentEvent.toUpperCase()] = person.eventos[currentEvent]
                .total.toLocaleString('pt-BR');
            }
            if (currentProfileId) {
              result[PEOPLE_COLS.details] = url.includes('localhost') || url.includes('homologacao')
                ? `https://app-voluntario-develop.web.app/pessoa-detalhes/${idpessoa}`
                : `https://voluntario.infogab.com.br/pessoa-detalhes/${idpessoa}`;
            }

            return result;
          }));
        });
      }
    }
  }, [
    city,
    dynamoIds,
    getCandidatesFromCity,
    getElectedFromCity,
    getAmendmentsFromCity,
    getPessoasFromCity,
    getPreCandidatesFromCity,
    setResult,
    status,
  ]);

  const TableComponent = useMemo(() => {
    switch (status) {
      case STATUS.ELECTED:
      case STATUS.CANDIDATES:
      case STATUS.PRE_CANDIDATES:
        return <PoliticsTable />;

      case STATUS.AMENDMENTS:
        return <EmendasTable />;

      default:
        if (status.startsWith('people-')) return <PessoasTable />;
        return <></>;
    }
  }, [status]);

  return (
    <>
      {
        resultByYear && (
          <Abas
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {
              Object.keys(resultByYear)
                .sort((a, b) => Number(a) - Number(b))
                .map((year) => (
                  <Aba
                    key={`key-${year}`}
                    label={year}
                    componente={
                      React.cloneElement(
                        TableComponent,
                        { rows: formatRows(resultByYear[year]) }
                      )
                    }
                  />
                ))
            }
            { // Código para tabs de emendas aplicando o filtro de emenda ativo
            // no mapa para mostrar uma tabela filtrada
            /* Guardando código para o futuro {
              years.length > 0
                ? years.map((year) => (
                  <StyledTab label={year} key={`key-${year}`} />
                ))
                : emendasFiltroGlobal.ativo
                  ? [
                    <StyledTab label="Com Filtro" key={0} />,
                    <StyledTab label="Sem Filtro" key={1} />
                  ]
                  : undefined
            } */}
          </Abas>
        )
      }
      {
        simpleResult && (
          React.cloneElement(
            TableComponent,
            { rows: simpleResult },
          )
        )
      }
    </>
  );
}

DataTable.propTypes = {
  city: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  status: PropTypes.oneOf(Object.values(STATUS)).isRequired,
};

export default memo(DataTable);
