import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react';

import './ContextTypes';

import {
  AppContext,
} from '..';

import { chaveExisteEm, emptyObject } from '../../utils/utils';

import { isAmendmentsFilter, isEventsFilter, isPeopleFilter, isPoliticFilter } from '../../utils/filters';

function sortByFilterSubject(a, b) {
  const { settings: settingsA } = a;
  const { settings: settingsB } = b;
  const { subject: subjectA, subType: subTypeA, filterParams: filterParamsA } = settingsA;
  const { subject: subjectB, subType: subTypeB, filterParams: filterParamsB } = settingsB;
  const yearA = filterParamsA.ano;
  const yearB = filterParamsB.ano;

  if (subjectA !== subjectB) {
    if (subjectA === 'eventos') return -1;
    if (subjectA === 'politico' && subjectB === 'emendas') return -1;
    if (subjectA === 'emendas' && subjectB === 'pessoas') return -1;
    if (subjectA === 'politico' && subjectB === 'eventos') return 1;
    if (subjectA === 'pessoas') return 1;
    return 0;
  }

  if (subTypeA === subTypeB && (yearA && yearB)) {
    if (yearA - yearB < 0) return -1;
    if (yearA - yearB > 0) return 1;
    return 0;
  }

  if (subjectA === 'eventos') {
    if (subTypeA === 'efl') return -1;
    if (subTypeA === 'svn') return 1;
    return 0;
  }
  if (subjectA === 'politico') {
    if (subTypeA === 'politics') return -1;
    if (subTypeA === 'candidates' && subTypeB === 'tse') return -1;
    if (subTypeA === 'tse' && subTypeB === 'votos-validos') return -1;
    if (subTypeA === 'candidates' && subTypeB === 'politics') return 1;
    if (subTypeA === 'votos-validos') return 1;
    return 0;
  }
  if (subjectA === 'pessoas') {
    if (subTypeA === 'perfis') return -1;
    if (subTypeA === 'grupos' && subTypeB.match(/cores/)) return -1;
    if (subTypeA.match(/cores/) && subTypeB === 'cadastros') return -1;
    if (subTypeA === 'grupos' && subTypeB === 'perfis') return 1;
    if (subTypeA === 'cadastros') return 1;
    return 0;
  }
  if (subjectA === 'emendas') {
    if (subTypeA.includes('Funasa')) return -1;
    if (subTypeA.includes('Ministério') && subTypeB.includes('Funasa')) return -1;
    if (subTypeA.includes('Ministério') && subTypeB.includes('Secretaria')) return 1;
    if (subTypeA.includes('Secretaria')) return 1;
    return 0;
  }
  return 0;
}

/**
 * @typedef {object} DataContextType
 * @prop {() => void} clearResult
 * Callback que limpa os filtros ativos
 * @prop {{ name: string, id: number }} currentCity
 * Estado que monitora a cidade atualmente selecionada (Dialogo de Detalhes)
 * @prop {DataResultType} dataResult
 * Estado que armazena os dados do filtro atualmente aplicado
 * @prop {string[]} filteredCities
 * Estado que monitora as cidades filtradas/resultantes do filtro
 * @prop {() => FiltersQuantities} getFilters
 * Callback que retorna os filtros e seus resultados
 * @prop {() => ResultObject | Filter} getResult
 * Callback que retorna as informações gerais de todos os filtros
 * @prop {Filter[]} invalidFilters
 * Estado que monitora quais foram os filtros invalidados na ultima requisição de filtragem
 * @prop {boolean} isSingleFilter
 * Estado que monitora se o filtro é simples, ou seja, se há apenas um filtro ativo
 * @prop {PeopleInfo} people
 * Estado de armazenamento das opções de tipo de pessoas
 * @prop {SetState<boolean>} setCleaningFilters
 * SetState para o estado `cleaningFilters`
 * @prop {SetState<DataResultType>} setDataResult
 * SetState para o estado `dataResult`
 * @prop {SetState<string[]>} setFilteredCities
 * SetState para o estado `filteredCities`
 * @prop {(elements: GetFilterResult) => void} setResult
 * Callback para construção do objeto de armazenamento de filtros `dataResult`
 */
/**
 * @type {React.Context<DataContextType>}
 */
const DataContext = createContext({});

/**
 * Esse contexto contempla todas as funções e estados relacionados
 * a manipulação de dados da aplicação
 *
 */
export const DataProvider = ({ children }) => {
  const {
    activeFilters,
    filterOptions,
    removeTaskRunning,
    setActiveFilters,
  } = useContext(AppContext);

  /**
   * Esse estado armazena a lista de todos os filtros
   * resultantes da requisição com as informações de cada um,
   * os filtro são separados por tipo e cada tipo possui uma lista de filtros
   * - settings: essa propriedade é um Object que armazena configurações
   * de um filtro listado aplicado como seu tipo e subtipo
   * - total: essa propriedade é um Object com a relação de todas cidades e os resultados
   * dados conforme o filtro listado. No caso de filtro composto (politico),
   * cada cidade tem o valor de todos os atributos resultantes do filtro.
   * Ex: 8891: { nao_eleito: 2, eleito: 1, suplente: 1 } => atributos politicos
   * - result: essa propriedade é um Object que contém a relação de todas cidades do filtro e o
   * resultado do atributo selecionado. Em filtro simples, o 'result' e 'total' são iguais, em
   * filtros composto (politico), o 'result' contém os dados do atributo selecionado,
   * por exemplo 'eleito', para um conjunto de cidades.
   * - status: essa propriedade contém o status referente ao filtro politico
   * Ex: status: { id: 'eleito', label: 'Eleito' }
   * - filteredCities: essa propriedade armazena as cidades contidas em todos os filtros
   *
   * @type {[
   *  DataResultType,
   *  SetState<DataResultType>>,
   * ]}
   */
  const [dataResult, setDataResult] = useState({
    events: [],
    politics: [],
    people: [],
    emendas: [],
    filteredCities: [],
  });
  const [invalidFilters, setInvalidFilters] = useState([]);

  const [filteredCities, setFilteredCities] = useState([]);
  const [politicsStatus, setPoliticsStatus] = useState({});
  const [people, setPeople] = useState({});

  useEffect(() => {
    if (filterOptions && filterOptions.politico) {
      setPoliticsStatus({
        politicos: (
          Object.entries(filterOptions.politico.politicos.situacao)
            .map(([key, item]) => ({ id: key, label: item }))
        ),
        tse: (
          Object.entries(filterOptions.politico.tse.situacao)
            .map(([key, item]) => ({ id: key, label: item }))
        )
      });
    }

    if (filterOptions && filterOptions.pessoas) {
      setPeople({
        perfis: (filterOptions.pessoas.perfis) && (
          filterOptions.pessoas.perfis.perfis
            .reduce((total, { id_perfil: idperfil, descricao }) => ({
              ...total,
              [idperfil]: descricao,
            }), {})
        ),
        grupos: (filterOptions.pessoas.grupos) && (
          filterOptions.pessoas.grupos.grupos
            .reduce((total, { id_grupo: idgrupo, descricao }) => ({
              ...total,
              [idgrupo]: descricao,
            }), {})
        ),
        cores: (filterOptions.pessoas.cores) && (
          filterOptions.pessoas.cores.cores
            .reduce((total, { id_cor: idcor, cor_nome: nome }) => ({
              ...total,
              [idcor]: nome,
            }), {})
        ),
      });
    }
  }, [filterOptions]);

  const formatText = useCallback((text) => (
    text.toUpperCase()
      .replaceAll('_', '-')
      .replaceAll(',', ', ')
  ), []);

  /**
   * Essa função constrói um titulo usando
   * os parametros passados pela resposta api-mapas
   * para cada filtro feito.
   */
  const buildTitle = useCallback((params) => {
    const title = [];
    function pushToTitle(str) { title.push(formatText(str)); }

    function formatAmendmentOrgan(organ) {
      if (organ.includes('(der)')) return 'DER';
      if (organ.match(/funasa/i)) return 'FUNASA';

      return organ
        .replace(/secretaria/i, 'Secr.')
        .replace(/estadual/i, 'Est.')
        .replace(/desenvolvimento/i, 'Desenv.');
    }

    const filterParameters = Object.keys(params).filter(k => k !== 'name');
    let numKeys = 0;
    let firstKey = '';
    switch (params.name) {
      case 'pessoas':
        numKeys = filterParameters.length;
        [firstKey] = filterParameters;
        if (!params.event) {
          pushToTitle(`${params.name} - ${params[firstKey]}`);
        } else if (numKeys > 1) {
          pushToTitle(`PARTICIPANTES (${params.event.join(', ')}) - ${params[firstKey]}`);
        } else if (numKeys === 1) {
          pushToTitle(`PARTICIPANTES (${params.event.join(', ')})`);
        }
        if (firstKey !== 'color' && chaveExisteEm(params, 'color')) pushToTitle(params.color);
        if (firstKey !== 'profile' && chaveExisteEm(params, 'profile')) pushToTitle(params.profile.join(', '));
        if (firstKey !== 'group' && chaveExisteEm(params, 'group')) pushToTitle(params.group.join(', '));
        break;

      case 'eventos':
        pushToTitle(`${params.version && params.version !== 'total' ? params.version : params.event} - ${params.year}`);
        break;

      case 'cores':
        pushToTitle(`${params.name} - ${params.color}`);
        if (params.year) pushToTitle(params.year);
        break;

      case 'grupos':
        pushToTitle(`${params.name} - ${params.group}`);
        break;

      case 'perfis':
        pushToTitle(`${params.name} - ${params.profile}`);
        break;

      case 'cadastros':
        pushToTitle(`${params.name} - ${params.year}`);
        break;

      case 'políticos':
        pushToTitle(`${params.name} (${params.status}) - ${params.year}`);
        if (chaveExisteEm(params, 'role')) pushToTitle(params.role);
        if (chaveExisteEm(params, 'party')) pushToTitle(params.party);
        if (chaveExisteEm(params, 'project')) pushToTitle(params.project);
        break;

      case 'candidatos':
        pushToTitle(`${params.name} - ${params.year}`);
        if (chaveExisteEm(params, 'role')) pushToTitle(params.role);
        if (chaveExisteEm(params, 'personName')) pushToTitle(params.personName);
        break;

      case 'tse':
        pushToTitle(`${params.name} (${params.status}) - ${params.year}`);
        if (chaveExisteEm(params, 'role')) pushToTitle(params.role);
        if (chaveExisteEm(params, 'party')) pushToTitle(params.party);
        break;

      case 'votos-validos':
        return ['VOTOS VÁLIDOS'];

      case 'emendas':
        pushToTitle(`${params.name}${params.organ ? ` (${formatAmendmentOrgan(params.organ)})` : ''} - ${params.year}`);
        if (chaveExisteEm(params, 'personName')) pushToTitle(params.personName);
        break;

      default:
        pushToTitle(params.name);
        break;
    }

    return title;
  }, [formatText]);

  /**
   * Essa função é usada para adicionar titulos
   * aos resultados do filtro. Os titulos podem ser
   * usados na legenda e dialogo de resultados.
   *
   * @type {(validFilters: Filter[]) => void}
   */
  const getFiltersTitles = useCallback((validFilters) => {
    const titledFilters = validFilters.map(filter => {
      const { settings, status } = filter;
      const { filterParams } = settings;

      const params = {
        name: settings.subType
          .replace('politicos', 'políticos')
          .replace('cores-anos', 'cores'),
      };

      if (!emptyObject(filterParams)) {
        if (chaveExisteEm(filterParams, 'ano')) {
          params.year = filterParams.ano;
        }

        if (chaveExisteEm(filterParams, 'cargo') || chaveExisteEm(filterParams, 'cargos')) {
          params.role = filterParams.cargo || filterParams.cargos;
        }

        if (chaveExisteEm(filterParams, 'partidos')) {
          params.party = filterParams.partidos;
        }

        if (chaveExisteEm(filterParams, 'projetos')) {
          params.project = filterParams.projetos;
        }

        if (status) {
          params.status = status.label;
        }

        // refere-se ao nome do candidato no filtro 'candidatos' ou 'votações'
        // ou ao mandatario das emendas
        if (chaveExisteEm(filterParams, 'nome') || chaveExisteEm(filterParams, 'mandatario')) {
          const nome = filterParams.nome || filterParams.mandatario;
          const [firstName, ...rest] = nome.split(' ');
          const lastName = rest[rest.length - 1];
          params.personName = `${firstName.charAt(0)}. ${lastName}`;
        }

        if (chaveExisteEm(filterParams, 'orgao')) {
          params.organ = filterParams.orgao;
        }

        if (
          // filtros compostos de pessoas
          params.name === 'pessoas'
          // unico parametro de filtro
          && Object.keys(filterParams).length === 1
          // parametro com unico filtro
          && Object.values(filterParams).every(param => !param.includes(','))
        ) {
          // gera titulo baseado no filtro unico de pessoas
          if (chaveExisteEm(filterParams, 'cor')) {
            params.name = 'cores';
            params.color = people.cores[filterParams.cor];
          }

          if (chaveExisteEm(filterParams, 'perfis')) {
            params.name = 'perfis';
            params.profile = people.perfis[filterParams.perfis];
          }

          if (chaveExisteEm(filterParams, 'grupos')) {
            params.name = 'grupos';
            params.group = people.grupos[filterParams.grupos];
          }

          if (chaveExisteEm(filterParams, 'eventos')) {
            const [event, year, version] = filterParams.eventos.split('.');
            params.name = 'eventos';
            params.event = event;
            params.year = year;
            params.version = version;
          }
        } else {
          if (chaveExisteEm(filterParams, 'cor')) {
            params.color = people.cores[filterParams.cor];
          }

          if (chaveExisteEm(filterParams, 'perfis')) {
            params.profile = filterParams.perfis.split(',').map(item => people.perfis[item]);
          }

          if (chaveExisteEm(filterParams, 'grupos')) {
            params.group = filterParams.grupos.split(',').map(item => people.grupos[item]);
          }

          if (chaveExisteEm(filterParams, 'eventos')) {
            params.event = filterParams.eventos.split(',')
              .map(item => {
                const [event, year, version] = item.split('.');
                return `${event}${version && version !== 'total' ? `-${version}` : ''} ${year}`;
              });
          }
        }
      }

      return {
        ...filter,
        settings: {
          ...settings,
          title: buildTitle(params),
        },
      };
    });

    const filteredCities = titledFilters.reduce((set, { total }) => {
      Object.keys(total).forEach(k => set.add(k));
      return set;
    }, new Set());
    setDataResult({
      people: titledFilters.filter(isPeopleFilter),
      events: titledFilters.filter(isEventsFilter),
      politics: titledFilters.filter(isPoliticFilter),
      emendas: titledFilters.filter(isAmendmentsFilter),
      filteredCities: Array.from(filteredCities)
    });
  }, [buildTitle, people]);

  //=============================================
  /**
   * Essa função armazena o resultado do filtro aplicado
   *
   * @type {(elements: GetFilterResult) => void}
   */
  const setResult = useCallback((elements) => {
    const filtersList = elements.flatMap(item => {
      const filterByStatus = item.subtipo.match(/politicos|tse/i);
      if (item.resultado && filterByStatus) {
        const filterStatuses = Object.values(item.resultado)
          .reduce((o, statusObj) => {
            const cityAvailableStatus = Object.keys(statusObj);
            if (o.length === 0) {
              return cityAvailableStatus
                .map(status => ({
                  id: status,
                  label: politicsStatus[item.subtipo].find(s => s.id === status).label
                }));
            }
            if (o.length === politicsStatus[item.subtipo].length) return o;
            cityAvailableStatus.forEach(status => {
              if (!o.find(s => s.id === status)) {
                o.push({
                  id: status,
                  label: politicsStatus[item.subtipo].find(s => s.id === status).label
                });
              }
            });
            return o;
          }, []).sort((a, b) => a.label > b.label ? 1 : -1);

        return filterStatuses.map(status => ({
          settings: {
            subject: item.tipo,
            subType: item.subtipo,
            filterParams: item.parametros,
          },
          total: item.resultado,
          result: Object.entries(item.resultado)
            .filter(([, value]) => `${status.id}` in value)
            .reduce((r, [key, value]) => ({ ...r, [key]: value[status.id] }), {}),
          status,
        }));
      }

      return {
        settings: {
          subject: item.tipo,
          subType: item.subtipo,
          filterParams: item.parametros,
        },
        total: item.resultado,
        result: item.resultado,
        status: undefined
      };
    });
    // `filtersList` possui todos os objetos de filtros retornados pela váriavel `final`
    const validFilters = filtersList.filter(({ total }) => total !== null);
    const invalidFilters = filtersList.filter(({ total }) => total === null);

    setInvalidFilters(invalidFilters);
    getFiltersTitles(validFilters);
  }, [getFiltersTitles, politicsStatus]);

  useEffect(() => {
    const { events, politics, people, emendas, filteredCities } = dataResult;
    if (filteredCities && filteredCities.length > 0) {
      setFilteredCities(filteredCities);
      setActiveFilters([...events, ...politics, ...emendas, ...people].sort(sortByFilterSubject));
    } else {
      setFilteredCities([]);
      setActiveFilters([]);
    }
  }, [dataResult, setActiveFilters]);

  const isSingleFilter = useMemo(() => (activeFilters.length <= 1), [activeFilters]);

  /**
   * Essa função obtém os dados referente ao resultado do
   * filtro aplicado
   */
  const getResult = useCallback(() => {
    if (activeFilters.length > 0 && isSingleFilter) {
      const [filtro] = activeFilters;
      const { settings, result, total, status } = filtro;
      return {
        total,
        result,
        settings,
        status,
      };
    }
    const { events, politics, people, emendas } = dataResult;
    return {
      events,
      politics,
      people,
      emendas,
    };
  }, [activeFilters, dataResult, isSingleFilter]);

  /**
   * Essa função separa os resultados do filtro em tipos e os organiza
   * para serem usados no menu de mostrar quantidades
   */

  const getFilters = useCallback(() => {
    const { events, politics, people, emendas } = dataResult;
    let filteredEvents = [];
    let filteredPolitics = [];
    let filteredPeople = [];
    let filteredEmendas = [];

    function filtersMap({ settings }) {
      return settings.title.join(', ');
    }

    if (events) {
      filteredEvents = events
        .sort(sortByFilterSubject)
        .map(filtersMap);
    }
    if (politics) {
      filteredPolitics = politics
        .sort(sortByFilterSubject)
        .map(filtersMap);
    }
    if (people) {
      filteredPeople = people
        .sort(sortByFilterSubject)
        .map(filtersMap);
    }
    if (emendas) {
      filteredEmendas = emendas
        .sort(sortByFilterSubject)
        .map(filtersMap);
    }
    return {
      events: filteredEvents,
      politics: filteredPolitics,
      people: filteredPeople,
      emendas: filteredEmendas,
    };
  }, [dataResult]);

  /**
   * Essa função remove os dados do filtro anteriormente aplicado
   *
   * @return void
   */
  const clearResult = useCallback(() => {
    setDataResult({
      events: [],
      politics: [],
      people: [],
      emendas: [],
      filteredCities: [],
    });
    setInvalidFilters([]);
  }, []);

  /**
   * Este estado monitora se os filtros estão sendo limpos.
   * @type {[boolean, SetState<boolean>]}
   */
  const [cleaningFilters, setCleaningFilters] = useState(false);

  /**
   * Este Effect monitora a renderização quando o estado
   * de limpeza de filtros for `true` e define se a limpeza terminou.
   */
  useLayoutEffect(() => {
    if (cleaningFilters) {
      setCleaningFilters(() => {
        if (Object.values(dataResult).every(value => value.length === 0)) {
          removeTaskRunning();
          return false;
        }
        return true;
      });
    }
  }, [cleaningFilters, dataResult, removeTaskRunning]);

  return (
    <DataContext.Provider
      value={{
        clearResult,
        dataResult,
        filteredCities,
        getFilters,
        getResult,
        invalidFilters,
        isSingleFilter,
        people,
        setCleaningFilters,
        setDataResult,
        setFilteredCities,
        setResult,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
