import React, {
  createContext,
  useContext,
  useCallback,
} from 'react';

import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { SaveNewViewHandler } from '../../api/handlers';

import {
  GetAvailableViewsResource,
  GetUsersFromSchemaResource,
  GetViewResource,
} from '../../api/resources';

import {
  AppContext,
  BorderRegionsContext,
  ColorContext,
  FilterContext,
  MapContext,
} from '../';

import { chaveExisteEm } from '../../utils';

import './ContextTypes';

/**
 * Funcao para converter os valores antigos de coloracao para o novo formato.
 * Dado que as visualizações sejam editaveis futuramente, essa função nao precisará ser mais utilizada
 * quando TODAS as visualizacoes do banco possuirem o novo formato
 */
function converterColoracaoAntigaParaNova(coloracao) {
  if (
    (typeof coloracao === 'object' && coloracao !== null)
    && chaveExisteEm(coloracao, 'type')
  ) {
    if (coloracao.type === 'gradient') {
      return { tipo: 'gradiente', cor: coloracao.color };
    }

    if (coloracao.type === 'scale') {
      return {
        tipo: 'escala-cores',
        regras: coloracao.intervals.map(regra => ({
          id: regra.id,
          cor: regra.color,
          de: regra.from,
          ate: regra.until
        }))
      };
    }

    if (coloracao.type === 'byFilter') {
      return {
        tipo: 'por-filtro',
        regras: coloracao.intervals.map(regra => {
          const objeto = {
            id: regra.id,
            cor: regra.color,
            item: regra.item
          };

          if (chaveExisteEm(regra, 'interval') && regra.interval) {
            objeto.intervalo = { de: regra.interval.from, ate: regra.interval.until };
          }

          if (chaveExisteEm(regra, 'disable')) {
            objeto.desabilitar = regra.disable;
          }

          return objeto;
        })
      };
    }
  }

  // estará na nova estrutura
  return coloracao; // 'tipo' in coloracao ou objeto nulo
}

/**
 * @typedef {object} MapViewContextType
 * @prop {() => Promise<ViewOption[]>} getAvailableViews
 * Busca de visualizações disponíveis para o usuário.
 * @prop {() => Promise<UserOption[]>} getUsersFromSchema
 * Busca de usuários ativos do schema.
 * @prop {(id: number) => Promise<View>} getView
 * Busca de uma visualziação dado seu ID.
 * @prop {() => void} handleViewLoadEnd
 * Callback que chama o fim do carregamento da visualização aberta.
 * @prop {(view: View) => Promise<void>} openView
 * Callback que aplica as informações de uma visualização.
 * @prop {(ViewProps: ViewProps) => boolean} saveView
 * Callback que executa a construção dos dados de salvamento da visualização do mapa.
 * @prop {(message: string | React.ReactNode) => void} setFeedback
 * Callback que chama uma snackbar de erro.
 */
/**
 * @type {React.Context<MapViewContextType>}
 */
const MapViewContext = createContext({});

export const MapViewProvider = ({ children }) => {
  const {
    addTaskRunning,
    options,
    removeTaskRunning,
    setOptions,
    hasViewLoading,
    token,
  } = useContext(AppContext);

  const {
    metodoColoracao,
    setMetodoColoracao,
  } = useContext(ColorContext);

  const {
    fieldEvents,
    setFieldEvents,
    handleApplyFilterClick: handleApplyFilter,
  } = useContext(FilterContext);

  const {
    isolateType,
    refFontStatus,
    numberLabels,
    setIsolateType,
    setNumberLabels,
    setShowColors,
    setShowNames,
    setShowNumbers,
    setShowQuantitiesOptions,
    setVisibleCities,
    showColors,
    showQuantitiesOptions,
    visibleCities,
  } = useContext(MapContext);

  const {
    borderType,
    setBorderType,
    setOption,
  } = useContext(BorderRegionsContext);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  /**
   * @callback handleViewLoadStartCallback
   *
   * Callback que inicia o carregamento de uma visualização
   * @type {handleViewLoadStartCallback}
   */
  const handleViewLoadStart = useCallback(() => {
    addTaskRunning();
    hasViewLoading.current = true;
  }, [addTaskRunning, hasViewLoading]);

  /**
   * @callback handleViewLoadEndCallback
   *
   * Callback que finaliza o carregamento de uma visualização
   * @type {handleViewLoadEndCallback}
   */
  const handleViewLoadEnd = useCallback(() => {
    hasViewLoading.current = false;
    removeTaskRunning();
  }, [hasViewLoading, removeTaskRunning]);

  /**
   * @callback getUsersFromSchemaCallback
   * @returns {UserOption[]}
   *
   * Requisição de busca de usuários ativos do schema
   * @type {getUsersFromSchemaCallback}
   */
  const getUsersFromSchema = useCallback(async () => {
    const resource = new GetUsersFromSchemaResource(token);
    addTaskRunning();
    const result = await resource.result();
    removeTaskRunning();
    return result;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * @callback getAvailableViewsCallback
   * @returns {View[]}
   *
   * Requisição de busca de visualizações disponíveis para o usuário
   * @type {getAvailableViewsCallback}
   */
  const getAvailableViews = useCallback(async () => {
    const resource = new GetAvailableViewsResource(token);
    addTaskRunning();
    const result = await resource.result();
    removeTaskRunning();
    return result;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * @callback getViewCallback
   * @param {number} id
   * @returns {View}
   *
   * Requisição de busca das informações de uma visualização dado seu id.
   * @type {getViewCallback}
   */
  const getView = useCallback(async (id) => {
    const resource = new GetViewResource(token, id);
    handleViewLoadStart();
    const result = await resource.result();
    return result;
  }, [handleViewLoadStart, token]);

  /**
   * @callback handleSaveViewCallback
   * @param {SavedView} save
   * @returns {Promise<boolean>}
   *
   * Requisição de salvamento de informações de uma visualização.
   * @type {handleSaveViewCallback}
   */
  const handleSaveView = useCallback(async (save) => {
    const handler = new SaveNewViewHandler(token, save);
    addTaskRunning();
    const result = await handler.handle();
    removeTaskRunning();
    return result;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * @callback buildViewInfoToSaveCallback
   * @returns {View}
   *
   * Callback que constrói o objeto da visualização a ser salva
   * @type {buildViewInfoToSaveCallback}
   */
  const buildViewInfoToSave = useCallback(() => ({
    borderType,
    colorMode: metodoColoracao,
    fieldEvents,
    isolateOptions: { visibleCities, isolateType },
    labelsSize: refFontStatus.current.size,
    showColors,
    showNames: options.showNames,
    showNumbers: { numberLabels, showQuantitiesOptions },
  }), [
    borderType,
    metodoColoracao,
    fieldEvents,
    isolateType,
    refFontStatus,
    numberLabels,
    options.showNames,
    showColors,
    showQuantitiesOptions,
    visibleCities,
  ]);

  /**
   * @callback saveViewCallback
   * @param {ViewProps} ViewProps
   * @returns {boolean}
   *
   * Callback que realiza o salvamento das informações da visualização atual
   * @type {saveViewCallback}
   */
  const saveView = useCallback(async ({ name, description, sharedWith }) => {
    const saveInfo = {
      name,
      description: description || '',
      sharedWith: sharedWith.map(item => item.id),
      data: buildViewInfoToSave(),
    };
    const status = await handleSaveView(saveInfo);
    return status;
  }, [buildViewInfoToSave, handleSaveView]);

  /**
   * @callback OpenViewCallback
   * @param {View} view
   * @returns {Promise<void}
   *
   * Callback que aplica as informações de uma visualização no mapa
   * @type {OpenViewCallback}
   */
  const openView = useCallback(async (view) => {
    const {
      borderType,
      colorMode,
      fieldEvents,
      isolateOptions: { isolateType, visibleCities },
      labelsSize,
      showColors,
      showNames,
      showNumbers: { numberLabels, showQuantitiesOptions },
    } = view;

    setFieldEvents(fieldEvents || []);
    setIsolateType(isolateType || 'regions');
    refFontStatus.current.size = labelsSize || { cityFont: 1, regionFont: 6, othersFont: 3 };

    setBorderType(borderType || 'none');
    setOption(borderType || 'none');

    setVisibleCities(visibleCities || []);

    if (fieldEvents && fieldEvents.length > 0) {
      await handleApplyFilter(fieldEvents);
    }

    setShowNames(Object.values(showNames).some(value => value));

    setNumberLabels(numberLabels);
    setShowQuantitiesOptions(showQuantitiesOptions);
    setShowNumbers(numberLabels.length > 0);

    setMetodoColoracao(
      converterColoracaoAntigaParaNova(colorMode)
      || { tipo: 'gradiente', cor: '#00991c' }
    );
    setShowColors(showColors);

    setOptions(options => ({
      ...options,
      showColors: showColors !== undefined ? true : false, // eslint-disable-line
      showNames,
      showNumbers: numberLabels.length > 0,
    }));
  }, [
    handleApplyFilter,
    refFontStatus,
    setBorderType,
    setMetodoColoracao,
    setFieldEvents,
    setIsolateType,
    setNumberLabels,
    setOption,
    setOptions,
    setShowColors,
    setShowNames,
    setShowNumbers,
    setShowQuantitiesOptions,
    setVisibleCities,
  ]);

  /**
   * @callback setFeedbackCallback
   * @param {string | React.ReactNode} message
   * @returns {void}
   *
   * Callback que chama uma snackbar de erro.
   * @type {setFeedbackCallback}
   */
  const setFeedback = useCallback((message) => {
    const closeButton = (key) => (
      <IconButton
        aria-label="close"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon />
      </IconButton>
    );

    enqueueSnackbar(message, {
      action: key => closeButton(key),
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      variant: 'error',
      style: {
        textAlign: 'left',
      }
    });
  }, [closeSnackbar, enqueueSnackbar]);

  return (
    <MapViewContext.Provider
      value={{
        getAvailableViews,
        getUsersFromSchema,
        getView,
        handleViewLoadEnd,
        openView,
        saveView,
        setFeedback,
      }}
    >
      { children }
    </MapViewContext.Provider>
  );
};

export default MapViewContext;
