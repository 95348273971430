import React, {
  memo,
} from 'react';

import ColorPicker from 'material-ui-color-picker';

import { ColorPickerWrapper } from './styles';

/*
  TODO: MELHORIA
    Substituir componente do 'material-ui-color-picker'
    pelo `ChromePicker` do 'react-color'
    - possibilitar controle de Popper que nao funciona atualmente
 */

/**
 * @typedef {object} ColorPickerPopperProps
 * @prop {JSX.IntrinsicElements["div"]} WrapperProps
 * @prop {string} color Cor atual selecionada pela componente
 * @prop {(color: string) => void} setColor Callback que define uma nova cor
 * @prop {string} className Nome de classe HTML
 */
/**
 * @param {React.ComponentPropsWithRef<typeof ColorPicker> & ColorPickerPopperProps} props da componente
 * @returns {JSX.Element} Componente de selção de cor
 */
function ColorPickerPopper(props) {
  const {
    color,
    className,
    setColor,
    WrapperProps,
    ...PickerProps
  } = props;

  return (
    <ColorPickerWrapper {...WrapperProps} className={className}>
      <ColorPicker
        {...PickerProps}
        name="color-picker"
        value={color}
        onChange={setColor}
      />
    </ColorPickerWrapper>
  );
}

/**
 * Componente "caixa de seleção" para definição de uma cor em hex
 * @param color string de controle do valor atual selecionado
 * @param setColor função chamada para definir a cor
 */
export default memo(ColorPickerPopper);
