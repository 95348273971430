import React, {
  memo,
} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';

import {
  Botao,
  Form,
} from '../../../../../../../..';

import {
  ConteinerFormulario,
  ControleEstilizado,
} from './styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: 2,
    padding: 3,
    border: 'solid 0.5px #777',
    borderRadius: 3,
    maxWidth: 100,
    backgroundColor: '#fff',
  },
  error: {
    border: 'solid 1px #d24a3b',
  }
}));

/**
 * @typedef {object} FormularioIntervaloProps
 * @prop {React.ChangeEventHandler<HTMLInputElement>} alterarValorIntervalo Callback para alterar valores do intervalo
 * @prop {number} [ate] Valor maximo do intervalo
 * @prop {boolean} ateInvalido Booleano do estado de erro para o valor maximo
 * @prop {number} de Valor minimo do intervalo
 * @prop {boolean} deInvalido Booleano do estado de erro para o valor minimo
 * @prop {VoidFunction} removerIntervalo Callback para remover o intervalo da regra
 */
/**
 * @param {FormularioIntervaloProps} props da componente
 * @returns {JSX.Element}
 */
function FormularioIntervalo(props) {
  const {
    de,
    ate = Number.MAX_VALUE,
    deInvalido,
    ateInvalido,
    alterarValorIntervalo,
    removerIntervalo,
  } = props;

  const classes = useStyles();

  return (
    <Form>
      <ConteinerFormulario align="center">
        <ControleEstilizado
          variant="filled"
          error={deInvalido}
        >
          <Input
            name="de"
            disableUnderline
            value={Number.isNaN(de) ? '' : de}
            classes={classes}
            placeholder="De:"
            onChange={alterarValorIntervalo}
          />
        </ControleEstilizado>
        <ControleEstilizado
          variant="filled"
          error={ateInvalido}
        >
          <Input
            name="ate"
            disableUnderline
            value={ate === Number.MAX_VALUE || Number.isNaN(ate) ? '' : ate}
            classes={classes}
            placeholder="Até:"
            onChange={alterarValorIntervalo}
          />
        </ControleEstilizado>
      </ConteinerFormulario>
      <Botao
        style={{
          padding: '8px 16px',
          backgroundColor: 'transparent',
          color: '#b23',
          fontSize: 12,
          maxWidth: 75,
        }}
        onClick={removerIntervalo}
      >
        Remover
      </Botao>
    </Form>
  );
}

/**
 * Componente para criação de regra de intervalo em uma regra do tipo Cor por Filtro
 * @param alterarValorIntervalo Callback para alterar valores do intervalo
 * @param ate Valor maximo do intervalo
 * @param ateInvalido Booleano do estado de erro para o valor maximo
 * @param de Valor minimo do intervalo
 * @param deInvalido Booleano do estado de erro para o valor minimo
 * @param removerIntervalo Callback para remover o intervalo da regra
 */
export default memo(FormularioIntervalo);
