import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';

import Typography from '@material-ui/core/Typography';

import {
  Dialog,
  Table,
} from '../../../../components';

import {
  AppContext,
  DataContext,
  MapContext,
} from '../../../../contexts';

/** Ordena filtros por ano */
function sortByYear(a, b) {
  const { settings: settingsA } = a;
  const { settings: settingsB } = b;
  const [yearA] = settingsA.filterParams.ano;
  const [yearB] = settingsB.filterParams.ano;

  if (!yearA || !yearB) return 0;
  if (yearA - yearB > 0) return 1;
  if (yearA - yearB < 0) return -1;
  return 0;
}

/** Ordena os filtros do tipo Pessoas */
function sortPeopleFilter(a, b) {
  if (a.settings.subType === b.settings.subType) return 0;

  if (a.settings.subType === 'perfis') return -1;
  if (a.settings.subType === 'pessoas') return 1;
  if (b.settings.subType === 'perfis') return 1;
  if (b.settings.subType === 'pessoas') return -1;

  if (a.settings.subType === 'grupos' && b.settings.subType.match(/cores|cadastros/)) return -1;
  if (a.settings.subType === 'cadastros' && b.settings.subType.match(/cores/)) return -1;
  if (a.settings.subType === 'cadastros' && b.settings.subType === 'grupos') return 1;
  if (a.settings.subType.match(/cores/) && b.settings.subType.match(/grupos|cadastros/)) return 1;
  return 0;
}

function ResultsDialog(props) {
  const { show, onClose } = props;

  const {
    activeFilters,
    cities,
  } = useContext(AppContext);

  const {
    filteredCities,
    getResult,
    isSingleFilter,
  } = useContext(DataContext);

  const { visibleCities } = useContext(MapContext);

  const filterData = useMemo(() => getResult(), [getResult]);

  /**
   * Os estados de headers e footer possuem a mesma estrutura
   * e armazenam objetos no formato CellObject
   * @typedef {object} CellObject
   * @prop {number} id
   * @prop {'left' | 'right'} align
   * @prop {string} label
   */
  /**
   * @type {[CellObject[], SetState<CellObject[]]}
   */
  const [headers, setHeaders] = useState([]);
  /**
   * @type {[CellObject[], SetState<CellObject[]]}
   */
  const [footer, setFooter] = useState([]);

  /**
   * @typedef {{
   *  Cidade: string,
   *  [filter: string]: number,
   * }} RowDataObject
   * O estado rowsData armazena as linhas de cada cidade na tabela.
   * Cada item tem o formato do objeto RowDataObject
   *
   * @type {[RowDataObject[], SetState<RowDataObject[]>]}
   */
  const [rowsData, setRowsData] = useState([]);

  const citiesToShow = useMemo(() => (
    visibleCities.length !== 0
      ? filteredCities.filter(item => (visibleCities.includes(item)))
      : filteredCities
  ), [visibleCities, filteredCities]);

  const createSingleTable = useCallback((result) => {
    const rowsData = Object.entries(result)
      .filter(([id]) => (visibleCities.length === 0 || visibleCities.includes(id)))
      .map(([key, item]) => ({ Cidade: cities[key].name, Quantidade: item }));

    const total = rowsData.reduce((a, b) => a + b['Quantidade'], 0).toLocaleString('pt-BR');

    const plural = rowsData.length > 1 ? 's' : '';
    setFooter([
      { id: 1, align: 'left', label: `TOTAL (${rowsData.length} cidade${plural})` },
      { id: 2, align: 'right', label: total },
    ]);

    setHeaders([
      { id: 1, align: 'left', label: 'Cidade' },
      { id: 2, align: 'right', label: 'Quantidade' },
    ]);

    return rowsData;
  }, [cities, visibleCities]);

  const createCompoundTable = useCallback(({ events, politics, people, emendas }) => {
    if (events.length > 0) events.sort(sortByYear);
    if (politics.length > 0) politics.sort(sortByYear);
    if (people.length > 0) people.sort(sortPeopleFilter);
    if (emendas.length > 0) emendas.sort(sortByYear);

    const footer = {};
    const rowsData = citiesToShow.map((id) => {
      const row = { Cidade: cities[id].name };

      function countRowValue({ settings, result }) {
        const column = settings.title.join(', ');

        if (Object.keys(result).find((city) => city === id)) {
          row[column] = result[id];
          footer[column] = (footer[column] || 0) + row[column];
        } else {
          row[column] = 0;
          if (!footer[column]) footer[column] = 0;
        }
      }

      if (events.length > 0) events.forEach(countRowValue);
      if (politics.length > 0) politics.forEach(countRowValue);
      if (people.length > 0) people.forEach(countRowValue);
      if (emendas.length > 0) emendas.forEach(countRowValue);

      return row;
    });

    const [row] = rowsData;
    if (row) {
      const headers = Object.keys(row)
        .map((key, index) => ({
          id: index + 1,
          align: (index === 0) ? 'left' : 'right',
          label: key,
        }));
      setHeaders(headers);
    } else setHeaders([]);

    if (Object.keys(footer).length > 0) {
      const plural = rowsData.length > 1 ? 's' : '';
      setFooter([
        { id: 1, align: 'left', label: `TOTAL (${rowsData.length} cidade${plural})` },
        ...Object.values(footer).map((value, index) => ({
          id: index + 2,
          align: 'right',
          label: value.toLocaleString('pt-BR'),
        }))
      ]);
    } else setFooter([]);

    return rowsData;
  }, [cities, citiesToShow]);

  const formatResult = useCallback((filter) => {
    let rows = [];

    if (filter.result && cities) {
      const { result } = filter;
      rows = createSingleTable(result);
    } else if (Object.values(filter).some(list => list.length > 0) && cities) {
      rows = createCompoundTable(filter);
    }

    return rows;
  }, [cities, createCompoundTable, createSingleTable]);

  useEffect(() => {
    if (activeFilters.length === 0) {
      setHeaders([]);
      setRowsData([]);
      setFooter([]);
    }
  }, [activeFilters.length]);

  useEffect(() => {
    if (activeFilters.length > 0
      && (rowsData.length === 0 || rowsData.length !== citiesToShow.length)) {
      setRowsData(formatResult(filterData));
    }
  }, [
    activeFilters.length,
    filterData,
    formatResult,
    rowsData.length,
    citiesToShow.length,
  ]);

  return (
    <Dialog
      title="Resultados"
      {...{ show, onClose }}
    >
      <>
        {
          isSingleFilter && (
            <div style={{ margin: '-10px 10px 10px' }}>
              {
                filterData.settings && filterData.settings.title.map((item) => (
                  <Typography
                    key={`item-${`${item}`.toLowerCase()}`}
                    style={{ fontSize: 12, maxWidth: 200, marginBottom: 2 }}
                  >
                    {item}
                  </Typography>
                ))
              }
            </div>
          )
        }
        <Table headers={headers} rows={rowsData} footer={footer} />
      </>
    </Dialog>
  );
}

export default ResultsDialog;
