import React, {
  memo,
  useCallback,
  useContext,
} from 'react';

import {
  AppContext,
  MapContext,
} from '../../../../../../contexts';

import Label from './components/Label';

function Labels() {
  const {
    admRegions,
    bacias,
    cities,
    dioceses,
    regions,
  } = useContext(AppContext);

  const { labels } = useContext(MapContext);

  const getLabelTypeAndData = useCallback((labelClass) => {
    if (labelClass.match(/city/i)) {
      return ['city', cities];
    }

    if (labelClass.match(/adm-region/i)) {
      return ['adm-region', admRegions];
    }

    if (labelClass.match(/bacia/i)) {
      return ['bacia', bacias];
    }

    if (labelClass.match(/diocese/i)) {
      return ['diocese', dioceses];
    }

    return ['region', regions];
  }, [admRegions, bacias, cities, dioceses, regions]);

  return (
    <g className="labels">
      {
        labels.map(props => {
          const [identifierClass] = props.classes;
          const [type, data] = getLabelTypeAndData(identifierClass);

          return (
            <Label
              type={type}
              initialData={data}
              classes={props.classes}
              x={props.x}
              y={props.y}
            />
          );
        })
      }
    </g>
  );
}

export default memo(Labels);
