import React, {
  memo,
} from 'react';

import { BotaoPadrao } from './styles';

/**
 * @param {import('@mui/material/Button').ButtonProps} props
 * @returns {JSX.Element}
 */
function Botao(props) {
  const { children, ...resto } = props;

  return (
    <BotaoPadrao {...resto}>
      {children}
    </BotaoPadrao>
  );
}

/**
 * Componente estilizado de botão.
 */
export default memo(Botao);
