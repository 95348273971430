import React, {
  memo,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';

import Collapse from '@material-ui/core/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Botao,
  FlexContainer,
  Text,
} from '../../../../..';

import {
  Tip,
  OptionContainer,
} from './styles';

/**
 * @typedef {object} ViewOptionProps
 * @prop {number} index
 * Indice de uma opção de visualizaçao
 * @prop {string} title
 * Título/Nome da visualização
 * @prop {string} [description]
 * Descrição opcional da visualização
 * @prop {number} id
 * ID da visualização.
 * @prop {string} isOpened
 * Identifica qual visualização está com sua descrição expandida.
 * @prop {(id: number) => void} onOpen
 * Callback para o clique de botão de abrir.
 * @prop {SetState<Inject<EventTarget, HTMLDivElement>>} setOpened
 * SetState para o estado `isOpened`
 * @prop {CSSProps} style
 * Estilização da caixa.
 */
/**
 * @param {ViewOptionProps} props
 * @returns {React.ReactElement<any, typeof FlexContainer>}
 * Componente caixa que representa uma visualização
 */
function ViewOption(props) {
  const {
    description,
    id,
    index,
    isOpened,
    onOpen,
    setOpened,
    title,
  } = props;

  /**
   * Referência ao "root" do componente `Collapse` da descrição.
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const refCollapse = useRef(null);

  /**
   * Estado que monitora o colapso/descolapso da descrição da view
   * @type {[boolean, SetState<boolean>]}
   */
  const [showDesc, setShowDesc] = useState(false);

  /**
   * Memo que monitora a existencia de uma descrição para a visualização
   * @type {boolean}
   */
  const hasDescription = useMemo(() => (description && description !== ''), [description]);

  useEffect(() => {
    if (isOpened !== title) {
      setShowDesc(false);
      const text = document.querySelector(`#description-trigger-${id}`);
      if (text) {
        text.style.height = 'auto';
        text.style.display = 'unset';
      }
    }
  }, [isOpened, id, title]);

  useEffect(() => {
    if (refCollapse.current
      && (!refCollapse.current.onmouseenter && !refCollapse.current.onmouseleave)
    ) {
      refCollapse.current.onmouseenter = (
        (e) => {
          const tip = e.currentTarget.querySelector(`#unexpand-tip-${id}`);
          if (tip) tip.style.display = 'block';
        }
      );
      refCollapse.current.onmouseleave = (
        (e) => {
          const tip = e.currentTarget.querySelector(`#unexpand-tip-${id}`);
          if (tip) tip.style.display = 'none';
        }
      );
    }
  });

  const matches = useMediaQuery('(max-width: 600px)');

  return (
    <OptionContainer index={index}>
      <FlexContainer
        flow={`${matches ? 'column' : 'row'} nowrap`}
        align="center"
        justify="space-between"
        style={{ padding: 8 }}
      >
        <FlexContainer
          align="center"
          justify={matches ? 'center' : 'flex-start'}
          style={{
            marginRight: 2,
            padding: 3,
            width: matches ? '100%' : undefined
          }}
        >
          <Text
            textAlign={matches ? 'center' : 'left'}
            fontSize={17}
            style={{ wordBreak: 'break-word' }}
          >
            {title}
          </Text>
        </FlexContainer>
        <FlexContainer
          flow={`${matches ? 'column' : 'row'} nowrap`}
          align="center"
          justify={!matches ? 'space-between' : undefined}
          style={{
            marginLeft: !matches ? 2 : undefined,
            padding: 3,
            width: matches ? '100%' : '55%'
          }}
        >
          <FlexContainer
            flow="column"
            style={{ maxWidth: '70%' }}
          >
            <Text
              id={`description-trigger-${id}`}
              textAlign={matches ? 'center' : 'left'}
              fontSize={!hasDescription ? 12 : 15}
              fontWeight={!hasDescription ? 'normal' : undefined}
              style={{
                cursor: hasDescription ? 'pointer' : 'auto',
                transition: 'height 500ms ease'
              }}
              onClick={(e) => {
                if (!hasDescription) return;
                setShowDesc(true);
                setOpened(title);
                e.currentTarget.style.height = '0px';
                e.currentTarget.style.display = 'none';
              }}
            >
              {hasDescription ? 'Expandir descrição' : 'Sem Descrição'}
            </Text>
            {
              (hasDescription) && (
                <Collapse
                  in={showDesc}
                  innerRef={refCollapse}
                >
                  <Tip
                    id={`unexpand-tip-${id}`}
                    fontSize={10}
                    fontWeight="normal"
                    onClick={() => setOpened(undefined)}
                  >
                    Clique para esconder
                  </Tip>
                  <Text
                    fontSize={12}
                    fontWeight="lighter"
                    textAlign="justify"
                    style={{
                      lineHeight: '1.25',
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word',
                    }}
                    onClick={() => setOpened(undefined)}
                  >
                    {description}
                  </Text>
                </Collapse>
              )
            }
          </FlexContainer>
          <Botao
            disabled={title === ''}
            style={{
              backgroundColor: '#3498DB',
              color: '#fff',
              padding: '4px 6px',
              marginTop: matches ? 6 : undefined,
              marginLeft: !matches ? 2 : undefined,
            }}
            onClick={() => onOpen(id)}
          >
            Abrir
          </Botao>
        </FlexContainer>
      </FlexContainer>
    </OptionContainer>
  );
}
/**
 * Componente que representa uma visualização em uma linha, dando a opção de abrir a visualização.
 * Mostra o nome da visualição e sua descrição, se houver uma.
 *
 * @param title Nome da visualização
 * @param description Descrição da visualização
 * @param id Identificação da visualização.
 * @param isOpened Estado que define a descrição expandida
 * @param onOpen callback chamado ao abrir uma visualização.
 * @param setOpened SetState usado para controlar as descrições em colapso
 * @param style Estilização da caixa.
 */
export default memo(ViewOption);
