import Resource from './Resource';

export default class GetAmendmentsFromCityResource extends Resource {
  /**
   * @param {string} token autenticação
   * @param {number} id idCidade
   */
  constructor(token, id) {
    super(token);
    this.id = id;
  }

  async result() {
    try {
      const response = await this.request.get(`/emendas/${this.id}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    return {};
  }
}
