import React, {
  memo,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';

import {
  AppContext,
  DataContext,
  MapContext,
} from '../../../../contexts';

import {
  Dialog,
  Botao,
  CheckBox,
} from '../../../../components';

import { FILTER_TYPES } from '../../../../contexts/constants';

import { analytics } from '../../../../index';

import { Prompt, FiltersOptions, FilterSubject, FilterType } from './styles';

function QuantityDialog(props) {
  const {
    isVisible,
    setVisible,
  } = props;

  const {
    activeFilters,
    hasViewLoading,
    setOptions,
  } = useContext(AppContext);

  const { getFilters } = useContext(DataContext);

  const {
    setNumberLabels,
    numberLabels,
    setShowQuantitiesOptions,
    setShowNumbers,
    showQuantitiesOptions,
  } = useContext(MapContext);

  const [selectedQuantitiesToShow, setSelectedQuantitiesToShow] = useState([]);
  const [selectedSumsToShow, setSelectedSumsToShow] = useState({
    sumEvents: false,
    sumPolitics: false,
    sumPeople: false,
    sumEmendas: false,
  });
  const currentFilters = useMemo(() => getFilters(), [getFilters]);

  useEffect(() => {
    if (hasViewLoading.current) {
      setSelectedQuantitiesToShow(numberLabels);
      setSelectedSumsToShow(showQuantitiesOptions);
    }
  }, [hasViewLoading, numberLabels, showQuantitiesOptions]);

  useEffect(() => {
    setSelectedQuantitiesToShow([]);
    setSelectedSumsToShow({
      sumEvents: false,
      sumPolitics: false,
      sumPeople: false,
      sumEmendas: false,
    });
  }, [activeFilters]);

  const getSumType = useCallback((type) => {
    let sumType;
    if (type === FILTER_TYPES.EVENTOS) {
      sumType = 'sumEvents';
    } else if (type === FILTER_TYPES.POLITICOS) {
      sumType = 'sumPolitics';
    } else if (type === FILTER_TYPES.PESSOAS) {
      sumType = 'sumPeople';
    } else if (type === FILTER_TYPES.EMENDAS) {
      sumType = 'sumEmendas';
    }
    return sumType;
  }, []);

  const updateSumTypeOption = useCallback((type) => {
    setSelectedSumsToShow(old => {
      const sumType = getSumType(type);
      return {
        ...old,
        [sumType]: !old[sumType],
      };
    });
  }, [getSumType]);

  const handleCloseClick = useCallback(() => setVisible(false), [setVisible]);

  const handleClearSelectedQuantities = useCallback(() => {
    setSelectedQuantitiesToShow([]);
    setSelectedSumsToShow({
      sumEvents: false,
      sumPolitics: false,
      sumPeople: false,
      sumEmendas: false,
    });

    setNumberLabels([]);
    setShowQuantitiesOptions({
      sumEvents: false,
      sumPolitics: false,
      sumPeople: false,
      sumEmendas: false,
    });
    setShowNumbers(false);
    setOptions(options => ({ ...options, showQuantities: false }));
  }, [
    setOptions,
    setNumberLabels,
    setShowNumbers,
    setShowQuantitiesOptions,
  ]);

  const handleApply = useCallback(() => {
    const isAnyQuantitySelected = selectedQuantitiesToShow.length > 0;
    setShowNumbers(isAnyQuantitySelected);
    setOptions(options => ({
      ...options,
      showQuantities: isAnyQuantitySelected
    }));
    setNumberLabels(selectedQuantitiesToShow);
    setShowQuantitiesOptions(selectedSumsToShow);
    handleCloseClick();
    analytics.logEvent('dialog-mostrar/esconder-quantidades-aplicar');
  }, [
    handleCloseClick,
    selectedQuantitiesToShow,
    selectedSumsToShow,
    setShowQuantitiesOptions,
    setOptions,
    setShowNumbers,
    setNumberLabels,
  ]);

  return (
    <Dialog
      actions={(
        <>
          <Botao
            style={{
              backgroundColor: '#3498DB',
              color: '#fff',
              padding: '5px 15px',
            }}
            onClick={handleApply}
          >
            Aplicar
          </Botao>
          <Botao
            style={{
              backgroundColor: 'transparent',
              color: '#222',
              padding: '5px 15px',
            }}
            onClick={handleClearSelectedQuantities}
          >
            Limpar
          </Botao>
        </>
      )}
      height={450}
      title="Mostrar quantidades"
      show={isVisible}
      onClose={handleCloseClick}
    >
      <Prompt>Selecione os filtros que devem mostrar sua quantidade no mapa:</Prompt>
      <FiltersOptions>
        {
          Object.entries(currentFilters).map(([type, options]) => {
            const sumType = getSumType(type);
            const selectedFilters = options
              .filter(filter => selectedQuantitiesToShow.includes(`${type}_${filter}`));
            return (
              options.length > 0 && (
                <FilterSubject key={`menu-${type}-part`}>
                  <FilterType>
                    {
                      type
                        .replace(/events/, 'eventos')
                        .replace(/politics/, 'políticos')
                        .replace(/people/, 'pessoas')
                        // .replace(/emendas/, 'emendas')
                    }
                  </FilterType>
                  <CheckBox
                    key={`sum-${type}-items`}
                    style={{ paddingLeft: 15, whiteSpace: 'nowrap' }}
                    label="-- Somar selecionados"
                    checked={selectedSumsToShow[sumType]}
                    disabled={selectedFilters.length <= 1}
                    onClick={() => selectedFilters.length > 1 && updateSumTypeOption(type)}
                  />
                  {
                    options.map(filter => (
                      <CheckBox
                        key={`menu-item-${filter}`}
                        style={{ paddingLeft: 15, whiteSpace: 'nowrap' }}
                        label={filter}
                        checked={selectedQuantitiesToShow.includes(`${type}_${filter}`)}
                        onClick={() => {
                          setSelectedQuantitiesToShow(old => {
                            if (!old.includes(`${type}_${filter}`)) {
                              return [...old, `${type}_${filter}`];
                            }

                            if (old.includes(`${type}_${filter}`)) {
                              return old.filter((item) => item !== `${type}_${filter}`);
                            }

                            return old;
                          });

                          if (selectedFilters.length < 2 && selectedSumsToShow[sumType]) {
                            updateSumTypeOption(type);
                          }
                        }}
                      />
                    ))
                  }
                </FilterSubject>
              )
            );
          })
        }
      </FiltersOptions>
    </Dialog>
  );
}

export default memo(QuantityDialog);
