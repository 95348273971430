import React from 'react';

import { CheckBoxItem } from './styles';

/**
 * @type {Component<import('./styles').CheckBoxProps>}
 */
const CheckBox = (props) => (
  <CheckBoxItem
    {...props}
  />
);
/**
 * Componente `CheckBox` estilizado.
 *
 * Construído do componente `FormControlLabel`.
 * Possui uma CheckBox e um label para a CheckBox.
 *
 * @param {string} checkColor
 * Cor do `CheckBox` quando selecionada.
 * Padrão `#0B70D2`
 * @param {boolean} checked
 * Booleano que indica se a `CheckBox` está selecionada.
 *
 * Não deve ser `true` ao mesmo tempo que `indeterminate`.
 * @param {string} color
 * Cor do `label` da componente. Padrão `#000`
 * @param {boolean} disabled
 * Booleano que indica se a `CheckBox` está desabilitada.
 * Padrão `false`.
 *
 * @param {boolean} indeterminate
 * Booleano que indica se a `CheckBox` está no estado indeterminado.
 * Padrão `false`.
 *
 * Não deve ser `true` ao mesmo tempo que `checked`.
 * @param {React.ReactNode} label
 * Label da componente.
 * Pode ser uma `string` ou componente.
 * @param {() => void} onClick
 * Callback, que quando existe, é executado no clique da componente.
 * @param {CSSProps} style
 * Estilização da componente.
 */
export default CheckBox;
