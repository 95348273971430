import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import MenuIcon from '@material-ui/icons/Menu';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ExportImageDialog from './components/ExportImageDialog';
import {
  Component,
  MuiAutocomplete,
  MuiAutocompletePopper,
  MuiTextField
} from './styles';

import {
  AppContext,
  MapContext,
} from '../../contexts';

import './autocomplete.css';

import { analytics } from '../../index';

function HeaderBar(props) {
  const { menuAction } = props;

  const {
    cities,
    menuClicked,
    showExportDialog,
    setShowExportDialog,
  } = useContext(AppContext);

  const {
    visibleCities,
    setActiveCity,
    currentCity,
  } = useContext(MapContext);

  const [searchQueryCities, setSearchQueryCities] = useState('');

  const handleMenuClick = useCallback(() => {
    menuAction();
    analytics.logEvent('barra-superior-expandir/esconder-menu');
  }, [menuAction]);

  const handleExportImageDialogShow = useCallback(() => {
    setShowExportDialog(true);
    analytics.logEvent('barra-superior-mostrar-dialog-exportar-imagem');
  }, [setShowExportDialog]);

  const handleOpenInNewTab = useCallback(() => {
    window.open(window.location.href, '_blank');
    analytics.logEvent('barra-superior-abrir-nova-guia');
  }, []);

  const optionCities = useMemo(() => (
    Object.entries(cities)
      .filter(([key]) => visibleCities.includes(key) || visibleCities.length === 0)
      .map(([key, value]) => ({ id: Number(key), label: value.name }))
  ), [cities, visibleCities]);

  const handleSearchSelect = useCallback((event, item) => {
    const city = document.querySelector(`.city-${item.id}`);
    setActiveCity(city);
    setSearchQueryCities(null);
    analytics.logEvent('barra-superior-buscar-cidade-por-nome');
  }, [setActiveCity]);

  return (
    <Component>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={handleMenuClick}
          style={{ backgroundColor: menuClicked ? '#222' : 'transparent' }}
        >
          <MenuIcon style={{ color: '#fff', fontWeight: 'bold' }} />
        </IconButton>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 'bold',
            color: '#fff',
            margin: '0 15px 0 0',
          }}
        >
          BI
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <MuiAutocomplete
          freeSolo
          clearOnEscape
          disableClearable
          size="small"
          id="search-auto-complete"
          value={currentCity}
          options={optionCities}
          getOptionSelected={city => city}
          getOptionLabel={city => city.label || ''}
          onChange={handleSearchSelect}
          onInputChange={(event, value) => setSearchQueryCities(value)}
          PopperComponent={props => (
            <MuiAutocompletePopper
              {...props}
              transition
              placement="bottom-end"
            />
          )}
          onKeyDown={event => event.keyCode === 27 && event.target.blur()}
          noOptionsText="Nenhuma cidade encontrada"
          renderInput={params => (
            <>
              <MuiTextField
                {...params}
                label="Pesquisar cidades..."
                variant="filled"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  value: (searchQueryCities || '')
                }}
              />
              <SearchIcon
                fontSize="small"
                style={{
                  padding: '8px',
                  color: '#fff'
                }}
              />
            </>
          )}
        />
        <Tooltip title="Exportar imagem">
          <IconButton
            style={{ padding: 8 }}
            onClick={handleExportImageDialogShow}
          >
            <GetAppIcon style={{ color: '#fff', fontSize: 18 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Abrir em uma nova guia">
          <IconButton
            onClick={handleOpenInNewTab}
            style={{ padding: 8 }}
          >
            <OpenInNewIcon style={{ color: '#fff', fontSize: 18 }} />
          </IconButton>
        </Tooltip>
      </div>
      <ExportImageDialog
        show={showExportDialog}
        onClose={() => setShowExportDialog(false)}
      />
    </Component>
  );
}

export default memo(HeaderBar);
