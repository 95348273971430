import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 75px;
  transition: all .5s ease;
  min-height: 40px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  &.visible {
    right: 0;
    width: auto;

    &:has(div:last-child.hidden) { // componente de alerta esta escondido

      & > .MuiPaper-root {
        border-bottom-left-radius: 4px;
      }
    }
  }

  &.hidden {
    right: -100%;
    width: 100%;
  }

  & > .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded {
    transition: border-radius 300ms ease-in-out;
    border-radius: 4px 0 0 4px;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 55px;
    height: 55px;
  }

  .MuiAccordionDetails-root {
    flex-flow: column;
    margin-top: -15px;
  }
`;
