import React, {
  memo,
  useMemo,
} from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

/**
 * @typedef {object} FeedbackIconProps
 * @prop {"alert" | "default" | "info"} type
 */
/**
 * @param {FeedbackIconProps} props
 * @returns {JSX.Element}
 */
function FeedbackIcon(props) {
  const { type } = props;

  const icon = useMemo(() => {
    if (type === 'alert') return <WarningIcon />;
    if (type === 'info') return <InfoIcon />;
    return <></>;
  }, [type]);

  return <span className="feedbackIcon">{icon}</span>;
}

export default memo(FeedbackIcon);
