import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';

import * as d3 from 'd3';
import hull from 'hull.js';

import { AppContext } from '../..';

import MapProviderContext from '../ProviderGroup';

import borderOverrides from './overrides.json';

import './ContextTypes';

/**
 * @typedef {object} BorderRegionsContextType
 * @prop {BorderTypes} borderType
 * Estado que monitora qual opção de contorno está aplicada.
 * @prop {BorderTypes} option
 * Opções de tipo de contornos a mostrar no mapa.
 * @prop {SetState<BorderTypes>} setOption
 * SetState para o estado `option`.
 * @prop {SetState<BorderTypes>} setBorderType
 * SetState para o estado `borderType`.
 * @prop {HullProps[]} hulls
 * Estado que armazena as propriedades dos contornos
 */
/**
 * @type {React.Context<BorderRegionsContextType>}
 */
const BorderRegionsContext = createContext({});

export const BorderRegionsProvider = ({ children }) => {
  /**
   * Define o tipo e opção de contorno
   * selecionado
   */
  const [option, setOption] = useState('none');

  const {
    admRegions,
    bacias,
    dioceses,
    regions,
  } = useContext(AppContext);

  const {
    getPointsOfPath,
    mounted,
    refMap,
    setMounted,
  } = useContext(MapProviderContext);

  const [hulls, setHulls] = useState([]);
  
  //========================================================
  /**
   * Essa função cria o polígono que define o contorno de um conjunto
   * de pontos fornecidos
   *
   * @param points Conjunto de pontos
   * @param type Tipo de contorno
   * @param id ID do contorno
   *
   * @returns void
   */
  const createPolygon = useCallback((points, type, id) => {
    const createLine = d3.line()
      .x((item) => item.x)
      .y((item) => item.y);

    const polygon = {};
    polygon.d = createLine(points);
    polygon.classes = [`${type}-${id}`, `${type}-hull`];

    return polygon;
  }, []);

  /**
   * Essa função realiza o parseamento dos dados dos contornos previamente
   * gerados e armazenados no localStorage do navegador
   *
   * @returns Object | undefined
   */
  const loadHulls = useCallback(() => {
    const data = localStorage.getItem('app-mapas:hulls');
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }, []);

  const createHullByGroup = useCallback((type, id, hulls, points = undefined) => {
    if (!points) {
      const cities = Array.from(refMap.current.querySelectorAll(`[data-${type}='${id}']`));
      points = cities.reduce((total, city) => [...total, ...getPointsOfPath(city)], []);
    }
    if (borderOverrides[`${type}-${id}`]) {
      borderOverrides[`${type}-${id}`].forEach((items) => {
        const hullProps = createPolygon(items, type, id);
        hulls.push(hullProps);
      });
    } else if (points.length > 0) {
      const selectedPoints = hull(points, 1.5, ['.x', '.y']);
      const hullProps = createPolygon(selectedPoints, type, id);
      hulls.push(hullProps);
      return selectedPoints;
    }
    return points;
  }, [createPolygon, getPointsOfPath, refMap]);

  const locations = useMemo(() => ({
    'adm-region': Object.keys(admRegions),
    bacia: Object.keys(bacias),
    diocese: Object.keys(dioceses),
    region: Object.keys(regions),
  }), [admRegions, bacias, dioceses, regions]);

  const isGeoDataLoaded = useMemo(() => (
    locations.region.length > 0
    && locations.diocese.length > 0
    && locations.bacia.length > 0
    && locations['adm-region'].length > 0
  ), [locations]);

  const createHulls = useCallback(() => {
    const storageHulls = loadHulls();
    const [today] = new Date().toISOString().split('T');
    const hulls = [];
    if (storageHulls && storageHulls.date === today) {
      Object.entries(storageHulls.elements).forEach(([type, item]) => {
        Object.entries(item).forEach(([id, points]) => {
          createHullByGroup(type, id, hulls, points);
        });
      });
      setHulls(hulls);
      setMounted(old => ({ ...old, hulls: 'loaded', labels: 'loading' }));
    } else if (isGeoDataLoaded) {
      const data = {
        elements: {},
        date: today
      };

      data.elements = Object.entries(locations).reduce((total, [type, list]) => ({
        ...total,
        [type]: list.reduce((total, element) => ({
          ...total,
          [element]: createHullByGroup(type, element, hulls)
        }), {})
      }), {});

      localStorage.setItem('app-mapas:hulls', JSON.stringify(data));
      setHulls(hulls);
      setMounted(old => ({ ...old, hulls: 'loaded', labels: 'loading' }));
    }
  }, [
    createHullByGroup,
    isGeoDataLoaded,
    loadHulls,
    locations,
    setMounted,
  ]);

  useEffect(() => {
    if (mounted.cities === 'loaded' && mounted.hulls === 'loading') {
      createHulls();
    }
  }, [createHulls, mounted.cities, mounted.hulls]);

  //========================================================

  const [borderType, setBorderType] = useState('none');

  return (
    <BorderRegionsContext.Provider
      value={{
        borderType,
        option,
        setOption,
        setBorderType,
        hulls,
      }}
    >
      {children}
    </BorderRegionsContext.Provider>
  );
};

export default BorderRegionsContext;
