import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

export const NoRowsMessage = styled(Typography)`
  & {
    color: #444;
    font-Size: 16;

    display: ${props => props.hidden ? 'none' : 'flex'};
    justify-Content: center;
    align-Items: center;

    padding: 20px 0;
  }
`;

export const TablePaper = styled(Paper)`
  & {
    display: ${props => props.hidden ? 'none' : 'auto'};

    margin-bottom: 16px;
    width: max-content;
  }
`;

export const StyledTableRow = styled(TableRow)`
  & {
    &:nth-of-type(odd) {
      background-color: #dedede;
    }
  }
`;

export const HiddenSpan = styled.span`
  & {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
`;
