import React, {
  memo,
  forwardRef,
} from 'react';

import styled from 'styled-components';

import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import TextFieldItem from '@material-ui/core/TextField';
import MuiAutoComplete from '@material-ui/lab/Autocomplete';

import {
  CheckBox,
} from '../../components';

/**
 * Componente TextField da variante "Filled"
 *
 * Props úteis de TextField: Pode ver mais na [API do MUI](https://mui.com/pt/api/text-field/)
 * @param {boolean} disabled
 * Booleano que habilta/desabilta componente
 * @param {string | number}
 * defaultValue Valor padrão para componente não controlada
 * @param {boolean} error
 * Booleano que indica erro no input
 * @param {React.ReactChild} label Label do componente
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange
 * Callback chamado na alteração do `value` do elemento inpu
 * @param {string} placeholder
 * String de placeholder para o input (aparece quando não há valor no input)
 * @param {boolean} required
 * Booleano que indica se o input deve possuir um valor
 * @param {string} type
 * Tipo do elemento input da componente (Deve ser um [tipo válido do HTML5](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types))
 * @param {string | number} value
 * Prop de controle do valor do elemento input (retorna o valor do atributo `value`)
 *
 * @type {React.NamedExoticComponent<import('@material-ui/core').FilledTextFieldProps>}
 */
export const TextField = memo((props) => (
  <TextFieldItem
    {...props}
    variant="filled"
  />
));

const StyledTextField = styled(TextField)`
  & {
    .MuiInputLabel-root.Mui-required {
      span[class*=asterisk] {
        color: red;
      }
    }
  }
`;

/**
 * Objeto de opção deve ter um id e pode ter outras propriedades
 * que podem ser utilizadas no componente através das funções
 * @typedef {{ id: string | number } & Object.<string, any>} AutoCompleteOption
 */
/**
 * @template T, Multiple, DisableClearable, FreeSolo
 * @typedef {
 *  import('@material-ui/lab/Autocomplete').AutocompleteProps<
 *    T,
 *    Multiple,
 *    DisableClearable,
 *    FreeSolo
 *  >
 * } MuiAutoCompletePropsTemplate
 */
/**
 * @typedef {
 *  SetState<AutoCompleteOption[]>
 *  | (option: AutoCompleteOption) => void
 * } AutoCompleteOnChange
 *
 * @typedef {object} AutoCompletePropsPart
 * @prop {AutoCompleteOnChange} onChange
 *
 * @typedef {
 *  MuiAutoCompletePropsTemplate<
 *    AutoCompleteOption,
 *    boolean,
 *    undefined,
 *    true
 *  >
 * } MuiAutoCompleteProps
 *
 * @typedef {
 *  Omit<MuiAutoCompleteProps, "onChange"> & AutoCompletePropsPart
 * } MuiAutoCompleteOverwritten
 *
 * @typedef {object} AutoCompleteProps
 * @prop {boolean} closeOnSelect
 * Booleano que define se o popup do Autocomplete deve fechar quando uma opção for selecionada
 * @prop {boolean} customOnChange
 * Booleano que define se onChange não utiliza
 * o formato padrão do componente Autocomplete (SetState)
 * @prop {string} label
 * Label passada para os props do componente TextField
 * @prop {boolean} multiple
 * Booleano que indica se podem ser feitas múltiplas escolhas
 * @prop {(value: string) => void} onTyping
 * Callback chamado durante a escrita no componente TextField
 * @prop {string} placeholder
 * Placeholder passado para os props do componente TextField
 * @prop {number | string} width
 * Propriedade `maxWidth` do componente TextField que serve de input para o Autocomplete
 *
 * @typedef {
 *  React.ForwardRefExoticComponent<MuiAutoCompleteOverwritten & AutoCompleteProps>
 * } ForwardedAutoComplete
 */
/**
 * Componente estilizada de Autocomplete com dois modos de seleção.
 * Uma seleção única e uma seleção múltipla.
 * - Propriedades únicas
 * @param {boolean} closeOnSelect
 * Booleano que define se o popup do Autocomplete deve fechar quando uma opção for selecionada
 * @param {boolean} customOnChange
 * Booleano que define se onChange não utiliza
 * o formato padrão do componente Autocomplete (SetState)
 * @param {string} label
 * Label passada para os props do componente TextField
 * @param {boolean} multiple
 * Booleano que indica se podem ser feitas múltiplas escolhas
 * @param {(value: string) => void} onTyping
 * Callback chamado durante a escrita no componente TextField
 * @param {string} placeholder
 * Placeholder passado para os props do componente TextField
 * @param {number | string} width
 * Propriedade `maxWidth` do componente TextField que serve de input para o Autocomplete
 *
 * - Propriedades padrões do Autocomplete utilizadas
 * @param {boolean} MuiAutoCompleteProps.disabled
 * Booleano de controle para habilitar/desabilitar o input
 * @param {(option: AutoCompleteOption) => string} MuiAutoCompleteProps.getOptionLabel
 * Callback para criar label que representa a opção no Autocomplete
 * @param {AutoCompleteOnChange} MuiAutoCompleteProps.onChange
 * Callback para quando o valor muda, por padrão deve ser um SetState
 * Quando usar `customOnChange` pode ser uma outra função que recebe uma opção `AutoCompleteOption`
 * @param {AutoCompleteOption[]} MuiAutoCompleteProps.options
 * Lista de opções do Autocomplete
 * @param {CSSProps} MuiAutoCompleteProps.style
 * Objeto de estilização do Autocomplete
 * @param {string | AutoCompleteOption | (string | AutoCompleteOption)[]} MuiAutoCompleteProps.value
 * Valor de controle do componente Autocomplete
 * @type {React.MemoExoticComponent<ForwardedAutoComplete>}
 */
export const AutoComplete = memo(forwardRef(
  ({
    /** Propriedades do componente AutoComplete */
    closeOnSelect = false,
    customOnChange = false,
    label,
    multiple = false,
    onTyping,
    placeholder,
    required,
    width,
    ...MuiAutoCompleteProps
  }, ref) => {
    /** Propriedades do componente Autocomplete do material-ui */
    const {
      disabled,
      getOptionLabel,
      onChange,
      options,
      style,
      value,
    } = MuiAutoCompleteProps;

    return (
      <MuiAutoComplete
        ref={ref}
        multiple={multiple}
        disabled={disabled}
        options={options}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect={!closeOnSelect}
        freeSolo
        style={style}
        value={value}
        onChange={(e, newValue, reason) => onChange(newValue, reason)}
        renderTags={(value) => (
          <>
            {
              value.filter((item, index) => index < 2)
                .map((option) => (
                  <Chip
                    label={getOptionLabel ? getOptionLabel(option) : ''}
                    size="small"
                    key={`item-${getOptionLabel(option)}`}
                    style={{ margin: 2 }}
                  />
                ))
            }
            {
              value.length > 2 && (
                <Chip
                  label={
                    value.length === 3
                      ? 'Outro 1 item'
                      : `Outros ${value.length - 2} itens`
                  }
                  size="small"
                  key="item-other-items"
                  style={{ margin: 2 }}
                />
              )
            }
          </>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...{ ...params, placeholder, label }}
            required={required}
            style={{
              maxWidth: width || 600,
              ...(style || {}),
            }}
            onChange={({ target }) => {
              if (onTyping) {
                onTyping(target.value);
              }
            }}
          />
        )}
        renderOption={(option) => (
          multiple ? (
            <CheckBox
              style={{
                display: 'block',
                marginRight: 8,
              }}
              checked={value.map(item => item.id).includes(option.id)}
              label={getOptionLabel ? getOptionLabel(option) : ''}
              onClick={() => {
                if (customOnChange) {
                  onChange(option);
                } else {
                  onChange((old) => (
                    old.some((item) => item.id === option.id)
                      ? old.filter((item) => item.id !== option.id)
                      : [...old, option]
                  ));
                }
              }}
            />
          ) : (
            <Typography
              onClick={() => onChange([option])}
            >
              {getOptionLabel ? getOptionLabel(option) : ''}
            </Typography>
          )
        )}
      />
    );
  }
));
