import Resource from './Resource';

export default class GetDiocesesResource extends Resource {
  async result() {
    try {
      const { data, status } = await this.request.get('/dioceses');
      if (status === 200) {
        return data.reduce((total, item) => ({
          ...total,
          [item.id_diocese]: {
            name: item.nome,
            region: item.id_regiao,
          }
        }), {});
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return {};
  }
}
