import React, {
  memo
} from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {
  Form,
} from '../../components';

import { StyledInputLabel } from './styles';

/**
 * @typedef {object} SelectProps
 * @prop {SelectComponentOption[]} children Array de opções do Select
 * @prop {boolean} disabled Indica se o componente está desabilitado
 * @prop {CSSProps} formControlStyle Estilização do componente FormControl
 * @prop {string} label Pequeno label usado no componente do Input
 * @prop {(event: React.ChangeEvent<{ value: string }>) => void} onChange
 * Callback para o evento de troca de opção do Select
 * @prop {CSSProps} style Estilização do componente Select
 * @prop {string} value Váriavel de controle do valor do componente
 */
/**
 * @param {SelectProps} props da componente
 * @returns {JSX.Element} Componente de Select estilizado
 */
function SelectItem(props) {
  const {
    children,
    disabled = false,
    formControlStyle = {},
    label,
    onChange,
    style,
    value,
    error,
    required,
    ...others
  } = props;

  return (
    <Form>
      <FormControl
        variant="filled"
        error={error}
        required={required}
        style={formControlStyle}
      >
        <StyledInputLabel>{label}</StyledInputLabel>
        <Select
          disabled={disabled}
          style={style}
          value={value}
          onChange={onChange}
          {...others}
        >
          {
            children.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Form>
  );
}

/**
 * Componente Select estilizado
 * Recebe uma lista de Objetos { id, label } para usar como opção
 * @param {SelectComponentOption[]} children Array de opções do Select
 * @param {boolean} disabled Indica se o componente está desabilitado
 * @param {CSSProps} formControlStyle Estilização do componente FormControl
 * @param {string} label Pequeno label usado no componente do Input
 * @param {(event: React.ChangeEvent<{ value: string }>) => void} onChange
 * Callback para o evento de troca de opção do Select
 * @param {CSSProps} style Estilização do componente Select
 * @param {string} value Váriavel de controle do valor do componente
 */
export default memo(SelectItem);
