import styled from 'styled-components';

export const ColorPickerWrapper = styled.div`
  & {
    &:hover {
      cursor: pointer;
    }

    .MuiFormControl-root {
      opacity: 0;
    }
  }
`;
