import React, {
  memo,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';

import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import {
  FilterContext,
} from '../../../../../../../contexts';

import WindowStorage from '../../../../../../../utils/storage';

import { chaveExisteEm } from '../../../../../../../utils';

import {
  FlexRowFormContainer,
  GridFormContainer,
  WarningContainer
} from '../styles';

import {
  AutoComplete,
  CheckBox,
  Botao,
  Select,
} from '../../../../../../../components';

import useDebounce from '../../../../../../../hooks/useDebounce';

const filterStorage = WindowStorage.sessionStorage('active-filters');

function PoliticsTab() {
  const {
    candidate,
    cargo,
    cargoCandidato,
    partido,
    // politicsFilters,
    project,
    getCandidatesList,
    getFilterOptions,
    getPartidosEleicoesList,
    getPartidosTSEList,
    getYearsPerOption,
    setCandidate,
    setCargo,
    setCargoCandidato,
    setFieldEvents,
    setPartido,
    setProject,
  } = useContext(FilterContext);

  const requestInProgress = useRef(false);
  const warning = useRef('');

  const politicsOptions = useMemo(() => getFilterOptions('politico'), [getFilterOptions]);
  const [politicEvent, setPoliticEvent] = useState(
    politicsOptions[0] ? politicsOptions[0].id : undefined
  );

  const [yearsOptions, setYearsOptions] = useState([]);
  const [year, setYear] = useState('');

  const disableSelection = useMemo(() => {
    if (politicEvent === undefined) {
      warning.current = 'Nenhum filtro deste tipo disponível';
      return true;
    }
    if (politicEvent === 'votos-validos') return false;

    const noYear = year === undefined;
    if (politicEvent === 'politicos' || politicEvent === 'tse') {
      warning.current = 'Nenhum dado foi encontrado para este filtro';
      return noYear;
    }

    if (politicEvent === 'candidatos') {
      const noRole = !cargoCandidato.length > 0;
      const noCandidate = !(candidate && candidate.name.length > 0);
      if (noYear) {
        warning.current = 'Nenhum dado foi encontrado para este filtro';
      } else if (noRole || noCandidate) {
        warning.current = 'Os campos indicados com * são obrigatórios';
      }

      return noYear || noRole || noCandidate;
    }

    return false;
  }, [
    year,
    cargoCandidato,
    candidate,
    politicEvent,
  ]);

  /**
   * Lista de opções de partidos dado um ano
   * @type {[string[], SetState<string[]>]}
   */
  const [partidos, setPartidos] = useState([]);

  const cargoOptions = useMemo(() => (
    (year - 2004) % 4 === 0 // Anos de eleições municipais retornam 0
      ? [
        { id: 'prefeito', name: 'Prefeito' },
        { id: 'vice_prefeito', name: 'Vice-prefeito' },
        { id: 'vereador', name: 'Vereador' }
      ]
      : [
        { id: 'governador', name: 'Governador' },
        { id: 'dep_estadual', name: 'Dep. Estadual' },
        { id: 'dep_federal', name: 'Dep. Federal' },
        { id: 'senador', name: 'Senador' }
      ]
  ), [year]);

  useEffect(() => {
    if (politicEvent) {
      const yearsOptions = getYearsPerOption('politico', politicEvent);
      setYearsOptions(yearsOptions || []);
      setYear(yearsOptions ? yearsOptions[0] : '');
    }
  }, [politicEvent, getYearsPerOption]);

  useEffect(() => {
    let politicEv;
    let year;

    if (politicsOptions && politicsOptions.length > 0) {
      if (filterStorage.existe()) {
        const filters = filterStorage.get();

        if (chaveExisteEm(filters, 'events') && filters.events.length > 0
          && filters.events.some(item => item.type === 'Político')) {
          const event = filters.events.find(item => item.type === 'Político');
          politicEv = event.name;
          year = event.year;
        } else {
          politicEv = politicsOptions[0].id;
        }
      } else {
        politicEv = politicsOptions[0].id;
      }

      setPoliticEvent(politicEv);
      const yearsOptions = getYearsPerOption('politico', politicEv);
      setYearsOptions(yearsOptions);
      setYear(year || yearsOptions[0]);
    }
  }, [getYearsPerOption, politicsOptions]);

  useEffect(() => {
    if (
      !requestInProgress.current && ['politicos', 'tse'].includes(politicEvent)
      && year && Number.isInteger(Number(year))
    ) {
      requestInProgress.current = true;
      try {
        if (politicEvent === 'politicos') {
          getPartidosEleicoesList(year)
            .then(result => setPartidos(result));
        }
        if (politicEvent === 'tse') {
          getPartidosTSEList(year)
            .then(result => setPartidos(result));
        }
      } catch (error) {
        console.log(error); //eslint-disable-line
      }
      requestInProgress.current = false;
    }
  }, [politicEvent, getPartidosEleicoesList, getPartidosTSEList, year]);

  useEffect(() => {
    setCargo([]);
    setPartido([]);
    setProject([]);

    setCandidate('');
    setCargoCandidato('');
  }, [
    politicEvent,
    setCandidate,
    setCargo,
    setCargoCandidato,
    setPartido,
    setProject,
  ]);

  //==================================================
  // Filtro candidatos

  const [candidatesList, setCandidatesList] = useState([]);

  const debouncedListarCandidatos = useDebounce((valor) => (
    getCandidatesList(year, cargoCandidato, ...valor)
      .then((items) => setCandidatesList(items))
      .catch((error) => console.error(error)) //eslint-disable-line
  ), [getCandidatesList, year, cargoCandidato]);

  //==================================================

  const addProject = useCallback((value) => {
    setProject(old => {
      if (old.includes(value)) {
        return old.filter(item => item !== value);
      }
      return [...old, value];
    });
  }, [setProject]);

  const addFilter = useCallback(() => {
    setFieldEvents(old => {
      const oldPolitics = old.reduce((politic, fieldEvent) => {
        const { filter } = fieldEvent;
        if (filter && filter.type === 'Político') {
          return [...politic, fieldEvent];
        }

        // possivel regra para impedir filtros de serem duplicados
        // mesmo quando o filtro já faz parte de um filtro composto
        // if (filters && filters.length > 0) {
        //   const f = filters.filter(f => f.filter.type === 'Político');
        //   return [...politic, ...f];
        // }

        return politic;
      }, []);
      const newItem = {
        type: 'Político',
        name: politicEvent,
      };

      if (politicEvent !== 'votos-validos') newItem.year = year;

      if (politicEvent === 'politicos' || politicEvent === 'tse') {
        if (project.length > 0) {
          newItem.project = project.join(',');
        }

        if (cargo.length !== 0) {
          newItem.cargo = cargo.map(item => item.id.replace('-', '_')).join(',');
        }

        if (partido.length !== 0) {
          newItem.partido = partido.map(item => item.id).join(',');
        }
      }

      if (politicEvent === 'candidatos') {
        newItem.role = cargoCandidato;
        newItem.candidato = candidate ? candidate.name : '';
      }

      const label = Object.values(newItem).map(i => i.toUpperCase());

      if (!oldPolitics.find(item => item.label.join(', ') === label.join(', '))) {
        return [...old, { filter: newItem, label }];
      }
      return old;
    });
  }, [candidate, cargo, cargoCandidato, politicEvent, partido, project, setFieldEvents, year]);

  return (
    <>
      <GridFormContainer minmax="200px, 1fr">
        <Select
          label="Tipo"
          value={politicEvent}
          disabled={politicEvent === undefined}
          onChange={({ target }) => setPoliticEvent(target.value)}
        >
          {politicsOptions}
        </Select>
        {
          politicEvent !== 'votos-validos' && (
            <Select
              label="Ano"
              value={year}
              disabled={year === undefined}
              onChange={({ target }) => setYear(target.value)}
            >
              {yearsOptions.map((item) => ({ id: item, label: item.toUpperCase() }))}
            </Select>
          )
        }
      </GridFormContainer>
      <Collapse in={politicEvent === 'politicos' || politicEvent === 'tse'}>
        <GridFormContainer minmax="200px, 1fr">
          <AutoComplete
            multiple
            onChange={setCargo}
            label="Cargo"
            placeholder="Cargo"
            options={cargoOptions}
            getOptionLabel={(item) => item.name}
            value={cargo}
          />
          <AutoComplete
            multiple
            onChange={setPartido}
            label="Partido"
            placeholder="Partido"
            options={partidos}
            getOptionLabel={(item) => item.name}
            value={partido}
          />
        </GridFormContainer>
        <FlexRowFormContainer show={politicEvent === 'politicos'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: 10,
            }}
          >
            <Typography
              style={{
                color: '#444',
                marginTop: 5,
                fontSize: 14,
              }}
            >
              Projeto:
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <CheckBox
                style={{ marginRight: 8, marginLeft: 5 }}
                checked={project.includes('estado')}
                onClick={() => addProject('estado')}
                label="Estado"
              />
              <CheckBox
                style={{ marginRight: 8 }}
                checked={project.includes('regiao')}
                onClick={() => addProject('regiao')}
                label="Região"
              />
            </div>
          </div>
        </FlexRowFormContainer>
      </Collapse>
      <Collapse in={politicEvent === 'candidatos'}>
        <GridFormContainer minmax="200px, 1fr">
          <Select
            required
            label="Cargo"
            value={cargoCandidato}
            disabled={year === undefined}
            onChange={({ target }) => setCargoCandidato(target.value)}
          >
            {cargoOptions.map((item) => ({ ...item, label: item.name }))}
          </Select>
          <AutoComplete
            required={!!cargoCandidato.length}
            disabled={!cargoCandidato.length}
            onTyping={debouncedListarCandidatos}
            onChange={setCandidate}
            label={!cargoCandidato.length ? 'Selecione um cargo...' : 'Candidato'}
            placeholder="Candidato"
            closeOnSelect
            options={candidatesList}
            getOptionLabel={(item) => item.name || ''}
            value={candidate}
          />
        </GridFormContainer>
      </Collapse>
      <WarningContainer>{disableSelection ? warning.current : ''}</WarningContainer>
      <Botao
        disabled={disableSelection}
        style={{
          backgroundColor: '#3498DB',
          color: '#fff',
          marginTop: '15px',
          padding: '5px 10px',
        }}
        onClick={addFilter}
      >
        Selecionar
      </Botao>
    </>
  );
}

export default memo(PoliticsTab);
