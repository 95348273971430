import React, {
  memo,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { FlexContainer } from '../../../../../..';

import AddRemoveButton from '../../AddRemoveButton';

import FormularioIntervalo from './components/FormularioIntervalo';

import { SeletorDeCorEstilizado } from './styles';

/**
 * @typedef {object} RegraEscalaCoresProps
 * @prop {SetState<RegraEscalaCores>} alterarEscalaCores Callback para alterar as regras
 * @prop {RegraEscalaCores[]} escalaCores Lista das regras definidas atualmente
 * @prop {boolean} permitirAplicar Booleano que define se a regra é aplicavel
 * @prop {number} posicaoRegra Indíce da regra desta componente
 * @prop {RegraEscalaCores} regra Regra para esta componente
 * @prop {SetState<boolean>} setPermitirAplicar setState de validação da regra
 */
/**
 * @param {RegraEscalaCoresProps} props
 * @returns {JSX.Element} Componente que define uma regra de escala de cores
 */
function RegraEscalaCores(props) {
  const {
    escalaCores,
    permitirAplicar,
    regra,
    posicaoRegra,
    setPermitirAplicar,
    alterarEscalaCores,
  } = props;

  const { id, cor, de, ate = Number.MAX_VALUE } = regra;

  const adicionarRegra = useCallback(() => {
    alterarEscalaCores(atual => {
      const { length } = atual;
      const item = {
        id: atual[length - 1].id + 1,
        cor: '#0B8209',
        de: atual.length > 0 ? Number(atual[length - 1].ate) + 1 : 0,
        ate: Number.MAX_VALUE,
      };

      return [...atual, item];
    });
  }, [alterarEscalaCores]);

  const removerRegra = useCallback(() => {
    alterarEscalaCores(atual => atual.filter(regra => regra.id !== id));
  }, [alterarEscalaCores, id]);

  const alterarCor = useCallback((cor) => {
    alterarEscalaCores(atual => atual.map((regra) => {
      if (regra.id === id) regra.cor = cor;
      return regra;
    }));
  }, [id, alterarEscalaCores]);

  const alterarValorIntervalo = useCallback((valor, chave) => {
    alterarEscalaCores(atual => atual.map((regra) => {
      if (regra.id === id) {
        const resultado = Number(valor);
        regra[chave] = resultado;
        if (chave === 'de' && resultado && resultado >= regra.ate) {
          regra.ate = resultado + 1;
        }
      }
      return regra;
    }));
  }, [alterarEscalaCores, id]);

  const regraInvalida = useMemo(() => (
    de > ate || (posicaoRegra > 0 && de <= escalaCores[posicaoRegra - 1].ate)
  ), [de, ate, posicaoRegra, escalaCores]);

  useEffect(() => setPermitirAplicar(!regraInvalida), [regraInvalida, setPermitirAplicar]);

  return (
    <FlexContainer
      key={`regra-${id}-escala-cores`}
      align="center"
    >
      <SeletorDeCorEstilizado
        color={cor}
        setColor={alterarCor}
        tamanho="1.25rem"
        popperTransform="translate(0.5rem, -50%)"
      />
      <FormularioIntervalo {...{ regraInvalida, de, ate, alterarValorIntervalo }} />
      {
        escalaCores.length > 1 && (
          <AddRemoveButton
            type="Remover"
            onClick={removerRegra}
          />
        )
      }
      {
        posicaoRegra === escalaCores.length - 1 && (
          <AddRemoveButton
            type="Adder"
            onClick={adicionarRegra}
            disabled={ate === Number.MAX_VALUE || !permitirAplicar}
          />
        )
      }
    </FlexContainer>
  );
}
/**
 * Componente de definição da regra de coloração por Escala de cores.
 * Define um intervalo da regra de escala de cores
 * @param alterarEscalaCores Callback para alterar as regras
 * @param escalaCores Lista das regras definidas atualmente
 * @param permitirAplicar Booleano que define se a regra é aplicavel
 * @param posicaoRegra Indíce da regra desta componente
 * @param regra Regra para esta componente
 * @param setPermitirAplicar setState de validação da regra
 */
export default memo(RegraEscalaCores);
