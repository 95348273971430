import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';

export const Component = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 30px);
  padding: 0 15px;
  height: 60px;
  background-color: #414b56;
  box-shadow: 0 0 5px #444;
  z-index: 10;
`;

export const MuiAutocomplete = styled(Autocomplete)`
  && {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: px;
    
    background: rgba(130,144,158, 0.09);
    border-radius: 4px;
    
    &:hover {
      background: rgba(130, 144, 158, 0.35);
    }
  }
`;

export const MuiTextField = styled(TextField)`
  && {
    min-width: 150px;
  
    .MuiFormLabel-root,
    input {
      font-size: 14px;

      color: rgba(255,255,255,0.8);
    }

    .MuiFormLabel-root {
      transform: translate(12px, 14px) scale(1);
      
      height: 12px;
    }

    .MuiInputBase-input {
      transition: 450ms linear;
      will-change: width;
    }
    .MuiInputBase-input.focus-visible {
      width: 250px;

      transition: 450ms linear;
      will-change: width;
    }

    .MuiFilledInput-root,
    .MuiFilledInput-root:hover {
      background-color: rgba(130,144,158, 0);
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense,
    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline:after {
      display: none;
    }

    .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"] {
      padding: 6px;
    }
  }
`;

export const MuiAutocompletePopper = styled(Popper)`
  &&[style] {
    width: 309px !important;

    > div {
      background: #414b56;
      color: rgba(255,255,255,0.8);
    }

    .MuiAutocomplete-listbox {
      background: rgba(130,144,158, 0.09);
    }

    .MuiAutocomplete-option:hover, .MuiAutocomplete-option[data-focus="true"] {
      background: rgba(130, 144, 158, 0.35);
    }
  }
`;
