import React, {
  memo,
} from 'react';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import CircleButton from '../../../../../CircleButton';

/**
 * @typedef {object} AddRemoveButtonProps
 * @property {"Adder" | "Remover"} type Tipo de botão
 * @property {() => void} onClick Função do evento de click do botão
 * @property {boolean} disabled Booleano para desabilitar botão
 * @property {CSSProps} style Estilização
 */
/**
 * @param {AddRemoveButtonProps} props
 * @returns {JSX.Element} Componente CircleButton estilizado
 */
function AddRemoveButton(props) {
  const {
    type,
    onClick,
    disabled,
    style = {},
  } = props;

  return (
    <CircleButton
      state="not-expanded"
      selected={false}
      style={{ backgroundColor: 'rgb(52, 152, 219)', ...style }}
      title={type}
      onClick={onClick}
      disabled={disabled}
    >
      {
        type === 'Adder'
          ? <Add style={{ color: '#fff', fontSize: 14 }} />
          : <Remove style={{ color: '#fff', fontSize: 14 }} />
      }
    </CircleButton>
  );
}

/**
 * Botão de adicionar ou remover baseado na prop `type`
 * @param type tipo de Botão ('Adder' ou 'Remover')
 * @param onClick callback para o evento de click
 * @param disabled booleano que indica se o botão deve estar ou não desabilitado
 */
export default memo(AddRemoveButton);
