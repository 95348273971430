import React, {
  createContext,
  useCallback,
} from 'react';

const ExportImageContext = createContext({});

export const ExportImageProvider = ({ children }) => {
  /**
   * Essa função é responsável por realizar o processo de download
   * do arquivo.
   * @param string Dados que serão preenchidos no arquivo
   * @param Object{ name: string, type: string } * Nome do arquivo e tipo da extensão
   */
  const downloadImage = useCallback((data, { name, type }) => {
    const link = document.createElement('a');
    link.download = `${name}.${type}`;
    link.href = data;
    link.click();
  }, []);

  /**
   * @function
   * @param {SVGSVGElement} map
   * @returns {string}
   *
   * Callback que retorna a string a ser transformada na imagem exportada.
   */
  const getExportedSVGString = useCallback((map) => `
    <svg xmlns="http://www.w3.org/2000/svg" width="${window.innerWidth}" height="${window.innerHeight}">
      <foreignObject width="100%" height="100%">
        ${map.outerHTML}
      </foreignObject>
    </svg>
  `, []);

  const generateSVGImage = useCallback((item, name) => {
    const content = new Blob([item], { type: 'image/svg+xml' });
    downloadImage(URL.createObjectURL(content), { name, type: 'svg' });
  }, [downloadImage]);

  const generatePNGImage = useCallback((map, name, options) => {
    const { size = 1, background } = options;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const width = size * map.clientWidth;
    const height = size * map.clientHeight;
    canvas.width = width;
    canvas.height = height;
    canvas.style.display = 'none';

    const tempImg = document.createElement('img');
    tempImg.addEventListener('load', ({ target: element }) => {
      context.scale(size, size);
      if (background === 'white') {
        context.fillStyle = '#fff';
        context.fillRect(0, 0, width, height);
      }
      context.drawImage(element, 0, 0);
      downloadImage(canvas.toDataURL(), { name, type: 'png' });
    });

    const item = getExportedSVGString(map);
    tempImg.src = `data:image/svg+xml,${encodeURIComponent(item)}`;
  }, [downloadImage, getExportedSVGString]);

  const exportImage = useCallback(async (name, type, options) => {
    const map = document.querySelector('svg.mapa');
    if (type === 'png') {
      generatePNGImage(map, name, options);
    } else {
      const item = getExportedSVGString(map);
      generateSVGImage(item, name);
    }
  }, [getExportedSVGString, generatePNGImage, generateSVGImage]);

  return (
    <ExportImageContext.Provider
      value={{
        exportImage,
      }}
    >
      { children}
    </ExportImageContext.Provider>
  );
};

export default ExportImageContext;
