import styled from 'styled-components';

/**
 * Caixa de conteudo para o estado atual.
 */
export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 5px;
`;
