import React, { memo } from 'react';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(52, 152, 219)',
    },
  },
});

function Form(props) {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  );
}

export default memo(Form);
