import React, {
  memo,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import FilterList from '@material-ui/icons/FilterList';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import TableChartIcon from '@material-ui/icons/TableChart';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SelectionIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import MoveIcon from '@material-ui/icons/OpenWith';
import BorderStyleIcon from '@material-ui/icons/BorderStyle';
import Check from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import NestedMenuItem from 'material-ui-nested-menu-item'; // eslint-disable-line

import {
  AppContext,
  ColorContext,
  DataContext,
  FilterContext,
  MapContext,
} from '../../contexts';

import { MapViewProvider } from '../../contexts/MapViewContext';

import { CircleButton } from '../';

import ResultsDialog from '../MapModule/components/ResultsDialog';

import {
  BorderRegionsDialog,
  DialogoCores,
  FilterDialog,
  IsolateRegionDialog,
  LateralSubMenu,
  OpenViewDialog,
  QuantityDialog,
  SaveViewDialog,
} from './components';

import FontSizeAdjust from '../LateralMenu/components/FontSizeAdjust';
import ZoomControl from '../LateralMenu/components/ZoomControl';

import {
  Container,
  Divider,
} from './styles';

import { FixedBlock } from './components/styles';

import packageJson from '../../../package.json';

import { analytics } from '../../index';

import { namesOptions } from './assets/constants';
import { emptyObject } from '../../utils';

const LateralMenu = memo(() => {
  const {
    activeFilters,
    addTaskRunning,
    isVisibleBorderRegionsDialog,
    isMobileDevice,
    menuClicked,
    options,
    setOptions,
    setStateMenu,
    setVisibleBorderRegionsDialog,
    stateMenu,
  } = useContext(AppContext);

  const {
    isSingleFilter,
    getResult,
  } = useContext(DataContext);

  const {
    coresCidades,
    setDialogoCoresVisivel
  } = useContext(ColorContext);

  const {
    mode,
    setNumberLabels,
    clear,
    handleShowNames,
    reset,
    showColors,
    setMode,
    setShowColors,
    setShowNumbers,
    showNames,
    transformer,
    visibleCities,
    currentCity,
  } = useContext(MapContext);

  const {
    handleClearFilterClick: clearFilterClick,
    isFilterReady,
  } = useContext(FilterContext);

  const [isVisibleResultsDialog, setVisibleResultsDialog] = useState(false);
  const [isVisibleFilterDialog, setVisibleFilterDialog] = useState(false);
  const [isVisibleIsolateRegionDialog, setVisibleIsolateRegionDialog] = useState(false);
  const [isVisibleOpenViewDialog, setVisibleOpenViewDialog] = useState(false);
  const [isVisibleQuantityDialog, setVisibleQuantityDialog] = useState(false);
  const [isVisibleSaveViewDialog, setVisibleSaveViewDialog] = useState(false);
  const [showNamesOptions, setShowNamesOptions] = useState(false);

  const nameDiv = useRef(null);

  const handleFilterClick = useCallback((e) => {
    e.preventDefault();
    setVisibleFilterDialog(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-novo-filtro');
  }, []);

  const handleColorClick = useCallback((e) => {
    e.preventDefault();
    setDialogoCoresVisivel(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-cores');
  }, [setDialogoCoresVisivel]);

  const handleShowRegionsLimits = useCallback(() => {
    setVisibleBorderRegionsDialog(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-limites-regioes');
  }, [setVisibleBorderRegionsDialog]);

  const handleShowColorsClick = useCallback(e => {
    e.preventDefault();
    setShowColors(old => !old);
    analytics.logEvent('menu-lateral-mostrar/ocultar-cores');
  }, [setShowColors]);

  /**
   * @callback handleShowNumbersClickCallback
   * @param {React.MouseEventHandler<HTMLButtonElement>} e Objeto de evento
   *
   * Callback que mostra quantidades de um filtro simples
   * @type {handleShowNumbersClickCallback}
   */
  const handleShowNumbersClick = useCallback((e) => {
    e.preventDefault();
    setOptions(options => {
      setShowNumbers(!options.showQuantities);
      setNumberLabels([getResult().settings.title.join(', ')]);
      return {
        ...options,
        showQuantities: !options.showQuantities,
      };
    });
    analytics.logEvent('menu-lateral-mostrar/esconder-quantidades');
  }, [getResult, setShowNumbers, setOptions, setNumberLabels]);

  /**
   * @callback handleShowQuantitiesDialogCallback
   * @param {React.MouseEventHandler<HTMLButtonElement>} e Objeto de evento
   *
   * Callback que abre o dialogo de mostrar quantidades
   * @type {handleShowQuantitiesDialogCallback}
   */
  const handleShowQuantitiesDialog = useCallback((e) => {
    e.preventDefault();
    setVisibleQuantityDialog(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-mostrar/esconder-quantidades');
  }, []);

  /**
   * Callback de clique do botão mostrar quantidades.
   *
   * Troca a função dependendo dos filtros ativos, quando há somente um filtro
   * serve de liga/desliga para as quantidades do mapa.
   *
   * Quando há mais de um filtro serve para abrir o dialogo de mostrar quantidades
   *
   * @type {handleShowNumbersClickCallback | handleShowQuantitiesDialogCallback}
   */
  const handleShowQuantitiesClick = useMemo(() => (
    isSingleFilter
      ? handleShowNumbersClick
      : handleShowQuantitiesDialog
  ), [handleShowNumbersClick, handleShowQuantitiesDialog, isSingleFilter]);

  useEffect(() => {
    setShowNamesOptions(atual => {
      if (stateMenu === 'minimized') return false;
      return atual;
    });
  }, [stateMenu]);

  const handleShowNamesClick = useCallback((type) => {
    handleShowNames(type);
    analytics.logEvent('menu-lateral-mostrar/esconder-nomes-cidades');
  }, [handleShowNames]);

  const showNamesLabel = useMemo(() => (
    <LateralSubMenu
      divRef={nameDiv}
      label="Mostrar nomes"
      showMenu={stateMenu === 'expanded' && showNamesOptions}
    >
      {
        namesOptions.map((item) => (
          <MenuItem
            key={`menu-name-${item.label}`}
            onClick={(e) => {
              e.preventDefault();
              handleShowNamesClick(item.key);
            }}
          >
            {
              options.showNames[item.key] && (
                <Check fontSize="small" style={{ color: '#3498db', marginRight: 10 }} />
              )
            }
            {item.label}
          </MenuItem>
        ))
      }
    </LateralSubMenu>
  ), [handleShowNamesClick, options, stateMenu, showNamesOptions]);

  const handleShowTableClick = useCallback((e) => {
    e.preventDefault();
    setVisibleResultsDialog(old => !old);
    analytics.logEvent('menu-lateral-mostrar/esconder-tabela-resultados');
  }, [setVisibleResultsDialog]);

  const handleIsolateRegions = useCallback((e) => {
    e.preventDefault();

    setOptions(options => ({ ...options, isolateRegion: !options.isolateRegion }));
    setVisibleIsolateRegionDialog(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-isolar-regioes');
  }, [setOptions, setVisibleIsolateRegionDialog]);

  /**
   * @callback handleSaveViewClickCallback
   * @param {React.MouseEventHandler<HTMLButtonElement>} e Objeto do evento
   *
   * Callback que abre diálogo de salvar visualização do mapa
   * @type {handleSaveViewClickCallback}
   */
  const handleSaveViewClick = useCallback((e) => {
    e.preventDefault();
    setVisibleSaveViewDialog(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-salvar-visualização');
  }, []);

  /**
   * @callback handleOpenViewClickCallback
   * @param {React.MouseEventHandler<HTMLButtonElement>} e Objeto do evento
   *
   * Callback que abre diálogo de abrir/aplicar visualização no mapa
   * @type {handleOpenViewClickCallback}
   */
  const handleOpenViewClick = useCallback((e) => {
    e.preventDefault();
    setVisibleOpenViewDialog(true);
    analytics.logEvent('menu-lateral-mostrar-dialog-abrir-visualização');
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (!menuClicked) setStateMenu('expanded');
  }, [menuClicked, setStateMenu]);

  const handleMouseLeave = useCallback(() => {
    if (!menuClicked) setStateMenu('minimized');
  }, [menuClicked, setStateMenu]);

  const isFilterActive = useMemo(() => (
    currentCity.id !== -1 || activeFilters.length > 0
  ), [currentCity.id, activeFilters]);

  const handleCenterMapClick = useCallback(() => {
    reset();
    analytics.logEvent('menu-lateral-centralizar-mapa');
  }, [reset]);

  const handleClearFilterClick = useCallback(() => {
    addTaskRunning();
    setShowColors(false);
    setTimeout(() => {
      clear();
      clearFilterClick();
    }, 100);
    analytics.logEvent('menu-lateral-limpar-filtro');
  }, [addTaskRunning, clear, clearFilterClick, setShowColors]);

  const handleChangeMode = useCallback((type) => {
    setMode(type);
  }, [setMode]);

  /*
   * Esse useEffect monitora se há alteração
   * dos filtros ativos. Quando novos filtros
   * são ativados, ResultsDialog pode aparecer
   * automaticamente.
   */
  useEffect(() => {
    setVisibleResultsDialog(activeFilters.length > 0);
  }, [activeFilters.length, setVisibleResultsDialog]);

  return (
    <>
      <Container
        className={stateMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            position: 'absolute',
            top: 'calc(50vh - 60px)',
            transform: 'translateY(-50%)',
          }}
        >
          {
            isFilterReady && (
              <>
                <CircleButton
                  state={stateMenu}
                  title="Filtrar"
                  onClick={handleFilterClick}
                  selected={isFilterActive}
                  style={{ backgroundColor: '#3498DB' }}
                >
                  <FilterList style={{ color: '#fff', fontSize: 18 }} />
                </CircleButton>
                {
                  isFilterActive && (
                    <>
                      <CircleButton
                        shifted
                        state={stateMenu}
                        title="Mostrar quantidades"
                        selected={options.showQuantities}
                        onClick={handleShowQuantitiesClick}
                        style={{ backgroundColor: '#9B59B6' }}
                      >
                        <LooksOneIcon style={{ color: '#fff', fontSize: 16 }} />
                      </CircleButton>
                      <CircleButton
                        shifted
                        state={stateMenu}
                        title="Mostrar tabela de resultados"
                        selected={isVisibleResultsDialog}
                        onClick={handleShowTableClick}
                        style={{ backgroundColor: '#E67E22' }}
                      >
                        <TableChartIcon style={{ color: '#fff', fontSize: 16 }} />
                      </CircleButton>
                      <CircleButton
                        shifted
                        state={stateMenu}
                        title="Limpar filtro"
                        onClick={handleClearFilterClick}
                        style={{ backgroundColor: '#f1c40f' }}
                      >
                        <ClearAllIcon style={{ color: '#fff', fontSize: 16 }} />
                      </CircleButton>
                    </>
                  )
                }
                <Divider />
              </>
            )
          }
          <CircleButton
            state={stateMenu}
            title="Cores"
            selected={options.showColors}
            onClick={handleColorClick}
            style={{ backgroundColor: '#2ECC71' }}
          >
            <ColorLensIcon style={{ color: '#fff', fontSize: 18 }} />
          </CircleButton>
          {
            !emptyObject(coresCidades) && (
              <CircleButton
                state={stateMenu}
                title={showColors ? 'Descolorir cidades' : 'Colorir cidades'}
                selected={!showColors}
                onClick={handleShowColorsClick}
                style={{ backgroundColor: '#26AE5F' }}
              >
                <FormatColorResetIcon style={{ color: '#fff', fontSize: 16 }} />
              </CircleButton>
            )
          }
          <div style={{ display: 'flex', width: 'auto' }}>
            <CircleButton
              state={stateMenu}
              title={showNamesLabel}
              selected={showNames}
              onClick={() => setShowNamesOptions(old => !old)}
              style={{ backgroundColor: '#34495E' }}
            >
              <TextFormatIcon style={{ color: '#fff', fontSize: 18 }} />
            </CircleButton>
            <div
              ref={nameDiv}
              style={{
                margin: 0,
                padding: 0,
                width: 0,
                heigth: 0,
              }}
            />
          </div>
          <CircleButton
            state={stateMenu}
            title="Isolar regiões"
            selected={visibleCities && visibleCities.length > 0}
            onClick={handleIsolateRegions}
            style={{ backgroundColor: '#E74C3C' }}
          >
            <SelectAllIcon style={{ color: '#fff', fontSize: 18 }} />
          </CircleButton>
          <CircleButton
            state={stateMenu}
            title="Mostrar contornos"
            onClick={handleShowRegionsLimits}
            style={{ backgroundColor: '#f1c40f' }}
          >
            <BorderStyleIcon style={{ color: '#fff', fontSize: 18 }} />
          </CircleButton>
          <Divider />
          {
            !isMobileDevice && (
              mode === 'move'
                ? (
                  <CircleButton
                    state={stateMenu}
                    title="Zoom por seleção"
                    onClick={() => {
                      handleChangeMode('zoom-selection');
                      analytics.logEvent('menu-lateral-habilitar-zoom-selecao');
                    }}
                    style={{ backgroundColor: '#e67e22' }}
                  >
                    <SelectionIcon style={{ color: '#fff', fontSize: 18 }} />
                  </CircleButton>
                )
                : (
                  <CircleButton
                    state={stateMenu}
                    title="Mover mapa"
                    onClick={() => {
                      handleChangeMode('move');
                      analytics.logEvent('menu-lateral-desabilitar-zoom-selecao');
                    }}
                    style={{ backgroundColor: '#e67e22' }}
                  >
                    <MoveIcon style={{ color: '#fff', fontSize: 18 }} />
                  </CircleButton>
                )
            )
          }
          <CircleButton
            state={stateMenu}
            title="Centralizar mapa"
            onClick={handleCenterMapClick}
            style={{ backgroundColor: '#95a5a6' }}
          >
            <CenterFocusWeakIcon style={{ color: '#fff', fontSize: 18 }} />
          </CircleButton>
          <Divider />
          <CircleButton
            state={stateMenu}
            title="Salvar visualização atual"
            onClick={handleSaveViewClick}
            style={{ backgroundColor: '#0049ff' }}
          >
            <SaveIcon style={{ color: '#fff', fontSize: 18 }} />
          </CircleButton>
          <CircleButton
            state={stateMenu}
            title="Abrir visualização"
            onClick={handleOpenViewClick}
            style={{ backgroundColor: '#FFB600' }}
          >
            <OpenInBrowser style={{ color: '#fff', fontSize: 18 }} />
          </CircleButton>
        </div>
        <Typography
          style={{
            fontSize: 12,
            opacity: 0.6,
            marginBottom: 3,
            whiteSpace: 'nowrap',
            position: 'absolute',
            bottom: 5,
          }}
        >
          v{packageJson.version}
        </Typography>
      </Container>
      {
        isVisibleFilterDialog && (
          <FilterDialog
            isVisible={isVisibleFilterDialog}
            setVisible={setVisibleFilterDialog}
          />
        )
      }
      <ResultsDialog
        show={isVisibleResultsDialog}
        onClose={() => setVisibleResultsDialog(false)}
      />
      <QuantityDialog
        isVisible={isVisibleQuantityDialog}
        setVisible={setVisibleQuantityDialog}
      />
      <DialogoCores />
      <IsolateRegionDialog
        isVisible={isVisibleIsolateRegionDialog}
        setVisible={setVisibleIsolateRegionDialog}
        setOptions={setOptions}
      />
      <BorderRegionsDialog
        isVisible={isVisibleBorderRegionsDialog}
        setVisible={setVisibleBorderRegionsDialog}
      />
      <MapViewProvider>
        <SaveViewDialog
          isVisible={isVisibleSaveViewDialog}
          setVisible={setVisibleSaveViewDialog}
        />
        <OpenViewDialog
          isVisible={isVisibleOpenViewDialog}
          setVisible={setVisibleOpenViewDialog}
        />
      </MapViewProvider>
      <FixedBlock>
        {transformer.current && <ZoomControl />}
        <FontSizeAdjust />
      </FixedBlock>
    </>
  );
});

export default memo(LateralMenu);
