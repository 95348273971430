import React, {
  memo,
} from 'react';

import Typography from '@material-ui/core/Typography';

import { FlexContainer } from '../../../../../..';

import { CaixaSeletorDeCor } from '../../../styles';

/**
 * @typedef {object} RegraGradienteProps
 * @prop {string} corGradiente Cor usado no gradiente
 * @prop {SetState<string>} alterarCorGradiente Callback para alterar a cor
 */
/**
 * @param {RegraGradienteProps} props Props da componente
 * @returns {JSX.Element} Componente de regra Degradê
 */
function RegraGradiente(props) {
  const {
    corGradiente,
    alterarCorGradiente,
  } = props;

  return (
    <FlexContainer
      style={{
        width: 'max-content',
        marginBottom: 10
      }}
    >
      <Typography
        style={{
          marginRight: 20,
          marginLeft: 15,
          fontSize: 14,
          opacity: 1.0,
        }}
      >
        Selecione a cor
      </Typography>
      <CaixaSeletorDeCor
        color={corGradiente}
        setColor={alterarCorGradiente}
        tamanho="1.25rem"
        popperTransform="translate(0.5rem, -50%)"
      />
    </FlexContainer>
  );
}

/**
 * Componente de definição da regra de coloração Degradê
 * @param corGradiente Cor usado no gradiente
 * @param alterarCorGradiente Callback para alterar a cor
 */
export default memo(RegraGradiente);
