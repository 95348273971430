import React, {
  memo,
  useCallback,
  useMemo
} from 'react';

import {
  CheckBox,
  FlexContainer
} from '../../../../../../components';

import ExpandControlBox from '../ExpandControlBox';

function BaciasTab(props) {
  const {
    isActive,
    isolateBacias,
    setIsolateBacias,
    isolateCities,
    setIsolateCities,
  } = props;

  const updateBacias = useCallback((bacia, result) => {
    const items = Object.entries(isolateCities)
      .filter(([, city]) => city.bacia === bacia);
    const quantity = items.filter(([, city]) => city.status !== 'unchecked').length;

    if (result === 'checked' && quantity === items.length - 1) {
      setIsolateBacias(old => ({ ...old, [bacia]: { ...old[bacia], status: 'checked' } }));
    } else if (result === 'unchecked' && quantity === 1) {
      setIsolateBacias(old => ({ ...old, [bacia]: { ...old[bacia], status: 'unchecked' } }));
    } else {
      setIsolateBacias(old => ({ ...old, [bacia]: { ...old[bacia], status: 'incomplete' } }));
    }
  }, [isolateCities, setIsolateBacias]);

  const handleCheckCity = useCallback((id) => {
    setIsolateCities(old => {
      const result = old[id].status === 'unchecked' ? 'checked' : 'unchecked';
      const { bacia } = old[id];
      updateBacias(bacia, result);
      return {
        ...old,
        [id]: { ...old[id], status: result }
      };
    });
  }, [setIsolateCities, updateBacias]);

  const handleMaximizedBacias = useCallback((id) => {
    setIsolateBacias(old => ({
      ...old,
      [id]: { ...old[id], maximized: !old[id].maximized }
    }));
  }, [setIsolateBacias]);

  const handleCheckBacias = useCallback((id) => {
    setIsolateBacias(old => ({
      ...old,
      [id]: { ...old[id], status: old[id].status === 'unchecked' ? 'checked' : 'unchecked' }
    }));
  }, [setIsolateBacias]);

  const content = useMemo(() => (
    Object.entries(isolateBacias).map(([idBacia, bacia]) => (
      <React.Fragment key={`fragment-${idBacia}`}>
        <FlexContainer
          key={`div-bacia-${idBacia}`}
          align="center"
        >
          <CheckBox
            key={`bacia-${idBacia}`}
            checked={bacia.status === 'checked'}
            onClick={() => handleCheckBacias(idBacia)}
            label={bacia.name}
            indeterminate={bacia.status === 'incomplete'}
          />
          <ExpandControlBox
            maximized={bacia.maximized}
            onClick={() => handleMaximizedBacias(idBacia)}
          />
        </FlexContainer>
        {
          bacia.maximized
          && Object.entries(isolateCities)
            .filter(([, item]) => item.bacia === Number(idBacia))
            .map(([idCity, city]) => (
              <React.Fragment key={`rf-${idCity}`}>
                <FlexContainer
                  key={`div-bacia-${idCity}`}
                  align="center"
                  style={{ marginLeft: 20 }}
                >
                  <CheckBox
                    key={`bacia-${idCity}`}
                    checked={city.status === 'checked'}
                    onClick={() => handleCheckCity(idCity)}
                    label={city.name}
                    indeterminate={city.status === 'incomplete'}
                  />
                </FlexContainer>
              </React.Fragment>
            ))
        }
      </React.Fragment>
    ))
  ), [handleCheckBacias, handleCheckCity, handleMaximizedBacias, isolateBacias, isolateCities]);

  return isActive ? content : <></>;
}

export default memo(BaciasTab);
