import React, {
  memo,
  useContext,
  useMemo,
} from 'react';

import { ColorContext } from '../../../../contexts';

import {
  Botao,
  Text,
} from '../../../';

import { AlertContainer } from './styles';

/**
 * @typedef {object} ColorlessFilterAlertProps
 */
/**
 * @param {ColorlessFilterAlertProps} props
 * @returns {JSX.Element}
 */
function ColorlessFilterAlert() {
  const {
    qntdFiltrosSemCor,
    setDialogoCoresVisivel,
  } = useContext(ColorContext);

  const alertStateClass = useMemo(() => (qntdFiltrosSemCor > 0 ? 'visible' : 'hidden'), [qntdFiltrosSemCor]);

  return (
    <AlertContainer className={`MuiPaper-elevation2 ${alertStateClass}`}>
      <Text fontSize={13}>
        {qntdFiltrosSemCor > 1 ? `Existem ${qntdFiltrosSemCor} filtros` : 'Existe 1 filtro'} sem cor
      </Text>
      <Botao
        style={{ color: '#3498db', fontSize: 13 }}
        onClick={() => setDialogoCoresVisivel(true)}
      >
        {qntdFiltrosSemCor > 1 ? 'Definir cores' : 'Definir cor'}
      </Botao>
    </AlertContainer>
  );
}

/** Componente de alerta para legenda quando há filtros sem cor */
export default memo(ColorlessFilterAlert);
