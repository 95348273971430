import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from 'react';

import * as d3 from 'd3';

import AppContext from '../../AppContext';
import MapContext from '../';
import SecondaryMenuContext from '../../SecondaryMenuContext';

import { analytics } from '../../..';
import MapLabelsContext from '../MapLabelsContext';

/**
 * @typedef {object} MapEventsContextType
 * @prop {VoidFunction} handleEventZoom
 * Callback para evento de zoom do mapa
 * @prop {VoidFunction} handleMouseDown
 * Callback para evento MouseDown/TouchStart do mapa
 * @prop {VoidFunction} handleMouseEnter
 * Callback para evento MouseEnter do mapa
 * @prop {VoidFunction} handleMouseMove
 * Callback para evento MouseMove/TouchMove do mapa
 * @prop {VoidFunction} handleMouseUp
 * Callback para evento MouseUp/TouchUp do mapa
 * @prop {React.MutableRefObject<HTMLParagraphElement>} refCursor
 * @prop {VoidFunction} svgMouseDown
 * Callback para evento MouseDown do svg
 * @prop {VoidFunction} svgMouseMove
 * Callback para evento MouseMove do svg
 * @prop {React.MutableRefObject<HTMLDivElement>} zoomRect
 */
/** @type {React.Context<MapEventsContextType>} */
const MapEventsContext = createContext({});

export function MapEventsProvider({ children }) {
  const { options, setVisibleBorderRegionsDialog } = useContext(AppContext);

  const { showSecondaryMenu } = useContext(SecondaryMenuContext);

  const {
    draggingTextLabel,
    overrideTextLabelPosition,
  } = useContext(MapLabelsContext);

  const {
    adjustCityLabelPositionMode,
    handleShowNames,
    mounted,
    refMap,
    reset,
    setActiveCity,
    setAdjustLabelMode,
    setMode,
    setShowColors,
    showColors,
    showNames,
    transformer,
  } = useContext(MapContext);

  /**
   * Referência que armazena configurações do estado atual do mapa
   * relacionadas às suas transformações
   */
   const mapSettings = useRef({
    init: {
      x: 0,
      y: 0,
    },
    mouse: {
      pressX: 0,
      pressY: 0,
    },
    pinch: {
      start: undefined,
      end: undefined,
    },
    dragging: false,
    pinching: false,
    moved: false,
    resize: false,
    cursor: {
      x: 0,
      y: 0,
    },
  });

  /**
   * Essa é a referência à tooltip usada para mostrar
   * o nome da cidade que o cursor passar por cima.
   */
  const refCursor = useRef(null);

  /**
   * Essas referências armazenam configurações relativas ao componente
   * de zoom por seleção
   * - zoomRect: referência à componente visual
   * - zoomItem: referência ao ponto de origem e ponto final da componente em drag
   * - refDraggingZoom: monitora se a componente está visível em evento de drag
   */
  const zoomRect = useRef(null);
  const zoomItem = useRef({ origin: undefined, target: undefined });
  const refDraggingZoom = useRef(false);

  /**
   * Essa função aplica o zoom por seleção no mapa
   * baseado nos dado de zoomItem.
   *
   * @returns void
   */
  const zoomInArea = useCallback(() => {
    const { origin, target } = zoomItem.current;
    const position = {
      x: (origin.x + target.x) / 2,
      y: (origin.y + target.y) / 2,
    };

    const screenCenter = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    };

    const deslocamento = {
      x: (screenCenter.x - position.x),
      y: (screenCenter.y - position.y)
    };

    const item = transformer.current.element;
    const factor = {
      x: 1,
      y: 1,
    };

    const dimension = {
      width: Math.abs(origin.x - target.x),
      height: Math.abs(origin.y - target.y),
    };

    if (dimension.width < dimension.height) {
      factor.x = 0.7 * (window.innerWidth / dimension.width);
      factor.x = factor.x > 25 ? 25 : factor.x;
      factor.y = factor.x * (item.height.baseVal.value / item.width.baseVal.value);
    } else {
      factor.y = 0.7 * (window.innerHeight / dimension.height);
      factor.y = factor.y > 25 ? 25 : factor.y;
      factor.x = factor.y * (item.width.baseVal.value / item.height.baseVal.value);
    }

    transformer.current.center(deslocamento, factor);
  }, [transformer]);

  /**
   * Inicializa o componente de zoom por seleção, definindo como vísivel
   * e inicializando os pontos de zoomItem
   */
  const initZoomRectangle = useCallback((point) => {
    zoomItem.current.origin = point;
    refDraggingZoom.current = true;
    zoomRect.current.style.display = 'block';
  }, []);

  /**
   * Limpa o componente de zoom por seleção, definindo como invísivel
   * e resetando os pontos de zoomItem
   */
  const clearZoomRectangle = useCallback(() => {
    zoomItem.current.origin = undefined;
    zoomItem.current.target = undefined;
    refDraggingZoom.current = false;
    zoomRect.current.style.display = 'none';
  }, []);

  /**
   * Essa função manipula as configurações de zoomItem e zoomRect
   * enquanto não for realizado o zoom por seleção
   */
  const updateZoomRectangle = useCallback((point) => {
    if (zoomRect.current) {
      zoomItem.current.target = point;
      const { origin, target } = zoomItem.current;
      const a = {};
      const b = {};

      if (origin.x < target.x) {
        a.x = origin.x;
        b.x = target.x;
      } else {
        a.x = target.x;
        b.x = origin.x;
      }

      if (origin.y < target.y) {
        a.y = origin.y;
        b.y = target.y;
      } else {
        a.y = target.y;
        b.y = origin.y;
      }

      zoomRect.current.style.left = `${a.x}px`;
      zoomRect.current.style.top = `${a.y}px`;
      zoomRect.current.style.width = `${b.x - a.x}px`;
      zoomRect.current.style.height = `${b.y - a.y}px`;
    }
  }, []);

  /**
   * Essa é a função de pinchZoom pelos dispositivos mobile
   */
  const applyPinchZoom = useCallback(() => {
    const { start, end } = mapSettings.current.pinch;

    if (start && end) {
      const distance = {
        initial: Math.hypot(start.ax - start.bx, start.ay - start.by),
        final: Math.hypot(end.ax - end.bx, end.ay - end.by),
      };

      const zoomCenter = {
        x: (start.ax + start.bx + end.ax + end.bx) / 4,
        y: (start.ay + start.by + end.ay + end.by) / 4,
      };

      const scale = distance.final / distance.initial;
      const factor = scale > 1 ? 1.05 : scale < 1 ? 0.95 : 1;

      transformer.current.scale(factor, factor, {
        x: zoomCenter.x,
        y: zoomCenter.y,
      });

      mapSettings.current.pinch.start = {
        ax: end.ax,
        ay: end.ay,
        bx: end.bx,
        by: end.by,
      }
    }
  }, [transformer]);
  //-------Funções de evento do mapa----------------

  const svgMouseDown = useCallback((event) => {
    if (refMap.current.mode !== 'move') {
      initZoomRectangle({ x: event.clientX, y: event.clientY });
    }
  }, [initZoomRectangle, refMap]);

  const svgMouseMove = useCallback((event) => {
    if (refMap.current.mode === 'zoom-selection' && refDraggingZoom.current) {
      updateZoomRectangle({ x: event.clientX, y: event.clientY });
    }
  }, [refMap, updateZoomRectangle]);

  const handleMouseUp = useCallback(() => {
    const target = refMap.current;
    if (refMap.current.mode === 'move') {
      target.style.cursor = 'auto';
      mapSettings.current.dragging = false;
      mapSettings.current.pinching = false;
    } else {
      // TODO talvez checar se o cursor é de zoom
      target.style.cursor = 'crosshair';
      mapSettings.current.resize = false;

      if (refMap.current.mode === 'zoom-selection' && refDraggingZoom.current) {
        zoomInArea();
        clearZoomRectangle();
        setMode('move');
      }
    }
  }, [clearZoomRectangle, refMap, setMode, zoomInArea]);

  const handleMouseEnter = useCallback(() => {
    document.activeElement.blur();
    refMap.current.focus();
  }, [refMap]);

  const handleMouseDown = useCallback((event) => {
    const isRightMB = event.button === 2;

    if (refMap.current.mode === 'move' && !isRightMB) {
      if (event.touches && event.touches.length === 2) {
        mapSettings.current = {
          ...mapSettings.current,
          pinch: {
            start: {
              ax: event.touches[0].clientX,
              ay: event.touches[0].clientY,
              bx: event.touches[1].clientX,
              by: event.touches[1].clientY,
            },
            end: undefined,
          },
          dragging: false,
          pinching: true,
        };
      } else {
        mapSettings.current = {
          ...mapSettings.current,
          mouse: {
            pressX: event.clientX || event.touches[0].clientX,
            pressY: event.clientY || event.touches[0].clientY,
          },
          pinching: false,
          dragging: true,
        };
      }
    } else if (isRightMB) {
      mapSettings.current = {
        ...mapSettings.current,
        dragging: false,
      };
      setMode('move');
    } else {
      mapSettings.current.resize = true;
    }
  }, [refMap, setMode]);

  const handleMouseMove = useCallback((event) => {
    if (mapSettings.current.dragging || mapSettings.current.pinching) {
      if (refMap.current.mode === 'move') {
        refMap.current.style.cursor = 'move';
        if (event.type === 'touchmove') {
          event.preventDefault();
          if (event.touches.length === 2) {
            mapSettings.current.pinch.end = {
              ax: event.touches[0].clientX,
              ay: event.touches[0].clientY,
              bx: event.touches[1].clientX,
              by: event.touches[1].clientY,
            };
            applyPinchZoom();
          } else {
            const diff = {
              x: (event.touches[0].clientX - mapSettings.current.mouse.pressX),
              y: (event.touches[0].clientY - mapSettings.current.mouse.pressY),
            };
            mapSettings.current.mouse = {
              pressX: mapSettings.current.mouse.pressX + diff.x,
              pressY: mapSettings.current.mouse.pressY + diff.y,
            };
            transformer.current.translate(diff.x, diff.y);
          }
        } else {
          transformer.current.translate(event.movementX, event.movementY);
          mapSettings.current.moved = true;
          mapSettings.current.mouse = {
            pressX: mapSettings.current.mouse.pressX + event.movementX,
            pressY: mapSettings.current.mouse.pressY + event.movementY,
          };
        }
      } else {
        mapSettings.current.resize = true;
      }
    }
  }, [applyPinchZoom, refMap, transformer]);

  const handleEventZoom = useCallback((event) => {
    if (refMap.current.mode === 'move' && document.activeElement.tagName === 'BODY') {
      const direction = event.deltaY < 0 ? 1 : -1;
      const factor = 1 + (0.1 * direction);

      const origin = {
        x: event.clientX,
        y: event.clientY,
      };

      transformer.current.scale(factor, factor, origin);
    }
  }, [refMap, transformer]);

  /**
   * Essa função pertence ao menu de contexto, cuja funcionalidade é exibir
   * os detalhes da cidade
   *
   * @param city PATH da cidade
   *
   * @returns void
   */
   const handleMenuContextVisibleCity = useCallback((city) => {
    setActiveCity(city);
    showSecondaryMenu(null);
    analytics.logEvent('mapa-menu-contexto-ver-dados');
  }, [showSecondaryMenu, setActiveCity]);

  /**
   * Essa função pertence ao menu de contexto, cuja funcionalidade é centralizar
   * o mapa
   *
   * @returns void
   */
  const handleMenuContextMapCenter = useCallback(() => {
    reset();
    showSecondaryMenu(null);
    analytics.logEvent('mapa-menu-contexto-centralizar-mapa');
  }, [reset, showSecondaryMenu]);

  /**
   * Essa função pertence ao menu de contexto, cuja funcionalidade exibe a
   * caixa de diálogo dos contornos das cidades
   *
   * @returns void
   */
  const handleMenuContextBorderRegions = useCallback(() => {
    setVisibleBorderRegionsDialog(true);
    showSecondaryMenu(null);
    analytics.logEvent('mapa-menu-contexto-contorno-regioes');
  }, [showSecondaryMenu, setVisibleBorderRegionsDialog]);

  /**
   * Essa função pertence ao menu de contexto, cuja funcionalidade
   * ativa e desativa a coloração do mapa
   *
   * @returns void
   */
  const handleMenuContextShowColors = useCallback(() => {
    setShowColors(old => !old);
    showSecondaryMenu(null);
    analytics.logEvent('mapa-menu-contexto-mostrar/ocultar-cores');
  }, [showSecondaryMenu, setShowColors]);

  /**
   * Essa função pertence ao menu de contexto, cuja funcionalidade
   * é exibir/ocultar o nome das cidades
   */
  const handleMenuContextShowNames = useCallback((e, type) => {
    e.preventDefault();
    handleShowNames(type);

    showSecondaryMenu(null);
    analytics.logEvent('mapa-menu-contexto-mostrar-nomes-cidades');
  }, [handleShowNames, showSecondaryMenu]);

  /**
   * Essa função adiciona o evento de menu de contexto
   * em uma cidade
   *
   * @param item Elemento HTML da cidade
   * @param params Dataset da cidade com id e nome
   *
   * @returns void
   */
  const addSecondaryMenuEvent = useCallback((item) => {
    const {
      showAdmRegions,
      showBacias,
      showCities,
      showDioceses,
      showRegions,
    } = options.showNames;

    item.oncontextmenu = (event) => {
      const oneCityModeActive = adjustCityLabelPositionMode.current === 'one-city';
      const allCitiesModeActive = adjustCityLabelPositionMode.current === 'all-cities';
      event.preventDefault();
      showSecondaryMenu({
        target: event.target,
        options: [
          {
            label: 'Ver dados',
            type: 'item',
            disable: false,
            action: () => handleMenuContextVisibleCity(item),
          },
          {
            label: 'Centralizar mapa',
            type: 'item',
            disable: false,
            action: handleMenuContextMapCenter,
          },
          {
            label: 'Contornos das regiões',
            type: 'item',
            disable: false,
            action: handleMenuContextBorderRegions,
          },
          {
            label: `${(showColors) ? 'Descolorir' : 'Colorir'} cidades`,
            type: 'item',
            disable: !options.showColors,
            action: handleMenuContextShowColors,
          },
          {
            label: 'Mostrar nomes',
            type: 'menu',
            disable: false,
            options: [
              {
                label: 'Bacias',
                type: 'item',
                disable: false,
                icon: showBacias ? 'check' : undefined,
                action: (e) => handleMenuContextShowNames(e, 'showBacias'),
              },
              {
                label: 'Cidades',
                type: 'item',
                disable: false,
                icon: showCities ? 'check' : undefined,
                action: (e) => handleMenuContextShowNames(e, 'showCities'),
              },
              {
                label: 'Dioceses',
                type: 'item',
                disable: false,
                icon: showDioceses ? 'check' : undefined,
                action: (e) => handleMenuContextShowNames(e, 'showDioceses'),
              },
              {
                label: 'Regiões',
                type: 'item',
                disable: false,
                icon: showRegions ? 'check' : undefined,
                action: (e) => handleMenuContextShowNames(e, 'showRegions'),
              },
              {
                label: 'Regiões administrativas',
                type: 'item',
                disable: false,
                icon: showAdmRegions ? 'check' : undefined,
                action: (e) => handleMenuContextShowNames(e, 'showAdmRegions'),
              }
            ]
          },
          {
            label: 'Posicionar nome(s)',
            type: 'menu',
            disable: !showNames,
            options: [
              {
                label: 'Uma única vez',
                type: 'item',
                icon: oneCityModeActive ? 'check' : undefined,
                action: () => {
                  const adjust = oneCityModeActive ? 'disabled' : 'one-city';
                  adjustCityLabelPositionMode.current = adjust;
                  setAdjustLabelMode(adjust);
                  analytics.logEvent('mapa-menu-contexto-ajustar-texto-uma-cidade');
                }
              },
              {
                label: `Várias vezes (${allCitiesModeActive ? 'desativar' : 'ativar'})`,
                type: 'item',
                icon: allCitiesModeActive ? 'check' : undefined,
                action: () => {
                  const adjust = allCitiesModeActive ? 'disabled' : 'all-cities';
                  adjustCityLabelPositionMode.current = adjust;
                  setAdjustLabelMode(adjust);
                  analytics.logEvent('mapa-menu-contexto-ajustar-texto-todas-cidades');
                },
              },
            ]
          },
        ],
      });
    };
  }, [
    adjustCityLabelPositionMode,
    handleMenuContextBorderRegions,
    handleMenuContextMapCenter,
    handleMenuContextShowNames,
    handleMenuContextShowColors,
    handleMenuContextVisibleCity,
    options.showColors,
    options.showNames,
    setAdjustLabelMode,
    showColors,
    showNames,
    showSecondaryMenu
  ]);

  //-----------------------------------------------
  //-------Funções que definem eventos no mapa------------

  const bindCitiesEvents = useCallback(() => {
    Array.from(refMap.current.querySelectorAll('.cities path'))
      .forEach((item) => {
        item.onmouseenter = (event) => {
          refCursor.current.innerHTML = item.dataset.nome || item.dataset.slug;
          refCursor.current.style.display = 'block';
          event.target.style.cursor = mapSettings.current.dragging ? 'move' : 'pointer';
        };
        item.onmouseleave = (event) => {
          refCursor.current.style.display = 'none';
          if (!mapSettings.current.dragging) {
            event.target.style.cursor = refMap.current.mode === 'move' ? 'auto' : 'crosshair';
          }
        };
        item.ondblclick = () => {
          setActiveCity(item);
          analytics.logEvent('mapa-clique-duplo-descricao-cidade');
        };
        addSecondaryMenuEvent(item);

        // implementação de clique duplo em iOS
        if (navigator.vendor.match(/apple/i)) {
          item.lasttap = 0;
          item.onclick = () => {
            const timeElapsed = Date.now() - item.lasttap;
            if (timeElapsed < 800) {
              item.ondblclick();
            }
            item.lasttap = Date.now();
          }
        }

      });

    if (!document.onmousemove) {
      document.onmousemove = (event) => {
        if (refCursor.current && document.activeElement.tagName === 'BODY') {
          refCursor.current.style.left = `${event.clientX + 10}px`;
          refCursor.current.style.top = `${event.clientY + 10}px`;
        } else if (refCursor.current) { refCursor.current.style.display = 'none'; }
      };
      document.ontouchmove = () => { refCursor.current.style.display = 'none'; };
    }
  }, [addSecondaryMenuEvent, refMap, setActiveCity]);

  const bindLabelsEvents = useCallback(() => {
    const groups = Array.from(document.querySelectorAll('.labels g'))
      .map(element => element.dataset.classId);

    groups.forEach(id => {
      d3.select(`g.${id}`)
        .call(
          d3.drag()
            .subject(event => event.target)
            .on('drag', event => draggingTextLabel(event, id))
            .on('end', () => overrideTextLabelPosition(id))
        );
    });
  }, [draggingTextLabel, overrideTextLabelPosition]);

  //---------------------------------------------------

  /**
   * Esse useEffect hook adiciona todos os eventos
   * relacionados ao mapa e seus componentes quando o mapa é carregado
   * e em toda atualização por DocumentFragment
   */
  useEffect(() => {
      if (Object.values(mounted).every(item => item === 'loaded')) {
      bindCitiesEvents();
      bindLabelsEvents();
    }
  }, [bindCitiesEvents, bindLabelsEvents, mounted]);

  return (
    <MapEventsContext.Provider
      value={{
        handleEventZoom,
        handleMouseDown,
        handleMouseEnter,
        handleMouseMove,
        handleMouseUp,
        refCursor,
        svgMouseDown,
        svgMouseMove,
        zoomRect,
      }}
    >
      {children}
    </MapEventsContext.Provider>
  )
}

export default MapEventsContext;
