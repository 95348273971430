import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';

import { FlexContainer } from '../../../../../../../..';

export const ConteinerFormulario = styled(FlexContainer)`
  padding: 2px 0 2px 2px;
  width: 100%;
`;

export const ControleEstilizado = styled(FormControl)`
  max-width: 50%;
`;
