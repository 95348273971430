import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';

import {
  GetAmendmentsFromCityResource,
  GetCandidatesFromCityResource,
  GetCityElectedResource,
  GetCityDescriptionResource,
  GetPeopleFromCityResource,
  GetPreCandidatesFromCityResource,
} from '../../api/resources';

import {
  AppContext,
  ColorContext,
} from '../../contexts';

import MapIsolateRegionsContext from './MapIsolateRegionsContext';
import MapLabelsContext from './MapLabelsContext';
import MapProviderContext from './ProviderGroup';

import './ContextTypes';

import {
  emptyObject,
  highest,
  lowest,
} from '../../utils';

/**
 * @typedef {object} MapContextType
 * @prop {React.MutableRefObject<LabelAdjustMode>} adjustCityLabelPositionMode
 * Referencia ao estado atual do modo de "drag" de labels
 * @prop {LabelAdjustMode} adjustLabelMode
 * Estado que monitora o modo de "drag" de labels
 * @prop {(zoomFactor: number) => void} center
 * Callback de centralização do mapa baseado em um fator (padrão 0.5)
 * @prop {VoidFunction} clear
 * Callback de limpeza visual do mapa
 * @prop {(pattern: PatternConfig, offsetFactor?: number) => void} createCompoundColorPattern
 * callback para criação de um novo pattern do mapa
 * @prop {{ name: string, id: number }} currentCity
 * Estado que armazena a cidade selecionada do mapa
 * @prop {(city: string) => Promise<object[]>} getAmendmentsFromCity
 * Requisição de busca de emendas de uma cidade
 * @prop {(id: string) => Promise<object[]>} getCandidatesFromCity
 * Requisição de busca de candidatos de uma cidade
 * @prop {(id: string) => Promise<object[]>} getElectedFromCity
 * Requisição de busca de eleitos de uma cidade
 * @prop {(city: string, filters: PeopleSearchParams) => Promise<object[]>} getPessoasFromCity
 * Requisição de busca de pessoas de uma cidade dado um objeto de busca
 * @prop {(id: string) => Promise<object[]>} getPreCandidatesFromCity
 * Requisição de busca de pré-candidatos de uma cidade
 * @prop {(labels: NumberLabelsType) => SumsObject} getSums
 * Callback para calcular as somas selecionadas do filtro.
 * @prop {(type: string) => void} handleShowNames
 * Callback para habilitar os labels de nomes dado um tipo de label
 * @prop {(factor: number) => void} increaseFontSize
 * Callback para alterar o tamanho das fontes baseado em um fator
 * @prop {IsolateTypes} isolateType
 * Estado que monitora o tipo de isolamento a ser usado no mapa
 * @prop {NumberLabelsType} numberLabels
 * Estado que armazena os labels de quantidade aplicados mais recentemente
 * @prop {React.MutableRefObject<HTMLDivElement>} refMap
 * Referência ao elemento caixa do mapa
 * @prop {LabelsProps[]} labels
 * Estado que aramzazena a configuração inicial de labels
 * @prop {React.MutableRefObject<FontStatus>} refFontStatus
 * Objeto de armazenamento dos tamanhos de fonte atualmente aplicados
 * @prop {LabelsSize} labelsSize
 * Estado que monitora os tamanhos de fonte
 * @prop {MapMode} mode
 * Estado que monitora o modo atual do mapa. O modo `move` é o modo padrão.
 * @prop {MountState} mounted
 * Estado que monitora os estado dos componentes do mapa.
 * @prop {PatternProps[]} patterns
 * Estado que armazena os patterns do mapa
 * @prop {VoidFunction} reset
 * Callback que reseta o tamanho e deslocamento do mapa
 * @prop {VoidFunction} resetMapText
 * Callback que reseta o tamanho das fontes de label do mapa
 * @prop {(id: string) => Promise<Object.<string, any>>} selectCity
 * Callback que seleciona uma cidade para abrir o diálogo de detalhes
 * @prop {(city: SVGPathElement) => void} setActiveCity
 * Callback que seleciona uma cidade do mapa
 * @prop {SetState<LabelAdjustMode>} setAdjustLabelMode
 * SetState do estado `adjustLabelMode`
 * @prop {SetState<IsolateTypes>} setIsolateType
 * SetState para o estado `isolateType`
 * @prop {SetState<LabelsSize>} setLabelsSize
 * SetState para estado `labelsSize`
 * @prop {SetState<MapMode>} setMode
 * SetState para o estado `mode`
 * @prop {SetState<MountState>} setMounted
 * SetState do estado `mounted`
 * @prop {SetState<NumberLabelsType>} setNumberLabels
 * SetState do estado `numberLabels`
 * @prop {SetState<PatternProps[]>} setPatterns
 * SetState para o estad `patterns`
 * @prop {SetState<boolean>} setShowColors
 * SetState do estado `showColors`
 * @prop {SetState<boolean>} setShowNames
 * SetState do estado `showNames`
 * @prop {SetState<boolean>} setShowNumbers
 * SetState do estado `showNumbers`
 * @prop {SetState<QuantitiesOptionsType>} setShowQuantitiesOptions
 * SetState do estado `showQuantitiesOptions`
 * @prop {SetState<string[]>} setVisibleCities
 * SetState do estado `visibleCities`
 * @prop {boolean} showColors
 * Estado que monitora a função de colorir e descolorir do mapa
 * @prop {QuantitiesOptionsType} showQuantitiesOptions
 * Estado que monitora as opções da função de mostrar quantidades do mapa
 * @prop {boolean} showNames
 * Estado que monitora se algum label de nome está ativo
 * @prop {boolean} showNumbers
 * Estado que monitora se o mapa está mostrando quantidades
 * @prop {React.MutableRefObject<ViewingTransformer>} transformer
 * Referência ao objeto transformador do mapa
 * @prop {number[]} visibleAdmRegions
 * Conjunto de ids das regiões administrativas com ao menos uma cidade vísivel
 * @prop {number[]} visibleBacias
 * Conjunto de ids das bacias com ao menos uma cidade vísivel
 * @prop {string[]} visibleCities
 * Conjunto de ids das cidades vísiveis
 * @prop {number[]} visibleDioceses
 * Conjunto de ids das dioceses com ao menos uma cidade vísivel
 * @prop {number[]} visibleRegions
 * Conjunto de ids das regiões com ao menos uma cidade vísivel
 */
/**
 * @type {React.Context<MapContextType>}
 */
const MapContext = createContext({});

export function MapProvider({ children }) {
  const {
    activeFilters,
    addTaskRunning,
    removeTaskRunning,
    setOptions,
    token,
  } = useContext(AppContext);

  const { coresCidades } = useContext(ColorContext);

  const {
    getAllCities,
    refMap,
    setShowNames,
    showColors,
    mode,
    mounted,
    setMode,
    setMounted,
    setShowColors,
    setShowNumbers,
    showNames,
    showNumbers,
    transformer,
  } = useContext(MapProviderContext);

  const {
    isolateType,
    setIsolateType,
    setVisibleCities,
    visibleAdmRegions,
    visibleBacias,
    visibleCities,
    visibleDioceses,
    visibleRegions,
  } = useContext(MapIsolateRegionsContext);

  const {
    adjustCityLabelPositionMode,
    adjustLabelMode,
    getSums,
    increaseFontSize,
    labels,
    labelsSize,
    numberLabels,
    refFontStatus,
    resetMapText,
    setAdjustLabelMode,
    setLabelsSize,
    setNumberLabels,
    setShowQuantitiesOptions,
    showQuantitiesOptions,
  } = useContext(MapLabelsContext);

  /**
   * Esse estado identifica a cidade selecionada para
   * exibição da caixa de diálogo de descrição da cidade
   */
  const [currentCity, setCurrentCity] = useState({ name: '', id: -1 });

  /**
   * Callback para selecionar uma cidade.
   */
  const setActiveCity = useCallback((city) => {
    if (city === null) {
      setCurrentCity(old => {
        const city = refMap.current.querySelector(`.city-${old.id}`);
        if (city) {
          city.dataset.active = 'off';
        }
        return { id: -1, name: '' };
      });
    } else {
      city.dataset.active = 'on';
      setCurrentCity({ id: city.dataset.id, name: city.dataset.nome });
    }
  }, [refMap]);

  /**
   * Essa função recebe as cidades de devem ser consideradas "ativas"
   * e manipula o dataset de seus elementos
   */
  const setActiveCities = useCallback((activeCities) => {
    const cities = getAllCities()
      .filter(item => activeCities.includes(item.dataset.id));

    cities.forEach(item => { item.dataset.active = 'on'; });
  }, [getAllCities]);

  /**
   * Essa função limpa as cidades ativas no mapa.
   */
  const clear = useCallback(() => {
    const activeCities = refMap.current.querySelectorAll('.cities [data-active="on"]');
    activeCities.forEach(city => { city.dataset.active = 'off'; });
  }, [refMap]);

  /**
   * Esta função busca as informações da descrição
   * da cidade por meio do serviço
   *
   * @param id ID da cidade
   *
   * @returns Os dados da descrição de cidade
   */
  const selectCity = useCallback(async (id) => {
    const resource = new GetCityDescriptionResource(token, id);
    addTaskRunning();
    const data = await resource.result();
    removeTaskRunning();
    return data;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * Esta função retorna os dados dos candidatos eleitos para
   * preenchimento da tabela
   *
   * @returns Os dados dos candidatos eleitos
   */
  const getElectedFromCity = useCallback(async (id) => {
    const resource = new GetCityElectedResource(token, id);
    addTaskRunning();
    const data = await resource.result();
    removeTaskRunning();
    return data;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * Esta função retorna os dados de todos os candidatos
   * referentes a cidades
   *
   * @returns Os dados de todos os candidatos
   */
  const getCandidatesFromCity = useCallback(async (id) => {
    const resource = new GetCandidatesFromCityResource(token, id);
    addTaskRunning();
    const data = await resource.result();
    removeTaskRunning();
    return data;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * Esta função retorna os dados dos pré-candidatos
   */
  const getPreCandidatesFromCity = useCallback(async (id) => {
    const resource = new GetPreCandidatesFromCityResource(token, id);
    addTaskRunning();
    const data = await resource.result();
    removeTaskRunning();
    return data;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * Função que retorna a lista de pessoas a partir de uma cidade
   * @param {number} city identificador da cidade
   * @param {object} filters objeto com possíveis listas de filtros
   * para requisição (events, colors, profiles)
   * @returns {Array} lista com objeto de cada pessoa
   * @example
   * ```
   * getPessoasFromCity(9103, { events: ['efl', 'svn'], colors: [1, 2, 3], profiles: 17 })
   *  .then((response) => {
   *     console.log(response);
   *     // [{
   *     //   "id_pessoa": 13,
   *     //   "nome": "Leandro Ungari",
   *     //   "apelido": "Leandro",
   *     //   "idcidade": 9103,
   *     //   "cor": { "id_cor": 3, "cor_nome": "Amarelo", "cor_hexa": "#ffff00" },
   *     //   "eventos": {
   *     //     "efl": { "2015": 3, "2016": 6, "total": 9 },
   *     //     "svn": { "2016": { "svn1": 3, "total": 3 }, "total": 3 },
   *     //   }
   *     //   "perfil": { "descricao": "Voluntário", "id_perfil": 17 }
   *     // }];
   *   });
   * ```
   */
  const getPessoasFromCity = useCallback(async (city, filters) => {
    const resource = new GetPeopleFromCityResource(token, city, filters);
    addTaskRunning();
    const data = await resource.result();
    removeTaskRunning();
    return data;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * Esta função retorna um array com as emendas de uma cidade desejada
   * Cada emenda é um objeto do array que possui os seguintes campos:
   *  - `ano`, `orgao`, `situacao`, `modalidade`, `proponente` e `valor_repasse`
   * @param {Number} cidade id da cidade desejada
   * @returns {[{ano, orgao, situacao, modalidade, proponente, valor_repasse}]} array com emendas
   */
  const getAmendmentsFromCity = useCallback(async (city) => {
    const resource = new GetAmendmentsFromCityResource(token, city);
    addTaskRunning();
    const data = await resource.result();
    removeTaskRunning();
    return data;
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * Essa função calcula as coordenadas do menor
   * retângulo possível que comporte a relação de cidades recebidas
   * no array items
   *
   * @param items Relação de cidades (elemento HTML do mapa)
   *
   * @returns Coordenadas do retângulo resultante
   */
  const getBox = useCallback((items = []) => (
    items.reduce((total, item) => {
      const rect = item.getClientRects()[0];
      if (rect) {
        return {
          minX: lowest(total.minX, rect.left),
          minY: lowest(total.minY, rect.top),
          maxX: highest(total.maxX, rect.right),
          maxY: highest(total.maxY, rect.bottom),
        };
      }
      return total;
    }, {})
  ), []);

  /**
   * Essa função calcula o retângulo que abrange as cidades
   * selecionadas
   *
   * @returns Retângulo da seleção
   */
  const getActiveBox = useCallback(() => (
    getBox(Array.from(refMap.current.querySelectorAll('[data-active="on"]')))
  ), [getBox, refMap]);

  /**
   * Essa função centraliza o mapa de acordo com o ponto médio
   * da região destacada no mapa
   *
   * @param zoomFactor Fator de aproximação do zoom
   *
   * @returns void
   */
  const center = useCallback((zoomFactor = 0.5) => {
    transformer.current.reset();
    const selection = getActiveBox();

    if (Object.keys(selection).length > 0) {
      const centerSelection = {
        x: (selection.minX + selection.maxX) / 2,
        y: (selection.minY + selection.maxY) / 2,
      };

      const offset = {
        x: (window.innerWidth / 2) - centerSelection.x,
        y: (window.innerHeight / 2) - centerSelection.y,
      };

      const dimensions = {
        width: (selection.maxX - selection.minX),
        height: (selection.maxY - selection.minY),
      };

      const target = document.querySelector('.mapa');
      const factor = {
        x: 1,
        y: 1,
      };
      if (window.innerWidth < window.innerHeight) {
        factor.x = (window.innerWidth * zoomFactor) / dimensions.width;
        factor.x = factor.x > 25 ? 25 : factor.x;
        factor.y = factor.x * (target.height.baseVal.value / target.width.baseVal.value);
      } else {
        factor.y = (window.innerHeight * zoomFactor) / dimensions.height;
        factor.y = factor.y > 25 ? 25 : factor.y;
        factor.x = factor.y * (target.width.baseVal.value / target.height.baseVal.value);
      }

      transformer.current.center(offset, factor);
    }
  }, [getActiveBox, transformer]);

  /**
   * Essa função retorna o mapa para o tamanho e deslocamento
   * inicial
   *
   * @returns void
   */
  const reset = useCallback(() => transformer.current.reset(), [transformer]);

  /**
   * Este estado controla os <linearGradients> do mapa.
   * @type {[any[], SetState<any[]>]}
   */
  const [patterns, setPatterns] = useState([]);

  /**
   * Essa função cria um pattern de cores baseado em um objeto
   * com propriedades patternId e patternColors.
   * @param patternId Exemplo: '_red-blue'
   * @param patternColors Exemplo: ['red', 'blue']
   * @param offsetFactor Um inteiro para definir o offset de stop
   *
   * @returns linearGradient com as cores de patternColors
   */
  const createCompoundColorPattern = useCallback(
    ({ patternId, patternColors }, offsetFactor = 5) => {
      const colorStops = [];

      let i = 0;
      while (i < 100) {
        // eslint-disable-next-line
        const newStops = patternColors.reduce((patternColorsTotal, color) => {
          const colorStopInitialOffset = <stop offset={`${i}%`} stopColor={color} />;
          const colorStopFinalOffset = <stop offset={`${i + offsetFactor}%`} stopColor={color} />;

          i += offsetFactor;
          return [...patternColorsTotal, colorStopInitialOffset, colorStopFinalOffset];
        }, []);
        colorStops.push(...newStops);
      }

      const colorPattern = {
        id: patternId,
        colorStops,
      };

      return colorPattern;
    }, []
  );

  /**
   * Essa função define as patterns das cidades baseado nas relaçoes cor-cidade
   *
   * @param citiesAndColors Dicionário com a valores de cada cidade
   *
   * result: { integer: [ string, string, ... ] }
   * result: { 1: [ 'red', 'blue', ... ] }
   * result: { 2: [ 'red' ] }
   *
   */
  const getPatterns = useCallback((citiesColors) => {
    // Lista que armazena combinações de cores unicas
    const compoundColorPatternsList = [];

    Object.values(citiesColors).forEach((colors) => {
      // Cor personalizada
      if (colors.length > 1) {
        // Multiplicas cores
        const colorPatternId = `_${colors.join('-')}`;
        if (!compoundColorPatternsList.find(item => item.patternId === colorPatternId)) {
          compoundColorPatternsList.push({
            patternId: colorPatternId,
            patternColors: colors,
          });
        }
      }
    });

    setPatterns(old => [
      ...old,
      ...compoundColorPatternsList
        .filter(item => !old.find(oldItem => oldItem.id === item.patternId))
        .map(item => createCompoundColorPattern(item)),
    ]);
  }, [createCompoundColorPattern]);

  /**
   * Referencia ao id de timeout de pintura do mapa.
   * @type {React.MutableRefObject<number>}
   */
  const refPaintRequest = useRef(-1);

  /**
   * Esse useEffect hook monitora a presença de dados
   * no objeto de cores e aplica o processo de coloração
   * do mapa
   */
  useEffect(() => {
    window.clearTimeout(refPaintRequest.current);
    refPaintRequest.current = window.setTimeout(() => {
      if (!emptyObject(coresCidades)) {
        clear();
        getPatterns(coresCidades);
        setActiveCities(Object.keys(coresCidades)); // cidades ativas
        center();
      } else {
        setOptions(options => ({ ...options, showColors: false }));
        setShowColors(false);
        clear();
      }
    }, 500);
  }, [
    center,
    coresCidades,
    clear,
    getPatterns,
    setActiveCities,
    setOptions,
    setShowColors,
  ]);

  /**
   * Esse use Effect monitora alterações nos filtros ativos.
   * Quando novos filtros são feitos, reseta diversas configurações do mapa
   */
  useEffect(() => {
    setNumberLabels([]);
    setShowQuantitiesOptions({
      sumEvents: false,
      sumPolitics: false,
      sumPeople: false,
      sumEmendas: false,
    });
    setShowNumbers(false);
    setOptions(options => ({ ...options, showQuantities: false }));
  }, [
    activeFilters,
    setNumberLabels,
    setOptions,
    setShowNumbers,
    setShowQuantitiesOptions
  ]);

  /**
   * Essa função define as configurações de exibição de nomes no mapa.
   * Altera os valores booleanos que definem a exibição de nomes
   */
  const handleShowNames = useCallback((type) => {
    setOptions(options => {
      const newShowNames = {
        ...options.showNames,
        [type]: !options.showNames[type] // novo valor
      };

      // `showNames` será `true` desde que `newShowNames` possua um valor `true`
      setShowNames(Object.values(newShowNames).some(value => value));

      // novo valor do Estado `options`
      return {
        ...options,
        showNames: newShowNames,
      };
    });
  }, [setOptions, setShowNames]);

  return (
    <MapContext.Provider
      value={{
        adjustCityLabelPositionMode,
        adjustLabelMode,
        center,
        clear,
        createCompoundColorPattern,
        currentCity,
        getAmendmentsFromCity,
        getCandidatesFromCity,
        getElectedFromCity,
        getPessoasFromCity,
        getPreCandidatesFromCity,
        getSums,
        handleShowNames,
        increaseFontSize,
        isolateType,
        labels,
        labelsSize,
        mode,
        mounted,
        numberLabels,
        patterns,
        refFontStatus,
        refMap,
        reset,
        resetMapText,
        selectCity,
        setActiveCity,
        setAdjustLabelMode,
        setIsolateType,
        setLabelsSize,
        setMode,
        setMounted,
        setNumberLabels,
        setPatterns,
        setShowColors,
        setShowNames,
        setShowNumbers,
        setShowQuantitiesOptions,
        setVisibleCities,
        showColors,
        showNames,
        showNumbers,
        showQuantitiesOptions,
        transformer,
        visibleAdmRegions,
        visibleBacias,
        visibleCities,
        visibleDioceses,
        visibleRegions,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

export default MapContext;
