import React, {
  memo,
  useContext,
} from 'react';

import { BorderRegionsContext } from '../../../../../../contexts';

function Hulls() {
  const { hulls } = useContext(BorderRegionsContext);
  
  return (
    <g className='hulls'>
      {
        hulls.map(props =>
          <path
            className={props.classes.join(' ')}
            d={props.d}
          />
        )
      }
    </g>
  );
}

export default memo(Hulls);