import React, {
  memo,
  useCallback,
  useState,
  useMemo,
} from 'react';

import Typography from '@material-ui/core/Typography';

const Item = ({ id, label, onSelect }) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      key={`item-${id}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onSelect({ id, label })}
      style={{
        padding: '8px 16px',
        width: 'calc(100% - 32px)',
        backgroundColor: hover ? '#3498DB' : '#fff',
        color: hover ? '#fff' : '#000',
        cursor: 'pointer',
      }}
    >
      <Typography
        style={{
          fontSize: 14,
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

function AutoCompleteList(props) {
  const {
    options,
    getOptionLabel,
    onSelect,
    searchValue
  } = props;

  const notShowList = useMemo(() => (
    searchValue.trim().length < 3
  ), [searchValue]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 60,
        left: 10,
        display: 'flex',
        width: '87%',
        flexDirection: 'column',
        zIndex: 20,
        backgroundColor: '#fff',
        boxShadow: '0 8px 8px #ccc',
      }}
    >
      {
        notShowList
          ? []
          : options.filter(item => (
            getOptionLabel(item).toLowerCase().includes(searchValue.toLowerCase())
          )).slice(0, 10).map(item => (
            <Item
              key={`item-a-${item.id}`}
              id={item.id}
              onSelect={onSelect}
              label={getOptionLabel(item)}
            />
          ))
      }
    </div>
  );
}

export default memo(AutoCompleteList);
