import styled from 'styled-components';

import RadioGroup from '@mui/material/RadioGroup';

export const GrupoRadioContornos = styled(RadioGroup)`
  &.MuiFormGroup-root {

    .MuiFormControlLabel-root {
      padding-block: .5rem;
    }
  }
`;
