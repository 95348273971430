import styled from 'styled-components';

export const FixedBlock = styled.div`
  position: fixed;
  right: 0;
  bottom: 20px;
  display: flex;
  padding: 10px;
  background-color: transparent;
  width: 250px;
  height: 150px;
`;

export const ButtonControl = styled.div`
  position: absolute;
  ${props => {
    if (props.top) return `top: ${props.top}px; right: ${props.right}px;`;
    if (props.bottom) return `bottom: ${props.bottom}px; right: ${props.right}px;`;
    return 'bottom: 20px; right: 0;';
  }};

  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 6px #aaa;
  border-radius: 4px 0 0 4px;
`;
