import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const CheckboxElement = withStyles({
  root: {
    padding: 3,
  },
  disabled: {
    opacity: 0.5,
    filter: 'grayscale(1)',
  }
})(Checkbox);

const FormControlLabelElement = withStyles({
  root: { margin: 0 },
  label: {
    fontSize: '14px',
    marginLeft: 3,
  }
})(FormControlLabel);

const preventDefault = event => event.preventDefault();

/**
 * @typedef {object} CheckBoxProps
 * @prop {string} [checkColor]
 * Cor do `CheckBox` quando selecionada. Padrão `#0B70D2`
 * @prop {boolean} checked
 * Booleano que indica se a `CheckBox` está selecionada.
 *
 * Não deve ser `true` ao mesmo tempo que `indeterminate`.
 * @prop {string} [color]
 * Cor do `label` da componente. Padrão `#000`
 * @prop {boolean} [disabled]
 * Booleano que indica se a `CheckBox` está desabilitada. Padrão `false`.
 * @prop {boolean} [indeterminate]
 * Booleano que indica se a `CheckBox` está no estado indeterminado. Padrão `false`.
 *
 * Não deve ser `true` ao mesmo tempo que `checked`.
 * @prop {React.ReactNode} label
 * Label da componente. Pode ser uma `string` ou componente.
 * @prop {() => void} [onClick]
 * Callback, que quando existe, é executado no clique da componente.
 * @prop {CSSProps} [style]
 * Estilização da componente.
 */
/**
 * @type {Component<CheckBoxProps>}
 */
export const CheckBoxItem = ({
  checkColor,
  checked,
  color,
  disabled = false,
  indeterminate = false,
  label,
  onClick,
  style,
}) => (
  <FormControlLabelElement
    style={{ ...style, color: color || '#000' }}
    label={label}
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      if (onClick) onClick();
    }}
    control={(
      <CheckboxElement
        style={{ color: checkColor || '#0B70D2' }}
        onSelect={preventDefault}
        onChange={preventDefault}
        size="small"
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
      />
    )}
  />
);
