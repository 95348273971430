import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';

export const ControleExpansao = styled(IconButton)`
  &.MuiIconButton-root {
    display: flex;
    border: 1px solid;
    border-radius: 0;

    margin-inline: .5rem;
    padding: 0;

    & > .MuiSvgIcon-root {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ItemArvore = styled(ListItem)`
  &.MuiListItem-root {
    padding: 0.125rem 0;
  }
`;

export const ConteinerIndentado = styled.div`
  & {
    margin-left: 1.5rem;
  }
`;
