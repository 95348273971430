import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import Badge from '@material-ui/core/Badge';

import {
  Aba,
  Abas,
  Dialog,
  Botao,
  Form,
} from '../../../../components';

import {
  AppContext,
  FilterContext,
  MapContext,
} from '../../../../contexts';

import { analytics } from '../../../../index';

import { capitalize } from '../../../../utils';
import { isAmendmentsFilter, isEventsFilter, isPeopleFilter, isPoliticFilter } from '../../../../utils/filters';

import FilterList from './components/FilterList';
import PeopleTab from './components/AbasFiltros/PeopleTab';
import EventsTab from './components/AbasFiltros/EventsTab';
import PoliticsTab from './components/AbasFiltros/PoliticsTab';
import EmendasTab from './components/AbasFiltros/EmendasTab';

import { FilterTab } from './styles';

function filtersOrder(a, b) {
  if (a === 'pessoas') return -1;
  if (a === 'emendas') return 1;
  if (b === 'pessoas') return 1;
  if (b === 'emendas') return -1;

  if (a === 'eventos' && b.match(/politico|emendas/)) return -1;
  if (a === 'politico' && b.match(/pessoas|eventos/)) return 1;
  return 0;
}

/**
 * @param {{ isVisible: boolean, setVisible: (value: boolean) => void }} props
 */
function FilterDialog(props) {
  const { isVisible, setVisible } = props;

  const {
    activeFilters,
    addTaskRunning,
    filterOptions,
    isLibcomSchema,
    setOptions,
  } = useContext(AppContext);

  const {
    fieldEvents,
    handleApplyFilterClick,
    handleClearFilterClick,
  } = useContext(FilterContext);

  const { setShowColors } = useContext(MapContext);

  // const [type, setType] = useState('events'); type do filtro regions
  const orderedFilters = useMemo(() => (
    Object.keys(filterOptions).sort(filtersOrder)
  ), [filterOptions]);

  const handleCloseClick = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleApplyFilter = useCallback(() => {
    analytics.logEvent('dialog-filtro-aplicar-filtro');
    handleApplyFilterClick()
      .then(() => {
        setShowColors(atual => {
          if (atual) return atual;
          setOptions(options => ({ ...options, showColors: true }));
          return true;
        });
        setVisible(false);
      });
  }, [handleApplyFilterClick, setVisible, setOptions, setShowColors]);

  const handleClearFilter = useCallback(() => {
    addTaskRunning();
    setShowColors(false);
    setTimeout(handleClearFilterClick, 100);
    analytics.logEvent('dialog-filtro-limpar-filtro');
  }, [addTaskRunning, handleClearFilterClick, setShowColors]);

  const formStatus = useMemo(() => {
    if (fieldEvents.length > 0) {
      const compounds = fieldEvents.filter(item => typeof item.id === 'number');
      return {
        validated: (
          compounds.every(f => f.filters.length > 1)
          && compounds.every((c, index) => (
            compounds
              .filter((_, i) => index !== i)
              .every(f => f.toString() !== c.toString())
          ))
        ),
        error: '',
      };
    }
    return {
      validated: false,
      error: 'Selecione pelo menos uma opção de filtro',
    };
  }, [fieldEvents]);

  const onMudaAba = useCallback((_, aba) => {
    switch (orderedFilters.find(item => item === aba)) {
      case 'pessoas':
        analytics.logEvent('dialog-filtro-seleciona-aba-pessoas');
        break;
      case 'eventos':
        analytics.logEvent('dialog-filtro-seleciona-aba-eventos');
        break;
      case 'politico':
        analytics.logEvent('dialog-filtro-seleciona-aba-politicos');
        break;
      case 'emendas':
        analytics.logEvent('dialog-filtro-seleciona-aba-emendas');
        break;
      default:
    }
  }, [orderedFilters]);

  const isIndicatorVisible = useMemo(() => ({
    pessoas: activeFilters.some(isPeopleFilter),
    eventos: activeFilters.some(isEventsFilter),
    politico: activeFilters.some(isPoliticFilter),
    emendas: activeFilters.some(isAmendmentsFilter),
  }), [activeFilters]);

  const availableTabs = useMemo(() => (
    orderedFilters
      .map(item => {
        switch (item) {
          case 'pessoas':
            return (
              <Aba
                value={item}
                label={<Badge color="secondary" variant="dot" invisible={!isIndicatorVisible[item]}>{capitalize(item)}</Badge>}
                componente={<PeopleTab />}
              />
            );
          case 'eventos':
            return (
              <Aba
                value={item}
                label={<Badge color="secondary" variant="dot" invisible={!isIndicatorVisible[item]}>{capitalize(item)}</Badge>}
                componente={<EventsTab />}
              />
            );
          case 'politico':
            return (
              <Aba
                value={item}
                label={<Badge color="secondary" variant="dot" invisible={!isIndicatorVisible[item]}>{capitalize(item)}</Badge>}
                style={{ display: isLibcomSchema ? 'none' : undefined }}
                componente={<PoliticsTab />}
              />
            );
          case 'emendas':
            return (
              <Aba
                value={item}
                label={<Badge color="secondary" variant="dot" invisible={!isIndicatorVisible[item]}>{capitalize(item)}</Badge>}
                style={{ display: isLibcomSchema ? 'none' : undefined }}
                componente={<EmendasTab />}
              />
            );

          default: return <></>;
        }
      })
  ), [orderedFilters, isIndicatorVisible, isLibcomSchema]);

  return (
    <Dialog
      actions={(
        <>
          <Botao
            style={{
              backgroundColor: '#3498DB',
              color: '#fff',
              padding: '10px 15px',
            }}
            onClick={handleApplyFilter}
            disabled={!formStatus.validated}
          >
            Aplicar
          </Botao>
          <Botao
            style={{
              backgroundColor: 'transparent',
              color: '#222',
              padding: '5px 15px',
            }}
            onClick={handleClearFilter}
          >
            Limpar
          </Botao>
        </>
      )}
      disableBackdropClick
      title="Novo Filtro"
      show={isVisible}
      onClose={handleCloseClick}
    >
      <Form>
        <Abas
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={onMudaAba}
          renderizarAbasEm={<FilterTab />}
        >
          {availableTabs}
        </Abas>

        <FilterList />
      </Form>
    </Dialog>
  );
}

export default memo(FilterDialog);
