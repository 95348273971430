import React, {
  memo,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import {
  Botao,
  Select,
} from '../../../../../../../components';

import { FilterContext } from '../../../../../../../contexts';

import { chaveExisteEm } from '../../../../../../../utils';

import {
  FlexRowFormContainer,
  WarningContainer
} from '../styles';

function PeopleTab() {
  const {
    getFilterOptions,
    peopleFilters = {},
    setFieldEvents,
  } = useContext(FilterContext);

  const warning = useRef('');

  /**
   * useMemo que carrega as opções de tipo do filtro pessoas
   * Perfis, Cores, Grupos e Cadastros
   */
  const peopleOptions = useMemo(() => getFilterOptions('pessoas'), [getFilterOptions]);
  const [typeOption, setTypeOption] = useState(peopleOptions[0] ? peopleOptions[0].id : undefined);

  /**
   * useMemo que carrega todas as opções de filtros pessoas
   */
  const { profiles, colors, groups, registerYears, colorsRegistersYears } = useMemo(() => {
    const result = {};

    let id = 'id';
    let label = 'rotulo';
    if (chaveExisteEm(peopleFilters, 'perfis')) {
      id = 'id_perfil';
      label = 'descricao';

      result.profiles = peopleFilters['perfis'].perfis.map((item) => ({ id: item[id], label: item[label] }));
    }

    if (chaveExisteEm(peopleFilters, 'cores')) {
      id = 'id_cor';
      label = 'cor_nome';

      result.colors = peopleFilters['cores'].cores.map((item) => ({ id: item[id], label: item[label] }));
    }

    if (chaveExisteEm(peopleFilters, 'grupos')) {
      id = 'id_grupo';
      label = 'descricao';

      result.groups = peopleFilters['grupos'].grupos.map((item) => ({ id: item[id], label: item[label] }));
    }

    if (chaveExisteEm(peopleFilters, 'cadastros')) {
      result.registerYears = [
        { id: 'total', label: 'Todos' },
        ...peopleFilters['cadastros'].anos.map(year => ({ id: year, label: year })),
      ];
    }

    if (chaveExisteEm(peopleFilters, 'cores-anos')) {
      result.colorsRegistersYears = peopleFilters['cores-anos'].anos.map((year) => ({ id: year, label: year }));
    }

    return {
      colorsRegistersYears: result.colorsRegistersYears || [],
      registerYears: result.registerYears || [],
      colors: result.colors || [],
      groups: result.groups || [],
      profiles: result.profiles || []
    };
  }, [peopleFilters]);

  const [profile, setProfile] = useState(
    profiles[0] ? profiles[0].id : undefined
  );
  const [color, setColor] = useState(
    colors[0] ? colors[0].id : undefined
  );
  const [colorYear, setColorYear] = useState(
    colorsRegistersYears[0] ? colorsRegistersYears[0].id : undefined
  );
  const [group, setGroup] = useState(
    groups[0] ? groups[0].id : undefined
  );
  const [registrationYear, setRegistrationYear] = useState(
    registerYears[0] ? registerYears[0].id : undefined
  );

  const disableSelection = useMemo(() => {
    if (typeOption === undefined) {
      warning.current = 'Nenhum filtro deste tipo disponível';
      return true;
    }

    warning.current = 'Nenhum dado foi encontrado para este filtro';
    const noProfile = profile === undefined;
    const noGroup = group === undefined;
    const noRegistersYear = registrationYear === undefined;
    const noColor = color === undefined;
    const noColorYear = colorYear === undefined;
    switch (typeOption) {
      case 'perfis': return noProfile;
      case 'grupos': return noGroup;
      case 'cadastros': return noRegistersYear;
      case 'cores':
        if (!noColor && noColorYear) {
          warning.current = 'Nenhum dado de ano foi encontrado para o filtro';
        }

        return noColor || noColorYear;
      default: return false;
    }
  }, [
    typeOption,
    profile,
    group,
    registrationYear,
    color,
    colorYear
  ]);

  const addFilter = useCallback(() => {
    setFieldEvents(old => {
      const oldPeople = old.reduce((people, fieldEvent) => {
        const { filter } = fieldEvent;
        if (filter && filter.type === 'Pessoas') {
          return [...people, fieldEvent];
        }

        // possivel regra para impedir filtros de serem duplicados
        // mesmo quando o filtro já faz parte de um filtro composto
        // if (filters && filters.length > 0) {
        //   const f = filters.filter(f => f.filter.type === 'Pessoas');
        //   return [...people, ...f];
        // }

        return people;
      }, []);
      const newItem = {
        type: 'Pessoas',
        name: typeOption,
      };

      if (typeOption === 'perfis') {
        newItem.profile = profiles.find(item => item.id === profile).label;
      }

      if (typeOption === 'cores') {
        newItem.color = colors.find(item => item.id === color).label;
        newItem.year = colorYear;
      }

      if (typeOption === 'grupos') {
        newItem.group = groups.find(item => item.id === group).label;
      }

      if (typeOption === 'cadastros') {
        newItem.year = registrationYear;
      }

      const label = Object.values(newItem).map(i => i.toUpperCase());
      // reset valores para ids
      if (chaveExisteEm(newItem, 'group')) newItem.group = group;
      if (chaveExisteEm(newItem, 'profile')) newItem.profile = profile;
      if (chaveExisteEm(newItem, 'color')) newItem.color = color;

      if (!oldPeople.find(item => item.label.join(', ') === label.join(', '))) {
        return [...old, { filter: newItem, label }];
      }
      return old;
    });
  }, [
    color,
    colors,
    colorYear,
    group,
    groups,
    profile,
    profiles,
    registrationYear,
    setFieldEvents,
    typeOption,
  ]);

  useEffect(() => {
    if (profiles.length > 0 && typeOption === 'perfis') {
      setProfile(profiles[0].id);
    } else if (colors.length > 0 && typeOption === 'cores') {
      setColor(colors[0].id);
    } else if (groups.length > 0 && typeOption === 'grupos') {
      setGroup(groups[0].id);
    } else if (registerYears.length > 0 && typeOption === 'cadastros') {
      setRegistrationYear(registerYears[0].id);
    } else if (colorsRegistersYears.length > 0 && typeOption === 'cores-anos') {
      setColorYear(colorsRegistersYears[0].id);
    }
  }, [
    colors,
    colorsRegistersYears,
    groups,
    profiles,
    registerYears,
    typeOption,
  ]);

  return (
    <>
      <FlexRowFormContainer>
        <Select
          label="Tipo"
          style={{
            margin: '0 10px 10px 0',
            minWidth: 100,
            maxWidth: 250,
          }}
          value={typeOption}
          onChange={({ target }) => setTypeOption(target.value)}
        >
          {/* TODO: adicionar Cores por Ano */}
          {peopleOptions.filter(item => item.id !== 'cores-anos')}
        </Select>

        {/* Formulário do Tipo Perfis */}
        {
          typeOption === 'perfis' && (
            <Select
              label="Perfil"
              style={{
                margin: '0 10px 10px 0',
                minWidth: 150,
                maxWidth: 300,
              }}
              disabled={profile === undefined}
              value={profile}
              onChange={({ target }) => setProfile(target.value)}
            >
              {
                profiles.sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                })
              }
            </Select>
          )
        }

        {/* Formulário do Tipo Cores */}
        {
          typeOption === 'cores' && (
            <>
              <Select
                label="Cor"
                style={{
                  margin: '0 10px 10px 0',
                  minWidth: 125,
                  maxWidth: 250,
                }}
                disabled={color === undefined}
                value={color}
                onChange={({ target }) => setColor(target.value)}
              >
                {
                  colors.sort((a, b) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                  })
                }
              </Select>
              <Select
                label="Ano"
                style={{
                  margin: '0 10px 10px 0',
                  minWidth: 100,
                  maxWidth: 200,
                }}
                disabled={colorYear === undefined}
                value={colorYear}
                onChange={({ target }) => setColorYear(target.value)}
              >
                {colorsRegistersYears}
              </Select>
            </>
          )
        }

        {/* Formulário do Tipo Grupos */}
        {
          typeOption === 'grupos' && (
            <Select
              label="Grupo"
              style={{
                margin: '0 10px 10px 0',
                minWidth: 150,
                maxWidth: 300,
              }}
              disabled={group === undefined}
              value={group}
              onChange={({ target }) => setGroup(target.value)}
            >
              {
                groups.sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                })
              }
            </Select>
          )
        }
        {/* Formulário do Tipo Cadastros */}
        {
          typeOption === 'cadastros' && (
            <Select
              label="Ano"
              style={{
                margin: '0 10px 10px 0',
                minWidth: 150,
                maxWidth: 300,
              }}
              disabled={registrationYear === undefined}
              value={registrationYear}
              onChange={({ target }) => setRegistrationYear(target.value)}
            >
              {registerYears}
            </Select>
          )
        }
        <Botao
          disabled={disableSelection}
          style={{
            backgroundColor: '#3498DB',
            color: '#fff',
            padding: '5px 10px',
          }}
          onClick={addFilter}
        >
          Selecionar
        </Botao>
        <WarningContainer>{disableSelection ? warning.current : ''}</WarningContainer>
      </FlexRowFormContainer>
    </>
  );
}

export default memo(PeopleTab);
