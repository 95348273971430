import React, {
  memo,
  useEffect,
  useContext,
  useCallback,
} from 'react';

import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  AppContext,
  DataContext,
} from '../../contexts';

import { MapProviderGroup } from '../../contexts/MapContext/ProviderGroup';
import FilterProviderGroup from '../../contexts/FilterContext/ProviderGroup';

import { ColorProvider } from '../../contexts/ColorContext';
import { ExportImageProvider } from '../../contexts/ExportImageContext';
import { FeedbackProvider } from '../../contexts/FeedbackContext';
import { SecondaryMenuProvider } from '../../contexts/SecondaryMenuContext';

import Map from './components/Map';

import {
  AuxiliarBox,
  Botao,
  HeaderBar,
  LateralMenu,
} from '../../components';

function MapModule() {
  const {
    isMobileDevice,
    setMenuClicked,
    setStateMenu,
  } = useContext(AppContext);

  const {
    invalidFilters,
    people,
  } = useContext(DataContext);

  const handleMenuClick = useCallback(() => {
    setStateMenu(old => {
      if (isMobileDevice) {
        return old === 'hidden' ? 'expanded' : 'hidden';
      }

      return old === 'minimized' ? 'expanded' : 'minimized';
    });
    setMenuClicked(old => !old);
  }, [isMobileDevice, setStateMenu, setMenuClicked]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * Esse useEffect monitora os filtros que nao retornaram resultados válidos
   * e cria componentes Snackbar para mostrar os filtros inválidos ao usuário
   */
  useEffect(() => {
    if (invalidFilters.length > 0) {
      closeSnackbar();
      invalidFilters.forEach(({ settings }) => {
        const closeButton = (key) => (
          <>
            {
              !isMobileDevice && (
                <IconButton
                  aria-label="close"
                  onClick={() => closeSnackbar(key)}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
            {
              (isMobileDevice || invalidFilters.length >= 3) && (
                <Botao
                  aria-label="close"
                  onClick={() => closeSnackbar()}
                  style={{
                    padding: '5px 10px',
                    fontSize: 13,
                  }}
                >
                  Fechar<br />Todos
                </Botao>
              )
            }
          </>
        );

        const filter = (
          <>
            {settings.subject.toUpperCase()}
            , {settings.subType.toUpperCase()}
            , {
              Object.values(settings.filterParams)
                .map(item => item.replace(',', ', '))
                .join(', ')
            }
          </>
        );

        const message = (
          <>
            Não há dados válidos para o filtro:<br />
            {filter}
          </>
        );

        enqueueSnackbar(message, {
          action: key => closeButton(key),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          persist: true,
          variant: 'error',
          style: {
            textAlign: 'left',
          }
        });
      });
    } else closeSnackbar();
  }, [closeSnackbar, enqueueSnackbar, invalidFilters, isMobileDevice, people]);

  return (
    <FeedbackProvider>
      <ColorProvider>
        <FilterProviderGroup>
          <SecondaryMenuProvider>
            <MapProviderGroup>
              <ExportImageProvider>
                <HeaderBar menuAction={handleMenuClick} />
                <Map />
                <AuxiliarBox />
                <LateralMenu />
              </ExportImageProvider>
            </MapProviderGroup>
          </SecondaryMenuProvider>
        </FilterProviderGroup>
      </ColorProvider>
    </FeedbackProvider>
  );
}

export default memo(MapModule);
