import React from 'react';

import { Botao } from '../../../../..';

function ActionsDialogoCores(props) {
  const { aplicar, permitirAplicar } = props;

  return (
    <Botao
      style={{
        backgroundColor: '#3498DB',
        color: '#fff',
        margin: '10px',
        padding: '5px 15px',
      }}
      onClick={aplicar}
      disabled={!permitirAplicar}
    >
      Aplicar
    </Botao>
  );
}

export default ActionsDialogoCores;
