import React, {
  useMemo,
  useContext,
  useCallback,
  useState
} from 'react';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { FeedbackContext, FilterContext } from '../../../../../../contexts';

import { Botao } from '../../../../../../components';

import FilterItem from './components/FilterItem';

import {
  CompoundFilterList,
  Explanation,
  FilterListContainer,
  HiddenDiv
} from './styles';

import { COMPOUND_FILTER_TYPES, compoundToString } from './assets/constants';

const COMPOUND_TO_FIELD_EVENT = Object.entries(COMPOUND_FILTER_TYPES);

function FilterList() {
  const { setFeedback } = useContext(FeedbackContext);

  const {
    fieldEvents,
    setFieldEvents,
  } = useContext(FilterContext);

  const [hiddenDivHover, setHiddenDivHover] = useState(NaN);

  const filters = useMemo(() => ({
    single: fieldEvents.filter(item => Array.isArray(item.label)),
    compound: fieldEvents.filter(item => typeof item.id === 'number'),
  }), [fieldEvents]);

  const openExplanation = useCallback(() => {
    setFeedback({
      type: 'info',
      title: 'Filtros compostos',
      message: (
        <Explanation>
          Os filtros compostos utilizam filtros distintos para caracterizar o dado final da resposta.
          Ao adicionar um novo filtro composto, um item correspondente será criada na lista.
          <ul>
            <li>Para construir um filtro composto, arraste os itens desejados para dentro da lista do filtro composto.</li>
            <li>Para retirar um item da lista de um filtro composto, arraste-o para o início da lista de filtros.</li>
            <li>O tipo do filtro composto mudará de acordo com os itens em sua lista.</li>
            <li className="exemplified-item">Caso o arraste seja inválido, o item arrastado retornará a sua posição inicial.<br /></li>
            <li>Filtros que não puderem ser arrastados não podem ser usados em filtros compostos.</li>
          </ul>
          Os filtros deste tipo devem seguir algumas regras para serem considerados válidos:
          <ol>
            <li>O filtro composto deve conter mais de um filtro.</li>
            <li>Nenhum dos filtros podem ser idênticos.</li>
          </ol>
        </Explanation>
      )
    });
  }, [setFeedback]);

  const addNewCompound = useCallback(() => {
    setFieldEvents(old => {
      const compound = old.filter(item => typeof item.id === 'number');
      if (compound.length > 0) {
        const id = compound.map(item => item.id).sort().pop() + 1;
        return [...old, { id, filters: [] }];
      }

      return [...old, { id: 1, filters: [] }];
    });
  }, [setFieldEvents]);

  const moveToSingle = useCallback((compoundId, fieldEvent) => {
    setFieldEvents(old => {
      const c = old.find(item => item.id === compoundId);
      const index = c.filters.findIndex(f => f.label.join(', ') === fieldEvent.label.join(', '));
      if (index > -1 && !old.find(f => f.label && (f.label.join(', ') === c.filters[index].label.join(', ')))) {
        const [f] = c.filters.splice(index, 1);

        if (c.filters.length > 0 && !c.filters.find(item => item.filter.type === f.filter.type)) {
          const removed = COMPOUND_FILTER_TYPES[c.type].filter(type => type !== f.filter.type);
          const newType = COMPOUND_TO_FIELD_EVENT
            .filter(([type, v]) => v.length === removed.length)
            .find(([type, v]) => v.every(i => removed.includes(i)));

          if (newType) {
            // c.type = type;
            [c.type] = newType;
          }
        } else if (c.filters.length === 0) {
          delete c.type;
        }

        old.push(f);
        return [...old]; // atualizar estado
      }

      return old; // nao atualiza estado
    });
  }, [setFieldEvents]);

  const moveToCompound = useCallback((fieldEvent, compoundId) => {
    setFieldEvents(old => {
      const c = old.find(item => item.id === compoundId);
      // se filtro cor, verificar se já existe na lista
      if (fieldEvent.filter.name === 'cores' && !!c.filters.find(f => f.filter.name === 'cores')) return old;

      const findex = old.findIndex(item => item.label && (item.label.join(', ') === fieldEvent.label.join(', ')));
      if (findex > -1) {
        if (c.filters.length === 0) {
          const entries = COMPOUND_TO_FIELD_EVENT.filter(([k, v]) => v.length === 1);
          const canChange = entries.find(([type, value]) => value.includes(fieldEvent.filter.type));

          if (!canChange) return old;
          // c.type = type;
          [c.type] = canChange;
        } else if (
          c.filters.length > 0
          // verifica se filtro é permitido no filtro composto
          && !COMPOUND_FILTER_TYPES[c.type].includes(fieldEvent.filter.type)
        ) {
          // se nao for permitido
          // verifica se existe outro tipo de filtro composto valido ao unir filtros atuais e novos
          const currentFieldEventTypes = COMPOUND_FILTER_TYPES[c.type];
          const entries = COMPOUND_TO_FIELD_EVENT
            .filter(([k, v]) => v.length === currentFieldEventTypes.length + 1);

          if (entries.length === 0) return old;
          const checks = [...currentFieldEventTypes, fieldEvent.filter.type];
          const canChange = entries.find(([type, value]) => checks.every(c => value.includes(c)));

          if (!canChange) return old;
          // c.type = type;
          [c.type] = canChange;
        }

        const [f] = old.splice(findex, 1);
        c.filters.push(f);
        return [...old]; // atualizar estado
      }

      return old; // nao atualiza estado
    });
  }, [setFieldEvents]);

  const changeBetweenCompound = useCallback((fromId, toId, fieldEvent) => {
    setFieldEvents(old => {
      const from = old.find(item => item.id === fromId);
      const to = old.find(item => item.id === toId);

      if (fieldEvent.filter.name === 'cores' && !!to.filters.find(f => f.filter.name === 'cores')) return old;

      if (!to.filters.some(f => f.label.join(', ') === fieldEvent.label.join(', '))) {
        if (to.filters.length === 0) {
          const entries = COMPOUND_TO_FIELD_EVENT.filter(([k, v]) => v.length === 1);
          const canChange = entries.find(([type, value]) => value.includes(fieldEvent.filter.type));

          if (!canChange) return old;
          // to.type = type;
          [to.type] = canChange;
        } else if (
          to.filters.length > 0
          // verifica se filtro é permitido no filtro composto
          && !COMPOUND_FILTER_TYPES[to.type].includes(fieldEvent.filter.type)
        ) {
          // se nao for permitido
          // verifica se existe outro tipo de filtro composto valido ao unir filtros atuais e novos
          const currentFieldEventTypes = COMPOUND_FILTER_TYPES[to.type];
          const entries = COMPOUND_TO_FIELD_EVENT
            .filter(([k, v]) => v.length === currentFieldEventTypes.length + 1);

          if (entries.length === 0) return old;
          const checks = [...currentFieldEventTypes, fieldEvent.filter.type];
          const canChange = entries.find(([type, value]) => checks.every(c => value.includes(c)));

          if (!canChange) return old;
          // to.type = type;
          [to.type] = canChange;
        }

        const findex = from.filters.findIndex(f => f.label.join(', ') === fieldEvent.label.join(', '));
        const [f] = from.filters.splice(findex, 1);

        if (from.filters.length > 0 && !from.filters.find(item => item.filter.type === f.filter.type)) {
          const removed = COMPOUND_FILTER_TYPES[from.type].filter(type => type !== f.filter.type);
          const newType = COMPOUND_TO_FIELD_EVENT
            .filter(([type, v]) => v.length === removed.length)
            .find(([type, v]) => v.every(i => removed.includes(i)));

          if (newType) {
            // from.type = type;
            [from.type] = newType;
          }
        } else if (to.filters.length === 0) {
          delete to.type;
        }

        to.filters.push(f);
        return [...old]; // atualizar estado
      }

      return old; // nao atualiza estado
    });
  }, [setFieldEvents]);

  const removeFilter = useCallback((fieldEventItem) => {
    setFieldEvents(old => {
      let findex = old.findIndex(item => item.label && (item.label.join(', ') === fieldEventItem.label.join(', ')));
      if (findex > -1) {
        old.splice(findex, 1);
      } else {
        const cindex = old.filter(item => item.id) // filtros compostos
          // verifica as listas
          .findIndex(item => item.filters.find(f => f.label.join(', ') === fieldEventItem.label.join(', ')));

        if (cindex > -1) {
          findex = old[cindex].filters.findIndex(f => f.label.join(', ') === fieldEventItem.label.join(', '));
          if (findex > -1) {
            old[cindex].filters.splice(findex, 1);

            if (old[cindex].filters.length === 0) {
              delete old[cindex].type;
            }
          }
        }
      }
      return [...old];
    });
  }, [setFieldEvents]);

  const removeCompoundFilter = useCallback((compoundFilter) => {
    setFieldEvents(old => {
      const removed = old.filter(item => (typeof item.id === 'number') ? item.id !== compoundFilter.id : true);
      return [...removed, ...compoundFilter.filters];
    });
  }, [setFieldEvents]);

  const handleDrag = useCallback((e) => {
    const touchDrag = e.type === 'touchmove' && e.touches.length === 1;
    if (touchDrag) {
      const touch = e.touches.item(0);
      const list = e.target.closest('#filter-list');

      const { x: listX, y: listY, width } = list.getBoundingClientRect();
      const endX = listX + width;

      const compounds = Array.from(list.querySelectorAll('.compound-item'));

      const [firstCompound] = compounds;
      const firstCompoundRect = firstCompound && firstCompound.getBoundingClientRect();
      if (firstCompound && firstCompoundRect.y <= touch.clientY) {
        // abaixo do primeiro composto
        const compound = compounds.find(compound => {
          const rect = compound.getBoundingClientRect();
          const endY = rect.y + rect.height;
          const touchOverList = listX <= touch.clientX && touch.clientX <= endX;
          const touchOverCompound = rect.y <= touch.clientY && touch.clientY <= endY;

          return (touchOverList && touchOverCompound);
        });

        setHiddenDivHover(compound ? Number(compound.dataset.id) : NaN);
      } else if (firstCompound) {
        // na lista de filtros individuais
        const touchOverList = listX <= touch.clientX && touch.clientX <= endX;
        const touchOverSingles = listY <= touch.clientY && touch.clientY < firstCompoundRect.y;

        setHiddenDivHover((touchOverList && touchOverSingles) ? 0 : NaN);
      } else {
        setHiddenDivHover(NaN);
      }
    }
  }, []);

  const handleDragStop = useCallback((e, data, fieldEvent) => {
    const { target } = e;
    const { node } = data;
    const fromCompound = node.closest('.compound-item'); // item arrastado tem origem de um filtro composto

    // em eventos de touch, essa variavel pode nao se comportar corretamente
    const draggedToCompound = target.closest('.compound-item');

    if (!fromCompound && draggedToCompound) {
      const { id } = draggedToCompound.dataset;
      moveToCompound(fieldEvent, Number(id));
    }

    if (fromCompound && (!target.matches('.compound-item *') && target.closest('#filter-list'))) {
      const { id } = fromCompound.dataset;
      moveToSingle(Number(id), fieldEvent);
    }

    if (fromCompound && draggedToCompound) {
      const { id: fromId } = fromCompound.dataset;
      const { id: toId } = draggedToCompound.dataset;
      changeBetweenCompound(Number(fromId), Number(toId), fieldEvent);
    }

    // se código padrão nao funcionar e for evento touch, executar fallback de touch
    const touchDrag = e.type === 'touchend';
    if (touchDrag) {
      const touch = e.changedTouches.item(0);
      const list = e.target.closest('#filter-list');

      const { x: listX, y: listY, width } = list.getBoundingClientRect();
      const listEndX = listX + width;

      const compounds = Array.from(list.querySelectorAll('.compound-item'));

      const [firstCompound] = compounds;
      const firstCompoundRect = firstCompound && firstCompound.getBoundingClientRect();
      if (firstCompound && firstCompoundRect.y <= touch.clientY) {
        // abaixo do primeiro composto
        compounds.some(compound => {
          const rect = compound.getBoundingClientRect();
          const endY = rect.y + rect.height;
          const touchOverList = listX <= touch.clientX && touch.clientX <= listEndX;
          const touchOverCompound = rect.y <= touch.clientY && touch.clientY <= endY;
          if (touchOverList && (touchOverCompound && fromCompound)) {
            const { id: fromId } = fromCompound.dataset;
            const { id: toId } = compound.dataset;
            changeBetweenCompound(Number(fromId), Number(toId), fieldEvent);
            return true;
          }

          if (touchOverList && (touchOverCompound && !fromCompound)) {
            const { id } = compound.dataset;
            moveToCompound(fieldEvent, Number(id));
            return true;
          }

          return false;
        });
      } else if (firstCompound) {
        // na lista de filtros individuais
        const touchOverList = listX <= touch.clientX && touch.clientX <= listEndX;
        const touchOverSingles = listY <= touch.clientY && touch.clientY < firstCompoundRect.y;
        if (touchOverList && (touchOverSingles && fromCompound)) {
          const { id } = fromCompound.dataset;
          moveToSingle(Number(id), fieldEvent);
        }
      }
    }
  }, [moveToCompound, moveToSingle, changeBetweenCompound]);

  const title = useMemo(() => {
    const len = filters.single.length + filters.compound.filter(item => item.filters.length).length;

    if (len === 0) return 'Nenhum filtro selecionado.';
    if (len === 1) return '1 filtro selecionado:';

    return `${len} filtros selecionados:`;
  }, [filters]);

  return (
    <FilterListContainer>
      <header>
        <h2>{title}</h2>
      </header>

      <div id="filter-list">
        <>
          {
            filters.single.map((fieldEvent, index) => (
              <FilterItem
                key={`filter-item-${index}`} // eslint-disable-line
                item={fieldEvent}
                onDelete={() => removeFilter(fieldEvent)}
                handleDrag={handleDrag}
                handleDragStop={(e, data) => handleDragStop(e, data, fieldEvent)}
              />
            ))
          }
          <HiddenDiv className={hiddenDivHover === 0 ? 'hover' : undefined} />
        </>

        {
          filters.compound.map((item) => (
            <CompoundFilterList
              key={`compound-item-${item.id}-with-${item.filters.length}`}
              data-id={item.id}
              className="compound-item"
              hasItems={item.filters.length}
            >
              <span>
                <RemoveCircleIcon onClick={() => removeCompoundFilter(item)} className="remove-handle" />
                {compoundToString(item)}
              </span>
              <div>
                {
                  item.filters.map((fieldEvent, index) => (
                    <FilterItem
                      key={`compound-${item.id}-filter-item-${index}`} // eslint-disable-line
                      item={fieldEvent}
                      onDelete={() => removeFilter(fieldEvent)}
                      handleDrag={handleDrag}
                      handleDragStop={(e, data) => handleDragStop(e, data, fieldEvent)}
                    />
                  ))
                }
                <HiddenDiv className={hiddenDivHover === item.id ? 'hover' : undefined} />
              </div>
            </CompoundFilterList>
          ))
        }
      </div>

      <footer>
        <Botao onClick={addNewCompound}>
          Adicionar filtro composto
        </Botao>
        <span onClick={openExplanation}>Como funciona?</span>
      </footer>
    </FilterListContainer>
  );
}

export default FilterList;
