import styled from 'styled-components';

export const FilterListContainer = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;

    margin-bottom: 30px;

    header h2 {
      font-size: 1rem;
      color: #3498db;
    }

    #filter-list {
      position: relative;
      z-index: 0;
      margin-bottom: 1rem;

      .remove-handle {
        color: #ff615f;
        cursor: pointer;

        &:hover {
          filter: brightness(1.3);
        }
      }

      & .filter-item {
        position: relative;
        z-index: 1;
      }

      & > .compound-item:has(+ .compound-item) {
        margin-bottom: 1rem;
      }

      &:has(.react-draggable-dragging) {
        cursor: grabbing;

        & .react-draggable-dragging {
          z-index: 2;
        }
      }
    }

    footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;

      & > .MuiButton-root {
        color: #3498db;
        background-color: transparent;
        border: 1px solid #3498db;

        .MuiTouchRipple-root {
          color: #cfcfcf;
        }
      }

      & > span {
        font-size: .85em;
        color: #999;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const HiddenDiv = styled.div`
  & {
    box-sizing: border-box;
    width: 100%;
    height: 0;
    max-height: 3rem;
    border: 1px dashed #606060;
    opacity: 0;

    transition: height, opacity;
    transition-duration: 300ms;

    &:has(+ .compound-item) {
      margin-bottom: 1rem;
    }
  }

  // hover with mouse
  #filter-list:has(.react-draggable-dragging):hover:not(:has(> .compound-item:hover)) > &,
  #filter-list:has(.react-draggable-dragging):hover:not(:has(> .compound-item:hover)):not(:has(> .filter-item)) > &,
  #filter-list:has(.react-draggable-dragging) > .compound-item:hover &,
  #filter-list:has(.react-draggable-dragging) > &:hover
  // touch events fix
  #filter-list:has(.react-draggable-dragging):not(:has(> .filter-item)) > &.hover,
  #filter-list:has(.react-draggable-dragging) > .compound-item &.hover,
  #filter-list:has(.react-draggable-dragging) > &.hover {
    height: 4rem;
    opacity: 1;
  }
`;

export const CompoundFilterList = styled.div`
  & {
    user-select: none;
    border: 2px dashed #606060;
    padding: .5rem;

    & > span {
      cursor: default;
      display: flex;
      align-items: center;

      width: 100%;
      ${props => props.hasItems ? 'margin: .25rem 0 .5rem;' : ''}

      position: relative;
      color: rgba(0, 0, 0, 0.6);

      &::after {
        display: ${props => !props.hasItems ? 'block' : 'none'};

        position: absolute;
        bottom: .25em;
        right: 0;
        line-height: 1rem;

        content: 'Arraste e solte aqui dentro';
        font-size: 12px;
        color: rgba(0, 0, 0, 0.3);
      }

      & > .remove-handle {
        margin: 0 10px 0 5px;
      }
    }
  }
`;

export const Explanation = styled.span`
  & {
    display: inline-block;
    font-size: .9rem;

    ul {
      padding: 0;
      list-style: none;
      margin-block: 1em;

      & > li {
        margin-block: .5em;

        &.exemplified-item::after {
          content: 'Ex: Adicionar mais de uma cor a um filtro composto de pessoas.';
          font-size: .75em;
        }
      }
    }

    ol {
      margin-block: .5em;
      padding-left: 1rem;

      & > li {
        margin-block: .1em;
      }
    }

  }

  div:has(> &) {
    text-align: left;
    line-height: normal;
  }
`;
