export { compoundToString } from '../../../../../../../contexts/FilterContext/assets/functions';

export const COMPOUND_FILTER_TYPES = {
  eventos: ['Eventos'],
  pessoas: ['Pessoas'],
  participantes: ['Pessoas', 'Eventos'],
  // politicos: ['Pessoas', 'Politico']
};

export const ALLOWED_FILTERS_TO_COMPOSE = [
  'perfis',
  'grupos',
  'cores',
  'efl',
  'svn',
];
