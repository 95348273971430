import React, { useCallback, useMemo, useRef, useState } from 'react';

import Draggable from 'react-draggable';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { FilterItemContainer } from './styles';

import { ALLOWED_FILTERS_TO_COMPOSE } from '../../assets/constants';

const INITIAL_POS = { x: 0, y: 0 };

/**
 * @typedef {object} FilterItemProps
 * @prop {FieldEventsFilterItem} item
 * @prop {() => void} item
 * @prop {Function} handleDragStop
 * @prop {boolean} allowDragOn
 *
 * @param {FilterItemProps} props do componente
 * @returns {JSX.Element} Componente individual usado em FilterList
 */
function FilterItem({ item, onDelete, handleDrag, handleDragStop }) {
  const refAbsolutePos = useRef({ ...INITIAL_POS });
  const [initialDrag, setInitialDrag] = useState(INITIAL_POS);

  const onDragStart = useCallback((e, data) => {
    const list = e.target.closest('#filter-list');
    const { x, y } = list.getBoundingClientRect();
    const { x: nodeX, y: nodeY } = data.node.getBoundingClientRect();

    let newX = NaN;
    let newY = NaN;
    refAbsolutePos.current.x = nodeX - x;
    refAbsolutePos.current.y = nodeY - y;

    const touchDrag = e.type === 'touchstart' && e.touches.length === 1;
    if (e.type === 'mousedown') {
      const mousePosX = e.clientX - x;
      const mousePosY = e.clientY - y;
      newX = mousePosX - refAbsolutePos.current.x;
      newY = mousePosY - refAbsolutePos.current.y;
    } else if (touchDrag) {
      const touch = e.touches.item(0);
      const touchPosX = touch.clientX - x;
      const touchPosY = touch.clientY - y;
      newX = touchPosX - refAbsolutePos.current.x;
      newY = touchPosY - refAbsolutePos.current.y;
    }

    if (newX && newY) setInitialDrag({ x: newX, y: newY });
  }, []);
  const onDrag = useCallback((e) => handleDrag(e), [handleDrag]);
  const onDragStop = useCallback((e, data) => {
    handleDragStop(e, data);
    setInitialDrag(INITIAL_POS);
  }, [handleDragStop]);

  const disableDrag = useMemo(() => !ALLOWED_FILTERS_TO_COMPOSE.includes(item.filter.name), [item]);

  const itemParts = Object.keys(item.filter);
  return (
    <Draggable
      handle=".filter-item"
      cancel=".remove-handle"
      bounds="#filter-list"
      position={initialDrag}
      onStart={onDragStart}
      onDrag={onDrag}
      onStop={onDragStop}
      disabled={disableDrag}
    >
      <FilterItemContainer className="filter-item" top={refAbsolutePos.current.y} left={refAbsolutePos.current.x}>
        <RemoveCircleIcon onClick={onDelete} className="remove-handle" />
        <span>
          {
            item.label.map((part, index) => (
              <span key={`filter-item-${part.toLowerCase()}`} className={`filter-item-part filter-item-${itemParts.at(index)}`}>
                {(index === item.label.length - 1) ? part : `${part}, `}
              </span>
            ))
          }
        </span>
        <div disabled={disableDrag || undefined}><DragHandleIcon className="drag-handle" /></div>
      </FilterItemContainer>
    </Draggable>
  );
}

export default FilterItem;
