import React, {
  memo,
  useCallback,
  useContext,
  // useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import {
  // AppContext,
  FilterContext,
} from '../../../../../../../contexts';

import {
  GridFormContainer,
  WarningContainer
} from '../styles';

import {
  Botao,
  Select
} from '../../../../../../../components';

function EmendasTab() {
  const {
    setFieldEvents,
    emendasFilters = {}
  } = useContext(FilterContext);

  const warning = useRef('');

  const { organOptions, yearsOptions } = useMemo(() => ({
    organOptions: [
      { id: 0, label: 'Todas' },
      ...(emendasFilters.organizacoes || []).map((org, i) => ({ id: i + 1, label: org }))
    ],
    yearsOptions: [
      { id: 'total', label: 'Todos' },
      ...(emendasFilters.anos || []).map(year => ({ id: year, label: year }))
    ]
  }), [emendasFilters]);

  const [organ, setOrgan] = useState(organOptions[0] ? organOptions[0].id : undefined);
  const [year, setYear] = useState(yearsOptions[0] ? yearsOptions[0].id : undefined);

  const [agent, setAgent] = useState('Todos');
  const agentOptions = useMemo(() => [
    'Todos',
    'Enrico Van Blarcum de Graaff Misasi',
    'Evandro Herrera Bertone Gussi',
    'Reinaldo de Souza Alguz',
  ], []);

  const disableSelection = useMemo(() => {
    const noOrgan = organ === undefined;
    const noYear = year === undefined;

    if (noOrgan) {
      warning.current = 'Nenhum dado foi encontrado para este filtro';
    } else if (noYear) {
      warning.current = 'Nenhum dado de ano foi encontrado para o filtro';
    }

    return noOrgan || noYear;
  }, [organ, year]);

  const addFilter = useCallback(() => {
    setFieldEvents(old => {
      const oldEmendas = old.reduce((emendas, fieldEvent) => {
        const { filter } = fieldEvent;
        if (filter && filter.type === 'Emendas') {
          return [...emendas, fieldEvent];
        }

        // possivel regra para impedir filtros de serem duplicados
        // mesmo quando o filtro já faz parte de um filtro composto
        // if (filters && filters.length > 0) {
        //   const f = filters.filter(f => f.filter.type === 'Emendas');
        //   return [...emendas, ...f];
        // }

        return emendas;
      }, []);
      const newItem = {
        type: 'Emendas',
        organ: organ !== 0 ? organOptions[organ].label : 'total',
        agent: agent !== 'Todos' ? agent : 'total',
        year: year !== 'Todos' ? year : 'total',
      };

      const label = Object.values(newItem).map(i => i.toUpperCase());

      if (!oldEmendas.find(item => item.label.join(', ') === label.join(', '))) {
        return [...old, { filter: newItem, label }];
      }
      return old;
    });
  }, [agent, organ, organOptions, setFieldEvents, year]);

  return (
    <>
      <GridFormContainer minmax="100%, 1fr">
        <Select
          label="Mandatário"
          value={agent}
          disabled={agent === undefined}
          onChange={({ target }) => setAgent(target.value)}
        >
          {agentOptions.map((item) => ({ id: item, label: item }))}
        </Select>

        <Select
          label="Organização"
          value={organ}
          disabled={organ === undefined}
          onChange={({ target }) => setOrgan(target.value)}
        >
          {organOptions}
        </Select>

        <Select
          label="Ano"
          value={year}
          disabled={year === undefined}
          onChange={({ target }) => setYear(target.value)}
        >
          {yearsOptions}
        </Select>
      </GridFormContainer>
      <WarningContainer>{disableSelection ? warning.current : ''}</WarningContainer>
      <Botao
        disabled={disableSelection}
        style={{
          backgroundColor: '#3498DB',
          color: '#fff',
          padding: '5px 10px',
        }}
        onClick={addFilter}
      >
        Selecionar
      </Botao>
    </>
  );
}

export default memo(EmendasTab);
