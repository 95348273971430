import { useCallback, useRef } from 'react';

const TIMEOUT_PADRAO = 800;
export default function useDebounce(callback, dependencies, timeout = TIMEOUT_PADRAO) {
  const refChamada = useRef(-1);

  const memoizedCallback = useCallback(callback, dependencies);

  const debounce = useCallback((...args) => {
    window.clearTimeout(refChamada.current);
    refChamada.current = window.setTimeout(() => {
      memoizedCallback(args);
    }, timeout);
  }, [memoizedCallback, timeout]);

  return debounce;
}
