import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';


import { SnackbarProvider } from 'notistack';

import Slide from '@material-ui/core/Slide';

import {
  ValidateSessionHandler
} from '../../api/handlers';

import { AppProvider } from '../../contexts/AppContext';
import { DataProvider } from '../../contexts/DataContext';

import {
  Loader,
  MapModule,
} from '../../components';

import NotAuthorizedScreen from './components/NotAuthorizedScreen';

/**
   * Essa função valida o token recebe na inicialização da aplicação
   * para autenticar a sessão do usuário
   *
   * @param token Token do usuário no aplicativo
   * @returns Retorna verdadeiro se o token for válido
   */
async function validateToken(token) {
  const handler = new ValidateSessionHandler(token);
  const result = await handler.execute();
  return result;
}

function Home() {
  const location = useLocation();

  const [validate, setValidate] = useState(undefined);

  const applicationToken = useMemo(() => (
    new URLSearchParams(location.search).get('session_token') || ''
  ), [location.search]);

  useEffect(() => {
    validateToken(applicationToken)
      .then(res => setValidate(res), err => console.error(err)); // eslint-disable-line
  }, [applicationToken]);

  // const module = useMemo(() => {
  //   switch (currentModule) {
  //     case 'map':
  //       return (
  //         <SnackbarProvider
  //           maxSnack={10}
  //           hideIconVariant
  //           preventDuplicate
  //           TransitionComponent={Slide}
  //         >
  //           <MapModule />;
  //         </SnackbarProvider>
  //       );
  //     default:
  //       return undefined;
  //   }
  // }, [currentModule]);

  return (validate === true)
    ? (
      <AppProvider token={applicationToken}>
        {
          (ctx) => (
            <DataProvider>
              <SnackbarProvider
                maxSnack={10}
                hideIconVariant
                preventDuplicate
                TransitionComponent={Slide}
              >
                <MapModule />
              </SnackbarProvider>
              <Loader show={ctx.isTaskRunning} />
            </DataProvider>
          )
        }
      </AppProvider>
    )
    : <NotAuthorizedScreen status={validate} />;
}

export default Home;
