import styled from 'styled-components';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const AbasWrapper = styled(Tabs)`
  &.MuiTabList-root {
    border-bottom: 1px solid #e8e8e8;

    .MuiTabList-scrollButtons {
      display: flex;
    }
  }
`;

export const ItemAba = styled(Tab)`
  &.MuiTab-root {
    text-transform: none;
    min-width: 72px;
    font-weight: bold;
    font-size: 16px;
    padding: 6px 16px;

    &:focus, &:hover {
      color: #40a9ff;
    }

    &:not(.Mui-selected) {
      opacity: 0.6;

      &:hover {
        opacity: 1
      }
    }

    &.Mui-selected {
      color: #1890ff;
    }
  }
`;

export const ConteudoAba = styled.div`
  & {
    margin-block-start: 1rem;
    padding-inline: 1rem;
  }
`;
