import React, {
  createContext,
  useCallback,
  useContext
} from 'react';

import {
  AppContext,
} from '..';

/**
 * @typedef {object} FilterLocationsContextType
 * @prop {getLocationsCallback} getAdmRegionsList
 * @prop {getLocationsCallback} getBaciasList
 * @prop {getCitiesLocationsCallback} getCitiesList
 * @prop {getDiocesesLocationsCallback} getDiocesesList
 * @prop {getLocationsCallback} getRegionsList
 */
/**
 * @type {React.Context<FilterLocationsContextType>}
 */
const FilterLocationsContext = createContext({});

export const FilterLocationsProvider = ({ children }) => {
  const {
    regions,
    dioceses,
    cities,
    bacias,
    admRegions,
  } = useContext(AppContext);

  /**
   * Essa função retorna a lista de regiões para o filtro
   * @returns Object[]
   */
  const getRegionsList = useCallback(() => (
    Object.entries(regions)
      .map(([key, value]) => ({ id: key, name: value['name'] }))
  ), [regions]);

  /**
   * Essa função retorna a lista de dioceses dentro em um lista de
   * regiões fornecidas como parâmetro de entrada
   * @param regions Object[] Lista de regiões
   * @returns Object[]
   */
  const getDiocesesList = useCallback((regions) => (
    Object.entries(dioceses)
      .filter(([, value]) => regions.map(item => Number(item.id)).includes(value['region']))
      .map(([key, value]) => ({ id: key, name: value['name'] }))
  ), [dioceses]);

  /**
   * Essa função retorna a lista de cidades dentro de um
   * conjunto de dioceses como parâmetro de entrada
   * @param type Tipo de localidade pai, pode ser 'diocese','admRegion' e 'bacia'.
   * @returns Object[]
   */
  const getCitiesList = useCallback((type, items) => (
    Object.entries(cities)
      .filter(([, value]) => items.map(item => Number(item.id)).includes(value[type]))
      .map(([key, value]) => ({ id: key, name: value['name'] }))
  ), [cities]);

  /**
   * Essa função retorna a lista de bacias para o filtro
   * @returns Object[]
   */
  const getBaciasList = useCallback(() => (
    Object.entries(bacias)
      .map(([key, value]) => ({ id: key, name: value['name'] }))
  ), [bacias]);

  /**
   * Essa função retorna a lista de regiões administrativas para o filtro
   * @returns Object[]
   */
  const getAdmRegionsList = useCallback(() => (
    Object.entries(admRegions)
      .map(([key, value]) => ({ id: key, name: value['name'] }))
  ), [admRegions]);

  return (
    <FilterLocationsContext.Provider
      value={{
        getRegionsList,
        getDiocesesList,
        getCitiesList,
        getAdmRegionsList,
        getBaciasList,
      }}
    >
      { children}
    </FilterLocationsContext.Provider>
  );
};

export default FilterLocationsContext;
