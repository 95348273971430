import React, {
  memo,
  useCallback,
  useMemo,
} from 'react';

import {
  CheckBox,
  FlexContainer,
} from '../../../../../../components';

import ExpandControlBox from '../ExpandControlBox';

function RegionsTab(props) {
  const {
    isActive,
    isolateRegions,
    setIsolateRegions,
    isolateDioceses,
    setIsolateDioceses,
    isolateCities,
    setIsolateCities,
  } = props;

  const updateRegion = useCallback((region, result) => {
    const items = Object.entries(isolateDioceses)
      .filter(([, diocese]) => diocese.region === region);
    const quantity = items.filter(([, diocese]) => diocese.status !== 'unchecked').length;

    if (result === 'checked' && quantity === items.length - 1) {
      setIsolateRegions(old => ({ ...old, [region]: { ...old[region], status: 'checked' } }));
    } else if (result === 'unchecked' && quantity === 1) {
      setIsolateRegions(old => ({ ...old, [region]: { ...old[region], status: 'unchecked' } }));
    } else {
      setIsolateRegions(old => ({ ...old, [region]: { ...old[region], status: 'incomplete' } }));
    }
  }, [isolateDioceses, setIsolateRegions]);

  const handleCheckDiocese = useCallback((id) => {
    setIsolateDioceses(old => {
      const result = old[id].status === 'unchecked' ? 'checked' : 'unchecked';
      const { region } = old[id];
      updateRegion(region, result);
      return {
        ...old,
        [id]: { ...old[id], status: old[id].status === 'unchecked' ? 'checked' : 'unchecked' }
      };
    });
  }, [setIsolateDioceses, updateRegion]);

  const updateDiocese = useCallback((diocese, result) => {
    const items = Object.entries(isolateCities).filter(([, city]) => city.diocese === diocese);
    const quantity = items.filter(([, city]) => city.status !== 'unchecked').length;

    setIsolateDioceses(old => {
      let value;
      if (result === 'checked' && quantity === items.length - 1) {
        value = 'checked';
      } else if (result === 'unchecked' && quantity === 1) {
        value = 'unchecked';
      } else {
        value = 'incomplete';
      }
      updateRegion(old[diocese].region, value);
      return { ...old, [diocese]: { ...old[diocese], status: value } };
    });
  }, [isolateCities, setIsolateDioceses, updateRegion]);

  const handleCheckCity = useCallback((id) => {
    setIsolateCities(old => {
      const result = old[id].status === 'unchecked' ? 'checked' : 'unchecked';
      const { diocese } = old[id];
      updateDiocese(diocese, result);
      return {
        ...old,
        [id]: { ...old[id], status: result }
      };
    });
  }, [setIsolateCities, updateDiocese]);

  const handleMaximizedRegion = useCallback((id) => {
    setIsolateRegions(old => ({
      ...old,
      [id]: { ...old[id], maximized: !old[id].maximized }
    }));
  }, [setIsolateRegions]);

  const handleMaximizedDiocese = useCallback((id) => {
    setIsolateDioceses(old => ({
      ...old,
      [id]: { ...old[id], maximized: !old[id].maximized }
    }));
  }, [setIsolateDioceses]);

  const handleCheckRegion = useCallback((id) => {
    setIsolateRegions(old => ({
      ...old,
      [id]: { ...old[id], status: old[id].status === 'unchecked' ? 'checked' : 'unchecked' }
    }));
  }, [setIsolateRegions]);

  const content = useMemo(() => (
    Object.entries(isolateRegions).map(([idRegion, region]) => (
      <React.Fragment key={`fragment-${idRegion}`}>
        <FlexContainer
          key={`div-region-${idRegion}`}
          align="center"
        >
          <CheckBox
            key={`region-${idRegion}`}
            checked={region.status === 'checked'}
            onClick={() => handleCheckRegion(idRegion)}
            label={region.name}
            indeterminate={region.status === 'incomplete'}
          />
          <ExpandControlBox
            maximized={region.maximized}
            onClick={() => handleMaximizedRegion(idRegion)}
          />
        </FlexContainer>
        {
          region.maximized
          && Object.entries(isolateDioceses)
            .filter(([, item]) => item.region === Number(idRegion))
            .map(([idDiocese, diocese]) => (
              <React.Fragment key={`rf-${idDiocese}`}>
                <FlexContainer
                  key={`div-diocese-${idDiocese}`}
                  align="center"
                  style={{ marginLeft: 20 }}
                >
                  <CheckBox
                    key={`diocese-${idDiocese}`}
                    checked={diocese.status === 'checked'}
                    onClick={() => handleCheckDiocese(idDiocese)}
                    label={diocese.name}
                    indeterminate={diocese.status === 'incomplete'}
                  />
                  <ExpandControlBox
                    maximized={diocese.maximized}
                    onClick={() => handleMaximizedDiocese(idDiocese)}
                  />
                </FlexContainer>
                {
                  diocese.maximized
                  && Object.entries(isolateCities)
                    .filter(([, item]) => item.diocese === Number(idDiocese))
                    .map(([idCity, city]) => (
                      <FlexContainer
                        key={`div-cidade-${idCity}`}
                        align="center"
                        style={{ marginLeft: 40 }}
                      >
                        <CheckBox
                          key={`city-${idCity}`}
                          checked={city.status === 'checked'}
                          onClick={() => handleCheckCity(idCity)}
                          label={city.name}
                        />
                      </FlexContainer>
                    ))
                }
              </React.Fragment>
            ))
        }
      </React.Fragment>
    ))
  ), [handleCheckCity, handleCheckDiocese, handleCheckRegion, handleMaximizedDiocese,
    handleMaximizedRegion, isolateCities, isolateDioceses, isolateRegions]);

  return isActive ? content : <></>;
}

export default memo(RegionsTab);
