/**
 * Opções de mostrar nomes usado no LateralMenu
 */
export const namesOptions = [
  {
    label: 'Bacias',
    key: 'showBacias',
  },
  {
    label: 'Cidades',
    key: 'showCities',
  },
  {
    label: 'Dioceses',
    key: 'showDioceses',
  },
  {
    label: 'Regiões',
    key: 'showRegions',
  },
  {
    label: 'Regiões administrativas',
    key: 'showAdmRegions',
  }
];
