import Resource from './Resource';

export default class GetCitiesResource extends Resource {
  async result() {
    try {
      const { data, status } = await this.request.get('/cidades');
      if (status === 200) {
        return data.reduce((total, item) => ({
          ...total,
          [item.id_cidade]: {
            region: item.id_regiao,
            diocese: item.id_diocese,
            name: item.nome,
            admRegion: item.id_regiao_adm,
            bacia: item.id_bacia,
          }
        }), {});
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return {};
  }
}
