import Resource from './Resource';

export default class GetRegionsResource extends Resource {
  async result() {
    try {
      const { data, status } = await this.request.get('/regioes');
      if (status === 200) {
        return data.reduce((total, item) => ({
          ...total,
          [item.id_regiao]: {
            name: item.nome,
            uf: item.uf,
          }
        }), {});
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return {};
  }
}
