import Resource from './Resource';

export default class GetAvailableFiltersResource extends Resource {
  async result() {
    try {
      const { data, status } = await this.request.get('/filtros');
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return {};
  }
}
