import styled from 'styled-components';

import { ChromePicker } from 'react-color';

import ListItemButton from '@mui/material/ListItemButton';

import { Botao } from '../../../../..';

import { CaixaSeletorDeCor } from '../../styles';

export const BotaoItemArvore = styled(ListItemButton)`
  &.MuiListItemButton-root {
    align-items: flex-start;
    justify-content: flex-start;

    padding-block: .25rem .125rem;
    padding-inline: .25rem !important;

    &.item-selecionado {
      color: #1890ff;
    }

    &.item-nao-selecionado {
      color: #000;
    }

    &.item-incompleto {
      color: #1976ff;
    }

    & > .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;

      margin-inline: .25rem;
      fill: #dc143c;
    }
  }
`;

const ESTADO_COR_PROPS = { WrapperProps: { title: 'Cor selecionada' }, disabled: true };
export const EstadoCorItemArvore = styled(CaixaSeletorDeCor).attrs(ESTADO_COR_PROPS)`
  & {
    border-radius: 50%;
    cursor: default;
    color: #000;
  }
`;

export const RotuloItemArvore = styled.span`
  & {
    margin-left: .75rem;
  }
`;

export const VisibilidadeWrapper = styled.div`
  & {
    display: flex;
    flex-flow: column;

    & > .MuiFormControl-root:first-child {
      min-width: 200px;
      align-self: flex-start;
    }
  }
`;

export const ArvoreWrapper = styled.div`
  & {
    &:before {
      content: 'Clique em um item para selecionar os itens correspondentes';

      display: inline-block;
      margin-block: 1rem .5rem;

      font-size: 14px;
    }
  }
`;

export const CorSelecaoSimultanea = styled.div`
  & {
    font-size: 14px;
    margin-block-start: .5rem;

    & > span {
      display: inline-flex;
      align-items: center;
    }
  }
`;

export const CaixaSeletorDeCorInline = styled.div`
  & {
    display: inline-block;

    position: relative;
    margin-inline: .7rem;
    border: solid 2px;
    width: 1.5em;
    height: 1.5em;

    background-color: ${props => props.cor};
  }
`;

export const SeletorCorWrapper = styled.div`
  & {
    &:focus,
    &:focus-within {
      .chrome-picker {
        opacity: 1;
        transform: translate(2rem, -50%) scale(1);

        @media screen and (max-width: 640px) {
          & {
            opacity: 1;
            transform: translate(-104%, -50%) scale(1);
          }
        }
      }
    }

    &:not(:focus, :focus-within) {
      .chrome-picker {
        opacity: 0;
        transform: translate(2rem, -50%) scale(0);

        @media screen and (max-width: 640px) {
          & {
            opacity: 1;
            transform: translate(-104%, -50%) scale(1);
          }

          & {
            opacity: 0;
            transform: translate(-104%, -50%) scale(0);
          }
        }
      }
    }
  }
`;

export const SeletorCor = styled(ChromePicker)`
  & {
    position: fixed;
    top: ${props => props.top}px;
    left: ${props => props.left}px;

    transition:
      opacity 270ms cubic-bezier(0.4, 0, 0.2, 1),
      transform 270ms cubic-bezier(0.4, 0, 0.2, 1);

    transform: translate(2rem, -50%); // posicao padrao
    transform-origin: left center;

    @media screen and (max-width: 640px) {
      transform: translate(-104%, -50%); // posicao padrao
      transform-origin: right center;
    }
  }
`;

export const BotaoLimparSelecao = styled(Botao)`
  &.MuiButton-root {
    display: block;
    padding-block: .25rem 0;
    padding-inline: 0;
  }
`;
