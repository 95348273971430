import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

export const StyledFeedback = styled(Dialog)`
  &.MuiModal-root {
    z-index: 2000;

    .MuiPaper-root.MuiDialog-paper {
      margin: 0;
      min-height: 150px;
      max-width: 360px;
      max-height: 360px;

      &.alert {
        background-color: #ff9800;
        color: #222;
      }

      &.info {
        background-color: #3498db;
        color: #fff;
        max-width: 420px;
        max-height: 500px;

        @media screen and (max-width: 560px) {
          max-width: 360px;
          max-height: none;
        }

        @media screen and (max-width: 360px) {
          margin: 0 1.5rem;
        }
      }

      &.default {
        background-color: #505050;
        color: #fff;
      }
    }

    .MuiDialogContent-root,
    .MuiDialogActions-root {
      box-sizing: border-box;
    }

    .MuiDialogContent-root {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      padding: 0 1rem;

      p {
        padding: 0 .5rem;
      }
    }

    .MuiDialogActions-root {
      justify-content: center;
      padding: 0 .5rem .5rem;

      & button {
        color: currentColor;
      }
    }
  }
`;

export const DialogIconTitle = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: .5rem 0;

    .feedbackIcon svg {
      font-size: 2rem;
    }

    h3 {
      margin-block: 0;
      margin-left: .5rem;
      padding: 0;
      display: inline-block;
    }
  }
`;

export const TextBlock = styled.div`
  & {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;

    padding: 0 1rem;
  }
`;
