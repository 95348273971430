import { useCallback, useReducer } from 'react';

/**
 * @template {"ALTERA" | "PERSISTE" | "LIMPA" | "ALTERA_E_PERSISTE"} Tipo
 * @typedef {{ tipo: Tipo, [dado: string]: Record<string, any> }} StorageAction<Tipo>
 */

/**
 * Ação de alteração do estado do redutor.
 *
 * **Altera valores no objeto sem persistir no storage.**
 *
 * @overload
 * @param {any} atual
 * @param {StorageAction<"ALTERA">}
 */
/**
 * Ação de persistencia do estado do redutor.
 *
 * **Grava/persiste os valores do estado do redutor no storage.**
 * @overload
 * @param {any} atual
 * @param {StorageAction<"PERSISTE">}
 */
/**
 * Ação de limpeza do estado do redutor.
 *
 * **Limpa os valores do estado do redutor e no storage.**
 * @overload
 * @param {any} atual
 * @param {StorageAction<"LIMPA">}
 */
/**
 * @overload
 * @param {any} atual Estado atual do redutor
 * @param {StorageAction<"ALTERA" | "PERSISTE" | "LIMPA">} action Ação do redutor
 * @returns Novo estado do redutor
 */
function reducerBase(atual, { tipo, ...dados }) {
  switch (tipo) {
    case 'ALTERA':
      return { ...atual, ...dados };
    case 'LIMPA':
      return {};
    default:
      return atual;
  }
}

/**
 * Hook para manipular valores de objetos em storage,
 * que monitora multiplas alterações,
 * e as persiste programaticamente.
 *
 * @param {import('../utils/storage').default} storage Objeto storage a ser monitorado
 * @returns
 */
export default function useStorage(storage, valorInicial = {}) {
  /** @type {typeof reducerBase} */
  const reducer = useCallback((atual, { tipo, ...dados }) => {
    switch (tipo) {
      case 'LIMPA':
        storage.limpa();
        break;
      case 'PERSISTE':
        storage.set(atual);
        break;

      case 'ALTERA_E_PERSISTE':
        return reducer(reducerBase(atual, { tipo: 'ALTERA', ...dados }), { tipo: 'PERSISTE' });
      default:
        break;
    }
    return reducerBase(atual, { tipo, ...dados });
  }, [storage]);

  const [, dispatch] = useReducer(
    reducer,
    storage.get() || {},
    (arg) => ({ ...valorInicial, ...arg })
  );

  return dispatch;
}
