import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import {
  AppContext,
  DataContext,
  FeedbackContext,
} from '..';

import NoArvoreExpansivel from '../../components/ArvoreExpansivel/assets/NoArvoreExpansivel';

import WindowStorage from '../../utils/storage';

import {
  chaveExisteEm,
  emptyObject,
  hexaParaRgb,
} from '../../utils';

import './ContextTypes';

const storageDialogoCores = WindowStorage.localStorage('dialogo-cores');

const GRADIENTE_INICIAL = {
  tipo: 'gradiente',
  cor: '#00991c',
};

const POR_FILTRO_INICIAL = {
  tipo: 'por-filtro',
  regras: [{
    id: 1,
    cor: '#0B8209',
    item: '',
  }],
};

const POR_REGIAO_INICIAL = {
  tipo: 'por-regiao',
  regras: new NoArvoreExpansivel([], []).clonarSemReferenciaCiclica(),
};

const SUGESTAO_CORES = [
  '#0B8209',
  '#A28A1A',
  '#8A1002',
  '#1033AA',
  '#FF5500',
  '#00484B',
];

function getCorPorId(id) {
  if (SUGESTAO_CORES[id - 1]) return SUGESTAO_CORES[id - 1];

  let seed;
  do { seed = Math.random(); } while (seed === 0);

  const cor = Math.round(seed * 0xffffff);
  return `#${(cor).toString(16).padStart(6, '0')}`;
}

/**
 * @typedef {object} ColorContextType
 * @prop {Record<number, string[]>} coresCidades
 * Regras de cores aplicadas a cada cidade
 * @prop {number} qntdFiltrosSemCor
 * Quantidade de filtros sem cor definida
 * @prop {RegraDeColoracao} metodoColoracao
 * Estado que armazena o método de coloração aplicado atualmente
 * @prop {(valor: number) => string} calcularGradienteDoValor
 * Callback que retorna uma cor validando a regra aplicada atualmente (Gradiente e Escala de Cores)
 * @prop {boolean} isDialogoCoresVisivel
 * Booleno que define se o DialogoCores está aberto
 * @prop {SetState<Record<number, string[]>} setCoresCidades
 * SetState para o estado `coresCidades`
 * @prop {SetState<boolean>} setDialogoCoresVisivel
 * SetState para o estado `isDialogoCoresVisivel`
 * @prop {SetState<RegraDeColoracao>} setMetodoColoracao
 * SetState para o estado `colorMode`
 */
/**
 * @type {React.Context<ColorContextType>}
 */
const ColorContext = createContext({});

export const ColorProvider = ({ children }) => {
  const {
    activeFilters,
    filterOptions,
    hasViewLoading,
  } = useContext(AppContext);

  const {
    getResult,
  } = useContext(DataContext);

  const { setFeedback } = useContext(FeedbackContext);

  const [isDialogoCoresVisivel, setDialogoCoresVisivel] = useState(false);

  /**
   * @type {[RegraDeColoracao, SetState<RegraDeColoracao>]}
   */
  const [metodoColoracao, setMetodoColoracao] = useState(() => {
    if (storageDialogoCores.existe()) {
      if (!storageDialogoCores.validaDataStore('2024-03-06')) return POR_REGIAO_INICIAL;

      const salvo = storageDialogoCores.get('metodoColoracao');
      if (salvo.tipo === 'por-regiao') {
        salvo.regras = new NoArvoreExpansivel(salvo.regras)
          .clonarSemReferenciaCiclica();
      }

      return salvo;
    }

    return POR_REGIAO_INICIAL;
  });

  /**
   * Este estado armazena a relação cidade-cor atualmente aplicada no mapa
   * @type {[{ [idcidade: string]: string[] }, SetState<{ [idcidade: string]: string[] }>]}
   */
  const [coresCidades, setCoresCidades] = useState({});

  const dadosFiltro = useMemo(() => getResult(), [getResult]);

  /**
   * Esse useEffect hook verifica se o modo de coloração é compatível com o filtro mais recente.
   * Se for compativel, mantem as regras,
   * caso contrário atualiza reras para um modo compativel aos filtros ativos (ou desativados)
   */
  useEffect(() => {
    const filtroSimplesInicial = (
      (storageDialogoCores.existe() && storageDialogoCores.get('corGradiente'))
        ? { tipo: 'gradiente', cor: storageDialogoCores.get('corGradiente') }
        : GRADIENTE_INICIAL
    );

    const porFiltroInicial = (
      (storageDialogoCores.existe() && storageDialogoCores.get('corPorFiltro'))
        ? { tipo: 'por-filtro', regras: storageDialogoCores.get('corPorFiltro') }
        : POR_FILTRO_INICIAL
    );

    const porRegiaoInicial = (
      (storageDialogoCores.existe() && storageDialogoCores.get('porRegiao'))
        ? { tipo: 'por-regiao', regras: new NoArvoreExpansivel(storageDialogoCores.get('porRegiao')) }
        : POR_REGIAO_INICIAL
    );

    setMetodoColoracao(atual => {
      if (activeFilters.length === 0) {
        return porRegiaoInicial;
      }

      if (atual.tipo === 'por-regiao') {
        return activeFilters.length <= 1 ? filtroSimplesInicial : porFiltroInicial;
      }

      if (activeFilters.length <= 1 && atual.tipo === 'por-filtro') return filtroSimplesInicial;
      if (activeFilters.length > 1 && atual.tipo !== 'por-filtro') return porFiltroInicial;
      return atual;
    });
  }, [activeFilters.length]);

  const getCorDeFiltroCor = useCallback(([anoCor, corId]) => {
    const cor = filterOptions.pessoas.cores.cores
      .find(color => color.id_cor === Number(corId)).cor_hexa;
    const anos = filterOptions.pessoas['cores-anos'].anos
      .map(year => Number(year))
      .sort();
    const opacidade = 1 - ((anos.pop() - anoCor) * 0.06);
    const { r, g, b } = hexaParaRgb(cor);
    return `rgba(${r},${g},${b},${opacidade})`;
  }, [filterOptions]);

  useEffect(() => {
    setMetodoColoracao(atual => {
      if (atual.tipo === 'por-filtro' && !hasViewLoading.current) {
        const salvo = storageDialogoCores.existe() && storageDialogoCores.get('corPorFiltro');

        if (salvo && salvo.length > 0) {
          const regrasValidasDaStore = salvo
            .map((regra, index) => {
              const objeto = {
                id: index + 1,
                cor: regra.cor,
                item: regra.item,
                desabilitar: regra.item !== '' && !activeFilters.some(({ settings }) => regra.item === settings.title.join(', '))
              };

              if (chaveExisteEm(regra, 'intervalo') && regra.intervalo) {
                objeto.intervalo = { ...regra.intervalo };
              }

              return objeto;
            });

          return { tipo: 'por-filtro', regras: regrasValidasDaStore };
        }

        const regras = activeFilters.map(({ settings }, index) => {
          const id = index + 1;
          const cor = settings.subType.match(/cores/)
            ? getCorDeFiltroCor([settings.filterParams.ano, settings.filterParams.cor])
            : getCorPorId(id);
          return { id, cor, item: settings.title.join(', ') };
        });

        return { tipo: 'por-filtro', regras };
      }

      return atual;
    });
  }, [
    activeFilters,
    getCorDeFiltroCor,
    hasViewLoading,
  ]);

  const intervaloResultadoFiltro = useMemo(() => {
    if (dadosFiltro.result) {
      const items = Object.values(dadosFiltro.result);
      return {
        min: 0,
        max: Math.max(...items),
      };
    }
    return { min: 0, max: 1 };
  }, [dadosFiltro.result]);

  const calcularGradienteDoValor = useCallback((valor) => {
    const { r, g, b } = hexaParaRgb(metodoColoracao.cor);
    const diferenca = intervaloResultadoFiltro.max - intervaloResultadoFiltro.min;
    const proporcao = diferenca !== 0
      ? (valor - intervaloResultadoFiltro.min) / diferenca
      : 1;
    return `rgba(${r},${g},${b},${1 - (1 - proporcao) * 0.9})`;
  }, [intervaloResultadoFiltro, metodoColoracao.cor]);

  /**
   * Essa função calcula a cor resultante com base nas cor
   * selecionada na categoria de gradiente
   */
  const calcularGradiente = useCallback(() => {
    return Object.entries(dadosFiltro.result || {}).reduce((total, [chave, valor]) => {
      const cor = calcularGradienteDoValor(valor);
      if (cor) return { ...total, [chave]: [cor] };
      return total;
    }, {});
  }, [calcularGradienteDoValor, dadosFiltro.result]);

  /**
   * Essa função identifica a cor com base no valor
   * na categoria de coloração de intervalos
   */
  const getIntervaloCores = useCallback(() => {
    function calcularDoValor(valor) {
      return metodoColoracao.regras.reduce((total, item) => {
        if (item.de <= valor && valor <= (item.ate || Infinity)) {
          return item.cor;
        }
        return total;
      }, '#000');
    }

    return Object.entries(dadosFiltro.result || {}).reduce((total, [chave, valor]) => {
      const cor = calcularDoValor(valor);
      if (cor) return { ...total, [chave]: [cor] };
      return total;
    }, {});
  }, [metodoColoracao.regras, dadosFiltro.result]);

  /**
   * @callback getColorByFilterCallback
   * @returns {void}
   *
   * Callbacbk que define regra de cores para o mapa
   * usando a regra de Cor por Filtro
   * @type {getColorByFilterCallback}
   */
  const getCorPorFiltro = useCallback(() => {
    // Mapeamento dos objetos que relacionam cidade e cor
    const coresCidadesPorRegra = metodoColoracao.regras.map((regra) => {
      // seleciona filtro baseado na regra e pega seu resultados (valores)
      const { result } = activeFilters.find(({ settings }) => regra.item === settings.title.join(', ')) || {};

      if (result) {
        // criação do objeto na forma { [idCidade]: [cor da regra], ... }
        const coresCidades = Object.entries(result)
          .reduce((total, [idCidade, valor]) => {
            if (chaveExisteEm(regra, 'intervalo')) {
              const { de, ate } = regra.intervalo;
              const valorNoIntervalo = de <= valor && valor <= ate;
              if (!valorNoIntervalo) return total;
            }
            return {
              ...total,
              [idCidade]: [regra.cor],
            };
          }, {});

        return coresCidades;
      }

      return {};
    });

    // união das regras criadas em um único objeto que relaciona uma cidade com diversas cores
    const coresCidadesFinal = coresCidadesPorRegra.reduce((coresCidades, regraCoresCidades) => {
      if (emptyObject(coresCidades)) return regraCoresCidades;

      const uniaoRegrasCoresCidades = Object.entries(regraCoresCidades)
        .reduce((uniaoRegras, [idCidade, cores]) => {
          if (`${idCidade}` in uniaoRegras) {
            // união das cores de regras diferentes
            return { ...uniaoRegras, [idCidade]: [...uniaoRegras[idCidade], ...cores] };
          }
          // senao aplica as regras apenas desta regra
          return { ...uniaoRegras, [idCidade]: cores };
        }, coresCidades);

      // contrução do objeto final,
      // novas cores são adicionadas às cidades do objeto
      // e novas cidades são adicionadas no objeto
      return uniaoRegrasCoresCidades;
    }, {});

    return coresCidadesFinal;
  }, [activeFilters, metodoColoracao.regras]);

  const qntdFiltrosSemCor = useMemo(() => {
    const semCor = [];

    if (metodoColoracao.tipo === 'por-filtro') {
      activeFilters.forEach(filtro => {
        const item = filtro.settings.title.join(', ');

        if (!semCor.includes(item)) {
          const temCor = metodoColoracao.regras.some(regra => regra.item === item);
          if (!temCor) semCor.push(item);
        }
      });
      return semCor.length;
    }
    return 0;
  }, [activeFilters, metodoColoracao.tipo, metodoColoracao.regras]);

  /** Referencia que controla quando o feedback de cor pode aparecer */
  const refFeedbackDeveAparecer = useRef(true);

  /**
   * Efeito que constrói o aviso de filtros sem cor ao usar filtros no mapa.
   *
   * O aviso atualmente aparece apenas uma vez quando novos filtros são feitos,
   * se houverem filtros sem cores.
   */
  useEffect(() => {
    if (refFeedbackDeveAparecer.current && qntdFiltrosSemCor > 0) {
      const singularOuPlural = (
        qntdFiltrosSemCor > 1
          ? `Existem ${qntdFiltrosSemCor} filtros`
          : 'Existe 1 filtro'
      );

      setFeedback({
        action: 'yesOrNo',
        message: <>{singularOuPlural} sem cor.<br />Deseja definir cores agora?</>,
        type: 'alert',
        onConfirm: () => setDialogoCoresVisivel(true)
      });
      refFeedbackDeveAparecer.current = false;
    }
  }, [
    qntdFiltrosSemCor,
    setFeedback,
    setDialogoCoresVisivel,
  ]);

  useEffect(() => {
    refFeedbackDeveAparecer.current = true;
  }, [activeFilters.length]);

  // Monitora as regras de coloração para filtros simples
  useEffect(() => {
    setCoresCidades((atual) => {
      if (dadosFiltro.result) {
        if (metodoColoracao.tipo === 'gradiente') return calcularGradiente();
        if (metodoColoracao.tipo === 'escala-cores') return getIntervaloCores();
      }
      return atual;
    });
  }, [metodoColoracao.tipo, calcularGradiente, dadosFiltro.result, getIntervaloCores]);

  // Monitora as regras de coloração para filtros simultaneos
  useEffect(() => {
    setCoresCidades(atual => {
      if (metodoColoracao.tipo === 'por-filtro') return getCorPorFiltro();
      return atual;
    });
  }, [metodoColoracao.tipo, getCorPorFiltro]);

  useEffect(() => {
    setCoresCidades(atual => {
      if (metodoColoracao.tipo === 'por-regiao') {
        return metodoColoracao.regras.getNosEntidade('cidades')
          .reduce((total, cidade) => {
            if (chaveExisteEm(cidade.dados, 'cor') && cidade.dados.cor !== '') return { ...total, [cidade.dados.id]: [cidade.dados.cor] };
            return total;
          }, {});
      }
      return atual;
    });
  }, [metodoColoracao.tipo, metodoColoracao.regras]);

  return (
    <ColorContext.Provider
      value={{
        coresCidades,
        qntdFiltrosSemCor,
        metodoColoracao,
        calcularGradienteDoValor,
        isDialogoCoresVisivel,
        setMetodoColoracao,
        setDialogoCoresVisivel,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

export default ColorContext;
