import styled from 'styled-components';

import Button from '@mui/material/Button';

/**
 * @type {StyledComponent<typeof Button, {}>}
 */
export const BotaoPadrao = styled(Button)`
  &.MuiButton-root {
    padding: .375rem .5rem;
    border-radius: 4px;

    font-weight: bold;
    font-size: 14px;

    cursor: pointer;

    &.Mui-disabled {
      cursor: not-allowed;
      color: #999;
      background-color: #ddd;
    }
  }
`;
