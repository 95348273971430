import styled from 'styled-components';

import RadioGroup from '@mui/material/RadioGroup';

import ColorPickerPopper from './components/ColorPickerPopper';

import { ConteudoAba } from '../../../Abas/styles';

export const AbaWrapper = styled(ConteudoAba)`
  & {
    padding: 0;
  }
`;

export const ConteinerRegrasCores = styled(RadioGroup)`
  &.MuiFormGroup-root {
    & > .MuiFormControlLabel-root {
      padding-block: .5rem;
      width: 100%;
    }

    .MuiCollapse-root {
      width: max-content;
    }
  }
`;

/**
 * @typedef {object} CaixaSeletorDeCorProps
 * @prop {CSSProps["width"]} tamanho
 * @prop {CSSProps["transform"]} popperTransform
 */
/**
 * @type {StyledComponent<typeof ColorPickerPopper, CaixaSeletorDeCorProps>}
 */
export const CaixaSeletorDeCor = styled(ColorPickerPopper)`
  & {
    display: flex;

    position: relative;
    border: solid 2px;
    width: ${props => props.tamanho || '1em'};
    height: ${props => props.tamanho || '1em'};

    background-color: ${props => props.color};

    & [name=color-picker] {
      padding: 0;
      cursor: pointer;
    }

    .chrome-picker {
      position: fixed;
      transform: ${props => props.popperTransform || 'translate(0, -50%)'};
    }
  }
`;

export const PendenteAplicar = styled.span`
  & {
    display: inline-block;

    color: #dc143c;
    font-size: .8rem;
    font-weight: bold;

    &:not(:empty) {
      margin-block-start: .75rem;
    }
  }
`;
