import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  AppContext,
  FilterContext,
  MapContext,
} from '../../../../contexts';

import {
  Botao,
  Dialog,
  VisibilidadeSelecaoSelect,
} from '../../../../components';

import WindowStorage from '../../../../utils/storage';

import RegionsTab from './components/RegionsTab';
import AdmRegionsTab from './components/AdmRegionsTab';
import BaciasTab from './components/BaciasTab';

import { TabContent } from './styles';

import { analytics } from '../../../../index';

const isolateStorage = WindowStorage.sessionStorage('isolate-region');

/*
  TODO: MELHORIA
  Migrar para componente ArvoreExpansivel
    - elimina multiplos states
    - elimina multiplos componentes e logicas repetidas

  Cuidados:
    Funções storage (Migrar para useStorage)
    Função loadFromVisibleCities (visualização sendo aberta)
*/
function IsolateRegionDialog(props) {
  const { isVisible, setVisible, setOptions } = props;

  /* eslint-disable */
  /**
   * @typedef {object} IsolateBaseOptions
   * @prop {string} name
   * @prop {"checked" | "unchecked" | "incomplete"} status
   *
   * @typedef {IsolateBaseOptions & { maximazed: boolean }} IsolateRegionsOptions
   */
  /**
   * @type {[Object.<number, IsolateRegionsOptions>, SetState<Object.<number, IsolateRegionsOptions>]}
   */
  const [isolateRegions, setIsolateRegions] = useState({});
  /**
   * @type {[Object.<number, IsolateRegionsOptions>, SetState<Object.<number, IsolateRegionsOptions>]}
   */
  const [isolateDioceses, setIsolateDioceses] = useState({});
  /**
   * @type {[Object.<number, IsolateBaseOptions & CityInfo>, SetState<Object.<number, IsolateBaseOptions & CityInfo>]}
   */
  const [isolateCities, setIsolateCities] = useState({});
  /**
   * @type {[Object.<number, IsolateRegionsOptions>, SetState<Object.<number, IsolateRegionsOptions>]}
   */
  const [isolateBacias, setIsolateBacias] = useState({});
  /**
   * @type {[Object.<number, IsolateRegionsOptions>, SetState<Object.<number, IsolateRegionsOptions>]}
   */
  const [isolateAdmRegions, setIsolateAdmRegions] = useState({});
  /* eslint-enable */

  const {
    admRegions,
    bacias,
    cities,
    dioceses,
    hasViewLoading,
    regions,
  } = useContext(AppContext);

  const {
    filteredAdmRegions,
    filteredBacias,
    filteredRegions,
  } = useContext(FilterContext);

  const {
    isolateType,
    setIsolateType,
    setVisibleCities,
    visibleCities,
  } = useContext(MapContext);

  const [typeSelection, setTypeSelection] = useState('option-region');

  useEffect(() => {
    setIsolateType(atual => {
      if (typeSelection === 'option-region') {
        return 'regions';
      }
      if (typeSelection === 'option-adm') {
        return 'adm-regions';
      }

      if (typeSelection === 'option-bacias') {
        return 'bacias';
      }

      return atual;
    });
  }, [typeSelection, setIsolateType]);

  const handleCloseClick = useCallback(() => {
    setVisible(false);
    setOptions(options => ({ ...options, isolateRegion: false }));
  }, [setVisible, setOptions]);

  const persistStorage = useCallback((clear = false) => {
    if (clear) {
      isolateStorage.limpa();
    } else {
      isolateStorage.set({
        isolateRegions,
        isolateDioceses,
        isolateCities,
        isolateAdmRegions,
        isolateBacias,
      });
    }
  }, [
    isolateAdmRegions,
    isolateBacias,
    isolateCities,
    isolateDioceses,
    isolateRegions,
  ]);

  const handleApply = useCallback(() => {
    const visibleCities = Object.entries(isolateCities)
      .filter(([, city]) => city.status === 'checked')
      .map(([id]) => id);

    setVisibleCities(visibleCities);
    setVisible(false);
    persistStorage();
    analytics.logEvent('dialog-isolar-regioes-aplicar-selecao');
  }, [isolateCities, persistStorage, setVisible, setVisibleCities]);

  useEffect(() => {
    const status = Object.entries(isolateRegions)
      .reduce((total, [key, item]) => ({ ...total, [key]: item.status }), {});

    setIsolateDioceses(old => Object.entries(old).reduce((total, [key, item]) => ({
      ...total,
      [key]: { ...item, status: status[item.region] === 'incomplete' ? item.status : status[item.region] }
    }), {}));
  }, [isolateRegions]);

  useEffect(() => {
    if (isolateDioceses && typeSelection === 'option-region') {
      const status = Object.entries(isolateDioceses)
        .reduce((total, [key, item]) => ({ ...total, [key]: item.status }), {});

      setIsolateCities(old => Object.entries(old).reduce((total, [key, item]) => ({
        ...total,
        [key]: { ...item, status: status[item.diocese] === 'incomplete' ? item.status : status[item.diocese] }
      }), {}));
    }
  }, [isolateDioceses, typeSelection]);

  useEffect(() => {
    if (isolateAdmRegions && typeSelection === 'option-adm') {
      const status = Object.entries(isolateAdmRegions)
        .reduce((total, [key, item]) => ({ ...total, [key]: item.status }), {});

      setIsolateCities(old => Object.entries(old).reduce((total, [key, item]) => ({
        ...total,
        [key]: { ...item, status: status[item.admRegion] === 'incomplete' ? item.status : status[item.admRegion] }
      }), {}));
    }
  }, [isolateAdmRegions, typeSelection]);

  useEffect(() => {
    if (isolateBacias && typeSelection === 'option-bacias') {
      const status = Object.entries(isolateBacias)
        .reduce((total, [key, item]) => ({ ...total, [key]: item.status }), {});

      setIsolateCities(old => Object.entries(old).reduce((total, [key, item]) => ({
        ...total,
        [key]: { ...item, status: status[item.bacia] === 'incomplete' ? item.status : status[item.bacia] }
      }), {}));
    }
  }, [isolateBacias, typeSelection]);

  const initializeCities = useCallback(() => {
    setIsolateCities(
      Object.entries(cities)
        .reduce((total, [id, info]) => ({
          ...total,
          [id]: {
            ...info,
            status: 'unchecked',
          },
        }), {})
    );
  }, [cities]);

  const initializeDioceses = useCallback(() => {
    setIsolateDioceses(
      Object.entries(dioceses)
        .reduce((total, [id, info]) => ({
          ...total,
          [id]: {
            ...info,
            status: 'unchecked',
          },
        }), {})
    );
  }, [dioceses]);

  const initializeRegions = useCallback((clear = false) => {
    setIsolateRegions(old => {
      const elements = Object.entries(old)
        .filter(([, item]) => item.status !== 'unchecked');

      if (elements.length === 0 || clear) {
        return Object.entries(regions).reduce((total, [key, item]) => {
          if (
            visibleCities.length > 0
            || filteredRegions.length === 0
            || filteredRegions.includes(Number(key))
          ) {
            if (old[key] && !clear) {
              return {
                ...total,
                [key]: { name: item.name, status: old[key].status || 'unchecked', maximized: old[key].maximized || false }
              };
            }
            return {
              ...total,
              [key]: { name: item.name, status: 'unchecked', maximized: false }
            };
          }
          return total;
        }, {});
      }
      return old;
    });
  }, [filteredRegions, regions, visibleCities.length]);

  const initializeAdmRegions = useCallback((clear = false) => {
    setIsolateAdmRegions(old => {
      const elements = Object.entries(old)
        .filter(([, item]) => item.status !== 'unchecked');

      if (elements.length === 0 || clear) {
        return Object.entries(admRegions).reduce((total, [key, item]) => {
          if (
            visibleCities.length > 0
            || filteredAdmRegions.length === 0
            || filteredAdmRegions.includes(Number(key))
          ) {
            if (old[key] && !clear) {
              return {
                ...total,
                [key]: { name: item.name, status: old[key].status || 'unchecked', maximized: old[key].maximized || false }
              };
            }
            return {
              ...total,
              [key]: { name: item.name, status: 'unchecked', maximized: false }
            };
          }
          return total;
        }, {});
      }
      return old;
    });
  }, [admRegions, filteredAdmRegions, visibleCities.length]);

  const initializeBacias = useCallback((clear = false) => {
    setIsolateBacias(old => {
      const elements = Object.entries(old)
        .filter(([, item]) => item.status !== 'unchecked');

      if (elements.length === 0 || clear) {
        return Object.entries(bacias).reduce((total, [key, item]) => {
          if (
            visibleCities.length > 0
            || filteredBacias.length === 0
            || filteredBacias.includes(Number(key))
          ) {
            if (old[key] && !clear) {
              return {
                ...total,
                [key]: { name: item.name, status: old[key].status || 'unchecked', maximized: old[key].maximized || false }
              };
            }
            return {
              ...total,
              [key]: { name: item.name, status: 'unchecked', maximized: false }
            };
          }
          return total;
        }, {});
      }
      return old;
    });
  }, [bacias, filteredBacias, visibleCities.length]);

  /**
   * @callback loadIsolateOptionsFromVisibleCitiesCallback
   * @param {IsolateTypes} type
   *
   * Callback que carrega opções de isolamento baseado
   * em um conjunto de cidades visíveis dado um tipo de isolamento
   * @type {loadIsolateOptionsFromVisibleCitiesCallback}
   */
  const loadIsolateOptionsFromVisibleCities = useCallback((type) => {
    if (visibleCities.length === 0) {
      initializeRegions(true);
      initializeAdmRegions(true);
      initializeBacias(true);
      return;
    }
    setIsolateCities(() => (
      Object.entries(cities)
        .reduce((total, [id, info]) => ({
          ...total,
          [id]: {
            ...info,
            status: visibleCities.includes(id) ? 'checked' : 'unchecked',
          },
        }), {})
    ));
    if (type === 'regions') {
      setIsolateDioceses(
        Object.entries(dioceses)
          .reduce((total, [id, info]) => {
            const dioceseCities = Object.entries(cities)
              .reduce((dioceseCities, [city, { diocese }]) => (
                Number(id) === diocese ? [...dioceseCities, city] : dioceseCities
              ), []);
            const visibleDioceseCities = dioceseCities
              .filter(city => visibleCities.includes(city));
            return {
              ...total,
              [id]: {
                ...info,
                status: visibleDioceseCities.length === 0
                  ? 'unchecked'
                  : visibleDioceseCities.length === dioceseCities.length
                    ? 'checked'
                    : 'incomplete',
                maximazed: false,
              },
            };
          }, {})
      );
      setIsolateRegions(
        Object.entries(regions)
          .reduce((total, [id, { name }]) => {
            const regionCities = Object.entries(cities)
              .reduce((regionCities, [city, { region }]) => (
                Number(id) === region ? [...regionCities, city] : regionCities
              ), []);
            const visibleRegionCities = regionCities
              .filter(city => visibleCities.includes(city));
            return {
              ...total,
              [id]: {
                name,
                status: visibleRegionCities.length === 0
                  ? 'unchecked'
                  : visibleRegionCities.length === regionCities.length
                    ? 'checked'
                    : 'incomplete',
                maximazed: false,
              },
            };
          }, {})
      );
    } else {
      const infoObject = type === 'adm-regions' ? admRegions : bacias;
      const setIsolate = type === 'adm-regions' ? setIsolateAdmRegions : setIsolateBacias;
      const infoType = type === 'adm-regions' ? 'admRegion' : 'bacia';
      setIsolate(
        Object.entries(infoObject)
          .reduce((total, [id, info]) => {
            const infoCities = Object.entries(cities)
              .reduce((infoCities, [city, info]) => (
                Number(id) === info[infoType] ? [...infoCities, city] : infoCities
              ), []);
            const visibleInfoCities = infoCities
              .filter(city => visibleCities.includes(city));
            return {
              ...total,
              [id]: {
                ...info,
                status: visibleInfoCities.length === 0
                  ? 'unchecked'
                  : visibleInfoCities.length === infoCities.length
                    ? 'checked'
                    : 'incomplete',
                maximazed: false,
              },
            };
          }, {})
      );
    }
  }, [
    admRegions,
    bacias,
    cities,
    dioceses,
    initializeAdmRegions,
    initializeBacias,
    initializeRegions,
    regions,
    visibleCities,
  ]);

  useEffect(() => {
    if (hasViewLoading.current) {
      persistStorage(true);
      const selection = isolateType !== 'adm-regions'
        ? isolateType === 'regions' ? 'option-region' : 'option-bacias'
        : 'option-adm';
      setTypeSelection(selection);
      loadIsolateOptionsFromVisibleCities(isolateType);
    }
  }, [hasViewLoading, isolateType, loadIsolateOptionsFromVisibleCities, persistStorage]);

  const handleCleanAllClick = useCallback(() => {
    initializeRegions(true);
    initializeAdmRegions(true);
    initializeBacias(true);
    setVisibleCities([]);
    persistStorage(true);
    analytics.logEvent('dialog-isolar-regioes-limpar-selecao');
  }, [initializeAdmRegions, initializeBacias, initializeRegions, persistStorage, setVisibleCities]);

  useEffect(() => {
    if (isolateStorage.existe()) {
      const inst = isolateStorage.get();
      setIsolateDioceses(inst.isolateDioceses);
    } else initializeDioceses();
  }, [dioceses, initializeDioceses]);

  useEffect(() => {
    if (isolateStorage.existe()) {
      const inst = isolateStorage.get();
      setIsolateCities(inst.isolateCities);
    } else initializeCities();
  }, [cities, initializeCities]);

  useEffect(() => {
    if (isolateStorage.existe()) {
      const inst = isolateStorage.get();
      setIsolateAdmRegions(inst.isolateAdmRegions);
    }
    initializeAdmRegions();
  }, [initializeAdmRegions]);

  useEffect(() => {
    if (isolateStorage.existe()) {
      const inst = isolateStorage.get();
      setIsolateBacias(inst.isolateBacias);
    }
    initializeBacias();
  }, [initializeBacias]);

  useEffect(() => {
    if (isVisible) {
      if (isolateStorage.existe()) {
        setIsolateRegions(isolateStorage.get('isolateRegions'));
      }
      initializeRegions();
    }
  }, [initializeRegions, isVisible]);

  const ComponenteRegioes = useMemo(() => (
    <RegionsTab
      {...{
        isActive: isolateType === 'regions',
        isolateRegions,
        setIsolateRegions,
        isolateDioceses,
        setIsolateDioceses,
        isolateCities,
        setIsolateCities,
      }}
    />
  ), [
    isolateType,
    isolateRegions,
    setIsolateRegions,
    isolateDioceses,
    setIsolateDioceses,
    isolateCities,
    setIsolateCities,
]);

  const ComponenteRegioesAdm = useMemo(() => (
    <AdmRegionsTab
      {...{
        isActive: isolateType === 'adm-regions',
        isolateAdmRegions,
        setIsolateAdmRegions,
        isolateCities,
        setIsolateCities,
      }}
    />
  ), [
    isolateType,
    isolateAdmRegions,
    setIsolateAdmRegions,
    isolateCities,
    setIsolateCities,
  ]);

  const ComponenteBacias = useMemo(() => (
    <BaciasTab
      {...{
        isActive: isolateType === 'bacias',
        isolateBacias,
        setIsolateBacias,
        isolateCities,
        setIsolateCities,
      }}
    />
  ), [
    isolateType,
    isolateBacias,
    setIsolateBacias,
    isolateCities,
    setIsolateCities,
  ]);

  return (
    <Dialog
      actions={(
        <>
          <Botao
            style={{
              backgroundColor: '#3498DB',
              color: '#fff',
              padding: 6,
            }}
            onClick={handleApply}
          >
            Aplicar
          </Botao>
          <Botao
            style={{
              backgroundColor: 'transparent',
              color: '#000',
              padding: 6,
            }}
            onClick={handleCleanAllClick}
          >
            Limpar Seleção
          </Botao>
        </>
      )}
      width="100%"
      title="Isolar região"
      show={isVisible}
      onClose={handleCloseClick}
    >
      <VisibilidadeSelecaoSelect
        label="Tipo de isolamento"
        onAlteraSelect={(_, valor) => setTypeSelection(valor)}
        value={typeSelection}
        style={{ minWidth: 200 }}
        opcoes={[
          { id: 'option-region', label: 'Regiões', componente: ComponenteRegioes },
          { id: 'option-adm', label: 'Regiões Administrativas', componente: ComponenteRegioesAdm },
          { id: 'option-bacias', label: 'Bacias PV', componente: ComponenteBacias },
        ]}
        renderizarEm={<TabContent />}
      />
    </Dialog>
  );
}

export default memo(IsolateRegionDialog);
