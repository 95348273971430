import Resource from './Resource';

/**
 * @typedef {object} PeopleSearchParams
 * @prop {"efl" | "svn"} events Evento filtrado
 * @prop {(1 | 2 | 3)[]} colors Cores filtradas
 * @prop {number} profiles Perfil filtrado
 */
export default class GetPeopleFromCityResource extends Resource {
  /**
   * Resource para requisição de lista de pessoas de uma cidade.
   * Pode buscar dados baseado um objeto definido seus parâmetros
   * @constructor
   * @param {string} token Token de Sessão
   * @param {string} city ID da cidade
   * @param {PeopleSearchParams} filters Parâmetros da busca
   */
  constructor(token, city, filters = { events: undefined, colors: undefined, profiles: undefined }) {
    super(token);
    this.city = city;
    this.filter = filters;
  }

  buildFilter() {
    let url = '';
    const queryParams = { idCidade: this.city };

    if (this.filter.events) {
      queryParams.eventos = this.filter.events;
    }

    if (this.filter.colors) {
      queryParams.cores = this.filter.colors;
    }

    if (this.filter.profiles) {
      queryParams.perfis = this.filter.profiles;
    }

    const urlQueryParams = Object.entries(queryParams).reduce((params, [key, values]) => (
      [...params, `${key.replaceAll('_', '-')}=${Array.isArray(values) ? values.join(',') : values}`]
    ), []).join('&');

    if (Object.keys(queryParams).length > 0) {
      url += `?${urlQueryParams}`;
    }

    return url;
  }

  async result() {
    const params = this.buildFilter();
    try {
      const { data, status } = await this.request.get(`/pessoas${params}`);

      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    return [];
  }
}
