import React from 'react';
import ReactDOM from 'react-dom';

import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/analytics';

import * as serviceWorker from './serviceWorker';

import { GlobalStyle } from './components';

import {
  Home,
} from './pages';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseInstance = firebase.initializeApp(firebaseConfig);
export const analytics = firebaseInstance.analytics();

document.title = 'App Mapas';

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyle />
    <Switch>
      <Route path="/" exact component={Home} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
