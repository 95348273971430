import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import Select from '../Select';

import { chaveExisteEm } from '../../utils';

import VisibilidadeSelecaoContext from './VisibilidadeSelecaoContext';

function VisibilidadeSelecaoSelect(props) {
  const {
    componente = <div />,
    children,
    opcoes,
    onAlteraSelect = () => {},
    renderizarEm = <div />,
    ...resto
  } = props;

  const [selecao, setSelecao] = useState(() => {
    const [primeiro] = opcoes;
    return primeiro.id || 0;
  });

  // se receber value, ignorar estado interno
  const selecaoAtual = useMemo(() => resto.value || selecao, [resto.value, selecao]);

  const [opcoesSelect, componentes] = useMemo(() => {
    const opcoesSelect = opcoes.map(({ id, label }, index) => ({ id: id || index, label }));
    const componentes = opcoes.reduce((total, { id, componente }, index) => (
      { ...total, [id || index]: componente }
    ), {});

    return [opcoesSelect, componentes];
  }, [opcoes]);

  const alterarSelecaoAtual = useCallback((e) => {
    onAlteraSelect(e, e.target.value);
    if (!chaveExisteEm(resto, 'value')) setSelecao(e.target.value); // componente controlado internamente
  }, [onAlteraSelect, resto]);

  const valor = useMemo(() => ({ selecaoAtual }), [selecaoAtual]);

  return (
    <VisibilidadeSelecaoContext.Provider value={valor}>
      {
        React.cloneElement(
          componente,
          { ...componente.props, className: `${componente.props.className || ''} VisibilidadeSelecaoSelect-raiz` },
          <>
            <Select
              {...resto}
              value={selecaoAtual}
              onChange={alterarSelecaoAtual}
            >
              {opcoesSelect}
            </Select>
            {
              React.cloneElement(
                renderizarEm,
                {
                  ...(renderizarEm.props || {}),
                  className: `${renderizarEm.props.className || ''} visibilidade-select-atual`
                },
                componentes[selecaoAtual] || null,
              )
            }
            {children || componente.children}
          </>
        )
      }
    </VisibilidadeSelecaoContext.Provider>
  );
}

export default VisibilidadeSelecaoSelect;
