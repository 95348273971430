import React from 'react';

import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

/**
 * @typedef {object} TextProps
 * @prop {CSSProps["color"]} color
 * CSS de cor do texto
 * @prop {CSSProps["fontSize"]} fontSize
 * CSS de tamanho da fonte
 * @prop {CSSProps["fontWeight"]} fontWeight
 * CSS de peso da fonte
 * @prop {CSSProps["textAlign"]} textAlign
 * CSS de alinhamento do texto
 */
/**
 * Componente de texto simples com estilo "bold" e alinhamento "center" por padrão
 * Herda as propriedades do componente Typography do Material-UI
 * @type {StyledComponent<typeof Typography, TextProps>}
 */
export const Text = styled(Typography)`
  && {
    font-size: ${({ fontSize }) => fontSize || 14}px;
    font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
    text-align: ${({ textAlign }) => textAlign || 'center'};
    color: ${props => props.color}
  }
`;

/**
 * @typedef {object} DividerTitleProps
 * @prop {CSSProps["color"]} color
 * Cor do texto
 * @prop {CSSProps["fontSize"]} fontSize
 * Tamanho da fonte do texto
 * @prop {CSSProps} dividerStyle
 * Estilização, com sobrescrição de propriedades, do componente `Divider`
 * @prop {CSSProps} textStyle
 * Estilização, sem sobrescrição de propriedades, do componente `Text`
 * @prop {JSX.Element} title
 * Título/String a ser usado no componente `Text`
 */
/**
 * Componente de texto que pode dividir conteúdos, funcionando como um título
 * @type {Component<DividerTitleProps>}
 */
export const DividerTitle = ({ color = '#000', dividerStyle, fontSize, textStyle, title }) => (
  <>
    <Text textAlign="left" {...{ color, fontSize, style: textStyle }}>{title}</Text>
    <Divider style={{ margin: '0 0 5px', ...dividerStyle }} />
  </>
);
