import styled from 'styled-components';

export const Container = styled.div`
  && {
    position: fixed;
    top: 60px;
    left: 0;
    transition: all 0.5s ease, left 0.25s linear;

    height: calc(100vh - 60px);
    width: 50px;
    
    padding: 0 10px;
    display: inline-flex;
    flex-direction: column;
  
    &.expanded {
      background-color: #fdfdfd;
      box-shadow: 4px 0 8px #aaa;
      width: 250px;
    }

    &.hidden {
      left: -200px;
    }

    &.minimized, &.hidden {
      background-color: transparent;
    }
  }
`;


export const Divider = styled.div`
  border: 0.5px solid #ccc;
  border-style: dashed;
  width: 30px;
  margin: 10px auto;
`;
