import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';

import './ContextTypes';

import {
  AppContext,
  DataContext,
} from '../';


import {
  GetFilterResource,
  GetCandidatesPerYearAndRoleResource,
  GetPartidosEleicoesPerYearResource,
  GetPartidosTSEPerYearResource,
} from '../../api/resources';

import WindowStorage from '../../utils/storage';

import {
  emptyObject,
} from '../../utils';

import FilterLocationsContext from './FilterLocationsContext';

const filterStorage = WindowStorage.sessionStorage('active-filters');

/**
 * @typedef {object} FilterContextType
 * @prop {number} currentTab
 * Index da aba do FilterDialog atual
 * @prop {string} candidate
 * Nome do candidato no filtro de "Votações"
 * @prop {string[]} cargo
 * Cargos selecionados nos filtros de "Eleições" e "TSE"
 * @prop {{ id: string, name: string }[]} cargoOptions
 * Opções de cargo para filtros Políticos
 * @prop {string} cargoCandidato
 * Cargo selecionado no filtro de "Votações"
 * @prop {FieldEvent[]} fieldEvents
 * Conjunto de objetos com propriedades de filtro usados na requisição e no componente FilterList
 * @prop {string[]} filteredAdmRegions
 * Regiões Administrativas que possuem cidades filtradas atualmente
 * @prop {string[]} filteredBacias
 * Bacias que possuem cidades filtradas filtradas atualmente
 * @prop {string[]} filteredCities
 * Cidades filtradas atualmente
 * @prop {string[]} filteredDioceses
 * Dioceses que possuem cidades filtradas atualmente
 * @prop {string[]} filteredRegions
 * Regiões que possuem cidades filtradas atualmente
 * @prop {getLocationsCallback} getAdmRegionsList
 * Callback para requisição de busca dos dados de Regiões Administrativas
 * @prop {getLocationsCallback} getBaciasList
 * Callback para requisição de busca dos dados de Bacias
 * @prop {getCandidatesCallback} getCandidatesList
 * Callback para requisição de busca de dados de candidatos
 * @prop {getCitiesLocationsCallback} getCitiesList
 * Callback para requisição de busca dos dados Cidades
 * @prop {getDiocesesLocationsCallback} getDiocesesList
 * Callback para requisição de busca dos dados de Dioceses
 * @prop {(key: FilterOptionsKeys) => SelectComponentOption[]} getFilterOptions
 * Callback para seleção e formatação dos dados de filtros
 * @prop {() => { [key: string]: SelectComponentOption[] }} getPeopleOptions
 * Callback para seleção e formatação dos dados de filtros "Pessoas"
 * @prop {(year: string) => { id: string, name: string }[]} getPartidosEleicoesList
 * Callback para busca de partidos baseado em um ano de Eleições
 * @prop {(year: string) => { id: string, name: string }[]} getPartidosTSEList
 * Callback para busca de partidos baseado em um ano do TSE
 * @prop {getLocationsCallback} getRegionsList
 * Callback para requisição de busca dos dados de Regiões
 * @prop {(key: FilterOptionsKeys, option: FilterOptionsSubKeys) => string[]} getYearsPerOption
 * Callback para retornar as opções de ano para um determinado tipo de filtro
 * @prop {() => Promise<void>} handleApplyFilterClick
 * Callback para aplicação dos filtros em FieldEvents
 * @prop {() => void} handleClearFilterClick
 * Callback para remover uma opção de filtro de FieldEvents
 * @prop {boolean} isFilterReady
 * Booleano que indica se as opções de filtro estão carregadas
 * @prop {string} partido
 * Partido selecionado no filtro de "Votações"
 * @prop {string[]} project
 * Opções de projeto selecionadas no filtro de "Eleições"
 * @prop {SetState<string>} setCandidate
 * SetState para o estado `candidate`
 * @prop {SetState<string[]>} setCargo
 * SetState para o estado `cargo`
 * @prop {SetState<string>} setCargoCandidato
 * SetState para o estado `cargoCandidato`
 * @prop {SetState<number>} setCurrentTab
 * SetState para o estado `currentTab`
 * @prop {SetState<FieldEvent[]>} setFieldEvents
 * SetState para o estado `fieldEvents`
 * @prop {SetState<string>} setPartido
 * SetState para o estado `partido`
 * @prop {SetState<string[]>} setProject
 * SetState para o estado `project`
 */
/**
 * @type {React.Context<FilterContextType>}
 */
const FilterContext = createContext({});

export const FilterProvider = ({ children }) => {
  const {
    addTaskRunning,
    cities,
    dioceses,
    filterOptions,
    removeTaskRunning,
    setAuxiliarBox,
    token
  } = useContext(AppContext);

  const {
    clearResult,
    filteredCities,
    setCleaningFilters,
    setResult,
  } = useContext(DataContext);

  const {
    getAdmRegionsList,
    getBaciasList,
    getCitiesList,
    getDiocesesList,
    getRegionsList,
  } = useContext(FilterLocationsContext);

  //=======================================================
  const isFilterReady = useMemo(() => (
    Object.values(filterOptions)
      .some(option => option && !emptyObject(option))
  ), [filterOptions]);

  const getFilterOptions = useCallback((key) => {
    const items = filterOptions[key] || {};
    return Object.entries(items).map(([id, item]) => ({ id, label: item.nome }));
  }, [filterOptions]);

  const getYearsPerOption = useCallback((key, option) => (
    filterOptions[key] && filterOptions[key][option] ? filterOptions[key][option].anos : []
  ), [filterOptions]);

  const [candidate, setCandidate] = useState('');
  const [cargo, setCargo] = useState([]);
  const [cargoCandidato, setCargoCandidato] = useState('');
  const [partido, setPartido] = useState([]);
  const [project, setProject] = useState([]);

  const getCandidatesList = useCallback(async (year, role, name) => {
    const resource = new GetCandidatesPerYearAndRoleResource(token, year, role, name);
    addTaskRunning();
    const result = await resource.result();
    removeTaskRunning();
    return result.map((item) => ({ id: item.toLowerCase(), name: item }));
  }, [addTaskRunning, removeTaskRunning, token]);

  const getPartidosEleicoesList = useCallback(async (year) => {
    const resource = new GetPartidosEleicoesPerYearResource(token, year);
    addTaskRunning();
    const result = await resource.result();
    removeTaskRunning();
    return result.map((item) => ({ id: item.toLowerCase(), name: item }));
  }, [addTaskRunning, removeTaskRunning, token]);

  const getPartidosTSEList = useCallback(async (year) => {
    const resource = new GetPartidosTSEPerYearResource(token, year);
    addTaskRunning();
    const result = await resource.result();
    removeTaskRunning();
    return result.map((item) => ({ id: item.toLowerCase(), name: item }));
  }, [addTaskRunning, removeTaskRunning, token]);

  /**
   * @type {[
   *  FieldEventsItem[],
   *  SetState<FieldEventsItem[]>>
   * ]}
   * Estado que armazena os filtros a serem buscados no mapa
   */
  const [fieldEvents, setFieldEvents] = useState([]);

  const handleClearFilterClick = useCallback(() => {
    setCleaningFilters(true);

    setFieldEvents([]);

    setCargo([]);
    setPartido([]);
    setProject([]);

    setCandidate('');
    setCargoCandidato('');

    clearResult();
  }, [clearResult, setCleaningFilters]);

  const filteredDioceses = useMemo(() => (
    filteredCities.reduce((total, item) => {
      const { diocese } = cities[item];
      if (!total.includes(diocese)) {
        return [...total, diocese];
      }
      return total;
    }, [])
  ), [cities, filteredCities]);

  const filteredAdmRegions = useMemo(() => (
    filteredCities.reduce((total, item) => {
      const { admRegion } = cities[item];
      if (!total.includes(admRegion)) {
        return [...total, admRegion];
      }
      return total;
    }, [])
  ), [cities, filteredCities]);

  const filteredBacias = useMemo(() => (
    filteredCities.reduce((total, item) => {
      const { bacia } = cities[item];
      if (!total.includes(bacia)) {
        return [...total, bacia];
      }
      return total;
    }, [])
  ), [cities, filteredCities]);

  const filteredRegions = useMemo(() => (
    filteredDioceses.reduce((total, item) => {
      const { region } = dioceses[item];
      if (!total.includes(region)) {
        return [...total, region];
      }
      return total;
    }, [])
  ), [dioceses, filteredDioceses]);

  const saveAppliedFilters = useCallback(() => {
    filterStorage.set({
      events: fieldEvents,
      project,
      cargo,
      partido,
      candidate,
      cargoCandidato
    });
  }, [cargo, fieldEvents, project, partido, candidate, cargoCandidato]);

  const handleApplyFilterClick = useCallback(async (filters = undefined) => {
    const filter = filters || fieldEvents;
    clearResult();
    setAuxiliarBox({
      state: 'hidden',
      title: 'Legenda',
      description: [],
      content: <></>,
    });

    const resource = new GetFilterResource(token, filter);
    addTaskRunning();
    const data = await resource.result();
    setResult(data);
    saveAppliedFilters();
    removeTaskRunning();
  }, [
    addTaskRunning,
    clearResult,
    fieldEvents,
    removeTaskRunning,
    saveAppliedFilters,
    setAuxiliarBox,
    setResult,
    token,
  ]);

  useEffect(() => {
    if (filterStorage.existe()) {
      const storage = filterStorage.get();
      if (storage.events) {
        setFieldEvents(storage.events);
      }

      // if (storage.currentTab) setCurrentTab(storage.currentTab);
      if (storage.cargo) setCargo(storage.cargo);
      if (storage.project) setProject(storage.project);
      if (storage.partido) setPartido(storage.partido);
      if (storage.candidate) setCandidate(storage.candidate);
      if (storage.cargoCandidato) setCargoCandidato(storage.cargoCandidato);
    }
  }, []);

  const resources = useMemo(() => ({
    candidate,
    cargo,
    cargoCandidato,
    emendasFilters: filterOptions.emendas,
    eventsFilters: filterOptions.eventos,
    fieldEvents,
    filteredAdmRegions,
    filteredBacias,
    filteredCities,
    filteredDioceses,
    filteredRegions,
    getAdmRegionsList,
    getBaciasList,
    getCandidatesList,
    getCitiesList,
    getDiocesesList,
    getFilterOptions,
    getPartidosEleicoesList,
    getPartidosTSEList,
    getRegionsList,
    getYearsPerOption,
    handleApplyFilterClick,
    handleClearFilterClick,
    isFilterReady,
    partido,
    peopleFilters: filterOptions.pessoas,
    politicsFilters: filterOptions.politico,
    project,
    setCandidate,
    setCargo,
    setCargoCandidato,
    setFieldEvents,
    setPartido,
    setProject,
  }), [
    candidate,
    cargo,
    cargoCandidato,
    fieldEvents,
    filteredAdmRegions,
    filteredBacias,
    filteredCities,
    filteredDioceses,
    filteredRegions,
    filterOptions,
    getAdmRegionsList,
    getBaciasList,
    getCandidatesList,
    getCitiesList,
    getDiocesesList,
    getFilterOptions,
    getPartidosEleicoesList,
    getPartidosTSEList,
    getRegionsList,
    getYearsPerOption,
    handleApplyFilterClick,
    handleClearFilterClick,
    isFilterReady,
    partido,
    project,
    setCandidate,
    setCargo,
    setCargoCandidato,
    setFieldEvents,
    setPartido,
    setProject,
  ]);

  return (
    <FilterContext.Provider value={resources}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
