import React, {
  forwardRef,
  memo,
} from 'react';

import { StyledTip } from './styles';

const Tip = forwardRef(({ children }, ref) => (
  <StyledTip ref={ref}>
    { children }
  </StyledTip>
));

export default memo(Tip);
