import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import WindowStorage from '../../../utils/storage';

import MapProviderContext from '../ProviderGroup';

import {
  INITIAL_LABELS_SIZE,
  INITIAL_LABEL_OVERRIDES,
  FILTER_TYPES,
} from '../../constants';

const overridesStorage = WindowStorage.localStorage('city-label-overrides');

/**
 * @typedef {object} MapLabelsContextType
 * @prop {React.MutableRefObject<LabelAdjustMode>} adjustCityLabelPositionMode
 * Referencia ao estado atual do modo de "drag" de labels
 * @prop {LabelAdjustMode} adjustLabelMode
 * Estado que monitora o modo de "drag" de labels
 * @prop {(event, id: string) => void} draggingTextLabel
 * Callback do evento de "drag" de um label
 * @prop {(labels: NumberLabelsType) => SumsObject} getSums
 * Callback para calcular as somas selecionadas do filtro.
 * @prop {(factor: number) => void} increaseFontSize
 * Callback para alterar o tamanho das fontes baseado em um fator
 * @prop {LabelsProps[]} labels
 * Estado que aramzazena a configuração inicial de labels
 * @prop {LabelsSize} labelsSize
 * Estado que monitora os tamanhos de fonte
 * @prop {NumberLabelsType} numberLabels
 * Estado que armazena os labels de quantidade aplicados mais recentemente
 * @prop {(id: string) => void} overrideTextLabelPosition
 * Callback do evento de finalização do "drag" de um label
 * @prop {React.MutableRefObject<FontStatus>} refFontStatus
 * Referencia ao estado atual das fontes
 * @prop {() => void} resetMapText
 * Callback que reseta o tamanho das fontes de label do mapa
 * @prop {SetState<LabelAdjustMode>} setAdjustLabelMode
 * SetState do estado `adjustLabelMode`
 * @prop {SetState<LabelsSize>} setLabelsSize
 * SetState para estado `labelsSize`
 * @prop {SetState<NumberLabelsType>} setNumberLabels
 * SetState para o estado `numberLabels`
 * @prop {SetState<QuantitiesOptionsType>} setShowQuantitiesOptions
 * SetState do estado `showQuantitiesOptions`
 * @prop {QuantitiesOptionsType} showQuantitiesOptions
 * Estado que monitora as opções da função de mostrar quantidades do mapa
 */
/** @type {React.Context<MapLabelsContextType>} */
const MapLabelsContext = createContext({});

export function MapLabelsProvider({ children }) {
  const {
    getAllCities,
    getAllHulls,
    getPointsOfPath,
    mounted,
    setMounted,
    showNames,
    showNumbers,
  } = useContext(MapProviderContext);

  /**
   * Esse estado armazena os labels contruidos no inicio da aplicação.
   */
  const [labels, setLabels] = useState([]);

  /**
   * Essa referência armazena as configurações referentes ao texto
   * dos rótulos das cidades no mapa
   */
  const refFontStatus = useRef({
    size: { ...INITIAL_LABELS_SIZE },
    visible: false,
  });

  const [labelsSize, setLabelsSize] = useState({ ...INITIAL_LABELS_SIZE });

  /**
   * Estado que salva os labels de quantidades aplicados mais recentemente
   * @type {[NumberLabelsType, SetState<NumberLabelsType>]}
   */
  const [numberLabels, setNumberLabels] = useState([]);

  /**
   * Esse useState armazena as opções de soma das quantidades selecionadas
   */
  const [showQuantitiesOptions, setShowQuantitiesOptions] = useState({
    sumEvents: false,
    sumPolitics: false,
    sumPeople: false,
    sumEmendas: false,
  });

  /**
  * Este estado armazena as posições sobrescritas
  * dos rótulos das cidades e hulls no mapa
  *
  * @type {[boolean, SetState<boolean>]}
  */
  const [overridesTextLabelPosition, setOverridesTextLabelPosition] = useState(() => {
    const overrides = overridesStorage.get();
    return overrides
      ? overrides['region-18'] ? overrides : { ...overrides, ...INITIAL_LABEL_OVERRIDES }
      : INITIAL_LABEL_OVERRIDES;
  });

  /**
   * Essa função calcula o ponto médio do elemento PATH
   * passado como parâmetro
   *
   * @param id ID da cidade ou hull
   * @param path Elemento PATH de uma cidade ou hull
   *
   * @returns Object
   */
  const getLabelPosition = useCallback((id, path) => {
    if (overridesTextLabelPosition[id]) {
      return overridesTextLabelPosition[id];
    }
    const points = getPointsOfPath(path);
    const sum = points.reduce((total, item) => ({
      x: total.x + item.x,
      y: total.y + item.y,
    }), { x: 0, y: 0 });

    return {
      x: sum.x / points.length,
      y: sum.y / points.length,
    };
  }, [getPointsOfPath, overridesTextLabelPosition]);

  /**
   * Esta função constrói o rótulo de uma cidade ou de um hull
   * para a exibição de dados.
   *
   * @param item Elemento HTML 'path' referente a cidade ou hull
   * @param id ID da cidade ou hull
   *
   * @returns Elemento Text de SVG
   */
  const createInitialLabel = useCallback((path, id) => {
    const splitId = id.split('-');
    splitId.pop();
    const pathType = splitId.join('-');

    const { x, y } = getLabelPosition(id, path);
    const labelClass = `${pathType}-labels`;

    const newLabel = {};
    newLabel.x = x;
    newLabel.y = y;
    newLabel.classes = [id, labelClass];

    return newLabel;
  }, [getLabelPosition]);

  /** UseEffect que carrega labels */
  useEffect(() => {
    if (mounted.cities === 'loaded' && mounted.hulls === 'loaded') {
      const allPaths = [...getAllCities(), ...getAllHulls()];
      const labels = allPaths.map(item => {
        const [identifierClass] = item.classList;
        return createInitialLabel(item, identifierClass);
      });

      // mapa carregado com cidades, hulls e labels
      setLabels(labels);
      setMounted(old => ({ ...old, labels: 'loaded' }));
    }
  }, [
    createInitialLabel,
    getAllCities,
    getAllHulls,
    mounted.cities,
    mounted.hulls,
    setMounted,
  ]);

  /**
   * Essa função modifica o tamanho do texto conforme um
   * dado fator
   *
   * @param factor Valor do fator para modificação da fonte
   *
   * @returns void
   */
  const increaseFontSize = useCallback((factor) => {
    // individualmente altera os tamanhos das fontes sempre baseado em factor

    // fontes de cidades
    refFontStatus.current.size.cityFont += factor;
    if (refFontStatus.current.size.cityFont < 0.5) {
      refFontStatus.current.size.cityFont = 0.4;
    } else if (refFontStatus.current.size.cityFont > 6) {
      refFontStatus.current.size.cityFont = 6;
    }

    // fontes de regiões
    refFontStatus.current.size.regionFont += 2.5 * factor;
    if (refFontStatus.current.size.regionFont < 4) {
      refFontStatus.current.size.regionFont = 4;
    } else if (refFontStatus.current.size.regionFont > 10) {
      refFontStatus.current.size.regionFont = 10;
    }

    // fontes de bacias, dioceses e regioes administrativas
    refFontStatus.current.size.othersFont += 1.75 * factor;
    if (refFontStatus.current.size.othersFont < 1.5) {
      refFontStatus.current.size.othersFont = 1.4;
    } else if (refFontStatus.current.size.othersFont > 6) {
      refFontStatus.current.size.othersFont = 6;
    }

    const { cityFont, othersFont, regionFont } = refFontStatus.current.size;
    setLabelsSize({ cityFont, othersFont, regionFont });
  }, []);

  /**
   * Essa função reseta o tamanho da fonte no mapa para
   * tamanho de 1px
   *
   * @returns void
   */
  const resetMapText = useCallback(() => {
    const { cityFont, othersFont, regionFont } = INITIAL_LABELS_SIZE;
    refFontStatus.current.size.cityFont = cityFont;
    refFontStatus.current.size.othersFont = othersFont;
    refFontStatus.current.size.regionFont = regionFont;
    setLabelsSize({ cityFont, othersFont, regionFont });
  }, []);

  /**
   * Esse useEffect hook monitora a visibilidade dos nomes e valores
   * das cidades para setar a referência de configuração
   * dos rótulos
   */
  useEffect(() => {
    refFontStatus.current.visible = (showNames || showNumbers);
  }, [showNames, showNumbers]);

  /**
   * Essa função calcula a soma dos resultados do filtro de mesmo tipo
   *
   * @param labels objeto de configuração de labels
   * @param sumType tipo de filtro a ser somado
   *
   * @returns Object { toSumFilters, pattern }
   * onde toSumFilters são os filtros a serem somados
   */
  const getSameTypeFilters = useCallback((labels, sumType) => {
    // seleciona o tipo de filtro a ser somado (Eventos, Politico, Emendas)
    const toSumFilters = Object.keys(labels).filter(typedFilter => {
      const [type] = typedFilter.split('_');
      return type === sumType;
    });

    return toSumFilters;
  }, []);

  const getSums = useCallback((labels) => {
    const { sumEvents, sumPolitics, sumPeople, sumEmendas } = showQuantitiesOptions;
    const sums = {};

    const sumFilters = (total, item) => {
      const quantity = labels[item];
      delete labels[item];
      return (total + quantity);
    };

    if (sumEvents) {
      const toSumEvents = getSameTypeFilters(labels, FILTER_TYPES.EVENTOS);
      const eventsSum = toSumEvents.reduce(sumFilters, 0);
      sums['eventsSum'] = eventsSum;
    }

    if (sumPolitics) {
      const toSumPolitics = getSameTypeFilters(labels, FILTER_TYPES.POLITICOS);
      const politicsSum = toSumPolitics.reduce(sumFilters, 0);
      sums['politicsSum'] = politicsSum;
    }

    if (sumPeople) {
      const toSumPeople = getSameTypeFilters(labels, FILTER_TYPES.PESSOAS);
      const peopleSum = toSumPeople.reduce(sumFilters, 0);
      sums['peopleSum'] = peopleSum;
    }

    if (sumEmendas) {
      const toSumEmendas = getSameTypeFilters(labels, FILTER_TYPES.EMENDAS);
      const emendasSum = toSumEmendas.reduce(sumFilters, 0);
      sums['emendasSum'] = emendasSum;
    }

    return sums;
  }, [getSameTypeFilters, showQuantitiesOptions]);

  /**
   * Essa referência controla o estado de manipulação dos nomes das cidades
   * do mapa
   *
   * @type {{ current: string }}
   */
  const adjustCityLabelPositionMode = useRef('disabled');

  const [adjustLabelMode, setAdjustLabelMode] = useState('disabled');

  /**
   * Essa função reposiciona o nome de uma cidade ou de um hull no mapa
   * conforme o evento passado e a identificação da cidade ou hull
   *
   * @param event Evento de drag-and-drop
   * @param id ID da cidade ou hull
   *
   * @returns void
   */
  const draggingTextLabel = useCallback((event, id) => {
    if (adjustCityLabelPositionMode.current !== 'disabled') {
      const item = document.querySelector(`g.${id}`);
      const position = {
        x: Number(item.dataset.x),
        y: Number(item.dataset.y),
      };

      const translate = {
        x: position.x + event.dx,
        y: position.y + event.dy,
      };

      item.dataset.x = translate.x;
      item.dataset.y = translate.y;
      item.dataset.overrideX = Number(item.dataset.overrideX) + event.dx;
      item.dataset.overrideY = Number(item.dataset.overrideY) + event.dy;

      item.setAttribute('transform', `translate(${translate.x}, ${translate.y})`);
    }
  }, []);

  /**
   * Essa função obtém a nova posição do label
   * de uma cidade ou hull e armazena no local storage
   *
   * @param id identificador da cidade
   *
   * @returns void
   */
  const overrideTextLabelPosition = useCallback((id) => {
    const item = document.querySelector(`g.${id}`);
    const point = {
      x: Number(item.dataset.overrideX),
      y: Number(item.dataset.overrideY),
    };

    setOverridesTextLabelPosition(old => {
      old[id] = point;
      overridesStorage.set(old);
      return { ...old };
    });

    if (adjustCityLabelPositionMode.current === 'one-city') {
      adjustCityLabelPositionMode.current = 'disabled';
      setAdjustLabelMode('disabled');
    }
  }, []);

  return (
    <MapLabelsContext.Provider
      value={{
        adjustCityLabelPositionMode,
        adjustLabelMode,
        draggingTextLabel,
        getSums,
        increaseFontSize,
        labels,
        labelsSize,
        numberLabels,
        overrideTextLabelPosition,
        refFontStatus,
        resetMapText,
        setAdjustLabelMode,
        setLabelsSize,
        setNumberLabels,
        setShowQuantitiesOptions,
        showQuantitiesOptions,
      }}
    >
      {children}
    </MapLabelsContext.Provider>
  );
}

export default MapLabelsContext;
