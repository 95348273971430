import Request from '../Request';

export default class SaveNewViewHandler extends Request {
  /**
   * @param {string} token
   * @param {SavedView} view
   */
  constructor(token, view) {
    super(token);
    this.view = view;
  }

  async handle() {
    try {
      const { status } = await this.request.post(
        '/usuarios/visualizacoes/',
        {
          titulo: this.view.name,
          descricao: this.view.description,
          permissao_usuarios: this.view.sharedWith,
          visualizacao: this.view.data
        }
      );
      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }
}
