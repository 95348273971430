import React, {
  memo,
  useCallback,
  useMemo
} from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';

import FeedbackIcon from './components/FeedbackIcon';

import { DialogIconTitle, StyledFeedback, TextBlock } from './styles';
import OkAction from './components/OkAction';
import YesOrNoAction from './components/YesOrNoAction';

/**
 * @typedef {object} FeedbackDialogProps
 * @prop {boolean} visible
 * @prop {'yesOrNo' | 'ok'} [action]
 * @prop {JSX.Element} message
 * @prop {string} [title]
 * @prop {"alert" | "default" | "info"} type
 * @prop {VoidFunction} [onClose]
 * @prop {string} [okText]
 * @prop {VoidFunction} [onConfirm]
 * @prop {VoidFunction} [onReject]
 * @prop {string} [confirmText]
 * @prop {string} [rejectText]
 */
/**
 * @param {FeedbackDialogProps} props
 * @returns {JSX.Element}
 */
function FeedbackDialog({ closeFeedback, ...props }) {
  const {
    action = 'ok',
    message,
    title,
    type = 'default',
    visible,
    onClose,
    okText = 'OK',
    onConfirm,
    onReject,
    confirmText = 'SIM',
    rejectText = 'NÃO'
  } = props;

  const close = useCallback(() => {
    if (onClose) onClose();
    closeFeedback();
  }, [onClose, closeFeedback]);

  const confirm = useCallback(() => {
    if (onConfirm) onConfirm();
    close();
  }, [close, onConfirm]);

  const reject = useCallback(() => {
    if (onReject) onReject();
    close();
  }, [close, onReject]);

  const actions = useMemo(() => {
    switch (action) {
      case 'yesOrNo':
        return (
          <YesOrNoAction
            yesText={confirmText}
            noText={rejectText}
            onConfirm={confirm}
            onReject={reject}
          />
        );

      case 'ok': return <OkAction onClose={close}>{okText}</OkAction>;
      default: return <OkAction onClose={close}>OK</OkAction>;
    }
  }, [action, confirm, confirmText, reject, rejectText, close, okText]);

  return (
    <StyledFeedback
      open={visible}
      scroll="paper"
      maxWidth={false}
      keepMounted
      hideBackdrop
      PaperComponent={(props) => <Paper {...props} className={`${props.className} ${type}`} />}
    >
      <DialogIconTitle>
        <FeedbackIcon type={type} />
        {title && <h3>{title}</h3>}
      </DialogIconTitle>
      <DialogContent>
        <TextBlock>{message}</TextBlock>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </StyledFeedback>
  );
}

/**
 * Dialogo de Feedback.
 * Possui diferentes estilizações baseadas no tipo de feedback.
 */
export default memo(FeedbackDialog);
