import Resource from './Resource';

export default class GetViewResource extends Resource {
  /**
   * @param {string} token
   * @param {number} id
   */
  constructor(token, id) {
    super(token);
    this.id = id;
  }

  /**
   * Busca os dados de uma visualização.
   * @returns {View}
   */
  async result() {
    try {
      const { data, status } = await this.request.get(`/usuarios/visualizacoes/${this.id}`);
      if (status === 200) {
        return data.visualizacao;
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
    return [];
  }
}
