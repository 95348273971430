import React from 'react';

import { Botao } from '../../../';

function YesOrNoAction({ onConfirm, onReject, yesText, noText }) {
  return (
    <>
      <Botao onClick={onConfirm}>
        {yesText}
      </Botao>
      <Botao onClick={onReject}>
        {noText}
      </Botao>
    </>
  );
}

export default YesOrNoAction;
