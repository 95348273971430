import React, {
  createContext,
  useCallback,
  useState,
} from 'react';

import './ContextTypes';

import { FeedbackDialog } from '../../components';

const INITIAL_FEEDBACK = {
  visible: false,
  // opcoes
  action: undefined,
  message: '',
  type: 'default',
  onClose: undefined,
  // acao ok
  okText: undefined,

  // acao sim ou nao
  onConfirm: undefined,
  onReject: undefined,
  confirmText: undefined,
  rejectText: undefined
};

/**
 * @typedef {object} FeedbackContextType
 * @prop {(feedback: FeedbackOptions) => FeedbackOptions} setFeedback Callback para utilizar o dialogo de Feedback
 */
/** @type {React.Context<FeedbackContextType>} */
const FeedbackContext = createContext({});

export function FeedbackProvider({ children }) {
  const [feedbackState, setFeedbackState] = useState(INITIAL_FEEDBACK);

  const setFeedback = useCallback((feedback) => setFeedbackState({ ...feedback, visible: true }), []);
  const closeFeedback = useCallback(() => setFeedbackState(INITIAL_FEEDBACK), []);

  return (
    <FeedbackContext.Provider value={{ setFeedback }}>
      <FeedbackDialog {...feedbackState} closeFeedback={closeFeedback} />
      {children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContext;
