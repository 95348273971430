import React, {
  useCallback,
  useMemo,
  useState
} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHeadElement from './components/TableHeadElement';
import TableFootElement from './components/TableFootElement';

import {
  NoRowsMessage,
  StyledTableRow,
  TablePaper
} from './styles';

export function descendingComparator(a, b, orderBy) {
  let aValue = a[orderBy];
  let bValue = b[orderBy];

  if (typeof aValue === 'string') {
    aValue = aValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    bValue = bValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  if (bValue < aValue) {
    return -1;
  }

  if (bValue > aValue) {
    return 1;
  }

  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const sortedEntries = Object.entries(array)
    .sort((a, b) => {
      const [indexA, itemA] = a;
      const [indexB, itemB] = b;

      const order = comparator(itemA, itemB);
      if (order !== 0) return order;
      return indexA - indexB;
    });

  return sortedEntries.map(([, item]) => item);
}

function TableElement(props) {
  const {
    rows,
    headers,
    footer = undefined,
    dense = true
  } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(() => {
    const [first] = headers;
    return first ? first.label : undefined;
  });

  const handleRequestSort = useCallback((event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [order, orderBy]);

  const tableRows = useMemo(() => (
    stableSort(rows, getComparator(order, orderBy))
  ), [order, orderBy, rows]);

  /*
    Funcionalidades inutilizadas  de seleção de linhas
    Mantendo para possível uso posterior
  */
  // const [selected, setSelected] = useState([]);

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  return (
    <>
      <NoRowsMessage hidden={rows.length > 0}>
        Não há items a serem listados
      </NoRowsMessage>
      <TablePaper hidden={rows.length === 0}>
        <Table
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <TableHeadElement
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            {...{ headers }}
            // numSelected={selected.length}
            // onSelectAllClick={handleSelectAllClick}
            // rowCount={rows.length}
          />

          <TableBody>
            {
              tableRows.map((row, index) => (
                <StyledTableRow
                  key={`row-${index + 1}`}
                  tabIndex={-1}
                  hover
                  // onClick={(event) => handleClick(event, row.name)}
                >
                  {
                    Object.keys(row).map((item, index) => (
                      <TableCell
                        key={`row-${index + 1}-cell-${item}`}
                        align={headers[index] ? headers[index].align : 'center'}
                      >
                        {
                          (row[item] !== null && row[item] !== undefined)
                            ? row[item].toLocaleString('pt-BR')
                            : '-'
                        }
                      </TableCell>
                    ))
                  }
                </StyledTableRow>
              ))
            }
          </TableBody>

          {footer && <TableFootElement footer={footer} />}
        </Table>
      </TablePaper>
    </>
  );
}

/**
 * Componente genérico estilizado de tabela
 * capaz de ordenar dados.
 *
 * @param rows
 * Lista de dados da tabela
 * Item da lista deve ter um `id`,
 * e suas propriedades devem corresponder aos nomes das colunas
 * @param headers
 * Lista de informações das colunas da tabela
 * Item da lista deve ter `id`, `label` e `align`,
 * - `label` é o nome da coluna (mostrado na tabela)
 * - `align` é o alinhamento dos dados da coluna (padrão: 'left')
 * @param footer
 * Lista de de informações em rodapé
 * Item da lista deve ter `id`, `label` e `align`,
 * possui mesmo comportamento que os `headers`
 * @param dense
 * Define o tamanho da tabela
 */
export default TableElement;
