import React, {
  useCallback,
  useContext,
} from 'react';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ButtonControl } from '../styles';

import {
  MapContext,
} from '../../../../contexts';

const OFFSET = {
  x: 0,
  y: 0,
};

function ZoomControl() {
  const { reset, transformer } = useContext(MapContext);

  const applyZoom = useCallback((zoomFactor) => {
    const factor = {
      x: zoomFactor,
      y: zoomFactor,
    };

    transformer.current.center(OFFSET, factor);
  }, [transformer]);

  return (
    <ButtonControl
      bottom={10}
      right={0}
    >
      <Tooltip title="Aumentar zoom">
        <IconButton
          onClick={() => applyZoom(1.35)}
          style={{ padding: '10px 5px' }}
        >
          <ZoomInIcon style={{ fontSize: 24 }} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Redefinir zoom do mapa">
        <IconButton
          onClick={reset}
          style={{ padding: 10 }}
        >
          <span style={{ fontSize: 16 }}>Redefinir</span>
        </IconButton>
      </Tooltip>

      <Tooltip title="Diminuir zoom">
        <IconButton
          onClick={() => applyZoom(0.65)}
          style={{ padding: '10px 5px' }}
        >
          <ZoomOutIcon style={{ fontSize: 24 }} />
        </IconButton>
      </Tooltip>
    </ButtonControl>
  );
}

export default ZoomControl;
