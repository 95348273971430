/**
 * @template {string | number | symbol} T
 * @param {Record<string | number | symbol, unknown>} objeto Objeto sendo verificado
 * @param {T} chave Chave a ser verificada no objeto
 * @returns {objeto is Record<T, unknown>} Retorna se a chave existe no objeto
 */
export function chaveExisteEm(objeto, chave) {
  return chave in objeto;
}

/**
 * @function
 * @param {string} str String para transformar
 * @returns {string} String capitalizada
 *
 * Função que capitaliza a prtimeira letra da string recebida
 */
export function capitalize(str) {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
}

/**
 * @function
 * @param {object} o Objeto para verificar. Pode ser qualquer objeto javascript
 * @returns {boolean} Se o objeto possui valores armazenados
 *
 * Função que retorna se um objeto está vazio ou não.
 */
export function emptyObject(o) {
  return Object.keys(o).length === 0;
}

/**
 * @function
 * @param {number | string} a Primeiro valor
 * @param {number | string} b Segundo valor
 * @returns {number | string}
 *
 * Função que recebe dois valores para comparação e retorna o menor valor.
 */
export const lowest = (a, b) => {
  if (a === undefined && b !== undefined) return b;
  if (a !== undefined && b === undefined) return a;
  return a < b ? a : b;
};

/**
 * @function
 * @param {number | string} a Primeiro valor
 * @param {number | string} b Segundo valor
 * @returns {number | string}
 *
 * Função que recebe dois valores para comparação e retorna o maior valor.
 */
export const highest = (a, b) => {
  if (a === undefined && b !== undefined) return b;
  if (a !== undefined && b === undefined) return a;
  return a > b ? a : b;
};

/**
 * @function
 * @param {number} number Número para abreviar
 * @returns {string} String do número abreviado
 *
 * Recebe um número para transformar em string e abreviar seu valor
 * Faz abreviação de números grandes com:
 *  - 1000 -> 1mil
 *  - 1000000 -> 1M
 *  - 1000000000 - 1B
 *  - 1000000000000 - 1T
 */
export const numberAbbreviation = (number) => {
  if (number >= 1e3 && number < 1e6) return `${+(number / 1e3).toFixed(1)}mil`;
  if (number >= 1e6 && number < 1e9) return `${+(number / 1e6).toFixed(1)}M`;
  if (number >= 1e9 && number < 1e12) return `${+(number / 1e9).toFixed(1)}B`;
  if (number >= 1e12) return `${+(number / 1e12).toFixed(1)}tri`;

  return number.toLocaleString('pt-BR');
};

/**
 * Essa função recebe uma cor no formato hexadecimal
 * e converte para o formato RGB
 * @param string hex Cor no formato '#ff1122'
 * @returns Object{
 *  r: number,
 *  g: number,
 *  b: number
 * }
 */
export function hexaParaRgb(hexaCor) {
  const bigint = Number.parseInt(hexaCor.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

/**
 * @function
 * @param {string} color Cor em hex ou rgb para análise
 * @returns {number} Número que representa claridade da cor
 *
 * Retorna um valor que representa o quão escuro ou claro é uma cor.
 * O resultado estará no intervalo de 0 a 255,
 * onde 0 é mais escuro e 255 mais claro. Valor médio é 128
 */
export const getBrightness = (color) => {
  if (color[0] === '#') {
    const { r, g, b } = hexaParaRgb(color);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // calculo de claridade
  }
  const valuesInStr = color.match(/[0-9.]+/g);
  const [r, g, b] = valuesInStr;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b; // calculo de claridade
};

/**
 * @function
 * @param {string[]} colors conjunto de cores em hex/rgb para análise
 * @returns {number} Número que representa claridade média entre as cores
 *
 * Essa função faz a média de claridade de um conjunto de cores em hex.
 * Resultado estará entre 0 e 255.
 */
export const getMeanBrightness = (colors) => {
  const colorsBrightness = colors.map(getBrightness);
  const sum = colorsBrightness.reduce((total, brightness) => total + brightness, 0);
  return sum / colorsBrightness.length;
};
