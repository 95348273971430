import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const ControlButton = styled.div`
  & {
    display: flex;
    border: 1px solid;
  }
`;

export const Expander = styled(AddIcon)`
  &.MuiSvgIcon-root {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`;

export const Collapser = styled(RemoveIcon)`
  &.MuiSvgIcon-root {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`;
