import Resource from './Resource';

export default class GetCityDescriptionResource extends Resource {
  constructor(token, id) {
    super(token);
    this.id = id;
  }

  async result() {
    try {
      const { data, status } = await this.request.get(`/cidades/${this.id}`);
      const {
        dados_demograficos: demographicData,
        dados_eventos: eventsData,
        resumo_perfil: summaryData,
      } = data.dados;

      if (status === 200) {
        return {
          id: data.id_cidade,
          name: data.nome,
          region: data.id_regiao,
          diocese: data.id_diocese,
          bacia: data.id_bacia,
          admRegion: data.id_regiao_adm,
          data: {
            demographic: {
              population: demographicData.populacao,
              voters: demographicData.qtde_eleitores,
              votes: demographicData.votos_validos,
            },
            events: eventsData && (
              Object.entries(eventsData)
                .reduce((o, [event, data]) => ({ ...o, [event]: data.total }), {})
            ),
            profile: {
              colors: summaryData.cores_por_ano,
              registrations: summaryData.cadastros,
            }
          },
        };
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
    return {};
  }
}
