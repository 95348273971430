import styled from 'styled-components';

export const FlexRowFormContainer = styled.div`
  & {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
`;

export const GridFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.minmax ? props.minmax : '240px, 1fr'}));
  grid-gap: 10px;
  margin-bottom: 10px;
`;

export const WarningContainer = styled.span`
  & {
    color: darkred;
    font-size: 14px;
    margin: 8px 0;
  }
`;
