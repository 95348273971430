import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef
} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../styles.css';

import { POLITICS_COLS } from '../../../../assets/constants';

import {
  rowCellBodyTemplate,
  rowCellColorBodyTemplate,
  multiselectItemTemplate,
  multiselectColorItemTemplate,
} from '../assets/templates';

function PoliticsTable(props) {
  const { rows } = props;

  const [corOptions, setCorOptions] = useState([]);
  const [cargoOptions, setCargoOptions] = useState([]);
  const [partidoOptions, setPartidoOptions] = useState([]);
  const [projetoOptions, setProjetoOptions] = useState([]);
  const [situacaoOptions, setSituacaoOptions] = useState([]);

  const [selectedCor, setSelectedCor] = useState([]);
  const [selectedCargo, setSelectedCargo] = useState(null);
  const [selectedPartido, setSelectedPartido] = useState(null);
  const [selectedProjeto, setSelectedProjeto] = useState(null);
  const [selectedSituacao, setSelectedSituacao] = useState(null);

  const dt = useRef(null);

  // useEffect impede conflito de opções do multiselect na mudança de abas/anos
  // useEffect(() => {
  //   if (currentTab) {
  //     setSelectedCor(null);
  //     setSelectedCargo(null);
  //     setSelectedPartido(null);
  //     setSelectedProjeto(null);
  //     setSelectedSituacao(null);
  //     dt.current.reset();
  //   }
  // }, [currentTab]);

  /**
   * Este useEffect pega as informações que estao contidas na tabela
   * para criar arrays de opçoes para os filtros de multiselect
   * existentes na tabela escolhido em InitialPage
   * Cor, Cargo, Partido e Projeto são comuns a todas as tabelas
   * Quando existe Situação, suas opções também são preenchidas
   */
  useEffect(() => {
    if (rows.length > 0) {
      const [first] = rows;
      if (first.Cor) {
        const array = rows.map((item) => (item.Cor.idcor || ''));
        const values = new Set(array);
        const colors = Array.from(values).map(id => (
          rows.map((item) => (item.Cor))
            .find(({ idcor }) => idcor === id) || { nome: 'Sem Cor' }
        ));
        setCorOptions(colors);
      }
      setCargoOptions([
        ...new Set(rows.map((item) => item.Cargo))
      ]);
      setPartidoOptions([
        ...new Set(rows.map((item) => item.Partido))
      ]);
      setProjetoOptions([
        ...new Set(rows.map((item) => item.Projeto))
      ]);
      if (first.Situação) {
        setSituacaoOptions([
          ...new Set(rows.map((item) => item.Situação))
        ]);
      }
    }
  }, [rows]);

  const onMultiSelectChange = (e, column) => {
    dt.current.filter(e.value, column, 'in');
    if (column === POLITICS_COLS.color) {
      setSelectedCor(e.value);
    } else if (column === POLITICS_COLS.role) {
      setSelectedCargo(e.value);
    } else if (column === POLITICS_COLS.party) {
      setSelectedPartido(e.value);
    } else if (column === POLITICS_COLS.project) {
      setSelectedProjeto(e.value);
    } else if (column === POLITICS_COLS.situation) {
      setSelectedSituacao(e.value);
    }
  };

  const getFilterType = useCallback((key) => {
    if (
      key === POLITICS_COLS.name
      || (POLITICS_COLS.votes).includes(key)
      || key === POLITICS_COLS.observation
    ) {
      return 'Text';
    }

    if ([
      POLITICS_COLS.color,
      POLITICS_COLS.role,
      POLITICS_COLS.party,
      POLITICS_COLS.project,
      POLITICS_COLS.situation,
    ].includes(key)) {
      return 'MultiSelect';
    }

    return '';
  }, []);

  const getKeyVariables = useCallback((key) => {
    if (key === POLITICS_COLS.color) {
      return [selectedCor, corOptions];
    }

    if (key === POLITICS_COLS.role) {
      return [selectedCargo, cargoOptions];
    }

    if (key === POLITICS_COLS.party) {
      return [selectedPartido, partidoOptions];
    }

    if (key === POLITICS_COLS.project) {
      return [selectedProjeto, projetoOptions];
    }

    if (key === POLITICS_COLS.situation) {
      return [selectedSituacao, situacaoOptions];
    }

    return [];
  }, [
    cargoOptions,
    corOptions,
    partidoOptions,
    projetoOptions,
    selectedCargo,
    selectedCor,
    selectedPartido,
    selectedProjeto,
    selectedSituacao,
    situacaoOptions,
  ]);

  const getFilterElement = useCallback((key, type) => {
    const [value, options] = getKeyVariables(key);
    if (type === 'MultiSelect') {
      return (key !== POLITICS_COLS.color)
        ? (
          <MultiSelect
            className="p-column-filter"
            value={value}
            options={options}
            onChange={(e) => onMultiSelectChange(e, key)}
            itemTemplate={multiselectItemTemplate}
            placeholder="Todos"
          />
        )
        : (
          <MultiSelect
            className="p-column-filter"
            value={value}
            options={options}
            optionLabel={(option) => option.nome}
            onChange={(e) => onMultiSelectChange(e, key)}
            itemTemplate={multiselectColorItemTemplate}
            placeholder="Todas"
          />
        );
    }
    return <></>;
  }, [getKeyVariables]);

  const columns = useMemo(() => {
    const [first] = rows;
    if (!first) {
      return [
        <Column />
      ];
    }
    return Object.keys(first).map((key) => {
      const filterType = getFilterType(key);
      const filterElement = getFilterElement(key, filterType);
      return (filterType !== 'Text')
        ? (
          <Column
            field={key}
            header={key}
            body={(rowData) => (
              (key !== POLITICS_COLS.color)
                ? rowCellBodyTemplate(key, rowData)
                : rowCellColorBodyTemplate(rowData)
            )}
            filter
            filterElement={filterElement}
            sortable
            sortField={(rowData) => (
              (key === POLITICS_COLS.color && rowData.Cor.nome)
                ? rowData.Cor.nome
                : rowData[key]
            )}
          />
        ) : (
          <Column
            field={key}
            header={key}
            body={(rowData) => rowCellBodyTemplate(key, rowData)}
            filter
            filterPlaceholder={
              (POLITICS_COLS.votes.includes(key)) // colunas de número (votos)
                ? 'Mínimo'
                : (key === POLITICS_COLS.observation)
                  // coluna observação
                  ? 'Filtro por Observação'
                  // qualquer coluna de nome
                  : 'Filtro por Nome'
            }
            filterMatchMode={(POLITICS_COLS.votes.includes(key)) ? 'gte' : 'contains'}
            filterMaxLength={(POLITICS_COLS.votes.includes(key)) ? 10 : 30}
            sortable
          />
        );
    });
  }, [getFilterElement, getFilterType, rows]);

  return (
    <div className="datatable-div">
      <div className="card">
        <DataTable
          ref={dt}
          value={rows}
          rows={rows.length}
          className="p-datatable-component"
          scrollable
          removableSort
          emptyMessage="Não há itens a serem listados."
        >
          {columns}
        </DataTable>
      </div>
    </div>
  );
}

export default PoliticsTable;
