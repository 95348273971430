import React, {
  useContext,
  useMemo,
} from 'react';

import TextFieldsIcon from '@material-ui/icons/TextFields';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ButtonControl } from '../styles';

import {
  MapContext,
} from '../../../../contexts';

function FontSizeAdjust() {
  const {
    increaseFontSize,
    resetMapText,
    showNames,
    showNumbers,
  } = useContext(MapContext);

  const isVisible = useMemo(() => showNames || showNumbers, [showNames, showNumbers]);

  return (
    <ButtonControl
      top={20}
      right={isVisible ? 0 : -210}
      style={{ transition: `right 200ms ${isVisible ? 'ease-out' : 'ease-in'}` }}
    >
      <Tooltip title="Tamanho de fonte">
        <TextFieldsIcon
          style={{
            marginRight: 10,
            color: '#444',
            fontSize: 24,
          }}
        />
      </Tooltip>

      <Tooltip title="Aumentar tamanho da fonte">
        <IconButton
          onClick={() => increaseFontSize(0.4)}
          style={{ padding: 10 }}
        >
          <AddIcon style={{ fontSize: 14 }} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Aplicar tamanho padrão">
        <IconButton
          onClick={resetMapText}
          style={{ padding: 10 }}
        >
          <span style={{ fontSize: 16 }}>Padrão</span>
        </IconButton>
      </Tooltip>

      <Tooltip title="Diminuir tamanho de fonte">
        <IconButton
          onClick={() => increaseFontSize(-0.4)}
          style={{ padding: 10 }}
        >
          <RemoveIcon style={{ fontSize: 16, padding: 2 }} />
        </IconButton>
      </Tooltip>
    </ButtonControl>
  );
}

export default FontSizeAdjust;
