import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { HiddenSpan } from '../styles';

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headers
  } = props;

  function createSortHandler(property) {
    return (event) => onRequestSort(event, property);
  }

  return (
    <TableHead>
      <TableRow>
        {
          headers.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align || 'left'}
              padding="default"
              sortDirection={orderBy === headCell.label ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.label}
                direction={order}
                onClick={createSortHandler(headCell.label)}
                style={{ minWidth: 20, whiteSpace: 'nowrap' }}
              >
                {headCell.label}
                {
                  orderBy === headCell.label && (
                    <HiddenSpan>
                      {
                        order === 'desc'
                          ? 'Ordenar de modo decrescente'
                          : 'Ordenar de modo crescente'
                      }
                    </HiddenSpan>
                  )
                }
              </TableSortLabel>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
