import React, {
  memo,
  useContext,
} from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AppContext } from '../../contexts';

import ColorlessFilterAlert from './components/ColorlessFilterAlert';

import { Container } from './styles';

const AuxiliarBox = memo(() => {
  const { auxiliarBox } = useContext(AppContext);

  return (
    <Container className={`auxiliar-box ${auxiliarBox.state}`}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
            }}
          >
            {auxiliarBox.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginBottom: '8px' }}>
            {
              auxiliarBox.description.map((item) => (
                <Typography
                  key={`item-${item.toLowerCase()}`}
                  style={{ fontSize: 11, maxWidth: 200, marginBottom: 2 }}
                >
                  {item}
                </Typography>
              ))
            }
          </div>
          <div>
            {auxiliarBox.content}
          </div>
        </AccordionDetails>
      </Accordion>
      <ColorlessFilterAlert />
    </Container>
  );
});

export default memo(AuxiliarBox);
