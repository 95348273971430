import styled from 'styled-components';

export const MapContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const ZoomArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: rgba(10, 141, 255, 0.2);
  display: none;
  border: 2px solid rgb(10,141,255);
  z-index: 9999;
`;