import styled from 'styled-components';

export const AlertContainer = styled.div`
  && {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 5%;
    right: 5%;
    height: 45px;
    width: 90%;
    z-index: -1;
    padding: 10px 0 2px;

    transition-property: bottom, opacity;
    transition-timing-function: ease-out;
    transition-delay: 0s, 500ms;

    &.visible {
      bottom: -55px;
      transition-duration: 1s;
      opacity: 1;
    }

    &.hidden {
      bottom: 2px;
      transition-duration: .5s;
      opacity: 0;
    }

    .MuiButton-root {
      padding: 2px 6px;
    }
  }
`;
