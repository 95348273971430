/**
 * Possíveis status selecionado para exibição de dados no DialogCity
 */
export const STATUS = {
  ELECTED: 'eleitos',
  PRE_CANDIDATES: 'pré-candidatos',
  CANDIDATES: 'candidatos',
  AMENDMENTS: 'emendas',
  PEOPLE_GREEN: 'people-green',
  PEOPLE_YELLOW: 'people-yellow',
  PEOPLE_RED: 'people-red',
  PEOPLE_SVN: 'people-svn',
  PEOPLE_EFL: 'people-efl',
  PEOPLE_DYNAMO: 'people-dynamo',
  PEOPLE_VOLUNTEER: 'people-volunteer',
};

/**
 * Arrays de status agrupados da forma que devem
 * aparecer nos detalhes de cidades
 * A ordem dos arrays definem a ordem
 * que os itens são apresentados no agrupamento.
 */
export const STATUS_GROUPS = {
  EVENTS: [STATUS.PEOPLE_EFL, STATUS.PEOPLE_SVN],
  COLORS: [STATUS.PEOPLE_GREEN, STATUS.PEOPLE_YELLOW, STATUS.PEOPLE_RED],
  POLITICS: [STATUS.AMENDMENTS, STATUS.CANDIDATES, STATUS.PRE_CANDIDATES, STATUS.ELECTED],
  PROFILES: [STATUS.PEOPLE_DYNAMO, STATUS.PEOPLE_VOLUNTEER],
};

/**
 * Mapeamento dos labels das colunas para tabela de dados políticos do DialogCity
 */
export const POLITICS_COLS = {
  name: 'Nome',
  role: 'Cargo',
  party: 'Partido',
  project: 'Projeto',
  situation: 'Situação',
  votes: ['Votos', 'Votos Previstos', 'Votos Obtidos'],
  color: 'Cor',
  observation: 'Observação',
};

/**
 * Mapeamento dos labels das colunas para tabela de Emendas do DialogCity
 */
export const AMENDMENTS_COLS = {
  year: 'Ano',
  situation: 'Situação',
  organ: 'Orgão',
  object: 'Objeto',
  leader: 'Mandatário',
  proponent: 'Proponente',
  amount: 'Valor Repasse',
};

/**
 * Mapeamento dos labels das colunas possíveis para as tabelas de Pessoas do DialogCity
 * Vale para qualquer STATUS com prefixo PEOPLE/people
 */
export const PEOPLE_COLS = {
  name: 'Nome',
  nickname: 'Apelido',
  color: 'Cor',
  EFL: 'EFL',
  SVN: 'SVN',
  details: 'Detalhes',
};

export const ROLE_MAPPER = {
  dep_federal: 'Deputado Federal',
  dep_estadual: 'Deputado Estadual',
  senador: 'Senador',
  governador: 'Governador',
  presidente: 'Presidente',
  vereador: 'Vereador',
  prefeito: 'Prefeito'
};
