import React, { forwardRef } from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';

import { StyledDialog } from './styles';

/**
 * Componente `Paper` para componente `Dialog`
 *
 * @param {import('@material-ui/core').PaperProps} props
 * @returns {JSX.Element} Componente `Paper` com Drag
 */
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel="[class*='MuiDialogContent-root']"
    >
      <Paper {...props} />
    </Draggable>
  );
}
/**
 * @typedef {"root" | "content" | "content" | "actions" | "closeButton" | "back" | "backPaper"} DialogTitleClassKeys
 *
 * @type {Styles<DialogTitleProps, DialogTitleClassKeys>}
 */
const styles = (theme) => ({
  root: {},
  content: {},
  actions: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  back: {},
  backPaper: {},
});
/**
 * @typedef {object} ClassesProp
 * @prop {CSSProps} root CSS da classe "root"
 * @prop {CSSProps} content CSS da classe "content"
 * @prop {CSSProps} actions CSS da classe "actions"
 * @prop {CSSProps} closeButton CSS da classe "closeButton"
 * @prop {CSSProps} back CSS da classe "back"
 * @prop {CSSProps} backPaper CSS da classe "backPaper"
 */
/**
 * @typedef {object} DialogTitleProps
 * @prop {() => void} backAction
 * Callback para função voltar do Dialogo
 * @prop {ClassesProp} classes
 * Objeto de overwrite das classes do componente
 * @prop {() => void} onClose
 * Callback para função de fechar o Dialogo
 * @prop {boolean} hideCloseButton
 * Booleano que indica se o botao de fechar está visível
 */
/**
 * Componente de barra de titúlo do Diálogo
 * Possui os botoes de voltar e fechar do Dialogo além do título
 */
const DialogTitle = withStyles(styles)(
  /**
   * @param {DialogTitleProps} props
   * @returns {JSX.Element} Componente de Barra de Título do Dialogo
   */
  (props) => {
    const {
      backAction,
      children,
      classes,
      onClose,
      hideCloseButton,
      ...other
    } = props;

    return (
      <MuiDialogTitle
        disableTypography
        className={classes.root}
        {...other}
        style={{
          cursor: 'move',
          padding: '25px 25px 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {
            backAction && (
              <IconButton
                style={{ padding: 4, marginRight: 5 }}
                onClick={backAction}
              >
                <ArrowBackIcon
                  style={{ color: '#444', cursor: 'pointer' }}
                />
              </IconButton>
            )
          }
          <Typography
            id="draggable-dialog-title"
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              width: '85%',
            }}
          >
            {children}
          </Typography>
        </div>
        {
          !hideCloseButton
            ? (
              <IconButton
                id="#close-floating-dialog"
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  onClose();
                  document.activeElement.blur();
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : <></>
        }
      </MuiDialogTitle>
    );
  }
);

/**
 * @typedef {object} FloatingDialogProps
 * @prop {React.ReactNode} [actions] Ações do Diálogo
 * @prop {React.ReactNode} [children]
 * Árvore de descendentes do conteúdo
 * @prop {() => void} [backAction]
 * Callback passado para o componente `DialogTitle`
 * para a função do botão "Voltar"
 * @prop {ClassesProp} [classes]
 * Objeto de overwrite das classes do componente
 * @prop {boolean} [disableBackdropClick]
 * Booleano que define se o clique no backdrop deve fechar ou não o Diálogo.
 * Quando `true` o clique não fechará o Diálogo
 * @prop {CSSProp["height"]} [height]
 * Propriedade `height` de estilo passado para a caixa de conteúdo do Diálogo
 * @prop {boolean} [hideBackdrop]
 * Booleano que define se o backdrop deve aparecer ou não
 * @prop {() => void} onClose
 * Callback passado para o componente `DialogTitle`
 * para a função do botão "Fechar"
 * @prop {React.Ref<HTMLDivElement>} [ref]
 * Referência à caixa de conteúdo do diálogo.
 * @prop {boolean} show
 * Booleano que define visibilidade do Diálogo
 * @prop {boolean} [hideCloseButton]
 * Booleano que define visibilidade do botão de "Fechar" do `DialogTitle`
 * @prop {CSSProps} [style]
 * Proppriedades CSS passadas para a caixa de conteúdo do Diálogo
 * @prop {React.ReactNode} title
 * Node passado para como `children` para `DialogTitle`, geralmente uma string
 * @prop {CSSProps["width"]} [width]
 * Propriedade `width` de estilo passado para a caixa de conteúdo do Diálogo
 */
const FloatingDialog = withStyles(styles)(
  forwardRef(
  /**
   * @param {FloatingDialogProps} props
   * @param {React.ForwardedRef<HTMLDivElement>} ref
   */
    (props, ref) => {
      const {
        actions,
        backAction,
        children,
        classes = {},
        disableBackdropClick,
        height,
        hideBackdrop,
        onClose,
        show,
        hideCloseButton = false,
        style = {},
        title,
        width,
      } = props;

      return (
        <StyledDialog
          ref={ref}
          open={!!show}
          onClose={(e, reason) => {
            if (disableBackdropClick && reason === 'backdropClick') return;
            onClose();
          }}
          scroll="paper"
          maxWidth={false}
          keepMounted
          hideBackdrop={hideBackdrop}
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            id="customized-dialog-title"
            {...{ hideCloseButton, onClose, backAction }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            className={classes.content}
            style={{ height, width, ...style }}
          >
            {children}
          </DialogContent>
          <DialogActions>
            {actions}
          </DialogActions>
        </StyledDialog>
      );
    }
  )
);

/**
 * Componente de Diálogo estilizada.
 *
 * É dividida em `DialogTitle`, `DialogContent` e `DialogActions`
 * `DialogTitle` funciona como um header do Diálogo e possui o título e os botões de "Voltar" e "Fechar".
 *
 * `DialogContent` é uma caixa de conteúdo do Diálogo e possui a árvore de Nodes passada no componente
 *
 * `DialogActions` é o componente de [ações do Diálogo](https://v4.mui.com/pt/api/dialog-actions/)
 *
 * @param {React.ReactNode} actions Ações do Diálogo
 * @param {() => void} backAction
 * Callback passado para o componente `DialogTitle`
 * para a função do botão "Voltar"
 * @param {ClassesProp} classes
 * Objeto de overwrite das classes do componente
 * @param {boolean} disableBackdropClick
 * Booleano que define se o clique no backdrop deve fechar ou não o Diálogo.
 * Quando `true` o clique não fechará o Diálogo
 * @param {number | string} height
 * Propriedade de estilo passado para a caixa de conteúdo do Diálogo
 * @param {boolean} hideBackdrop
 * Booleano que define se o backdrop deve aparecer ou não
 * @param {() => void} onClose
 * Callback passado para o componente `DialogTitle`
 * para a função do botão "Fechar"
 * @param {boolean} show
 * Booleano que define visibilidade do Diálogo
 * @param {boolean} hideCloseButton
 * Booleano que define visibilidade do botão de "Fechar" do `DialogTitle`
 * @param {CSSProps} style
 * Proppriedades CSS passadas para a caixa de conteúdo do Diálogo
 * @param {React.ReactNode} title
 * Node passado para como `children` para `DialogTitle`, geralmente uma string
 */
export default FloatingDialog;
