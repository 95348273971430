function aggregateCompoundLabel(compound) {
  return Array.from(compound.filters).sort((a) => a.filter.type === 'Eventos' ? -1 : 1)
    .map((f) => {
      const [type, name, ...params] = f.label; // eslint-disable-line
      if (type === 'EVENTOS') {
        return `${name} ${params.at(0)}`;
      }

      return params.at(0);
    })
    .join(', ');
}

export function compoundToString(item) {
  return item.type ? `${item.type.toUpperCase()}, ${aggregateCompoundLabel(item)}` : `Filtro composto ${item.id}`;
}
