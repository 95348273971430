import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import { CaixaSeletorDeCor } from '../../../styles';

export const SeletorDeCorEstilizado = styled(CaixaSeletorDeCor)`
  & {
    margin-left: 10px;
  }
`;

/**
 * @typedef {object} CollapseLabelProps
 * @prop {boolean} intervalExists
 * @prop {boolean} itemExists
 * @prop {boolean} disable
 */
/**
 * @type {StyledComponent<typeof Typography, CollapseLabelProps>}
 */
export const CollapseLabel = styled(Typography)`
  && {
    margin: 0 3px;
    width: max-content;
    font-size: 11px;

    display: ${({ intervalExists }) => intervalExists ? 'none' : 'block'};
    opacity: ${({ itemExists, disable }) => !itemExists || disable ? '0.6' : undefined};

    ${({ itemExists, disable }) => (
    (itemExists && !disable)
      ? `
      color: rgb(32, 112, 255);
      text-decoration: underline rgb(12, 92, 235);
      cursor: pointer;
    ` : `
      text-decoration: none;
      cursor: default;
    `)};

    &:hover {
      ${({ itemExists, disable }) => (
    (itemExists && !disable)
      ? `
        color: rgb(10, 170, 255);
        text-decoration: underline rgb(32, 112, 255);
      ` : `
        text-decoration: none;
      `)};
    }
  }
`;

export const IntervalContainer = styled.div`
  && {
    margin: 0 3px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.09);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .MuiCollapse-wrapperInner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }

`;
