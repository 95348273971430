import styled from 'styled-components';

import { FlexContainer } from '../../../../../../../';

export const TableListItem = styled(FlexContainer)`
  && {
    margin: 2px 0;

    & > p {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const TableTagsContainer = styled(FlexContainer)`
  && {
    padding: 2px;
  }
`;