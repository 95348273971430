import styled from 'styled-components';

export const FilterTab = styled.div`
  box-sizing: border-box;

  padding: 25px 20px;
  margin-bottom: 20px;

  width: 100%;

  background-color: #f2f2f2;
`;
